export const NOTIFICATION_ICONS = {
  danger: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 6.66675V10.0001M10.0003 13.3334H10.0087M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
        stroke="#FF424E"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  delete: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 5.25L5.25 12.75M5.25 5.25L12.75 12.75"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  info: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57533 7.50008C7.77125 6.94314 8.15795 6.4735 8.66695 6.17436C9.17596 5.87521 9.77441 5.76586 10.3563 5.86567C10.9382 5.96549 11.466 6.26802 11.8462 6.71969C12.2264 7.17136 12.4345 7.74302 12.4337 8.33342C12.4337 10.0001 9.93366 10.8334 9.93366 10.8334M10.0003 14.1667H10.0087M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
        stroke="#1A94FF"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  success: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25033 10.0001L8.75033 12.5001L13.7503 7.50008M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
        stroke="#00AB56"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  warning: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 7.49995V10.8333M10.0001 14.1666H10.0085M8.84622 3.24305L1.99215 15.0819C1.61198 15.7386 1.42189 16.0669 1.44999 16.3364C1.47449 16.5714 1.59763 16.785 1.78876 16.924C2.0079 17.0833 2.38728 17.0833 3.14605 17.0833H16.8542C17.613 17.0833 17.9923 17.0833 18.2115 16.924C18.4026 16.785 18.5258 16.5714 18.5503 16.3364C18.5783 16.0669 18.3883 15.7386 18.0081 15.0819L11.154 3.24305C10.7752 2.58875 10.5858 2.26159 10.3387 2.15172C10.1232 2.05587 9.87709 2.05587 9.66154 2.15172C9.41443 2.26159 9.22503 2.58875 8.84622 3.24305Z"
        stroke="#FF9F41"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
