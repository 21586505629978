import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../common/button";
import useCreateUserManagement from "../../hooks/useCreateUserManagement";
import { PATH } from "../../../../const/path";
import { useTranslation } from "react-i18next";
import { DISPLAY_NAME_MENU } from "../../../../const/display_name_menu";

export const ActionFormBtnList = () => {
    const { t } = useTranslation()
    const { functions, canSubmit, canSubmitUpdate } = useCreateUserManagement()
    const navigate = useNavigate()
    const { userId } = useParams()
    return (
        <>
            <Button appearance="ghost" onClick={() => navigate(PATH.USER_MANAGEMENT)}>
                {t(DISPLAY_NAME_MENU.GENERAL.CANCEL)}
            </Button>
            <Button onClick={() => functions.submit()} style={{ marginLeft: 12 }} disabled={!!!userId ? canSubmit : canSubmitUpdate}>
                {t(DISPLAY_NAME_MENU.GENERAL.SAVE)}
            </Button>
        </>
    )
}