import {PATH} from "../../../../../const/path";

export const CREATE_TEACHER_BREARDCUM=[
    {id: 1, name: 'Trang chủ', url: PATH.ADMIN_DASHBOAR},
    {id: 2, name: 'Quản lý giáo viên', url: '#'},
    {id: 3, name: 'Thêm mới giáo viên ', url: '#'}
]
export const EDIT_TEACHER_BREARDCUM=[
    {id: 1, name: 'Trang chủ', url: PATH.ADMIN_DASHBOAR},
    {id: 2, name: 'Quản lý giáo viên', url: '#'},
    {id: 3, name: 'Chỉnh sửa giáo viên ', url: '#'}
]
export const CREATE_TEACHER_FORM = [
    'userName',
    'schoolName',
    'phone',
    'email',
    'name',
    'lastName',
    'birthdate',
    'identify',
    'gender',
    'address',
    'password',
    'statusPerson',
    'accountNumber',
    'accountOwner',
    'nameBank'
]
export const CREATE_TEACHER_GENDER=[
    {name: 'Nam', value: 'male'},
    {name: 'Nữ', value: 'female'},
    {name: 'Khác', value: 'other'},
]
export const STATUS_TEACHER = [
    {id: 1, title: 'Đang hoạt động', value: 'active'},
    {id: 2, title: 'Đang khóa', value: 'inactive'},
]

export const TAB_CREATE_TEACHER_EDIT = [
    {name: 'Thông tin giáo viên', tab:'teacher'},
    {name: 'Sản phẩm bán', tab:'products'},
]

export const TEACHER_TABLE_HEADER =[
    {name: 'Tên đăng nhập', id: 1},
    {name: 'Email', id: 2},
    {name: 'Tên giáo viên', id: 3},
    {name: 'Ngày tạo', id: 4},
    {name: 'Trạng thái', id: 5},
]


export const PRODUCT_TABLE_HEADER =[
    {name: 'Tên sản phẩm', id: 1},
    {name: 'Giá bán', id: 2},
    {name: 'Giá khuyến mãi', id: 3},
    {name: 'Thời hạn bản quyền', id: 4},
]
