import useGlobalContext from "../../containerContext/storeContext";
import {publicRoutes} from "../../routes";
import {PATH} from "../../const/path";

export const LinkPage = (route, state) => {

    const notPermission = publicRoutes.find(item => item.path === PATH.NOT_PERMISSION).component
    const {user} = state
    const userType = user?.user_type
    if ((route?.prefix === "teacher" && +userType === 3) || (route?.prefix === "principal" && +userType === 2))
        return route.component
    return route?.prefix === 'publish' ? route.component : notPermission
}

export const checkPermission = () => {
    const [state,] = useGlobalContext()
    const {user} = state
    const {permission} = user

    if (+user?.is_manage === 1) return true

    //tiếp tục check permission

    return true
}