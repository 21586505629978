import { Button } from 'common/button'
import { Text } from 'common/text'
import { StyledInvoiceEmpty } from './_styled'
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const InvoiceEmpty = ({ ...props }) => {
  return (
    <StyledInvoiceEmpty {...props}>
      <img
        className="invoice-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}> Không tìm thấy dữ liệu </Text>
    </StyledInvoiceEmpty>
  )
}
