import {useContext, useState} from "react";
import {CreateTeacherContext} from "../provider/_context";
import {useCreateTeacherAction} from "../provider/_reducer";
import {
    convertDateTimeToApiFormatV2,
    formatDatetimeV2
} from "../../../../../common/form/datePicker/_functions";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import useAlert from "../../../../../hook/useAlert";
import {useNavigate, useParams} from "react-router-dom";
import {PATH} from "../../../../../const/path";
import {removeVietnameseTones} from "../../../../../util/checkPasswordVN";

export const useCreateTeacherForm = () => {
    const {pageState, pageDispatch} = useContext(CreateTeacherContext)
    const {information} = pageState

    const {showAlert} = useAlert()
    const navigate = useNavigate()

    const [typePassword, setTypePassword] = useState('password')
    const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')


    const handleChangeTypePassword = () => setTypePassword(typePassword === 'password' ? 'text' : 'password')
    const handleChangeTypeConfirmPassword = () => setTypeConfirmPassword(typeConfirmPassword === 'password' ? 'text' : 'password')
    //=======USER NAME========
    const valueUserName = information.userName?.value
    const statusUserName = information.userName?.status
    const messageUserName = information.userName?.message

    const handleChangeUserName = data => {
        const userNameFormat = data.replace(/[^\w\s]/gi, '').replaceAll(' ', '')
        pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                userName: {
                    value: userNameFormat.trim(),
                    status: false,
                    message: ''
                }
            }
        })
    }
    const handleBlurUserName = data => {
        if (!!!data) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                userName: {
                    value: data,
                    status: true,
                    message: 'Tên đăng nhập không được để trống'
                }
            }
        })
        else if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                userName: {
                    value: data,
                    status: true,
                    message: 'Tên đăng nhập không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    userName: {
                        value: data,
                        status: false,
                        message: ''
                    }
                }
            })
    }

    //=======SCHOOL NAME========
    const valueSchoolName = information.schoolName?.value
    const statusSchoolName = information.schoolName?.status
    const messageSchoolName = information.schoolName?.message

    const handleChangeSchoolName = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            schoolName: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurSchoolName = data => {
        if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                schoolName: {
                    value: data,
                    status: true,
                    message: 'Tên trường không được phép vượt quá 200 ký tự'
                }
            }
        })

        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                schoolName: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }


    //=======Phone========
    const valuePhone = information.phone?.value
    const statusPhone = information.phone?.status
    const messagePhone = information.phone?.message

    const handleChangePhone = data => {
        const regexNumb = /^(|[0-9]\d*)$/
        if (regexNumb.test(data) || data === '') pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                phone: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }
    const handleBlurPhone = data => {
        if (data?.length > 11 || data?.length < 10 && data?.length > 0) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                phone: {
                    value: data,
                    status: true,
                    message: 'Số điện thoại chỉ được phép nhập 10, 11 ký tự.'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                phone: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }


    //=======Email========
    const valueEmail = information.email?.value
    const statusEmail = information.email?.status
    const messageEmail = information.email?.message

    const handleChangeEmail = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            email: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurEmail = data => {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (!!!data)
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    email: {
                        value: data,
                        status: true,
                        message: 'Email không được để trống'
                    }
                }
            })
        else if (!regex.test(data) && data !== '') pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                email: {
                    value: data,
                    status: true,
                    message: 'Email phải nhập theo example@gmail.com'
                }
            }
        })
        else if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                email: {
                    value: data,
                    status: true,
                    message: 'Email được phép nhập 200 ký tự.'
                }
            }
        })
        else pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    email: {
                        value: data,
                        status: false,
                        message: ''
                    }
                }
            })
    }

    //=======NAME========
    const valueName = information.name?.value
    const statusName = information.name?.status
    const messageName = information.name?.message

    const handleChangeName = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            name: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurName = data => {
        if (!!!data) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                name: {
                    value: data,
                    status: true,
                    message: 'Tên giáo viên không được để trống'
                }
            }
        })
        else if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                name: {
                    value: data,
                    status: true,
                    message: 'Tên giáo viên không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    name: {
                        value: data,
                        status: false,
                        message: ''
                    }
                }
            })
    }


    //=======last name========
    const valueLastName = information.lastName?.value
    const statusLastName = information.lastName?.status
    const messageLastName = information.lastName?.message

    const handleChangeLastName = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            lastName: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurLastName = data => {
        if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                lastName: {
                    value: data,
                    status: true,
                    message: 'Họ giáo viên không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                lastName: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }

    //=======Birthday========
    const valueBirthday = information.birthday?.value
    const statusBirthday = information.birthday?.status
    const messageBirthday = information.birthday?.message

    const handleChangeBirthday = data => {
        const dateTime = formatDatetimeV2(data)
        pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                birthday: {
                    value: dateTime,
                    status: false,
                    message: ''
                }
            }
        })
    }


    //=======Identify========
    const valueID = information.identify?.value
    const statusID = information.identify?.status
    const messageID = information.identify?.message

    const handleChangeID = data => {
        const regexNumb = /^(|[0-9]\d*)$/
        if (regexNumb.test(data)) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                identify: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }
    const handleBlurID = data => {
        const regexNumb = /^(|[0-9]\d*)$/
        if (regexNumb.test(data)) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                identify: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
        else if (data?.length > 12 || data?.length < 12) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                identify: {
                    value: data,
                    status: true,
                    message: 'Số CMND chỉ được phép nhập 12 ký tự.'
                }
            }
        })
        else pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    identify: {
                        value: data,
                        status: false,
                        message: ''
                    }
                }
            })
    }

    //=======TEACHING SUBJECT========

    const valueTeachingSubject = information.teachingSubject?.value
    const statusTeachingSubject = information.teachingSubject?.status
    const messageTeachingSubject = information.teachingSubject?.message

    const handleChangeTeachingSubject = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            teachingSubject: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurTeachingSubject = data => {
        if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                teachingSubject: {
                    value: data,
                    status: true,
                    message: 'Bộ môn không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                teachingSubject: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }


    //======gender=====
    const valueGender = information.gender?.value
    const statusGender = information.gender?.status
    const messageGender = information.gender?.message
    const activeValue = information.gender?.message

    const handleChangeGender = (data) => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            gender: {
                ...information.gender,
                value: data
            }
        }
    })

    //=======Address========
    const valueAddress = information.address?.value
    const statusAddress = information.address?.status
    const messageAddress = information.address?.message

    const handleChangeAddress = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            address: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurAddress = data => {
        if (data?.length >= 101) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                address: {
                    value: data,
                    status: true,
                    message: 'Địa chỉ không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                address: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }

    //=======Password========
    const valueLastPassword = information.password?.value
    const statusLastPassword = information.password?.status
    const messageLastPassword = information.password?.message

    const handleChangePassword = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            password: {
                value: removeVietnameseTones(data),
                status: false,
                message: ''
            }
        }
    })
    const handleBlurPassword = data => {
        // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        const password = removeVietnameseTones(data)
        if (!!!password || password.length === 0) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                password: {
                    value: password,
                    status: true,
                    message: 'Mật khẩu không được để trống'
                }
            }
        })
        else if (password.length < 8) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                password: {
                    value: password,
                    status: true,
                    message: 'Mật khẩu phải có ít nhất 8 ký tự'
                }
            }
        })
        else if (password !== valueLastPasswordConfirm) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                passwordConfirm: {
                    value: valueLastPasswordConfirm,
                    status: true,
                    message: 'Mật khẩu xác nhận không khớp'
                }
            }
        })
        else pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    password: {
                        value: password,
                        status: false,
                        message: ''
                    },
                    passwordConfirm: {
                        value: valueLastPasswordConfirm,
                        status: false,
                        message: ''
                    }
                }
            })
    }



    //=======Password Confirm========
    const valueLastPasswordConfirm = information.passwordConfirm?.value
    const statusLastPasswordConfirm = information.passwordConfirm?.status
    const messageLastPasswordConfirm = information.passwordConfirm?.message

    const handleChangePasswordConfirm = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            passwordConfirm: {
                value: removeVietnameseTones(data),
                status: false,
                message: ''
            }
        }
    })
    const handleBlurPasswordConfirm = data => {
        // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        const passwordConfirm = removeVietnameseTones(data)
        if (!!!passwordConfirm || passwordConfirm.length === 0) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                passwordConfirm: {
                    value: passwordConfirm,
                    status: true,
                    message: 'Mật khẩu xác nhận không được để trống'
                }
            }
        })
        else if (passwordConfirm !== valueLastPassword) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                passwordConfirm: {
                    value: passwordConfirm,
                    status: true,
                    message: 'Mật khẩu xác nhận không khớp'
                }
            }
        })
        else pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    passwordConfirm: {
                        value: passwordConfirm,
                        status: false,
                        message: ''
                    }
                }
            })
    }


    //=======StatusPerson========
    const valueLastStatusPerson = information.statusPerson?.value
    const statusLastStatusPersond = information.statusPerson?.status
    const messageLastStatusPerson = information.statusPerson?.message

    const handleChangeStatusPerson = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            statusPerson: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurStatusPerson = data => {
        if (!!!data) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                statusPerson: {
                    value: data,
                    status: true,
                    message: 'Trạng thái không được để trống'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                statusPerson: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }

    //=======AccountNumber========
    const valueCommission = information.commission?.value
    const statusCommission = information.commission?.status
    const messageCommission = information.commission?.message

    const handleChangeCommission = data => {
        const regexNumb = /^(|[0-9]\d*)$/
        if (regexNumb.test(data)) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                commission: {
                    value: data > 99 ? 99 : data,
                    status: false,
                    message: ''
                }
            }
        })
    }



    //=======AccountNumber========
    const valueAccountNumber = information.accountNumber?.value
    const statusAccountNumber = information.accountNumber?.status
    const messageAccountNumber = information.accountNumber?.message

    const handleChangeAccountNumber = data => {
        const regexNumb = /^(|[0-9]\d*)$/
        if (regexNumb.test(data)) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                accountNumber: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }

    //=======AccountOwner========
    const valueLastAccountOwner = information.accountOwner?.value
    const statusLastAccountOwner = information.accountOwner?.status
    const messageLastAccountOwner = information.accountOwner?.message

    const handleChangeAccountOwner = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            accountOwner: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurAccountOwner = data => {
        if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                accountOwner: {
                    value: data,
                    status: true,
                    message: 'Chủ tài khoản không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                accountOwner: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }
    //=======BankName========
    const valueLastBankName = information.bankName?.value
    const statusLastBankName = information.bankName?.status
    const messageLastBankName = information.bankName?.message

    const handleChangeBankName = data => pageDispatch({
        type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
            bankName: {
                value: data,
                status: false,
                message: ''
            }
        }
    })
    const handleBlurBankName = data => {
        if (data?.length >= 201) pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                bankName: {
                    value: data,
                    status: true,
                    message: 'Tên ngân hàng không được phép vượt quá 200 ký tự'
                }
            }
        })
        else pageDispatch({
            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                bankName: {
                    value: data,
                    status: false,
                    message: ''
                }
            }
        })
    }
    const canConfirmForm = [
        statusUserName,
        statusSchoolName,
        statusUserName,
        statusLastName,
        statusPhone,
        statusEmail,
        statusLastPassword,
        statusID,
        statusLastStatusPersond,
        statusLastBankName,
        statusLastAccountOwner,
        statusAccountNumber
    ].filter(filter => filter === true)


    const data = {
        "username": !!valueUserName ? valueUserName.trim() : '',
        "school_name": !!valueSchoolName ? valueSchoolName.trim() : '',
        "phone": !!valuePhone ? valuePhone.trim() : '',
        "email": !!valueEmail ? valueEmail.trim() : '',
        "name": !!valueName ? valueName.trim() : '',
        "teaching_subject" : valueTeachingSubject || '',
        "last_name": !!valueLastName ? valueLastName.trim() : '',
        "birthday": convertDateTimeToApiFormatV2(valueBirthday) || '',
        "identity_number": valueID || '',
        "gender": valueGender?.value || 'male',
        "address": !!valueAddress ? valueAddress.trim() : '',
        "password": !!valueLastPassword ? valueLastPassword.trim() : '',
        "status": valueLastStatusPerson?.value || '',
        "commission_rate": !!valueCommission ? valueCommission : 0,
        "bank_name": !!valueLastBankName ? valueLastBankName?.trim() : '',
        "bank_number": valueAccountNumber || '',
        "bank_owner": !!valueLastAccountOwner ? valueLastAccountOwner?.trim() : ''
    }

    const {teacherId} = useParams()

    const validateBeforeSubmit = () => {

        let canSubmit = true

        if (!valueCommission) {
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    commission: {
                        value: '',
                        status: true,
                        message: '% hoa hồng không được để trống'
                    }
                }
            })

            canSubmit = false
        }

        if (!valueUserName) {
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    userName: {
                        value: '',
                        status: true,
                        message: 'Tên đăng nhập không được để trống'
                    }
                }
            })

            canSubmit = false
        }

        if (!valueName) {
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    name: {
                        value: '',
                        status: true,
                        message: 'Tên giáo viên không được để trống'
                    }
                }
            })

            canSubmit = false
        }else handleBlurName(valueName)

        if (!valueEmail) {
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    email: {
                        value: '',
                        status: true,
                        message: 'Email không được để trống'
                    }
                }
            })

            canSubmit = false
        }else handleBlurEmail(valueEmail)

        if (!valueLastPassword) {
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    password: {
                        value: '',
                        status: true,
                        message: 'Mật khẩu không được để trống'
                    }
                }
            })

            canSubmit = false
        }

        if (!valueLastStatusPerson) {
            pageDispatch({
                type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                    statusPerson: {
                        value: '',
                        status: true,
                        message: 'Trạng thái không được để trống'
                    }
                }
            })

            canSubmit = false
        }

        return canSubmit
    }


    const handleSave = async () => {
        if(!teacherId && !validateBeforeSubmit()) return
        const url = !!teacherId ? `update/${teacherId}` : 'create'
        let formData = {...data}
        if (!!teacherId) formData = {...formData, avatar: information?.avatar?.value || ''}
        const response = await sendRequestAuth('post', `${config.API}/principal/teacher/${url}`, formData)
        if (response?.data?.success) {
            showAlert({type: 'success', content: response?.data?.message})
            navigate(PATH.TEACHER)
        } else {
            showAlert({type: 'danger', content: 'Bạn cần kiểm tra lại các thông tin không hợp lệ'})
            response?.data?.errors?.forEach((map) => {
                switch (map.field) {
                    case 'username':
                        pageDispatch({
                            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                                userName: {
                                    value: '',
                                    status: true,
                                    message: map?.message
                                }
                            }
                        })
                        break;
                    case 'name':
                        pageDispatch({
                            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                                name: {
                                    value: '',
                                    status: true,
                                    message: map?.message
                                }
                            }
                        })
                        break;
                    case 'password':
                        pageDispatch({
                            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                                password: {
                                    value: '',
                                    status: true,
                                    message: map?.message
                                }
                            }
                        })
                        break;
                    case 'email':
                        pageDispatch({
                            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                                password: {
                                    value: '',
                                    status: true,
                                    message: map?.message
                                }
                            }
                        })
                        break;
                    case 'commission_rate':
                        pageDispatch({
                            type: useCreateTeacherAction.FORM_INFOMATION_UPDATE, payload: {
                                commission: {
                                    value: '',
                                    status: true,
                                    message: map?.message
                                }
                            }
                        })
                        break;
                    default:
                        break;
                }
            })
        }
    }

    const handleUploadAvatar = async (val) => {
        let image = val.target.files[0]
        const formData = new FormData()
        formData.append('image', image)
        formData.get('image')
        try {
            const response = await sendRequestAuth('post', `${config.API}/principal/teacher/upload`, formData)
            if (response?.data?.success) {
                showAlert({type: 'success', content: response?.data?.message})
                pageDispatch({type: useCreateTeacherAction.FORM_UPLOAD_AVATAR, payload: response?.data?.file_url})
            } else showAlert({type: 'danger', content: response?.data?.errors[0]?.message})
        } catch (e) {
            console.log(e)
        }
    }


    return {
        canConfirmForm,

        typeInput: {
            password: typePassword,
            confirmPassword: typeConfirmPassword,
        },
        userName: {
            value: valueUserName,
            status: statusUserName,
            message: messageUserName,
            methods: {
                onChange: handleChangeUserName,
                onBlur: handleBlurUserName
            }
        },
        schoolName: {
            value: valueSchoolName,
            status: statusSchoolName,
            message: messageSchoolName,
            methods: {
                onChange: handleChangeSchoolName,
                onBlur: handleBlurSchoolName
            }
        },
        phone: {
            value: valuePhone,
            status: statusPhone,
            message: messagePhone,
            methods: {
                onChange: handleChangePhone,
                onBlur: handleBlurPhone
            }
        },
        email: {
            value: valueEmail,
            status: statusEmail,
            message: messageEmail,
            methods: {
                onChange: handleChangeEmail,
                onBlur: handleBlurEmail
            }
        },
        name: {
            value: valueName,
            status: statusName,
            message: messageName,
            methods: {
                onChange: handleChangeName,
                onBlur: handleBlurName
            }
        },
        teachingSubject: {
            value: valueTeachingSubject,
            status: statusTeachingSubject,
            message: messageTeachingSubject,
            methods: {
                onChange: handleChangeTeachingSubject,
                onBlur: handleBlurTeachingSubject
            }
        },
        lastName: {
            value: valueLastName,
            status: statusLastName,
            message: messageLastName,
            methods: {
                onChange: handleChangeLastName,
                onBlur: handleBlurLastName
            }
        },
        birthday: {
            value: valueBirthday,
            status: statusBirthday,
            message: messageBirthday,
            methods: {
                onChange: handleChangeBirthday,
            }
        },
        identify: {
            value: valueID,
            status: statusID,
            message: messageID,
            methods: {
                onChange: handleChangeID,
                onBlur: handleBlurID
            }
        },
        gender: {
            value: valueGender,
            status: statusGender,
            message: messageGender,
            active: activeValue,
            methods: {
                onChange: handleChangeGender,
            }
        },
        address: {
            value: valueAddress,
            status: statusAddress,
            message: messageAddress,
            methods: {
                onChange: handleChangeAddress,
                onBlur: handleBlurAddress
            }
        },
        password: {
            value: valueLastPassword,
            status: statusLastPassword,
            message: messageLastPassword,
            methods: {
                onChange: handleChangePassword,
                onBlur: handleBlurPassword,
                onChangeTypePassword: handleChangeTypePassword,

            }
        },
        passwordConfirm: {
            value: valueLastPasswordConfirm,
            status: statusLastPasswordConfirm,
            message: messageLastPasswordConfirm,
            methods: {
                onChange: handleChangePasswordConfirm,
                onBlur: handleBlurPasswordConfirm,
                onChangeTypeConfirmPassword: handleChangeTypeConfirmPassword,
            }
        },
        statusPerson: {
            value: valueLastStatusPerson,
            status: statusLastStatusPersond,
            message: messageLastStatusPerson,
            methods: {
                onChange: handleChangeStatusPerson,
                onBlur: handleBlurStatusPerson
            }
        },
        accountNumber: {
            value: valueAccountNumber,
            status: statusAccountNumber,
            message: messageAccountNumber,
            methods: {
                onChange: handleChangeAccountNumber,
            }
        },
        accountOwner: {
            value: valueLastAccountOwner,
            status: statusLastAccountOwner,
            message: messageLastAccountOwner,
            methods: {
                onChange: handleChangeAccountOwner,
                onBlur: handleBlurAccountOwner
            }
        },
        commission: {
            value: valueCommission,
            status: statusCommission,
            message: messageCommission,
            methods: {
                onChange: handleChangeCommission,
            }
        },
        bankName: {
            value: valueLastBankName,
            status: statusLastBankName,
            message: messageLastBankName,
            methods: {
                onChange: handleChangeBankName,
                onBlur: handleBlurBankName
            }
        },
        avatar: {
            value: information?.avatar?.value,
        },
        detail: information?.detail,
        actionList: {
            onSave: handleSave
        },
        onUploadAvatar: handleUploadAvatar
    }


}