import useCommissionFilterForm from 'Pages/commission/commissionTeacher/hooks/useCommissionFilterForm'
import { CategoryInput } from '../../../../../common/form/input/_categoryInput'

export const CommissionSearch = () => {
  const {orderId} = useCommissionFilterForm()
  return (
  <CategoryInput
    className={'commission-filter-form__input-wide'}
    categoryList={[]}
    categoryValue={{name: 'Mã đơn hàng', value: ''}}
    categoryWidth={104}
    placeholder='Nhập mã đơn hàng'
    value={orderId?.value || ''}
    onChange={orderId.onChange}
    style={{ position: 'relative'}}
  />
  )
}
