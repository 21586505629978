export const BREADCRUMB_ICONS = {
  arrowLeft: (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.5L10 8.5L7 11.5"
        stroke="#7C88A6"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  back: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.77145 12.6036C8.96671 12.7988 9.28329 12.7988 9.47855 12.6036C9.67382 12.4083 9.67382 12.0917 9.47855 11.8964L8.77145 12.6036ZM6 9.125L5.64645 8.77145C5.45118 8.96671 5.45118 9.28329 5.64645 9.47855L6 9.125ZM9.47855 6.35355C9.67382 6.15829 9.67382 5.84171 9.47855 5.64645C9.28329 5.45118 8.96671 5.45118 8.77145 5.64645L9.47855 6.35355ZM15.5 16C15.5 16.2761 15.7239 16.5 16 16.5C16.2761 16.5 16.5 16.2761 16.5 16H15.5ZM15.5913 11.1725L15.1458 11.3995L15.5913 11.1725ZM13.9525 9.53373L13.7255 9.97923L13.9525 9.53373ZM9.47855 11.8964L6.35355 8.77145L5.64645 9.47855L8.77145 12.6036L9.47855 11.8964ZM6.35355 9.47855L9.47855 6.35355L8.77145 5.64645L5.64645 8.77145L6.35355 9.47855ZM16.5 16V15.125H15.5V16H16.5ZM10 8.625H6V9.625H10V8.625ZM16.5 15.125C16.5 14.0832 16.5004 13.2717 16.4472 12.6213C16.3936 11.9651 16.283 11.4289 16.0368 10.9455L15.1458 11.3995C15.3082 11.7184 15.402 12.1083 15.4506 12.7027C15.4996 13.303 15.5 14.0667 15.5 15.125H16.5ZM10 9.625C11.0583 9.625 11.822 9.62539 12.4223 9.67443C13.0167 9.72299 13.4066 9.81677 13.7255 9.97923L14.1795 9.08822C13.6961 8.84196 13.1599 8.73137 12.5037 8.67775C11.8533 8.62461 11.0418 8.625 10 8.625V9.625ZM16.0368 10.9455C15.6293 10.1459 14.9791 9.49568 14.1795 9.08822L13.7255 9.97923C14.337 10.2908 14.8342 10.788 15.1458 11.3995L16.0368 10.9455Z"
        fill="#3264FF"
      />
    </svg>
  ),
}
