import { Skeleton } from '@mui/material'
export const LoadingChart = () => (
  <div className={'content-report__wrap'} style={{ height: 411 }}>
    <Skeleton
      sx={{
        width: '100%',
        height: 411,
        background:
          'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
      }}
      variant={'rectangular'}
    />
  </div>
)