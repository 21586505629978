import {Skeleton} from '@mui/material'
import {Text} from 'common/text'
import config from 'config'
import useAlert from 'hook/useAlert'
import {Td} from 'layouts/tableLayout/_td'
import {Tr} from 'layouts/tableLayout/_tr'
import useReceiptRow from 'Pages/receipt/hooks/useReceiptRow'
import {ReceiptContext} from 'Pages/receipt/provider/_context'
import React, {useContext, useState} from 'react'
import {ReceiptEmpty} from '../receiptEmpty'
import {RowReceiptExtra} from './_rowExtra'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import {PrintReceiptDetail} from '../printDetail'
import {fDateTimeCustom, fDateTimeDMY} from '../../../../util/formatTime'
import {Loading} from '../../../../common/loading'
import {formatMoney} from '../../../../util/functionUtil'
import {CellReceipt} from "./_cellReceipt";
import {CellCodeReceipt} from "./_cellCodeReceipt";
import useGlobalContext from "../../../../containerContext/storeContext";

export const ReceiptTBody = () => {
    const {pageState} = useContext(ReceiptContext)
    const {table} = pageState

    const displayList = table.display.list
    const displayLoading = table.display.loading
    const paginationTotalItems = table.pagination.totalItems
    return (
        <>
            {displayLoading ? (
                Array.from(Array(20), (e, i) => (
                    <ReceiptPlaceholder key={i}/>
                ))
            ) : paginationTotalItems > 0 ? (
                displayList.map(item => <ReceiptTr key={item.id} data={item}/>)
            ) : (
                <ReceiptEmpty/>
            )}
            <PrintReceiptDetail/>
        </>
    )
}

const ReceiptPlaceholder = ({...props}) => {
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type
    return (
        <Tr {...props} className="receipt-table__row">
            {Array.from(Array(+userType === 3 ? 6 : 7), (e, i) => (
                <Td key={i} className="receipt-table__cell" data-type="td">
                    <Skeleton
                        sx={{
                            width: '100%',
                            height: 33,
                            background:
                                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                        }}
                    />
                </Td>
            ))}
        </Tr>
    )
}

const ReceiptTr = ({data, ...props}) => {
    const {pageState, pageDispatch} = useContext(ReceiptContext)
    const receiptRow = useReceiptRow(data)
    const {cell, detail, row} = receiptRow
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type

    const [isLoading, setIsLoading] = useState(false)

    const [searchParams] = useSearchParams()
    useEffect(() => {
        const querySearch = searchParams.get('search') || ''

        if (querySearch && pageState?.table?.display?.list?.length === 1)
            row.onToggleDetail()
    }, [])

    return (
        <>
            {isLoading && (
                <Loading/>
            )}
            <Tr
                {...props}
                className="receipt-table__row"
                extra={
                    <RowReceiptExtra
                        id={detail?.id}
                        active={row.shouldOpenDetail}
                        data={detail?.active}
                        rowData={receiptRow}
                    />
                }
                data-active={row.shouldOpenDetail}
                onClick={row.onToggleDetail}
            >
                <Td className="receipt-table__cell" data-type="td">
                    <Td className="principal-table__cell" data-type="td">
                        <CellCodeReceipt
                            id={data.tracking_code}
                            time={fDateTimeDMY(data.created_at)}
                        />
                    </Td>
                </Td>
                <Td className="receipt-table__cell" data-type="td">
                    <CellReceipt
                        id={data?.id}
                        name={data.month_time}
                        phone={data.tracking_title}
                    />
                </Td>
                <Td className="receipt-table__cell" data-type="td">
                    <Text>{formatMoney(data?.month_value || 0)}</Text>
                </Td>
                <Td className="receipt-table__cell" data-type="td">

                    <Text>{formatMoney(data?.payment_value || 0)}</Text>
                </Td>
                <Td className="receipt-table__cell" data-type="td">
                    <Text>{formatMoney(data?.month_remaining || 0)}</Text>
                </Td>
                {+userType !== 3 && <Td className="receipt-table__cell" data-type="td">
                    <Text>{data?.payment_method_name || '---'}</Text>
                </Td>}
                <Td className="receipt-table__cell" data-type="td">
                    <Text as={'p'} className={'receipt-table__status'}
                          data-status={data?.payment_status}>{data?.payment_statust_name}</Text>
                </Td>
            </Tr>
        </>
    )
}
