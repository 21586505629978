import {Th} from 'layouts/tableLayout/_th'
import {Tr} from 'layouts/tableLayout/_tr'
import useGlobalContext from "../../../../containerContext/storeContext";

export const ReceiptTHead = ({...props}) => {
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type

    return (
        <Tr {...props} type="tHead">
            <Th className="receipt-table__cell">Phiếu thu</Th>
            <Th className="receipt-table__cell">Kỳ thanh toán</Th>
            <Th className="receipt-table__cell">Tổng phải trả trong kỳ
            </Th>
            <Th className="receipt-table__cell">Giá trị thu</Th>
            <Th className="receipt-table__cell">Còn lại</Th>
            {+userType !== 3 && <Th className="receipt-table__cell">Phương thức</Th>}
            <Th className="receipt-table__cell">Trạng thái</Th>
        </Tr>
    )
}
