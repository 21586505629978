import { sendRequestAuth } from 'api/api'
import { removeAcent } from 'common/fieldText/_functions'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import {
    STUDENT_FILTER_TAG_FIELDS,
} from '../interfaces/_constants'
import { StudentContext } from '../provider/_context'
import { studentActions } from '../provider/_reducer'
import { debounce } from '@mui/material'
import { studentInitialState } from 'Pages/student/provider/_initState'
import { useSearchParams } from 'react-router-dom'

import { getDateFromNow } from 'util/date'

const useStudentFilterForm = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { pageState, pageDispatch } = useContext(StudentContext)
    const { filter, table } = pageState

    // ===== ===== ===== ===== =====
    // SEARCH
    // ===== ===== ===== ===== =====
    const searchValue = filter.search.value
    const querySearch = searchParams.get('search') || ''

    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
    // ===== ===== ===== ===== =====
    // DATE TIME
    // ===== ===== ===== ===== =====
    const { afterToday } = DateRangePicker
    const dateTimeActiveValue = filter.dateTime.activeValue
    const dateTimeEnd = filter.dateTime.end
    const dateTimeStart = filter.dateTime.start
    const dateTimeDefaultValue = [dateTimeStart, dateTimeEnd]
    const dateTimeType = filter.dateTime.type
    const dateTimeValue = filter.dateTime.value
    const dateTimeTrigger = filter.dateTime.trigger

    const handleDateTimeChange = data =>
        pageDispatch({
            type: studentActions.FILTER_DATE_TIME_UPDATE,
            payload: {
                end: data.value[1],
                start: data.value[0],
                type: data.category,
                value: data.formatValue,
            },
        })


    // ===== ===== ===== ===== =====
    // principal
    // ===== ===== ===== ===== =====
    const principalActiveValue = filter.principal.activeValue
    const principalKeyword = filter.principal.keyword
    const principalList = filter.principal.list
    const principalListOrigin = filter.principal.listOrigin
    const principalValue = filter.principal.value

    const handlePrincipalChange = async (data) => {
        pageDispatch({
            type: studentActions.FILTER_PRINCIPAL_UPDATE,
            payload: { value: data },
        })
        // get list student
        // const response = await sendRequestAuth('get', `${config.API}/order/filter/students?principal_id=${data?.value}`)
        // pageDispatch({
        //     type: studentActions.FILTER_STUDENT_LIST_UPDATE,
        //     payload: {
        //         student: {
        //             list: response.data?.map(item => ({
        //                 name: item?.name || '',
        //                 value: item?.id || '',
        //             })),
        //         }
        //     },
        // })
    }

    const handlePrincipalKeywordChange = data => {
        const formatDataValue = data?.value
            ? removeAcent(data?.value?.toLowerCase())
            : ''

        const principalListData = principalListOrigin.filter(item => {
            const formatNameItem = item?.name
                ? removeAcent(item.name.toLowerCase())
                : ''
            if (formatNameItem.includes(formatDataValue)) return true
            return false
        })

        pageDispatch({
            type: studentActions.FILTER_PRINCIPAL_KEYWORD_UPDATE,
            payload: {
                keyword: data?.value || '',
                list: principalListData,
            },
        })
    }
    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

    // ===== ===== ===== ===== =====
    // Student
    // ===== ===== ===== ===== =====
    const studentActiveValue = filter.student.activeValue
    const studentKeyword = filter.student.keyword
    const studentList = filter.student.list
    const studentListOrigin = filter.student.listOrigin
    const studentValue = filter.student.value

    const handleStudentChange = data =>
        pageDispatch({
            type: studentActions.FILTER_STUDENT_UPDATE,
            payload: { value: data },
        })

    const handleStudentKeywordChange = data => {
        const formatDataValue = data?.value
            ? removeAcent(data?.value?.toLowerCase())
            : ''

        const studentListData = studentListOrigin.filter(item => {
            const formatNameItem = item?.name
                ? removeAcent(item.name.toLowerCase())
                : ''
            if (formatNameItem.includes(formatDataValue)) return true
            return false
        })

        pageDispatch({
            type: studentActions.FILTER_STUDENT_KEYWORD_UPDATE,
            payload: {
                keyword: data?.value || '',
                list: studentListData,
            },
        })
    }

    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====


    // ===== ===== ===== ===== =====
    // STUDENT ID
    // ===== ===== ===== ===== =====
    const studentIdValue = filter.studentId.value
    const studentIdActiveValue = filter.studentId.activeValue

    const handleStudentIdChange = value => {
        if (value === ' ') value = ''
        pageDispatch({
            type: studentActions.FILTER_STUDENT_ID_UPDATE,
            payload: { value: value },
        })
    }
    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

    // ===== ===== ===== ===== =====
    // STUDENT INFO
    // ===== ===== ===== ===== =====
    const studentInfoValue = filter.studentInfo.value
    const studentInfoActiveValue = filter.studentInfo.activeValue

    const handleStudentInfoChange = value => {
        if (value === ' ') value = ''
        pageDispatch({
            type: studentActions.FILTER_STUDENT_INFO_UPDATE,
            payload: { value: value },
        })
    }
    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

    // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
    const canSubmitOtherFilter = [
        dateTimeActiveValue.value !== dateTimeValue ||
        JSON.stringify(dateTimeActiveValue.type) !== JSON.stringify(dateTimeType),
        JSON.stringify(principalActiveValue) !== JSON.stringify(principalValue),
        JSON.stringify(studentActiveValue) !== JSON.stringify(studentValue),
        JSON.stringify(studentIdActiveValue) !== JSON.stringify(studentIdValue),
        JSON.stringify(studentInfoActiveValue) !== JSON.stringify(studentInfoValue),
    ].includes(true)

    const queries = {
        status: principalActiveValue?.value || '',
        keyword: studentInfoActiveValue || '',
        per_page: table?.pagination?.amount || 20,
        start: 0,
    }

    const debounceStudentByFilter = useCallback(debounce((keyword, queries) => {
        fetchStudentByFilter(
            { ...queries, keyword: keyword.trim() },
            { forceLoading: true },
        )
    }, 500), [queries])

    const handleSearchChange = e => {
        if (e.target.value === ' ') e.target.value = ''
        const keyword = e.target.value.replace(/\s+/g, ',') || ''
        pageDispatch({
            type: studentActions.FILTER_SEARCH_UPDATE,
            payload: { value: keyword },
        })
        debounceStudentByFilter(keyword.trim().split(' ').join(','), queries)
        // if(keyword.trim().length > 0) debounceStudentByFilter(keyword.trim().split(' ').join(','))
    }

    const applyStudentOtherFilter = async () => {
        const collection = {
            ...queries,
            status: principalValue?.value || '',
            keyword: studentInfoValue || '',
        }
        fetchStudentByFilter(collection, { forceLoading: true })
    }

    const fetchStudentByFilter = async (qs, opt) => {
        setSearchParams('')
        if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
            pageDispatch({
                type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
                payload: { table: { display: { loading: true } } },
            })

        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }

        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/teacher/customer/customers${queryString}`),
        ])

        if (!!response[0]?.data?.success) {
            pageDispatch({
                type: studentActions.OTHER_FILTER_APPLY,
                payload: {
                    display: {
                        list: response[0].data.data
                    },
                    pagination: {
                        active: opt?.activePage || 0,
                        amount: table.pagination.amount,
                        total: response[0]?.data?.meta?.total
                            ? Math.ceil(
                                response[0]?.data?.meta?.total / table.pagination.amount,
                            )
                            : 0,
                        totalItems: response[0]?.data?.meta?.total || 0,
                    },
                },
            })
        }

        if (!!!opt?.notClearDetail)
            pageDispatch({
                type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: null },
            })

        if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
            pageDispatch({
                type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
                payload: { table: { display: { loading: false } } },
            })

        pageDispatch({
            type: studentActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
            payload: { id: null },
        })
    }

    const filterTagDelete = t => {
        pageDispatch({
            type: studentActions.TAG_FILTER_DELETE,
            payload: { type: t, isSingle: true },
        })

        let tmpCollection = {}
        switch (t) {
            case 'dateTime.current':
                tmpCollection = {
                    ...tmpCollection,
                    date_type: '',
                    start_date: '',
                    end_date: '',
                }

                pageDispatch({
                    type: studentActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
                    payload: { trigger: null },
                })

                break

            case STUDENT_FILTER_TAG_FIELDS[8]:
                tmpCollection = {
                    ...tmpCollection,
                }
                break

            case STUDENT_FILTER_TAG_FIELDS[0]:
                tmpCollection = {
                    ...tmpCollection,
                    student_id: ''
                }
                break

            case STUDENT_FILTER_TAG_FIELDS[1]:
                tmpCollection = { ...tmpCollection, keyword: '' }
                break

            case STUDENT_FILTER_TAG_FIELDS[2]:
                tmpCollection = { ...tmpCollection, product_ids: '' }
                break

            case STUDENT_FILTER_TAG_FIELDS[3]:
                tmpCollection = { ...tmpCollection, payment_status: '' }
                break

            case STUDENT_FILTER_TAG_FIELDS[4]:
                tmpCollection = { ...tmpCollection, status: '' }
                break

            case STUDENT_FILTER_TAG_FIELDS[5]:
                tmpCollection = { ...tmpCollection, student_id: '' }
                break

            case STUDENT_FILTER_TAG_FIELDS[6]:
                tmpCollection = { ...tmpCollection, payment_method: '' }
                break

            case STUDENT_FILTER_TAG_FIELDS[7]:
                tmpCollection = { ...tmpCollection, student_status: '' }
                break

            default:
                break
        }

        const collection = { ...queries, ...tmpCollection }

        fetchStudentByFilter(collection, { forceLoading: true })
    }

    const filterTagDeleteAll = isSoft => {
        STUDENT_FILTER_TAG_FIELDS.forEach(item =>
            pageDispatch({
                type: studentActions.TAG_FILTER_DELETE,
                payload: { type: item },
            }),
        )

        pageDispatch({
            type: studentActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
            payload: {
                trigger: dateTimeTrigger === null ? true : !dateTimeTrigger,
            },
        })

        if (isSoft) return

        const collection = {
            ...queries,
            status: '',
            keyword: '',
        }

        fetchStudentByFilter(collection, { forceLoading: true })
    }

    return {
        pageState,
        dateTime: {
            activeValue: dateTimeActiveValue,
            defaultValue: dateTimeDefaultValue,
            disabledDate: afterToday(),
            triggerDefault: dateTimeTrigger,
            start: filter.dateTime.start,
            end: filter.dateTime.end,
            value: dateTimeValue,
            onChange: handleDateTimeChange,
        },
        studentId: {
            value: studentIdValue,
            activeValue: studentIdActiveValue,
            onChange: handleStudentIdChange,
        },
        studentInfo: {
            value: studentInfoValue,
            activeValue: studentInfoActiveValue,
            onChange: handleStudentInfoChange,
        },
        principal: {
            activeValue: principalActiveValue,
            keyword: principalKeyword,
            list: principalList,
            value: principalValue,
            onChange: handlePrincipalChange,
            onKeywordChange: handlePrincipalKeywordChange,
        },
        student: {
            activeValue: studentActiveValue,
            keyword: studentKeyword,
            list: studentList,
            listOrigin: studentListOrigin,
            value: studentValue,
            onChange: handleStudentChange,
            onKeywordChange: handleStudentKeywordChange,
        },
        canSubmitOtherFilter,
        queries,
        functions: {
            hasFilter: () => [
                JSON.stringify(dateTimeActiveValue?.value) !==
                JSON.stringify(studentInitialState.filter.dateTime.activeValue?.value),
                !!principalActiveValue?.name,
                !!studentActiveValue?.name,
                !!studentInfoActiveValue,
            ].includes(true),
            applyStudentOtherFilter,
            refresh: () =>
                fetchStudentByFilter(
                    {
                        ...queries,
                        type: 2,
                        start: table.pagination.active * table.pagination.amount || 0,
                    },
                    { activePage: table.pagination.active, forceLoading: true },
                ),
            fetchUpdateData: () =>
                fetchStudentByFilter(
                    {
                        ...queries,
                        type: 1,
                        start: table.pagination.active * table.pagination.amount,
                    },
                    { activePage: table.pagination.active, notClearDetail: true },
                ),
            filterTagDelete,
            filterTagDeleteAll,
        },
    }
}

export default useStudentFilterForm