import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import config from 'config'
import useAlert from 'hook/useAlert'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import useStudentFilterForm from 'Pages/student/hooks/useStudentFilterForm'
import useStudentRow from 'Pages/student/hooks/useStudentRow'
import { StudentContext } from 'Pages/student/provider/_context'
import React, { useContext, useState } from 'react'
import { StudentEmpty } from '../studentEmpty'
import { RowStudentExtra } from './_rowStudentExtra'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Tooltip as TooltipV2 } from 'common/tooltipv2'
import { PrintStudentDetail } from '../printDetail'
import { fDateTimeCustom, fDateTimeDMY } from '../../../../util/formatTime'
import { formatMoney } from '../../../../util/functionUtil'
import { Loading } from '../../../../common/loading'
import ReactImageFallback from 'react-image-fallback'
import { sendRequestAuth } from '../../../../api/api'
import {OrderModal} from "./tabProduct/modalOrders";
import {useProductAction} from "../../../customer/provider/_reducer";

export const StudentTBody = () => {
    const { pageState,pageDispatch } = useContext(StudentContext)
    const { table } = pageState

    const displayList = table.display.list
    const displayLoading = table.display.loading
    const paginationTotalItems = table.pagination.totalItems
    return (
        <>
            {displayLoading ? (
                Array.from(Array(20), (e, i) => (
                    <StudentPlaceholder key={i} />
                ))
            ) : paginationTotalItems > 0 ? (
                displayList.map(item => <StudentTr key={item.id} data={item} />)
            ) : (
                <StudentEmpty />
            )}
            <PrintStudentDetail />
            <OrderModal closeModal={() => pageDispatch({type: useProductAction.UPDATE_MODAL_ORDER, payload: false})}/>
        </>
    )
}

const StudentPlaceholder = ({ ...props }) => {
    return (
        <Tr {...props} className="student-table__row">
            {Array.from(Array(9), (e, i) => (
                <Td key={i} className="student-table__cell" data-type="td">
                    <Skeleton
                        sx={{
                            width: '100%',
                            height: 33,
                            background: 'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                        }}
                    />
                </Td>
            ))}
        </Tr>
    )
}

const StudentTr = ({ data, ...props }) => {
    const { showAlert } = useAlert()
    const { pageState, pageDispatch } = useContext(StudentContext)
    const { functions } = useStudentFilterForm()
    const studentRow = useStudentRow(data)
    const { cell, detail, row } = studentRow

    const [isLoading, setIsLoading] = useState(false)

    const [searchParams] = useSearchParams()
    useEffect(() => {
        const querySearch = searchParams.get('search') || ''

        if (querySearch && pageState?.table?.display?.list?.length === 1)
            row.onToggleDetail()
    }, [])

    const handleActiveStudent = async (type) => {
        const response = await sendRequestAuth('post',
            `${config.API}/teacher/customer/${type}/${data?.zalo_id}`
        )
        if (!!response.data?.success) {
            showAlert({
                type: 'success',
                content: response.data.message
            })
            functions.refresh()
        }
        else
            showAlert({
                type: 'danger',
                content: response.data.message
            })
    }

    return (
        <>
            {isLoading && (
                <Loading />
            )}
            <Tr
                {...props}
                className="student-table__row"
                extra={
                    <RowStudentExtra
                        id={detail?.id}
                        active={row.shouldOpenDetail}
                        data={detail?.active}
                        rowData={studentRow}
                    />
                }
                data-active={row.shouldOpenDetail}
                onClick={row.onToggleDetail}
            >
                <Td className="student-table__cell" data-type="td">
                    <ReactImageFallback
                        src={data?.avatar || '/img/useravatar.jpg'}
                        fallbackImage='/img/useravatar.jpg'
                        style={{ width: 36, height: 36, borderRadius: 50, marginRight: 8 }}
                    />
                    <div>
                        <TooltipV2 title={data?.name} baseOn={'height'} className='principal-table__cell-tooltip'>
                            <Text>{data?.name || '---'}</Text>
                        </TooltipV2>

                        <TooltipV2 title={data?.phone} baseOn={'height'} className='principal-table__cell-tooltip'>
                            <Text>{data?.phone || '---'}</Text>
                        </TooltipV2>
                    </div>
                </Td>
                <Td className="student-table__cell" data-type="td">
                    <Text>{data?.total_orders || 0}</Text>
                </Td>
                <Td className="student-table__cell" data-type="td">
                    <Text>{data?.commission_teacher ? formatMoney(data?.grand_totals) : 0}</Text>
                </Td>
                <Td className="student-table__cell" data-type="td">
                    <Text>{data?.commission_teacher ? formatMoney(data?.commission_teacher) : 0}</Text>
                </Td>
                <Td className="student-table__cell" data-type="td">
                    <Text as={'p'}>{fDateTimeCustom(data?.scaned_at, { format: 'HH:mm:ss' })}</Text>
                    <Text as={'p'}>{fDateTimeDMY(data?.scaned_at)}</Text>
                </Td>
                <Td className="student-table__cell" data-type="td">
                    <Text as={'p'} className={"student-table__status"} data-status={data?.linked_teacher_id === data?.teacher_id}>
                        {data?.linked_teacher_id === data?.teacher_id ? 'Đang kết nối' : 'Không kết nối'}
                    </Text>
                </Td>
            </Tr>
        </>
    )
}
