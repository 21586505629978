import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const NamePerson = ()=>{
    const {name} = useCreateTeacherForm()
    const {methods} = name
    return (
        <Input
            label={
                <>
                    Tên <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={name?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={name?.message}
            validateType={name?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập tên'}
            maxLength={201}
        />
    )
}