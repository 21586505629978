import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { StudentFilterForm } from './components/studentFilterForm'
import { StudentTBody } from './components/studentTable/_studentTBody'
import { StudentTHead } from './components/studentTable/_studentTHead'
import {
    STUDENT_BREADCRUMB,
    STUDENT_PAGE_HEADER_ACTIONS,
} from './interfaces/_constants'
import { StudentProvider } from './provider'
import './components/studentTable/index.scss'
import useStudent from './hooks/useStudent'
import { StudentNotifications } from './components/studentNotifications'
import { PageHeader } from './components/studentPageHeader'
import PopupNotPermission from '../permision/popupNotPermission'
import {ModalExport} from "../../common/modalExport";
import useCommissionFilterForm from "../commission/commissionPrincipal/hooks/useCommissionFilterForm";
import useAlert from "../../hook/useAlert";
import useStudentFilterForm from "./hooks/useStudentFilterForm";
import {GLOBAL_ICONS} from "../../interface/icon";

export const StudentPage = () => {
    const { fetch, pagination, provider } = useStudent()

    const { state, dispatch } = provider
    const { table } = state

    useEffect(() => {
        fetch.origin()
    }, [])
    return (
        <StudentProvider value={{ pageState: state, pageDispatch: dispatch }}>
            <Header />
            <StudentNotifications />
            <TableLayout
                header={
                    <>
                        <StudentFilterForm />
                    </>
                }
                table={{
                    tHead: <StudentTHead />,
                    tBody: <StudentTBody />,
                }}
                pagination={{
                    ...table.pagination,
                    onAmountChange: pagination.onAmountChange,
                    onPageChange: pagination.onPageChange,
                }}
            />
        </StudentProvider>
    )
}

const Header = () => {
    const [showPermission, setShowPermission] = useState(false)

    const  {queries, pageState} = useStudentFilterForm()
    const [exportModalData, setExportModalData] = useState(null)
    const {showAlert} = useAlert()

    const handleExportClick = () => {
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
        ...queries,
        per_page: '',
        start: '',
      })) {
        queryString += `${i > 0 ? '&' : ''}${key}=${value}`
        i++
      }

      handleLargeExport(
        {
          ...queries,
          per_page: '',
          start: '',
        }
      )
    }

    const handleLargeExport = (q) => {
      if (pageState.table.pagination.totalItems <= 0) {
        showAlert({ type: 'info', content: `Số lượng học sinh phải lớn hơn  0` })
        return
      }

      setExportModalData({
        data: {
          query: q,
          total: pageState.table.pagination.totalItems,
        },
        onClose: () => setExportModalData(null),
      })
    }
    return (
        <>
            {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)} />}
            <PageHeader
                breadcrumbLinks={STUDENT_BREADCRUMB}
                breadcrumbTitle="Quản lý học sinh"
                actions={[
                  {
                    name: 'Xuất excel',
                    icon: GLOBAL_ICONS.export,
                    onClick: handleExportClick,
                    appearance: 'secondary',
                  }
                ]}
            />

          {!!exportModalData && <ModalExport data={exportModalData} title={'học sinh'} api={`/teacher/customer/export-customers`}/>}
        </>
    )
}
