import React, { useContext } from 'react'
import { TableLayout } from '../../../../../../layouts/tableLayout'
import { TableTeacherHeader } from './_tableHeader'
import styled from 'styled-components'
import TbodyProduct from './_tableBody'
import { useCreateProductTable } from '../../hook/useCreateProductTable'
import FilterForm from './filter'

export const ProductTeachers = () => {
  const { table, pagination } = useCreateProductTable()
  return (
    <StyledTable>
      <TableLayout
        header={
          <>
            <FilterForm/>
          </>
        }
        table={{
          tHead: <TableTeacherHeader/>,
          tBody: <TbodyProduct/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}

      />
    </StyledTable>
  )
}

const StyledTable = styled.div`
.create-teacher-table{
    &[data-type = 'tHead']{
        .tr__container{
            height: 44px;
        }
    }
    &_cell{
        &:nth-child(1){
           width: 76px;
           display: flex;
           align-items: center;
          .create-teacher-table_cell-checkbox{
            margin-right: 12px;
          }
        }
         &:nth-child(2){
           flex: 1;
        }
        &:nth-child(3){
           width: 15%;
        }
        &:nth-child(4){
           width: 15%;
        }
        &:nth-child(5){
            width: 20%;
        }
        &:nth-child(6){
            width: 100px;
        }
    }
          
    &__selected-action-dropdown {
      position: relative;
  
      margin-left: 12px;
    }
  
    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;
  
      border-radius: 14px !important;
  
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
  
      width: 100vw;
      height: 100vh;
    }
  
    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;
  
      width: 150px;
      padding: 8px;
  
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__selected-action-menu-item {
      padding: 8px;
  
      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  
      transition: color 0.25s;
  
      cursor: pointer;
  
      &:hover {
        color: #3264ff;
      }
    }
  }
`