import React, {useEffect, useRef, useState} from "react";
import {Text} from "common/text";
import styled from 'styled-components'
import { DatePicker } from 'rsuite';
import { useModal } from '../../hooks/useModal'
import { GLOBAL_ICONS } from '../../../../interface/icon'
export const DateNote = ()=>{
    const {paymentDate} = useModal()
    const {methods} = paymentDate
    const [changePosition, setChangePosition] = useState(false)
    const positionRef = useRef(null)


    const handleOpen = (boo)=>{

        const wrapper = document.querySelector('#content-wrap')
        if (wrapper && boo) {
            wrapper.classList.add('--overflow-hidden')

        } else wrapper.classList.remove('--overflow-hidden')

    }

    const reverse = ()=>{
        const viewportOffset = positionRef.current.getBoundingClientRect()
        // these are relative to the viewport, i.e. the window
        const top = viewportOffset.top
        if(top + 500 > screen.height || top - 50 < 0) setChangePosition(true)
        else setChangePosition(false)
    }


    return (
        <StyleBirthday>
            <Text>Ngày ghi sổ</Text>
            <div ref={positionRef} onClick={reverse} className='principal_paymentDate' >
                {
                    !!paymentDate?.value ? <Text  className='principal_paymentDate-placehodaler'>{paymentDate?.value}</Text>
                            :
                        <Text  className='principal_paymentDate-placehodaler' color={'#7C88A6'}>{`dd/mm/yyyy`}</Text>
                }

                <DatePicker
                    format="yyyy-MM-dd"
                    className='principal_paymentDate-date'
                    onOpen={()=> handleOpen(true)}
                    onClose={()=> handleOpen(false)}
                    placement={!!changePosition ? 'topStart' : 'bottomStart'}
                    onChange={(val)=> methods.onChange(val)}
                />
                <div className='principal_paymentDate-icon' >
                    {GLOBAL_ICONS.date}
                </div>
            </div>

        </StyleBirthday>
    )
}
const StyleBirthday = styled.div`
.principal_paymentDate{
    width: 100%;
    height: 34px;
    padding: 0px 21px 0px 11px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(235, 238, 245);
    border-radius: 6px;
    color: rgb(0, 8, 29);
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    transition: all 0.25s ease 0s;
    margin-top: 8px;
    position: relative;
    &-date{
        opacity: 0;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 12;
    }
    &-icon{
        position: absolute;
        top: 9px;
        right: 16px;
        pointer-events: none;
        cursor: pointer;
    }
    &-placehodaler{
        position: absolute;
        top: 5px;
        left: 16px;
    }
}
`