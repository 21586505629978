import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useCommissionFilterForm from 'Pages/commission/commissionTeacher/hooks/useCommissionFilterForm'
import { COMMISSION_FILTER_TAG_FIELDS } from 'Pages/commission/commissionTeacher/interfaces/_constants'
import { StyledCommissionTags } from './_styled'
import { CommissionTag } from './_tag'

export const CommissionTags = ({ ...props }) => {
  const {
    dateTime,
    orderId,
    customerInfo,
    teacher,
    orderType,
    principal,
    functions,
  } = useCommissionFilterForm()

  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledCommissionTags {...props}>

      {orderId?.activeValue && (
        <CommissionTag
          onDelete={() => functions.filterTagDelete(COMMISSION_FILTER_TAG_FIELDS[0])}
        >
          Mã đơn hàng: {orderId.activeValue}
        </CommissionTag>
      )}
      {customerInfo.activeValue && (
        <CommissionTag
          onDelete={() => functions.filterTagDelete(COMMISSION_FILTER_TAG_FIELDS[1])}
        >
          Tên khách hàng/Số điện thoại: {customerInfo.activeValue}
        </CommissionTag>
      )}

      {orderType?.activeValue?.name && (
        <CommissionTag
          onDelete={() => functions.filterTagDelete(COMMISSION_FILTER_TAG_FIELDS[2])}
        >
          Loại đơn hàng: {orderType.activeValue.name}
        </CommissionTag>
      )}
      {principal?.activeValue?.name && (
        <CommissionTag
          onDelete={() => functions.filterTagDelete(COMMISSION_FILTER_TAG_FIELDS[3])}
        >
          Hiệu trưởng: {principal.activeValue.name}
        </CommissionTag>
      )}
      {teacher?.activeValue?.name && (
        <CommissionTag
          onDelete={() => functions.filterTagDelete(COMMISSION_FILTER_TAG_FIELDS[4])}
        >
          Giáo viên: {teacher.activeValue.name}
        </CommissionTag>
      )}

      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <CommissionTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </CommissionTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledCommissionTags>
  )
}
