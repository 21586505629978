export const SCRIPT_NOTE_PRODUCT={
    SUCCESS_ACTIVE:'product_category_active_status',
    SUCCESS_DEACTIVE:'product_category_deactive_status',
    CREATE_SUCEESS: "product_page_added_successfully",
    UPDATE_SUCCESS:'product_category_update_success',
    DELETE_SUCESS : 'product_category_delete_success',
    DELETE_ERROR:'product_category_delete_fail',
    VALID_CODE:{
        EMPTY_CODE:'validate_empty_group_product_code',
        SPECIAL_CODE:'validate_customer_group_code',
        HAVE_CODE :'validate_exists_group_product_code',
        MAX_CODE:'product_category_validate_limit',
    },
    VALID_NAME :{
        EMPTY_NAME:'validate_empty_group_product_name',
        MAX_NAME:'product_category_name_validate_limit',
    },
    VALID_NOTE:{
        MAX_NOTE:'note_content_is_allowed_to_input_a_maximum_of_255_characters',
    }
}