import {
  EXPORT_EXCEL_SELECTIVE_ACTIVE,
  EXPORT_CUSTOMER_EXCEL_SELECTIVE,
  CUSTOMER_FILTER_FORM_DATE_TIME_SORT_TYPES, CUSTOMER_FILTER_FORM_CUSTOMER_STATUS,
  CUSTOMER_FILTER_FORM_PAYMENT_METHOD, CUSTOMER_FILTER_FORM_PAYMENT_STATUS,
  CUSTOMER_TABLE_THEAD_PAYMENT_FILTER_LIST, CUSTOMER_FILTER_FORM_PRODUCT_TYPE
} from '../interfaces/_constants'

export const customerInitialState = {
  moveCustomer: {
    dataCurrent: null,
    dataSelect: {
      principal: {
        activeValue: null,
        keyword: '',
        list: [],
        listOrigin: [],
        value: null,
      },
      teacher: {
        activeValue: null,
        keyword: '',
        list: [],
        listOrigin: [],
        value: null,
      },
    },
    modal_confirm: false,
    change_modal: false,
    disabled: false,
    open_modal: false,
    validate: {
      principal: '',
      teacher: '',
    }
  },
  filter: {
    customerId: {
      value: null,
      activeValue: null
    },
    customerInfo: {
      value: null,
      activeValue: null
    },
    dateTime: {
      activeValue: {
        end: null,
        start: null,
        type: CUSTOMER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
        value: null,
      },
      end: null,
      start: null,
      type: CUSTOMER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
      trigger: true,
      value: null,
    },
    status: {
      activeValue: null,
      value: null,
    },
    customerType: {
      activeValue: null,
      value: null,
    },
    search: {
      value: '',
    },
    principal: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    teacher: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
  },
  notifications: {
    list: [],
  },
  table: {
    display: {
      list: [],
      loading: true,
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
    qrHistory: {
      detail: {
        list: [],
        loading: true,
      },
    },
    orderHistory: {
      detail: {
        list: [],
        loading: true,
      },
      filter: {
        orderId: {
          value: null,
          activeValue: null
        },
        dateTime: {
          activeValue: {
            end: null,
            start: null,
            type: CUSTOMER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
            value: null,
          },
          end: null,
          start: null,
          type: CUSTOMER_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
          trigger: true,
          value: null,
        },
        payment: {
          value: CUSTOMER_TABLE_THEAD_PAYMENT_FILTER_LIST.map(item => item?.value),
        },
        product: {
          activeValue: [],
          value: [],
        },
        search: {
          value: '',
        },
        paymentMethod: {
          activeValue: null,
          keyword: '',
          list: CUSTOMER_FILTER_FORM_PAYMENT_METHOD,
          listOrigin: CUSTOMER_FILTER_FORM_PAYMENT_METHOD,
          value: null,
        },
        paymentStatus: {
          activeValue: null,
          keyword: '',
          list: CUSTOMER_FILTER_FORM_PAYMENT_STATUS,
          listOrigin: CUSTOMER_FILTER_FORM_PAYMENT_STATUS,
          value: null,
        },
        productType: {
          activeValue: null,
          keyword: '',
          list: CUSTOMER_FILTER_FORM_PRODUCT_TYPE,
          listOrigin: CUSTOMER_FILTER_FORM_PRODUCT_TYPE,
          value: null,
        },
        orderStatus: {
          activeValue: null,
          keyword: '',
          list: CUSTOMER_FILTER_FORM_CUSTOMER_STATUS,
          listOrigin: CUSTOMER_FILTER_FORM_CUSTOMER_STATUS,
          value: null,
        },
      },
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 0,
      totalItems: 0,
    },
    selected: {
      list: [],
    },
    productTab: {
      filter: {
        search: {
          value: null,
          active: null
        },
        category: {
          list: [],
          listOrigin: [],
          activeValue: null,
          value: null,
          keyword: '',
        },
      },
      table: {
        display: {
          list: [],
          listDefault: [],
          loading: true,
        },
        selected: {
          list: [],
        },
        detail: {
          id: null,
          active: null,
          list: [],
        },
        pagination: {
          active: 0,
          amount: 999,
          total: 0,
          totalItems: 0,
        },
      },
      orderModal: false
    },
  },
  printDetail: {},
  exportExcel: {
    list: EXPORT_CUSTOMER_EXCEL_SELECTIVE,
    activeList: EXPORT_CUSTOMER_EXCEL_SELECTIVE,
    selectedList: EXPORT_EXCEL_SELECTIVE_ACTIVE,
    keyword: '',
    hiddenList: EXPORT_CUSTOMER_EXCEL_SELECTIVE
  },
  formEdit: {
    feeShip: {
      value: '',
      status: true,
      message: ''
    },
    customerName: {
      value: '',
      status: true,
      message: ''
    },
    customerPhone: {
      value: '',
      status: true,
      message: ''
    },
    customerEmail: {
      value: '',
      status: true,
      message: ''
    },
    customerAddress: {
      value: '',
      status: true,
      message: ''
    },
    customerNotes: {
      value: '',
      status: true,
      message: ''
    },
    details: [],
    customer: {}
  }
}