import cls from 'clsx'
import css from './index.module.scss'

const Index = () => {
    return (
        <div className={cls(css.content)}>
            <div className={cls(css.logo)}>
                <img src={'/img/logo-light.png'} alt={'logo'}/>
            </div>
            <p className={cls(css.title)}>NỀN TẢNG QUẢN LÝ BÁN SÁCH</p>
            <p className={cls(css.subtitle)}>Vui lòng truy cập bằng PC hoặc laptop để có trải nghiệm tốt nhất</p>
        </div>
    )
};

export default Index;