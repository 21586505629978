import {useContext} from "react";
import {QrAffiliateContext} from "../provider/_context";
import {useQrAffiliateAction} from "../provider/_action";

export const useQrAffiliateTab = ()=>{
    const {pageState, pageDispatch} = useContext(QrAffiliateContext)
    const {tab} = pageState

    const handleChangeTab = (data) =>
        pageDispatch({type: useQrAffiliateAction.FORM_CREATE_TAB_CHANGE,payload: data})

    return{
        tab:{
            list: tab?.list,
            active: tab?.activeTab,
            onChange: handleChangeTab
        }
    }

}