import React, { useContext, useRef } from 'react'
import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tr } from 'layouts/tableLayout/_tr'
import { Td } from 'layouts/tableLayout/_td'
import { Text } from 'common/text'
import { GLOBAL_ICONS } from 'interface/icon'
import { Tooltip } from 'common/tooltip'
import { CreateTeacherContext } from '../../../../provider/_context'
import ReactImageFallback from 'react-image-fallback'
import { useAddProductModal } from '../../../../hook/useAddProductModal'
import { Checkbox } from '../../../../../../../../common/form/checkbox'
import { useCreateProductAction, useModalProductAction } from '../../../../provider/_action'

const TbodyProduct = () => {
  const { t } = useTranslation()
  const { pageState } = useContext(CreateTeacherContext)
  const { productTab } = pageState
  const { modalProduct } = productTab
  const { table } = modalProduct
  const displayList = table?.display?.list
  const displayListDefault = table?.display?.listDefault
  const displayLoading = table.display.loading
  const paginationAmount = table.pagination.amount
  const paginationTotalItems = table.pagination.totalItems
  // const tableRef = useRef()
  //
  // const handleDropdownScroll = () => {
  //   if (!tableRef?.current || !canLoadMore)
  //     return
  //
  //   const clientHeight = tableRef.current.clientHeight
  //   const scrollHeight = tableRef.current.scrollHeight
  //   const scrollTop = tableRef.current.scrollTop
  //   console.log(clientHeight + scrollTop > scrollHeight - 100,clientHeight , scrollTop,scrollHeight )
  //   // if (clientHeight + scrollTop > scrollHeight - 100) menuProps.onLoadMore()
  // }
  return (
    // <StyledTBody className={'product-table common-scrollbar'} ref={tableRef} onScroll={handleDropdownScroll}>
    <StyledTBody className={'product-table common-scrollbar'}>
      {displayLoading ? (
        Array.from(Array(paginationAmount), (e, i) => (
          <TeacherPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map((item, key) => <TeacherTr t={t} key={item.id} stt={key} data={item}/>)
      ) : (
        <TeacherEmpty t={t} list={displayListDefault}/>
      )}
    </StyledTBody>
  )
}

export default TbodyProduct

const TeacherPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="create-teacher-table">
      {Array.from(Array(3), (e, i) => (
        <Td key={i} className="create-teacher-table_cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const TeacherEmpty = ({ list, t, ...props }) => {
  return (
    <StyledTeacherEmpty {...props}>
      <img
        className="create-teacher-table-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}>
        {t('no_matching_data')}
      </Text>
    </StyledTeacherEmpty>
  )
}

const TeacherTr = ({ data, t, stt, ...props }) => {

  const { pageDispatch } = useContext(CreateTeacherContext)
  const {methods, selected} = useAddProductModal()
  const isSelected = !!selected.list.find(item => item?.id === data?.id)

  const rowCheckboxChange = () =>
    pageDispatch({
      type: useModalProductAction.TABLE_SELECTED_LIST_UPDATE,
      payload: {
        selected: {
          list: isSelected
            ? selected.list.filter(item => item?.id !== data?.id)
            : [...selected.list, data],
        },
      },
    })
  return (
    <>
      <Tr
        {...props}
        className="create-teacher-table__row"
        onClick={e => {
          rowCheckboxChange()
        }}
      >
        <Td className="create-teacher-table__cell" data-type="td">
          <Checkbox
            checked={isSelected}
            onClick={e => {
              e.stopPropagation()
              rowCheckboxChange()
            }}
            className={"create-teacher-table_cell-checkbox"}
          />
          {+stt + 1}
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <div className="create-teacher-table__cell-image">
            <ReactImageFallback
              src={data?.image}
              fallbackImage={'/img/grid-default.png'}
              alt="img bottm img"
            />
          </div>
          <div>
            <Text as={'p'} fontWeight={600}>{data?.product_sku || '---'}</Text>
            <Text as={'p'} color={'#2150B7'}>{data?.title || '---'}</Text>
          </div>
        </Td>
        <Td
          className="create-teacher-table__cell"
          data-menu="true"
          data-type="td"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {!!data?.teacher_id
            ?
            <Text
              className="create-teacher-table__remove"
              onClick={() => methods.removeProductToPrincipal(data?.id)}
            >
              <Tooltip title={'Xóa sản phẩm'}>{GLOBAL_ICONS.recycle} Xóa</Tooltip>
            </Text>
            :

            <Text
              className="create-teacher-table__add"
              onClick={(e) => {
                e.stopPropagation()
                methods.addProductToPrincipal(data?.id)
              }}
            >
              <Tooltip title={'Xóa sản phẩm'}>{GLOBAL_ICONS.plus} Thêm</Tooltip>
            </Text>
          }

        </Td>
      </Tr>
    </>
  )
}

export const StyledTBody = styled.div`

  max-height: calc(80vh - 286px);
  overflow: auto;
  .create-teacher-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;

      width: 100vw;
      height: 100vh;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.25);

      img {
        width: 80px;
        height: 80px;
      }
    }

    &__row {
      &:hover {
        .create-teacher-table__delete {
          display: block;
        }
        .create-teacher-table__edit {
          display: block;
        }
      }
    }

    &__cell {
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }

      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 76px;
        display: flex;
        align-items: center;
        .create-teacher-table_cell-checkbox{
          margin-right: 12px;
        }
      }
      &:nth-child(2) {
        flex: 1;
        .create-teacher-table__cell-image{
          height: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          img{
            height: 100px;
            width: auto
          }
        }
      }
      &:nth-child(3) {
        width: 100px;
        align-items: center;
        justify-content: center;
      }

    }
    &__add {
      width: 20px;
      height: 20px !important;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;
      display: flex;
      align-items: center;
      
      font-size: 12px !important;
      line-height: 24px !important;

      cursor: pointer;
      svg{
        margin-right: 8px;
        path{
          stroke: #3264FF
        }
      }
      span{
        display: flex;
        align-items: center;
      }
    }
    &__remove {
      width: 20px;
      height: 20px !important;
      margin-right: 16px;
      background: transparent;
      border: none;
      span{
        display: flex;
        align-items: center;
      }

      font-size: 12px !important;
      line-height: 24px !important;

      cursor: pointer;
      svg{
        margin-right: 8px;
      }
    }

    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}
`
export const StyledTeacherEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
