import { CategoryInput } from 'common/form/input/_categoryInput'
import { useHistoryOrderFilterForm } from '../../../../hooks/useHistoryOrderFilterForm'

export const ProductSearch = () => {
  const {product} = useHistoryOrderFilterForm()
  return (
  <CategoryInput
    className={'order-filter-form__input-wide'}
    categoryList={[]}
    categoryValue={{name: 'Sản phẩm', value: ''}}
    categoryWidth={84}
    placeholder='Nhập tên sản phẩm'
    value={product?.value || ''}
    onChange={product.onChange}
    style={{ position: 'relative'}}
  />
  )
}
