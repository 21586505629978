import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const AccountOwner = ()=>{
    const {accountOwner} = useCreateTeacherForm()
    const {methods} = accountOwner
    return (
        <Input
            label={
                <>
                    Chủ tài khoản
                </>
            }
            value={accountOwner?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={accountOwner?.message}
            validateType={accountOwner?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập  tên chủ tài khoản'}
            maxLength={201}
        />
    )
}