import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation keyframes
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the loading spinner
const LoadingSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid #1086e9;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

// Loading component
export const Loading = () => {
  return (
  //   <div className="upos-loading-screen upos-text text-tur-dark upos-text-h1"></div>
  // <img className="upos-loading-img" src="/svg/ico-logo.png" />
    <div className="upos-loading-screen upos-text text-tur-dark upos-text-h1">
      <LoadingSpinner className="upos-loading-img" />
    </div>
  );
};


