export const useQrAffiliateAction = {
   UPDATE_QR_CODE: 'UPDATE_QR_CODE',
   IS_LOADING_PANEL: 'IS_LOADING_PANEL',
   UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
   FORM_CREATE_TAB_CHANGE: 'FORM_CREATE_TAB_CHANGE',
   SET_DISPLAY_SHARE_MODAL: 'SET_DISPLAY_SHARE_MODAL',
}
export const useQrCategoryAction = {
   FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'FILTER_CATEGORY_LIST_ORIGIN_UPDATE',
   FILTER_CATEGORY_VALUE_UPDATE: 'PRODUCT_FILTER_CATEGORY_VALUE_UPDATE',
   FILTER_CATEGORY_LIST_UPDATE: 'PRODUCT_FILTER_CATEGORY_LIST_UPDATE',
   TABLE_LOADING_DISPLAY: 'PRODUCT_TABLE_LOADING_DISPLAY',
   TABLE_UPDATE_DISPLAY_LIST: 'PRODUCT_TABLE_UPDATE_DISPLAY_LIST',
   TABLE_UPDATE_PAGINATION: 'PRODUCT_TABLE_UPDATE_PAGINATION',
}

export const useQrEventAction = {
   FILTER_EVENT_LIST_ORIGIN_UPDATE: 'FILTER_EVENT_LIST_ORIGIN_UPDATE',
   FILTER_EVENT_VALUE_UPDATE: 'PRODUCT_FILTER_EVENT_VALUE_UPDATE',
   FILTER_EVENT_LIST_UPDATE: 'PRODUCT_FILTER_EVENT_LIST_UPDATE',
   TABLE_LOADING_DISPLAY: 'EVENT_TABLE_LOADING_DISPLAY',
   TABLE_UPDATE_DISPLAY_LIST: 'EVENT_TABLE_UPDATE_DISPLAY_LIST',
   TABLE_UPDATE_PAGINATION: 'EVENT_TABLE_UPDATE_PAGINATION',
}
export const useQrProductAction = {
   FILTER_SEARCH_UPDATE:'PRODUCT_TAB_FILTER_SEARCH_UPDATE',
   FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'FILTER_PRODUCT_TAB_LIST_ORIGIN_UPDATE',
   FILTER_CATEGORY_VALUE_UPDATE: 'PRODUCT_TAB_FILTER_CATEGORY_VALUE_UPDATE',
   FILTER_CATEGORY_LIST_UPDATE: 'PRODUCT_TAB_FILTER_CATEGORY_LIST_UPDATE',
   TABLE_LOADING_DISPLAY: 'PRODUCT_TAB_TABLE_LOADING_DISPLAY',
   TABLE_UPDATE_DISPLAY_LIST: 'PRODUCT_TAB_TABLE_UPDATE_DISPLAY_LIST',
   TABLE_UPDATE_PAGINATION: 'PRODUCT_TAB_TABLE_UPDATE_PAGINATION',
}