import {CategoryDateTimeRangePicker} from 'common/form/datePicker/_categoryDateTimeRangePicker'
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import { useHistoryOrderFilterForm } from '../../../../hooks/useHistoryOrderFilterForm'

export const getDateFromNow = (n, otp) => {
  let date = new Date()
  if (otp && otp?.type == 'start') date.setHours(0, 0, 0, 0)
  if (otp && otp?.type == 'end') date.setHours(23, 59, 0, 0)
  const res = date.setTime(date.getTime() + n * 24 * 60 * 60 * 1000)
  return new Date(res)
}

export const OrderDateTime = () => {
  const {dateTime} = useHistoryOrderFilterForm()
  const predefinedRanges = [
    {
      label: 'Hôm qua',
      value: [addDays(getDateFromNow(0, {type: 'start'}), -1), addDays(getDateFromNow(0, {type: 'end'}), -1)],
    },
    {
      label: 'Hôm nay',
      value: [getDateFromNow(0, {type: 'start'}), getDateFromNow(0, {type: 'end'})],
    },
    {
      label: '7 ngày trước',
      value: [subDays(getDateFromNow(0, {type: 'start'}), 6), new Date()],
    },
    {
      label: 'Tháng này',
      value: [startOfMonth(getDateFromNow(0, {type: 'start'})), new Date()],
    },
  ];
  const ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES = [
    {id: 1, name: 'Ngày tạo đơn', value: 'created'},
  ]
  return (
    <CategoryDateTimeRangePicker
      className="order-filter-form__input-wide"
      categoryList={ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES}
      categoryWidth={104}
      value={dateTime.value}
      triggerDefault={dateTime.triggerDefault}
      onChange={dateTime.onChange}
      datePickerProps={{
        defaultValue: dateTime.defaultValue,
        disabledDate: dateTime.disabledDate,
        placeholder: 'dd/mm/yyyy HH:mm ~ dd/mm/yyyy HH:mm',
        range: predefinedRanges
      }}
    />
  )
}
