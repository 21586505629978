import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import useInvoiceTHead from 'Pages/invoice/hooks/useInvoiceTHead'

export const InvoiceTHead = ({ ...props }) => {

  const { globalLoading } = useInvoiceTHead()

  return (
    <>
      <Tr {...props} type="tHead">
        <Th className="invoice-table__cell">Phiếu chi</Th>
        <Th className="invoice-table__cell">Giáo viên</Th>
        <Th className="invoice-table__cell">Kỳ thanh toán</Th>
        <Th className="invoice-table__cell">Tổng phải trả trong kỳ
        </Th>
        <Th className="invoice-table__cell">Giá trị chi</Th>
        <Th className="invoice-table__cell">Còn lại</Th>
        <Th className="invoice-table__cell">Trạng thái</Th>
        <Th
          className="invoice-table__cell"
          style={{ display: 'flex' }}
        />
      </Tr>
      {globalLoading.value && (
        <div className="invoice-table__loading">
          <img src="/img/loading.gif"/>
        </div>
      )}
    </>
  )
}
