import {useReducer} from 'react'
import {sendRequestAuth} from 'api/api'
import {useDashboardAction, useDashboardInitialState, useDashboardReducer} from '../provider/_reducer'
import {useEffect} from 'react'
import config from "../../../config";

const useDashboard = () => {
    const [state, dispatch] = useReducer(useDashboardReducer, useDashboardInitialState)

    const handleOriginFetch = () => {
        // xử lý API hiệu trưởng
    }

    return {
        fetch: {
            origin: handleOriginFetch
        },
        provider: {
            state,
            dispatch
        },
    }
}
export default useDashboard