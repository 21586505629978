import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const TeachingSubject = ()=>{
    const {teachingSubject} = useCreateTeacherForm()
    const {methods} = teachingSubject
    return (
        <Input
            label={
                <>
                    Bộ môn
                </>
            }
            value={teachingSubject?.value}
            onChange={e => methods.onChange(e.target?.value)}
            placeholder={'Vui lòng nhập bộ môn'}
            maxLength={201}
        />
    )
}