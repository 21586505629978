export const PAGINATION_ICONS = {
  arrow: (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.495 10.005C12.2216 9.73166 11.7784 9.73166 11.505 10.005C11.2317 10.2784 11.2317 10.7216 11.505 10.995L12.495 10.005ZM16 14.5L16.495 14.995C16.7683 14.7216 16.7683 14.2784 16.495 14.005L16 14.5ZM11.505 18.005C11.2317 18.2784 11.2317 18.7216 11.505 18.995C11.7784 19.2683 12.2216 19.2683 12.495 18.995L11.505 18.005ZM11.505 10.995L15.505 14.995L16.495 14.005L12.495 10.005L11.505 10.995ZM15.505 14.005L11.505 18.005L12.495 18.995L16.495 14.995L15.505 14.005Z"
        fill="#00081D"
      />
    </svg>
  ),
  carotArrow: (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.73258 3.98281L1.98258 7.73281C1.93171 7.78703 1.89723 7.85455 1.88312 7.92754C1.86902 8.00054 1.87588 8.07604 1.90289 8.14531C1.93158 8.21356 1.97983 8.27179 2.04156 8.31267C2.10329 8.35354 2.17573 8.37523 2.24977 8.375L9.74977 8.375C9.8238 8.37523 9.89625 8.35354 9.95798 8.31267C10.0197 8.27179 10.068 8.21356 10.0966 8.14531C10.115 8.09905 10.1246 8.04977 10.1248 8C10.1243 7.90042 10.0858 7.80479 10.017 7.73281L6.26696 3.98281C6.19586 3.91239 6.09984 3.87289 5.99977 3.87289C5.8997 3.87289 5.80368 3.91239 5.73258 3.98281Z"
        fill="black"
      />
    </svg>
  ),
  check: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4742 3.64975C11.7137 3.86527 11.7331 4.2341 11.5176 4.47357L6.26758 10.3069C6.15695 10.4298 5.99936 10.5 5.83399 10.5C5.66862 10.5 5.51103 10.4298 5.4004 10.3069L2.48373 7.06616C2.26822 6.8267 2.28763 6.45786 2.52709 6.24234C2.76656 6.02682 3.13539 6.04624 3.35091 6.2857L5.83399 9.04468L10.6504 3.69311C10.8659 3.45364 11.2348 3.43423 11.4742 3.64975Z"
        fill="#3264FF"
      />
    </svg>
  ),
  doubleArrow: (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49498 9.67169C9.22161 9.39832 8.77839 9.39832 8.50503 9.67169C8.23166 9.94506 8.23166 10.3883 8.50503 10.6616L9.49498 9.67169ZM13.1667 14.3333L13.6616 14.8283C13.935 14.5549 13.935 14.1117 13.6616 13.8384L13.1667 14.3333ZM8.50503 18.005C8.23166 18.2784 8.23166 18.7216 8.50503 18.995C8.77839 19.2683 9.22161 19.2683 9.49498 18.995L8.50503 18.005ZM15.3283 9.67169C15.0549 9.39832 14.6117 9.39832 14.3384 9.67169C14.065 9.94506 14.065 10.3883 14.3384 10.6616L15.3283 9.67169ZM19 14.3333L19.495 14.8283C19.7683 14.5549 19.7683 14.1117 19.495 13.8384L19 14.3333ZM14.3384 18.005C14.065 18.2784 14.065 18.7216 14.3384 18.995C14.6117 19.2683 15.0549 19.2683 15.3283 18.995L14.3384 18.005ZM8.50503 10.6616L12.6717 14.8283L13.6616 13.8384L9.49498 9.67169L8.50503 10.6616ZM12.6717 13.8384L8.50503 18.005L9.49498 18.995L13.6616 14.8283L12.6717 13.8384ZM14.3384 10.6616L18.505 14.8283L19.495 13.8384L15.3283 9.67169L14.3384 10.6616ZM18.505 13.8384L14.3384 18.005L15.3283 18.995L19.495 14.8283L18.505 13.8384Z"
        fill="#00081D"
      />
    </svg>
  ),
}
