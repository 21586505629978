import React, { useReducer } from 'react';
import { userManagementActions, userManagementInitialState, userManagementReducer } from "../provider/_reducer";
import { sendRequestAuth } from "../../../api/api";
import config from "../../../config";
import { transformOriginData } from "../utils/transform";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAlert from "../../../hook/useAlert";
import { PATH } from 'const/path';

const useUserManagement = () => {
    const { showAlert } = useAlert()
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(userManagementReducer, userManagementInitialState)

    // Fetch user detail when edit user information
    const fetchUserDetail = async userId => {
        const response = await sendRequestAuth('get', `${config.API}/user/detail/${userId}`)
        if (response?.data.success) {
            let data = response?.data?.data

            dispatch({
                type: userManagementActions.FORM_UPDATE_USER_INFO,
                payload: {
                    name: {value: data?.name || ''},
                    last_name: {value: data?.last_name || ''},
                    email: {value: data?.email || ''},
                    phone: {value: data?.phone || ''},
                    staff_id: {value: data?.staff_id || ''},
                    department: {id: data?.department || '', name: data?.department_name || ''},
                    status: {id: data?.status || '', name: data?.status_name || ''},
                    group: {id: data?.group || '', name: data?.group_name || ''},
                }
            })
            dispatch({
                type: userManagementActions.FORM_USER_NAME_UPDATE,
                payload: { value: data?.username }
            })
            dispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    name: { status: false, message: '' },
                    last_name: { status: false, message: '' },
                    email: { status: false, message: '' },
                    staff_id: { status: false, message: '' },
                }
            })
        }else{
            navigate(PATH.USER_MANAGEMENT)
            showAlert({ type: 'danger', content: response?.data?.message })
        }
    }

    const collectOriginData = data => {
        let collections = {}
        dispatch({
            type: userManagementActions.FILTER,
            payload: transformOriginData(collections, state),
        })
    }
    const [searchParams] = useSearchParams()
    const handleUserManagement = async () => {
        const keyword = searchParams.get('search') || ''
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/user/users?keyword=${keyword}&group&status&per_page=20&start=0`),
        ])
        collectOriginData(response)
        if (response[0]?.data.success) {
            let { meta } = response[0].data
            dispatch({ type: userManagementActions.LIST_USER, payload: response[0]?.data.data })
            dispatch({ type: userManagementActions.LOADING, payload: true })
            dispatch({
                type: userManagementActions.GET_PAGINATE, payload: {
                    active: 0,
                    amount: meta?.per_page,
                    total: Math.ceil(meta?.total / meta?.per_page),
                    totalItems: meta?.total,
                }
            })
        }
    }
    const queryStartFrom = state.paginate.active * state.paginate.amount

    const queries = {
        keyword: state?.search || '',
        group: state?.filter?.groupEmployee?.activeValue?.id || '',
        status: state?.filter?.groupStatus?.activeValue?.id || '',
        per_page: state.paginate.amount,
        start: queryStartFrom,
    }
    const handlePaginationAmountChange = async n => {
        const currentPage = state.paginate.active || 0
        const totalPages = Math.ceil(state.paginate.totalItems / n)
        const page =
            state.paginate.totalItems < currentPage * n
                ? totalPages - 1
                : currentPage
        let queryStr = '?'
        let i = 0
        for (const [key, value] of Object.entries({
            ...queries,
            per_page: n,
            start: page * n,
        })) {
            queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        dispatch({ type: userManagementActions.LOADING, payload: false })
        const response = await sendRequestAuth(
            'get',
            `${config.API}/user/users${queryStr}`,
        )
        if (response?.data?.success) {
            let { meta } = response.data
            dispatch({ type: userManagementActions.LIST_USER, payload: response?.data.data })
            dispatch({
                type: userManagementActions.GET_PAGINATE, payload: {
                    active: 0,
                    amount: meta?.per_page,
                    total: Math.ceil(meta?.total / meta?.per_page),
                    totalItems: meta?.total,
                }
            })
            dispatch({ type: userManagementActions.LOADING, payload: true })
            dispatch({ type: userManagementActions.IS_CHECK, payload: [] })
            dispatch({ type: userManagementActions.IS_CHECK_ALL, payload: false })
            dispatch({ type: userManagementActions.COUNT, payload: 0 })
        }
    }

    const handlePaginationPageChange = async page => {
        const amount = state.paginate?.amount || 10
        dispatch({ type: userManagementActions.LOADING, payload: false })
        let queryStr = '?'
        let i = 0
        for (const [key, value] of Object.entries({
            ...queries,
            per_page: amount,
            start: page * amount,
        })) {
            queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        // dispatch({type: 'SET_LOADING', payload: false})
        const response = await sendRequestAuth('get', `${config.API}/user/users${queryStr}`,)
        if (response?.data?.success) {
            let meta = response?.data?.meta
            console.log(meta)
            dispatch({ type: userManagementActions.LIST_USER, payload: response?.data?.data })
            dispatch({
                type: userManagementActions.GET_PAGINATE, payload: {
                    active: Math.ceil(meta?.start / meta?.per_page),
                    amount: meta?.per_page,
                    total: Math.ceil(meta?.total / meta?.per_page),
                    totalItems: meta?.total,
                }
            })
            dispatch({ type: userManagementActions.LOADING, payload: true })
            dispatch({ type: userManagementActions.IS_CHECK, payload: [] })
            dispatch({ type: userManagementActions.IS_CHECK_ALL, payload: false })
            dispatch({ type: userManagementActions.COUNT, payload: 0 })
        }
    }
    return {
        provider: { state, dispatch },
        fetch: {
            origin: fetchUserDetail,
            userManagement: handleUserManagement
        },
        handlePaginationAmountChange,
        handlePaginationPageChange
    }
};

export default useUserManagement;