import {Breadcrumb} from 'common/breadcrumb'
import {Button} from 'common/button'
import {Tooltip} from 'common/tooltip'
import { useContext } from 'react'
import PopoverPopupCommission from '../commissionPopper'
import { Link, useLocation } from 'react-router-dom'
import {StyledCommissionPageHeader} from "./_styled";
import {CommissionContext} from "../../provider/_context";

export const PageHeader = ({
  actions,
  breadcrumbLinks,
  breadcrumbSubTitle,
  breadcrumbTitle,
  ...props
}) => {
  const {pageState, pageDispatch} = useContext(CommissionContext)
  const {loading} = pageState.table.display.loading
  const location = useLocation()
  return (
    <StyledCommissionPageHeader {...props}>
      <div className="commission-page-header__breadcrumb">
        <Breadcrumb
          links={breadcrumbLinks}
          subTitle={breadcrumbSubTitle}
          title={breadcrumbTitle}
        />
      </div>
      <div className="commission-page-header__actions">
        {Array.isArray(actions) &&
          actions.map(item => (
            <div key={item?.id} className={"commission-page-header__action-item " + item?.className}>
              {item?.type == 'dropdown'
              ?
                <PopoverPopupCommission item={item}/>
              :
                (item?.isDeveloping ? (
                  <Tooltip placement="bottom" title="Tính năng đang phát triển">
                    <Button
                      appearance={item?.appearance}
                      icon={item?.icon}
                      onClick={item?.onClick}
                      disabled={!loading}
                    >
                      {item?.name}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    href={item?.href}
                    state={{from: location.pathname}}
                    appearance={item?.appearance}
                    icon={item?.icon}
                    onClick={item?.onClick}
                    disabled={loading}
                    >
                    {item?.name}
                  </Button>
                ))
              }
            </div>

          ))}
      </div>
    </StyledCommissionPageHeader>
  )
}
