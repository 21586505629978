import {Button} from 'common/button'
import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import React from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

export default function NotFound({className = '', style}) {
  return (
    <div className={`p-404 ${className}`}>
      <div className="p-404__container">
        <img
          src={'/img/no-connection/page-not-found.png'}
          alt="error-404"
          className="p-404__img-error"
        />
        <Text
          fontSize={32}
          fontWeight={600}
          textAlign="center"
          style={{display: 'block', margin: '0 auto 1rem'}}
        >
          Ối, không tìm thấy trang rồi!
        </Text>
        <Text
          fontSize={18}
          style={{width: '100%', textAlign: 'center',display: 'block', color: '#7C88A6', marginBottom: '1.5rem'}}
        >
          Nội dung bạn yêu cầu không không tồn tại, vui lòng kiểm tra lại đường
          dẫn trang.
        </Text>
        <Link to="/">
          <Button appearance="secondary" style={{width: '214px', height: '36px'}}>
            Về trang chủ
          </Button>
        </Link>
      </div>
    </div>
  )
}
