import React, { useEffect } from 'react';
import { GridLayout } from "../../layouts/gridLayout";
import { PageHeader } from "../../layouts/pageHeader";
import { CREATE_PRODUCT_CONSTANTS } from "./interfaces/~constants";
import styled from "styled-components";
import ActionFormBrnList from "./conponents/actionFormBrnList";

import { ProfileProvider } from "./provider";
import useProfile from "./hooks/useProfile";
import { useTranslation } from "react-i18next";
import InfoBank from './conponents/_infoBank';
import InfoBasic from "./conponents/_infoBasic";
import InfoAccount from "./conponents/_infoAccount";
import {InfoCommission} from "./conponents/infoComission";

const UpdateProfile = ({ ...props }) => {
    const { t } = useTranslation()
    const { fetch, provider } = useProfile()
    const { state, dispatch } = provider

    useEffect(() => {
        fetch.origin()
    }, [])

    return (
        <ProfileProvider value={{ pageState: state, pageDispatch: dispatch }}>
            <StyledCreateProduct>
                <GridLayout
                    {...props}
                    header={
                        <PageHeader
                            breadcrumbLinks={CREATE_PRODUCT_CONSTANTS.header.breadcrumb}
                            breadcrumbTitle='Thông tin người dùng'
                        />
                    }
                    grid={[
                        {
                            width: 100,
                            sections: [
                                {
                                    title: t('Thông tin tài khoản'),
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoAccount/>,
                                    },
                                },
                                {
                                    title: t('Thông tin cá nhân'),
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoBasic />,
                                    },
                                },
                                {
                                    title: t('Thông tin hoa hồng'),
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoCommission />,
                                    },
                                },
                                {
                                    title: t('Thông tin ngân hàng'),
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <InfoBank />,
                                    },
                                },
                                {
                                    type: 'sticky-bottom-transparent',
                                    props: {
                                        style: {
                                            position: 'sticky',
                                            bottom: -44,
                                            marginBottom: 0,
                                            zIndex: 10,
                                            padding: "12px 24px 0 12px"
                                        },
                                        children: <ActionFormBrnList />,
                                    },
                                },
                            ],
                            props: { style: { position: 'relative' } },
                        }
                    ]}
                    data-model="container"
                />
            </StyledCreateProduct>
        </ProfileProvider>
    );
};

export default UpdateProfile;

export const StyledCreateProduct = styled.div`
    .grid-layout {
        &__header {
            & .breadcrumb__title h1 {
                line-height: 140% !important;
                min-width: 13.1875rem !important;
                height: 2.125rem !important;
            }
        }
    }
`