import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";
import {USER_PROFILE} from "../../../../../../Component/Icons";

export const PasswordConfirm = () => {
    const {passwordConfirm, typeInput} = useCreateTeacherForm()
    const {methods} = passwordConfirm
    return (
        <Input
            label={
                <>
                    <span>Xác nhận mật khẩu</span>
                    <Text color={THEME_SEMANTICS.failed}> *</Text>
                </>
            }
            value={passwordConfirm?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={passwordConfirm?.message}
            validateType={passwordConfirm?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập mật khẩu xác nhận'}
            maxLength={32}
            type={typeInput?.confirmPassword}
            icon={typeInput?.confirmPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> :
                <i>{USER_PROFILE.eye}</i>}
            onIconClick={() => passwordConfirm?.methods?.onChangeTypeConfirmPassword()}
            autoComplete={'new-passwordConfirm'}
        />
    )
}