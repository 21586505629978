import {Text} from '../../../common/text'
import styled from 'styled-components'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import ReactImageFallback from "react-image-fallback"
import useProfileUpdate from '../hooks/useProfileUpdate'
import useGlobalContext from 'containerContext/storeContext'

const infoAccount = () => {
    const {t} = useTranslation()
    const { value, functions} = useProfileUpdate()
    const data = value?.userData;

    const [globalState,] = useGlobalContext()
    const userState = globalState?.user;

    return (
        <StyleInformation>
            <div className="create-principal-avatar">
                <div className="create-principal-avatar_teacher" onMouseLeave={()=>functions.setHover(false)} onMouseEnter={()=>functions.setHover(true)}>
                    <input 
                        title="" type='file' accept="image/png, image/jpeg"
                        onChange={functions.onUploadAvatar}
                        className='create-principal_update_image'
                    />
                    <ReactImageFallback
                        src={data?.avatar || '/img/avatar_default.png'}
                        alt={data?.name}
                        fallbackImage="/img/avatar_default.png"
                        style={{width: 102, height: 102, borderRadius: 8}}
                    />
                    {value.avatarHover && <div className={'hover_image'}><img src={'/img/camera-01.png'} /></div>}
                </div>
                <div style={{marginLeft: 20, display: 'flex', alignItems: 'center'}}>
                    <div>
                        <Text as={'p'} style={{fontWeight : 'bold'}}>{userState?.last_name} {userState?.name}</Text>
                        <Text as={'p'} color={'rgba(0, 0, 0, 0.50)'}>
                            {userState?.username}
                        </Text>
                    </div>
                </div>
            </div>
        </StyleInformation>
    )
}

const StyleInformation = styled.div`
    max-width: 1366px;
    margin: 0 auto;
    .create-principal{
        &-tabs{
            display: flex;
        }
        &-tab{
            height: 36px;
            padding: 10px 32px;
            border-radius: 8px 8px 0px 0px;
            background: #E2EAF8;
            cursor: pointer;
            margin-right: 4px;
            &[data-active = true]{
               background: #ffffff;
            }
        }
        &-tabs_content{
            padding-top: 24px;
            background: #ffffff;
            max-width: 1360px;
        }
        &-avatar{
            background: #ffffff;
            border-radius: 8px;  
            display: flex;
        }
        &-avatar_teacher{
            position: relative;
           
        }
        &_update_image{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .grid-layout__section {
        margin: 0px 9px 24px;
        &:nth-child(1){
            border-radius: 0px 8px 8px 8px;
        }    
    }
    .hover_image {
        position: absolute;
        top: 0;
        left: 0;
        width: 102px;
        height: 102px;
        border-radius: 8px;
        background: #7093b5ad;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export default infoAccount
