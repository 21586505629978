import { formatDatetime } from 'common/form/datePicker/_functions'
import {
  STUDENT_FILTER_TAG_FIELDS
} from '../interfaces/_constants'
import { getDateFromNow } from '../utils/date'

export const useProductAction = {
  FILTER_SEARCH_UPDATE: 'PRODUCT_TAB_FILTER_SEARCH_UPDATE',
  FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'FILTER_PRODUCT_TAB_LIST_ORIGIN_UPDATE',
  FILTER_CATEGORY_VALUE_UPDATE: 'PRODUCT_TAB_FILTER_CATEGORY_VALUE_UPDATE',
  FILTER_CATEGORY_LIST_UPDATE: 'PRODUCT_TAB_FILTER_CATEGORY_LIST_UPDATE',
  TABLE_LOADING_DISPLAY: 'PRODUCT_TAB_TABLE_LOADING_DISPLAY',
  TABLE_UPDATE_DISPLAY_LIST: 'PRODUCT_TAB_TABLE_UPDATE_DISPLAY_LIST',
  TABLE_UPDATE_PAGINATION: 'PRODUCT_TAB_TABLE_UPDATE_PAGINATION',
  UPDATE_MODAL_ORDER: 'UPDATE_MODAL_ORDER',
}

export const studentActions = {
  TABLE_AMOUNT_UPDATE: 'TABLE_AMOUNT_UPDATE',
  //  FILTER
  FILTER_ADVANCED_SEARCH_UPDATE: 'FILTER_ADVANCED_SEARCH_UPDATE',
  FILTER_DATE_TIME_UPDATE: 'FILTER_DATE_TIME_UPDATE',
  FILTER_ACTIVE_DATE_TIME_UPDATE: 'FILTER_ACTIVE_DATE_TIME_UPDATE',
  FILTER_DATE_TIME_TRIGGER_UPDATE: 'FILTER_DATE_TIME_TRIGGER_UPDATE',
  FILTER_DUPLICATE_UPDATE: 'FILTER_DUPLICATE_UPDATE',
  FILTER_EMPLOYEE_UPDATE: 'FILTER_EMPLOYEE_UPDATE',
  FILTER_EMPLOYEE_CATEGORY_UPDATE: 'FILTER_EMPLOYEE_CATEGORY_UPDATE',
  FILTER_EMPLOYEE_KEYWORD_UPDATE: 'FILTER_EMPLOYEE_KEYWORD_UPDATE',
  FILTER_EMPLOYEE_TAB_UPDATE: 'FILTER_EMPLOYEE_TAB_UPDATE',
  FILTER_ORIGIN_DATA_UPDATE: 'FILTER_ORIGIN_DATA_UPDATE',
  FILTER_PAYMENT_UPDATE: 'FILTER_PAYMENT_UPDATE',
  FILTER_PRODUCT_ENABLE_LOADING: 'FILTER_PRODUCT_ENABLE_LOADING',
  FILTER_PRODUCT_KEYWORD_UPDATE: 'FILTER_PRODUCT_KEYWORD_UPDATE',
  FILTER_PRODUCT_TAB_UPDATE: 'FILTER_PRODUCT_TAB_UPDATE',
  FILTER_PRODUCT_UPDATE: 'FILTER_PRODUCT_UPDATE',
  FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
  FILTER_PAYMENT_METHOD_KEYWORD_UPDATE:
    'FILTER_PAYMENT_METHOD_KEYWORD_UPDATE',
  FILTER_PAYMENT_METHOD_UPDATE: 'FILTER_PAYMENT_METHOD_UPDATE',
  FILTER_PRINCIPAL_KEYWORD_UPDATE: 'FILTER_PRINCIPAL_KEYWORD_UPDATE',
  FILTER_PRINCIPAL_UPDATE: 'FILTER_PRINCIPAL_UPDATE',
  FILTER_STUDENT_KEYWORD_UPDATE: 'FILTER_STUDENT_KEYWORD_UPDATE',
  FILTER_STUDENT_UPDATE: 'FILTER_STUDENT_UPDATE',
  FILTER_STUDENT_LIST_UPDATE: 'FILTER_STUDENT_LIST_UPDATE',
  FILTER_STUDENT_ID_UPDATE: 'FILTER_STUDENT_ID_UPDATE',
  FILTER_STUDENT_INFO_UPDATE: 'FILTER_STUDENT_INFO_UPDATE',
  FILTER_SHIPPING_STATUS_KEYWORD_UPDATE:
    'FILTER_SHIPPING_STATUS_KEYWORD_UPDATE',
  FILTER_SHIPPING_STATUS_TAB_UPDATE: 'FILTER_SHIPPING_STATUS_TAB_UPDATE',
  FILTER_SHIPPING_STATUS_UPDATE: 'FILTER_SHIPPING_STATUS_UPDATE',
  FILTER_SOURCE_KEYWORD_UPDATE: 'FILTER_SOURCE_KEYWORD_UPDATE',
  FILTER_SOURCE_UPDATE: 'FILTER_SOURCE_UPDATE',
  FILTER_STUDENT_STATUS_KEYWORD_UPDATE: 'FILTER_STUDENT_STATUS_KEYWORD_UPDATE',
  FILTER_STUDENT_STATUS_UPDATE: 'FILTER_STUDENT_STATUS_UPDATE',
  OTHER_FILTER_APPLY: 'OTHER_FILTER_APPLY',
  TAG_FILTER_DELETE: 'TAG_FILTER_DELETE',
  // NOTIFICATIONS
  NOTIFICATIONS_LIST_UPDATE: 'NOTIFICATIONS_LIST_UPDATE',
  // PANELS
  PANELS_UPDATE: 'PANELS_UPDATE',
  PRINT_DETAIL_UPDATE: 'PRINT_DETAIL_UPDATE',
  // TABLE
  TABLE_DISPLAY_DATA_UPDATE: 'TABLE_DISPLAY_DATA_UPDATE',
  TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
  TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
  TABLE_DISPLAY_LOADING_UPDATE: 'TABLE_DISPLAY_LOADING_UPDATE',
  TABLE_PAGINATION_UPDATE: 'TABLE_PAGINATION_UPDATE',
  TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',

  //selective export excel
  UPDATE_SELECTED_LIST_ACTIVE: 'UPDATE_SELECTED_LIST_ACTIVE',
  SEARCH_SELECTED_LIST_ACTIVE: 'SEARCH_SELECTED_LIST_ACTIVE',
  UPDATE_DRAG_SELECTED_LIST: 'UPDATE_DRAG_SELECTED_LIST',

  // EDIT STUDENT
  UPDATE_ORIGIN_DATA_FORM_EDIT: 'UPDATE_ORIGIN_DATA_FORM_EDIT',
  FORM_INFOMATION_UPDATE: 'FORM_INFOMATION_UPDATE',
  UPDATE_FORM_EDIT_DETAILS: 'UPDATE_FORM_EDIT_DETAILS',
  UPDATE_FORM_EDIT_DETAILS_TOTAL: 'UPDATE_FORM_EDIT_DETAILS_TOTAL',

  //  FILTER
  FILTER_HISTORY_DATE_TIME_UPDATE: 'FILTER_HISTORY_DATE_TIME_UPDATE',
  FILTER_HISTORY_ACTIVE_DATE_TIME_UPDATE: 'FILTER_HISTORY_ACTIVE_DATE_TIME_UPDATE',
  FILTER_HISTORY_DATE_TIME_TRIGGER_UPDATE: 'FILTER_HISTORY_DATE_TIME_TRIGGER_UPDATE',
  FILTER_HISTORY_ORIGIN_DATA_UPDATE: 'FILTER_HISTORY_ORIGIN_DATA_UPDATE',
  FILTER_HISTORY_PAYMENT_UPDATE: 'FILTER_HISTORY_PAYMENT_UPDATE',
  FILTER_HISTORY_PRODUCT_ENABLE_LOADING: 'FILTER_HISTORY_PRODUCT_ENABLE_LOADING',
  FILTER_HISTORY_PRODUCT_KEYWORD_UPDATE: 'FILTER_HISTORY_PRODUCT_KEYWORD_UPDATE',
  FILTER_HISTORY_PRODUCT_TAB_UPDATE: 'FILTER_HISTORY_PRODUCT_TAB_UPDATE',
  FILTER_HISTORY_PRODUCT_UPDATE: 'FILTER_HISTORY_PRODUCT_UPDATE',
  FILTER_HISTORY_SEARCH_UPDATE: 'FILTER_HISTORY_SEARCH_UPDATE',
  FILTER_HISTORY_PAYMENT_METHOD_KEYWORD_UPDATE:
    'FILTER_HISTORY_PAYMENT_METHOD_KEYWORD_UPDATE',
  FILTER_HISTORY_PAYMENT_METHOD_UPDATE: 'FILTER_HISTORY_PAYMENT_METHOD_UPDATE',
  FILTER_HISTORY_ORDER_ID_UPDATE: 'FILTER_HISTORY_ORDER_ID_UPDATE',
  FILTER_HISTORY_SHIPPING_STATUS_KEYWORD_UPDATE:
    'FILTER_HISTORY_SHIPPING_STATUS_KEYWORD_UPDATE',
  FILTER_HISTORY_SHIPPING_STATUS_TAB_UPDATE: 'FILTER_HISTORY_SHIPPING_STATUS_TAB_UPDATE',
  FILTER_HISTORY_SHIPPING_STATUS_UPDATE: 'FILTER_HISTORY_SHIPPING_STATUS_UPDATE',
  FILTER_HISTORY_PAYMENT_STATUS_KEYWORD_UPDATE: 'FILTER_HISTORY_PAYMENT_STATUS_KEYWORD_UPDATE',
  FILTER_HISTORY_PAYMENT_STATUS_UPDATE: 'FILTER_HISTORY_PAYMENT_STATUS_UPDATE',
  FILTER_HISTORY_ORDER_STATUS_KEYWORD_UPDATE: 'FILTER_HISTORY_ORDER_STATUS_KEYWORD_UPDATE',
  FILTER_HISTORY_ORDER_STATUS_UPDATE: 'FILTER_HISTORY_ORDER_STATUS_UPDATE',
  OTHER_FILTER_HISTORY_APPLY: 'OTHER_FILTER_HISTORY_APPLY',
  UPDATE_HISTORY_TABLE: 'UPDATE_HISTORY_TABLE',
  FILTER_HISTORY_PRODUCT_TYPE_UPDATE: 'FILTER_HISTORY_PRODUCT_TYPE_UPDATE',
  FILTER_HISTORY_PRODUCT_TYPE_KEYWORD_UPDATE: 'FILTER_HISTORY_PRODUCT_TYPE_KEYWORD_UPDATE',
  UPDATE_QR_HISTORY_TABLE: 'UPDATE_QR_HISTORY_TABLE',
  HISTORY_TABLE_DISPLAY_LOADING_UPDATE: "HISTORY_TABLE_DISPLAY_LOADING_UPDATE",
  HISTORY_QRCODE_TABLE_DISPLAY_LOADING_UPDATE: "HISTORY_QRCODE_TABLE_DISPLAY_LOADING_UPDATE"
}

export const dateTimeDefaultValue = [
  getDateFromNow(-7, { type: 'start' }),
  getDateFromNow(0, { type: 'end' }),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
  dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const studentReducer = (state, action) => {
  switch (action.type) {

    case studentActions.FILTER_ADVANCED_SEARCH_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          advancedSearch: {
            ...state.filter.advancedSearch,
            student: {
              ...state.filter.advancedSearch.student,
              keyword:
                typeof action.payload?.student?.keyword === 'string'
                  ? action.payload.student.keyword
                  : state.filter.advancedSearch.student.keyword,
              value:
                typeof action.payload?.student?.value === 'string'
                  ? action.payload.student.value
                  : state.filter.advancedSearch.student.value,
            },
            liveVideoId:
              typeof action.payload?.liveVideoId === 'string'
                ? action.payload.liveVideoId
                : state.filter.advancedSearch.liveVideoId,
          },
        },
      }

    case studentActions.FILTER_DATE_TIME_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            end: action.payload?.end,
            start: action.payload?.start,
            type: action.payload?.type,
            value: action.payload?.value,
          },
        },
      }

    case studentActions.FILTER_ACTIVE_DATE_TIME_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              ...state.filter.dateTime.activeValue,
              end: action.payload?.end,
              start: action.payload?.start,
              type: action.payload?.type,
              value: action.payload?.value,
            },
          },
        },
      }

    case studentActions.FILTER_DATE_TIME_TRIGGER_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            trigger: action.payload?.trigger,
          },
        },
      }

    case studentActions.FILTER_ORIGIN_DATA_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            list: action.payload?.product?.list || [],
            listOrigin: action.payload?.product?.list || [],
          },
          principal: {
            ...state.filter.principal,
            list: action.payload?.principal?.list || [],
            listOrigin: action.payload?.principal?.list || [],
          },
        },
      }

    case studentActions.FILTER_PAYMENT_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          payment: {
            ...state.filter.payment,
            value: action.payload?.payment?.value || [],
          },
        },
      }

    case studentActions.FILTER_PRODUCT_ENABLE_LOADING:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            loading: true,
          },
        },
      }

    case studentActions.FILTER_PRODUCT_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            keyword: action.payload.keyword,
            list: action.payload.list,
            loading: false,
            tab: action.payload?.tab || state.filter.product.tab,
          },
        },
      }

    case studentActions.FILTER_PRODUCT_TAB_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            keyword: '',
            tab: action.payload.tab,
            list: action.payload.list,
          },
        },
      }

    case studentActions.FILTER_PRODUCT_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          product: {
            ...state.filter.product,
            list: action?.payload?.list,
            value: action.payload?.value || [],
          },
        },
      }
    case studentActions.FILTER_SEARCH_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: {
            ...state.filter.search,
            value: action.payload.value || '',
          },
        },
      }

    case studentActions.FILTER_STUDENT_ID_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          studentId: {
            ...state.filter.studentId,
            ...action.payload,
          },
        },
      }

    case studentActions.FILTER_STUDENT_INFO_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          studentInfo: {
            ...state.filter.studentInfo,
            ...action.payload,
          },
        },
      }


    // Payment method

    case studentActions.FILTER_PAYMENT_METHOD_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMethod: {
            ...state.filter.paymentMethod,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case studentActions.FILTER_PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMethod: {
            ...state.filter.paymentMethod,
            value: action.payload?.value || null,
          },
        },
      }

    //  principals

    case studentActions.FILTER_PRINCIPAL_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          principal: {
            ...state.filter.principal,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case studentActions.FILTER_PRINCIPAL_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          principal: {
            ...state.filter.principal,
            value: action.payload?.value || null,
          },
        },
      }
    //  students

    case studentActions.FILTER_STUDENT_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          student: {
            ...state.filter.student,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case studentActions.FILTER_STUDENT_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          student: {
            ...state.filter.student,
            value: action.payload?.value || null,
          },
        },
      }

    case studentActions.FILTER_STUDENT_LIST_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          student: {
            ...state.filter.student,
            list: action.payload?.student?.list || [],
            listOrigin: action.payload?.student?.list || [],
          },
        },
      }


    // Payment status

    case studentActions.FILTER_SOURCE_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentStatus: {
            ...state.filter.paymentStatus,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case studentActions.FILTER_SOURCE_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentStatus: {
            ...state.filter.paymentStatus,
            value: action.payload?.value || null,
          },
        },
      }

    // student status

    case studentActions.FILTER_STUDENT_STATUS_KEYWORD_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          studentStatus: {
            ...state.filter.studentStatus,
            keyword: action.payload.keyword,
            list: action.payload.list,
          },
        },
      }

    case studentActions.FILTER_STUDENT_STATUS_UPDATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          studentStatus: {
            ...state.filter.studentStatus,
            value: action.payload?.value || null,
          },
        },
      }

    case studentActions.NOTIFICATIONS_LIST_UPDATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: action.payload?.notifications?.list || [],
        },
      }

    case studentActions.OTHER_FILTER_APPLY:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateTime: {
            ...state.filter.dateTime,
            activeValue: {
              end: state.filter.dateTime.end,
              start: state.filter.dateTime.start,
              type: state.filter.dateTime.type,
              value: state.filter.dateTime.value,
            },
          },
          paymentMethod: {
            ...state.filter.paymentMethod,
            activeValue: state.filter.paymentMethod.value,
          },
          principal: {
            ...state.filter.principal,
            activeValue: state.filter.principal.value,
          },
          paymentStatus: {
            ...state.filter.paymentStatus,
            activeValue: state.filter.paymentStatus.value,
          },
          studentStatus: {
            ...state.filter.studentStatus,
            activeValue: state.filter.studentStatus.value,
          },
          product: {
            ...state.filter.product,
            activeValue: state.filter.product.value,
          },
          studentId: {
            ...state.filter.studentId,
            activeValue: state.filter.studentId.value,
          },
          studentInfo: {
            ...state.filter.studentInfo,
            activeValue: state.filter.studentInfo.value,
          },
          student: {
            ...state.filter.student,
            activeValue: state.filter.student.value,
          },
        },
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action.payload?.pagination?.totalItems,
          },
        },
      }

    case studentActions.PANELS_UPDATE:
      return {
        ...state,
        panels: {
          ...state.panels,
          codTotal: action.payload?.panels?.codTotal,
          studentTotal: action.payload?.panels?.studentTotal,
          studentValueTotal: action.payload?.panels?.studentValueTotal,
          shippingFeeTotal: action.payload?.panels?.shippingFeeTotal,
        },
        table: {
          ...state.table,
          pagination: {
            ...state.table.pagination,
            total: Math.ceil(
              action.payload.pagination.totalItems /
              state.table.pagination.amount,
            ),
            totalItems: action.payload.pagination.totalItems,
          },
        },
      }

    case studentActions.PRINT_DETAIL_UPDATE:
      console.log(action.payload)
      return {
        ...state,
        printDetail: action.payload
      }

    case studentActions.TABLE_AMOUNT_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
            amount: action.payload?.pagination?.amount,
            total: action.payload?.pagination?.total,
            totalItems: action?.payload?.pagination?.totalItems,
          },
        },
      }

    case studentActions.TABLE_DISPLAY_DATA_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
        },
      }

    case studentActions.TABLE_DISPLAY_DETAIL_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            active: action.payload?.active || null,
            list: action.payload?.list || [],
          },
        },
      }

    case studentActions.TABLE_DISPLAY_DETAIL_ID_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          detail: {
            ...state.table.detail,
            id: action.payload?.id || null,
          },
        },
      }

    case studentActions.TABLE_DISPLAY_LOADING_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            loading: action.payload?.table?.display?.loading,
          },
        },
      }

    case studentActions.TABLE_PAGINATION_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state.table.display,
            list: action.payload?.display?.list || [],
          },
          pagination: {
            ...state.table.pagination,
            active: action.payload?.pagination?.active,
          },
        },
      }

    case studentActions.TABLE_SELECTED_LIST_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          selected: {
            ...state.table.selected,
            list: action.payload?.selected?.list || [],
          },
        },
      }

    case studentActions.TAG_FILTER_DELETE:
      switch (action.payload?.type) {
        case 'dateTime.current':
          return {
            ...state,
            filter: {
              ...state.filter,
              dateTime: {
                ...state.filter.dateTime,
                activeValue: {
                  end: null,
                  start: null,
                  type: '',
                  value: null,
                },
                end: null,
                start: null,
                type: '',
                value: null,
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[8]:
          return {
            ...state,
            filter: {
              ...state.filter,
              dateTime: {
                ...state.filter.dateTime,
                activeValue: {
                  end: null,
                  start: null,
                  type: '',
                  value: null,
                },
                end: null,
                start: null,
                type: '',
                value: null,
              },
            },
          }

        case STUDENT_FILTER_TAG_FIELDS[0]:
          return {
            ...state,
            filter: {
              ...state.filter,
              studentId: {
                ...state.filter.studentId,
                activeValue: null,
                value: null,
              },
            },
          }

        case STUDENT_FILTER_TAG_FIELDS[1]:
          return {
            ...state,
            filter: {
              ...state.filter,
              studentInfo: {
                ...state.filter.studentInfo,
                activeValue: null,
                value: null,
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[2]:
          return {
            ...state,
            filter: {
              ...state.filter,
              product: {
                ...state.filter.product,
                activeValue: [],
                keyword: '',
                loading: false,
                tab: 'all', // all | checked
                value: [],
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[3]:
          return {
            ...state,
            filter: {
              ...state.filter,
              paymentStatus: {
                ...state.filter.paymentStatus,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[4]:
          return {
            ...state,
            filter: {
              ...state.filter,
              principal: {
                ...state.filter.principal,
                activeValue: null,
                keyword: '',
                value: null,
              },
              student: {
                ...state.filter.student,
                activeValue: null,
                keyword: '',
                value: null,
                list: [],
                listOrigin: [],
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[5]:
          return {
            ...state,
            filter: {
              ...state.filter,
              student: {
                ...state.filter.student,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[6]:
          return {
            ...state,
            filter: {
              ...state.filter,
              paymentMethod: {
                ...state.filter.paymentMethod,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        case STUDENT_FILTER_TAG_FIELDS[7]:
          return {
            ...state,
            filter: {
              ...state.filter,
              studentStatus: {
                ...state.filter.studentStatus,
                activeValue: null,
                keyword: '',
                value: null,
              },
            },
          }
        default:
          return { ...state }
      }
    case studentActions.UPDATE_SELECTED_LIST_ACTIVE:
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          activeList: action.payload.active,
          list: action.payload.list,
          selectedList: action.payload.selected,
        }
      }
    case studentActions.SEARCH_SELECTED_LIST_ACTIVE:
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          activeList: action.payload.activeList,
          keyword: action.payload.keyword,
        }
      }
    case studentActions.UPDATE_DRAG_SELECTED_LIST:
      return {
        ...state,
        exportExcel: {
          ...state.exportExcel,
          selectedList: action.payload,
        }
      }

    case studentActions.UPDATE_ORIGIN_DATA_FORM_EDIT:
      return {
        ...state,
        formEdit: {
          ...state.formEdit,
          ...action.payload,
        }
      }

    case studentActions.UPDATE_FORM_EDIT_DETAILS:
      return {
        ...state,
        formEdit: {
          ...state.formEdit,
          details: action.payload,
        }
      }

    case studentActions.UPDATE_FORM_EDIT_DETAILS_TOTAL:
      return {
        ...state,
        formEdit: {
          ...state.formEdit,
          student: {
            ...state.formEdit.student,
            ...action.payload,
          }
        }
      }

    // QR  HISTORY ORDER

    case studentActions.UPDATE_QR_HISTORY_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          qrHistory: {
            ...state.table.qrHistory,
            detail: {
              ...state.table.qrHistory.detail,
              list: action.payload,
              loading: false,
            },
          }
        }
      }


    // HISTORY ORDER

    case studentActions.UPDATE_HISTORY_TABLE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            detail: {
              ...state.table.orderHistory.detail,
              list: action.payload,
              loading: false,
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_DATE_TIME_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              dateTime: {
                ...state.table.orderHistory.filter.dateTime,
                end: action.payload?.end,
                start: action.payload?.start,
                type: action.payload?.type,
                value: action.payload?.value,
                trigger: !state.table.orderHistory.filter.dateTime.trigger
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_ACTIVE_DATE_TIME_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              dateTime: {
                ...state.table.orderHistory.filter.dateTime,
                activeValue: {
                  ...state.table.orderHistory.filter.dateTime.activeValue,
                  end: action.payload?.end,
                  start: action.payload?.start,
                  type: action.payload?.type,
                  value: action.payload?.value,
                },
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_DATE_TIME_TRIGGER_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              dateTime: {
                ...state.table.orderHistory.filter.dateTime,
                trigger: action.payload?.trigger,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_ORIGIN_DATA_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              product: {
                ...state.table.orderHistory.filter.product,
                list: action.payload?.product?.list || [],
                listOrigin: action.payload?.product?.list || [],
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PAYMENT_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              payment: {
                ...state.table.orderHistory.filter.payment,
                value: action.payload?.payment?.value || [],
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PRODUCT_ENABLE_LOADING:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              product: {
                ...state.table.orderHistory.filter.product,
                loading: true,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PRODUCT_KEYWORD_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              product: {
                ...state.table.orderHistory.filter.product,
                keyword: action.payload.keyword,
                list: action.payload.list,
                loading: false,
                tab: action.payload?.tab || state.table.orderHistory.filter.product.tab,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PRODUCT_TAB_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              product: {
                ...state.table.orderHistory.filter.product,
                keyword: '',
                tab: action.payload.tab,
                list: action.payload.list,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PRODUCT_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              product: {
                ...state.table.orderHistory.filter.product,
                value: action.payload?.value || [],
              },
            },
          }
        }
      }
    case studentActions.FILTER_HISTORY_SEARCH_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              search: {
                ...state.table.orderHistory.filter.search,
                value: action.payload.value || '',
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_ORDER_ID_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              orderId: {
                ...state.table.orderHistory.filter.orderId,
                ...action.payload,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_STUDENT_INFO_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              studentInfo: {
                ...state.table.orderHistory.filter.studentInfo,
                ...action.payload,
              },
            },
          }
        }
      }


    // Payment method

    case studentActions.FILTER_HISTORY_PAYMENT_METHOD_KEYWORD_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              paymentMethod: {
                ...state.table.orderHistory.filter.paymentMethod,
                keyword: action.payload.keyword,
                list: action.payload.list,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              paymentMethod: {
                ...state.table.orderHistory.filter.paymentMethod,
                value: action.payload?.value || null,
              },
            },
          }
        }
      }
    // Payment status

    case studentActions.FILTER_HISTORY_PAYMENT_STATUS_KEYWORD_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              paymentStatus: {
                ...state.table.orderHistory.filter.paymentStatus,
                keyword: action.payload.keyword,
                list: action.payload.list,
              },
            },
          }
        }
      }
    case studentActions.FILTER_HISTORY_PAYMENT_STATUS_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              paymentStatus: {
                ...state.table.orderHistory.filter.paymentStatus,
                value: action.payload?.value || null,
              },
            },
          }
        }
      }

      //product type

    case studentActions.FILTER_HISTORY_PRODUCT_TYPE_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              productType: {
                ...state.table.orderHistory.filter.productType,
                value: action.payload?.value || null,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_PRODUCT_TYPE_KEYWORD_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              productType: {
                ...state.table.orderHistory.filter.productType,
                keyword: action.payload.keyword,
                list: action.payload.list,
              },
            },
          }
        }
      }

    // order status

    case studentActions.FILTER_HISTORY_ORDER_STATUS_KEYWORD_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              orderStatus: {
                ...state.table.orderHistory.filter.orderStatus,
                keyword: action.payload.keyword,
                list: action.payload.list,
              },
            },
          }
        }
      }

    case studentActions.FILTER_HISTORY_ORDER_STATUS_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            filter: {
              ...state.table.orderHistory.filter,
              orderStatus: {
                ...state.table.orderHistory.filter.orderStatus,
                value: action.payload?.value || null,
              },
            },
          }
        }
      }

    case studentActions.OTHER_FILTER_HISTORY_APPLY:
      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            detail: {
                ...state.table.orderHistory.detail,
                list: action.payload?.list || [],
            },
          }
        }
      }

    case studentActions.HISTORY_TABLE_DISPLAY_LOADING_UPDATE:

      return {
        ...state,
        table: {
          ...state.table,
          orderHistory: {
            ...state.table.orderHistory,
            detail: {
              ...state.table.orderHistory.detail,
              loading: action.payload,
            },
          }
        }
      }

    case studentActions.HISTORY_QRCODE_TABLE_DISPLAY_LOADING_UPDATE:

      return {
        ...state,
        table: {
          ...state.table,
          qrHistory: {
            ...state.table.qrHistory,
            detail: {
              ...state.table.qrHistory.detail,
              loading: action.payload,
            },
          }
        }
      }
      // PRODUCT TAB

    case useProductAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            table: {
              ...state.table.productTab.table,
              display: {
                ...state.table?.productTab?.table?.display,
                loading: action?.payload
              }
            },
          }
        }
      }

    case useProductAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            table: {
              ...state.table.productTab.table,
              display: {
                ...state.table?.productTab?.table?.display,
                list: action?.payload?.list || [],
                listDefault: action?.payload?.listDefault || [],
                loading: action?.payload?.loading
              }
            },
          }
        }
      }
    case useProductAction.UPDATE_MODAL_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            orderModal: action.payload
          }
        }
      }
    case useProductAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            table: {
              ...state.table.productTab.table,
              pagination: {
                ...state.table.productTab.table.pagination,
                active: action.payload?.active || 0,
                amount: action.payload?.amount || 20,
                total: action.payload?.total || 0,
                totalItems: action.payload?.totalItems || 0,
              },
            },
          }
        }
      }
    case useProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            filter: {
              ...state.table.productTab.filter,
              category: {
                ...state.table.productTab.filter.category,
                list: action.payload?.list,
                listOrigin: action.payload?.listOrigin,
              },
            },
          }
        }
      }
    case useProductAction.FILTER_SEARCH_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            filter: {
              ...state.table.productTab.filter,
              search: {
                ...state.table.productTab.filter.search,
                value: action.payload
              },
            },
          }
        }
      }
    case useProductAction.FILTER_CATEGORY_LIST_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            filter: {
              ...state.table.productTab.filter,
              category: {
                ...state.table.productTab.filter.category,
                list: action.payload,
              },
            },
          }
        }
      }
    case useProductAction.FILTER_CATEGORY_VALUE_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            filter: {
              ...state.table.productTab.filter,
              category: {
                ...state.table.productTab.filter.category,
                value: action.payload,
              },
            },
          }
        }
      }
    case useProductAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
      return {
        ...state,
        table: {
          ...state.table,
          productTab: {
            ...state.table.productTab,
            filter: {
              ...state.table.productTab.filter,
              category: {
                ...state.table.productTab.filter.category,
                activeValue: action.payload,
              },
            },
          }
        }
      }
    default:
      throw new Error()
  }
}
