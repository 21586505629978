import { useContext } from "react";
import { UserManagementContext } from "../provider/_context";
import { userManagementActions } from "../provider/_reducer";
import useAlert from "../../../hook/useAlert";
import { useNavigate, useParams } from "react-router-dom";
import { sendRequestAuth } from "../../../api/api";
import config from "../../../config";

export const useUpdatePassword = () => {
    const { pageState, pageDispatch } = useContext(UserManagementContext)
    const { modalPassword, infomation } = pageState

    const { showAlert } = useAlert()
    const navigate = useNavigate()

    const { userId } = useParams()

    const valueLastPassword = modalPassword?.value
    const statusLastPassword = modalPassword?.status
    const messageLastPassword = modalPassword?.message

    const handleOpenConfirm = (boo) => {
        pageDispatch({ type: userManagementActions.FORM_PASSWORD_OPEN_MODAL, payload: boo })
    }

    const handleChangePassword = data => pageDispatch({
        type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE, payload: {
            value: data,
            status: false,
            message: ''
        }
    })
    const handleBlurPassword = data => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        if (!!!data) pageDispatch({
            type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE, payload: {
                value: data,
                status: true,
                message: 'Mật khẩu không được để trống'
            }
        })
        else if (data.trim().length < 8) {
            pageDispatch({
                type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE, payload: {
                    value: data,
                    status: true,
                    message: 'Mật khẩu tối thiểu phải có 8 ký tự'
                }
            })
        }
        else if (data.trim().length > 32) {
            pageDispatch({
                type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE, payload: {
                    value: data,
                    status: true,
                    message: 'Mật khẩu chỉ được phép tối đa 32 ký tự'
                }
            })
        }
        // else if (!regex.test(data)) pageDispatch({
        //     type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE, payload: {
        //         value: data,
        //         status: true,
        //         message: 'Mật khẩu phải có ít nhất 8 ký tự trong đó có ít nhất 1 ký tự số, ít nhất 1 ký tự đặc biệt'
        //     }
        // })
        else pageDispatch({
            type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE,
            payload: {
                value: data,
                status: false,
                message: ''
            }
        })
    }


    const handleUpdatePassword = async () => {
        if (!!valueLastPassword) {
            const data = {
                password: valueLastPassword
            }
            const response = await sendRequestAuth('post', `${config.API}/user/change-password/${userId}`, data)
            if (response?.data?.success) {
                showAlert({ type: 'success', content: response?.data?.message })
            } else {
                showAlert({ type: 'danger', content: response?.data?.message })
            }
            handleOpenConfirm(false)
        } else pageDispatch({
            type: userManagementActions.FORM_MODAL_PASSWORD_UPDATE, payload: {
                value: '',
                status: true,
                message: 'Mật khẩu không được để trống'
            }
        })
    }
    return {
        modalPassword: {
            open: modalPassword?.open,
            value: valueLastPassword,
            status: statusLastPassword,
            message: messageLastPassword,
            methods: {
                onOpen: handleOpenConfirm,
                onChange: handleChangePassword,
                onBlur: handleBlurPassword,
                onSave: handleUpdatePassword
            }
        }
    }
}