import React, {memo, useContext} from "react";
import {UserManagementContext} from "../../provider/_context";
import {useModal} from "../../hooks/useModal";
import {RightSightPopup} from "../../../../layouts/rightSightPopup";
import {Grid} from "@mui/material";
import {Input} from "../../../../common/form/input";
import {Text} from "../../../../common/text";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";
import {ConfirmModal} from "../../../../layouts/rightSightPopup/confirm";
import styled from "styled-components";
import {Tooltip} from "../../../../common/tooltip";
import {USER_ICON} from "../../icon/icon";
import {USER_PROFILE} from "../../../../Component/Icons";
import {useTranslation} from "react-i18next";
import {DISPLAY_NAME_MENU} from "../../../../const/display_name_menu";

export const ModalUserManagementPassword = memo(({...props}) => {
  const {t} = useTranslation()
  const { pageState } = useContext(UserManagementContext)
  const {functions, valueForm, validate, typeInput} = useModal()

  return (
    <>
      <RightSightPopup
        openModal={pageState.openModalUserPass}
        confirmBeforeClose={true}
        disableSubmit={validate?.canSubmitPass}
        clickClose={() => functions.onCloseModalUserPass()}
        animationClose={functions.closeModalPass}
        header={
          {
            title: t(DISPLAY_NAME_MENU.USER_PAGE.PASS_TITLE),
            subTitle: t(DISPLAY_NAME_MENU.USER_PAGE.PASS_SUBTITLE),
          }
        }
        body={[
          {
            item: (<StyledContentModalUserPass>
              <div className={'right-sight-popup'}>
                <Grid container className={"right-sight-popup__group-role"}>
                  <Grid xs={12} sm={12} md={12} lg={12} item>
                    <div className="right-sight-popup__form-group right-sight-popup__form-group--full-name">
                      <Input
                        {...props}
                        label={
                          <>
                            {t(DISPLAY_NAME_MENU.USER_PAGE.USERNAME)} <Text color={THEME_SEMANTICS.failed}>*</Text>
                          </>
                        }
                        placeholder={t(DISPLAY_NAME_MENU.GENERAL.ENTER_FULL_NAME)}
                        autoComplete={'false'}
                        maxLength={100}
                        value={valueForm.currentUserName}
                        disabled={true}
                        onChange={e => functions.onChangeFullName(e.target.value)}
                        onBlur={() => validate.onBlurFullName()}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} item>
                    <div className="right-sight-popup__form-group right-sight-popup__form-password">
                      <Input
                        {...props}
                        label={
                          <>
                            <span>{t(DISPLAY_NAME_MENU.GENERAL.USER_PROFILE_CURRENT_PASS)} </span>
                            <Text color={THEME_SEMANTICS.failed}> *</Text>
                          </>
                        }
                        placeholder={t(DISPLAY_NAME_MENU.USER_PAGE.ENTER_CURRENT_PASS)}
                        autoComplete={'new-password'}
                        value={valueForm?.currentPassword}
                        // maxLength={30}
                        validateText={validate?.errorCurrentPassword?.status ? validate?.errorCurrentPassword?.message : null}
                        validateType={!validate?.errorCurrentPassword?.status ? 'success' : 'danger'}
                        type={typeInput.currentPassword}
                        icon={typeInput.currentPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> : <i>{USER_PROFILE.eye}</i>}
                        onChange={e => functions.onChangeCurrentPassword(e.target.value)}
                        onBlur={e => validate?.onBlurCurrentPassword(e.target.value)}
                        onIconClick={() => functions.onChangeTypeCurrentPassword()}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} item>
                    <div className="right-sight-popup__form-group right-sight-popup__form-password">
                      <Input
                        {...props}
                        label={
                          <>
                            <span>{t(DISPLAY_NAME_MENU.GENERAL.USER_PROFILE_NEW_PASS)}</span>
                            <Text color={THEME_SEMANTICS.failed}> *</Text>
                            <Tooltip placement="bottom" title={t(DISPLAY_NAME_MENU.USER_PAGE.NEW_PASS_TOOLTIP)}>
                              <span
                                style={{
                                  marginLeft: 6,
                                  display: 'inline-block',
                                  transform: 'translateY(2px)',
                                }}
                              >
                              </span>
                                    <span className={"right-sight-popup__form-group-account--tooltip"}>{USER_ICON.question}</span>
                                  </Tooltip>
                                </>
                              }
                              placeholder={t(DISPLAY_NAME_MENU.USER_PAGE.ENTER_NEW_PASS)}
                              autoComplete={'new-password'}
                              value={valueForm?.password}
                              // maxLength={30}
                              validateText={validate?.errorNewPassword?.status ? validate?.errorNewPassword?.message : null}
                              validateType={!validate?.errorNewPassword?.status ? 'success' : 'danger'}
                              type={typeInput.newPassword}
                              icon={typeInput.newPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> : <i>{USER_PROFILE.eye}</i>}
                              onChange={e => functions.onChangeNewPassword(e.target.value)}
                              onBlur={e => validate?.onBlurNewPassword(e.target.value)}
                              onIconClick={() => functions.onChangeTypeNewPassword()}
                            />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} item>
                    <div className="right-sight-popup__form-group right-sight-popup__form-password">
                      <Input
                        {...props}
                        label={
                          <>
                            <span>{t(DISPLAY_NAME_MENU.GENERAL.USER_PROFILE_CONFIRM_PASS)}</span>
                            <Text color={THEME_SEMANTICS.failed}> *</Text>
                          </>
                        }
                        placeholder={t(DISPLAY_NAME_MENU.USER_PAGE.ENTER_CONFIRM_PASS)}
                        autoComplete={'new-password'}
                        value={valueForm?.confirmPassword}
                        // maxLength={30}
                        validateText={validate?.errorConfirmPassword?.status ? validate?.errorConfirmPassword?.message : null}
                        validateType={!validate?.errorConfirmPassword?.status ? 'success' : 'danger'}
                        type={typeInput.confirmPassword}
                        icon={typeInput.confirmPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> : <i>{USER_PROFILE.eye}</i>}
                        onChange={e => functions.onChangeConfirmPassword(e.target.value)}
                        onBlur={e => validate?.onBlurConfirmPassword(e.target.value)}
                        onIconClick={() => functions.onChangeTypeConfirmPassword()}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </StyledContentModalUserPass>)
          }
        ]}
        footer={
          {
            cancel: {
              width: 74,
              title: t(DISPLAY_NAME_MENU.GENERAL.CANCEL)
            },
            save: {
              width: 110,
              title: t(DISPLAY_NAME_MENU.GENERAL.SAVE)
            },
          }
        }
        acceptance={() => functions.onSubmitPass()}
      />
      <ConfirmModal
        openModal={pageState.modalConfirmUserPass}
        body={<p>{t(DISPLAY_NAME_MENU.GENERAL.CONFIRM_LEAVING_CONTENT)}</p>}
        footer={
          {
            cancel: {
              width: 74,
              title: t(DISPLAY_NAME_MENU.GENERAL.CANCEL)
            },
            acceptance: {
              width: 110,
              title: t(DISPLAY_NAME_MENU.GENERAL.CONFIRM)
            },
          }
        }
        closeModal={() => functions.closeModalPassConfirm()}
        acceptance={() => functions.acceptanceModalPassConfirm()}
      />
    </>
  );
})


const StyledContentModalUserPass = styled.div`
  .right-sight-popup {
    &__form-password {
      margin-top: 24px;
    }
  }
`