import { sendRequestAuth } from 'api/api'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useReducer } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    studentActions,
    studentReducer,
} from '../provider/_reducer'

import {
    studentInitialState,
} from '../provider/_initState'

const useStudent = () => {
    const [searchParams] = useSearchParams()
    const nav = useNavigate()
    const [state, dispatch] = useReducer(studentReducer, studentInitialState)
    const { filter, table } = state

    const filterQueries = {
        status: filter.principal.activeValue?.value?.value || '',
        keyword: filter.studentInfo.activeValue || '',
    }

    const fetchRowDetail = async data => {
        if (!!!data?.id) {
            dispatch({
                type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
                payload: { table: { display: { loading: false } } },
            })
            return
        }

        const response = await sendRequestAuth(
            'get',
            `${config.API}/teacher/customer/detail/${data?.id}`,
        )

        if (!!response?.data?.success) {
            const newItem = response?.data?.data
            dispatch({
                type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
                payload: { active: newItem, list: [newItem] },
            })
        }

        dispatch({
            type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
            payload: { table: { display: { loading: false } } },
        })
    }

    const handleOriginFetch = async () => {
        const querySearch = searchParams.get('search') || ''
        if (querySearch)
            dispatch({
                type: studentActions.FILTER_ACTIVE_DATE_TIME_UPDATE,
                payload: {
                    end: '',
                    start: '',
                    type: filter.dateTime.type,
                    value: '',
                },
            })
        const response = await sendRequestAuth(
            'get',
            `${config.API}/teacher/customer/customers?keyword=&status=&start=0&per_page=20`,
        )

        if (!!response?.data?.success) {
            const displayListData = Array.isArray(response?.data?.data)
                ? response.data.data
                : []
            // set default value for input filter
            dispatch({
                type: studentActions.FILTER_SEARCH_UPDATE,
                payload: { value: querySearch },
            })
            // update display list
            dispatch({
                type: studentActions.TABLE_AMOUNT_UPDATE,
                payload: {
                    display: {
                        list: displayListData,
                    },
                    pagination: {
                        totalItems: response?.data?.meta?.total || 0,
                        active: 0,
                        amount: 20,
                        total: response?.data?.meta?.total
                            ? Math.ceil(
                                response?.data?.meta?.total / 20,
                            )
                            : 0,
                    },
                },
            })
            // auto fetch detail student id and open
            if (querySearch) {
                dispatch({
                    type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
                    payload: { active: displayListData[0] },
                })
                fetchRowDetail({ id: querySearch })
            }
        }

        if (!querySearch) {
            dispatch({
                type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
                payload: { table: { display: { loading: false } } },
            })
        }
    }

    const handleDetailFetch = async (studentId) => {
        const response = await sendRequestAuth(
            'get',
            `${config.API}/teacher/customer/detail/${studentId}`,
        )

        if (!!response?.data?.success) {
            // set default value for input filter
            const studentDetail = response.data.data
            if (!['new', 'processing'].includes(studentDetail.student_status)) nav('/student/digital')
            dispatch({
                type: studentActions.UPDATE_ORIGIN_DATA_FORM_EDIT,
                payload: {
                    student: studentDetail,
                    studentName: {
                        value: studentDetail?.student_name,
                        status: true,
                        message: ''
                    },
                    studentPhone: {
                        value: studentDetail?.student_phone,
                        status: true,
                        message: ''
                    },
                    studentEmail: {
                        value: studentDetail?.student_email,
                        status: true,
                        message: ''
                    },
                    studentAddress: {
                        value: studentDetail?.student_address,
                        status: true,
                        message: ''
                    },
                    studentNotes: {
                        value: studentDetail?.student_notes,
                        status: true,
                        message: ''
                    },
                    feeShip: {
                        value: studentDetail?.shipping_fee,
                        status: true,
                        message: ''
                    },
                    details: studentDetail?.details,
                },
            })
        }
    }


    const handleFetchDetailHistory = async (historyId) => {
        const response = await sendRequestAuth(
            'get',
            `${config.API}/teacher/customer/log/detail/${historyId}`,
        )

        if (!!response?.data?.success) { }
    }

    const handlePaginationAmountChange = async n => {
        dispatch({
            type: studentActions.TABLE_SELECTED_LIST_UPDATE,
            payload: { selected: { list: [] } },
        })
        dispatch({
            type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
            payload: { table: { display: { loading: true } } },
        })

        const currentPage = table.pagination.active || 0
        const totalPages = Math.ceil(table.pagination.totalItems / n)
        const page =
            table.pagination.totalItems < currentPage * n
                ? totalPages - 1
                : currentPage

        let queryStr = '?'
        let i = 0
        for (const [key, value] of Object.entries({
            ...filterQueries,
            per_page: n,
            start: page * n,
        })) {
            queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }

        const response = await sendRequestAuth(
            'get',
            `${config.API}/teacher/customer/customers${queryStr}`,
        )

        if (!!response?.data?.success) {
            const displayListData = Array.isArray(response?.data?.data)
                ? response.data.data
                : []
            dispatch({
                type: studentActions.TABLE_AMOUNT_UPDATE,
                payload: {
                    display: {
                        list: displayListData,
                    },
                    pagination: { active: page, amount: n, totalItems: response?.data?.meta?.total || 0, total: totalPages },
                },
            })

        }

        dispatch({
            type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
            payload: { active: null },
        })

        dispatch({
            type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
            payload: { table: { display: { loading: false } } },
        })
    }

    const handlePaginationPageChange = async page => {
        dispatch({
            type: studentActions.TABLE_SELECTED_LIST_UPDATE,
            payload: { selected: { list: [] } },
        })
        dispatch({
            type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
            payload: { table: { display: { loading: true } } },
        })

        const amount = table.pagination?.amount || 20

        let queryStr = '?'
        let i = 0
        for (const [key, value] of Object.entries({
            ...filterQueries,
            per_page: amount,
            start: page * amount,
        })) {
            queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }

        const response = await sendRequestAuth(
            'get',
            `${config.API}/teacher/customer/customers${queryStr}`,
        )

        if (!!response?.data?.success) {
            const displayListData = Array.isArray(response?.data?.data)
                ? response.data.data
                : []

            dispatch({
                type: studentActions.TABLE_PAGINATION_UPDATE,
                payload: {
                    display: {
                        list: displayListData,
                    },
                    pagination: { active: page },
                },
            })
        }

        dispatch({
            type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
            payload: { active: null },
        })

        dispatch({
            type: studentActions.TABLE_DISPLAY_LOADING_UPDATE,
            payload: { table: { display: { loading: false } } },
        })
    }

    return {
        fetch: {
            origin: handleOriginFetch,
            detail: handleDetailFetch,
            history: handleFetchDetailHistory,
        },
        pagination: {
            onAmountChange: handlePaginationAmountChange,
            onPageChange: handlePaginationPageChange,
        },
        provider: { state, dispatch },
    }
}

export default useStudent
