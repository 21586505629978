import { RightSightPopup } from 'layouts/rightSightPopup'
import React from 'react'
import { useContext } from 'react'
import ModalContent from './contentModal'
import { ConfirmModal } from '../../../../layouts/rightSightPopup/confirm'
import { Text } from 'common/text'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { InvoiceContext } from '../../provider/_context'
import { useModal } from '../../hooks/useModal'
import { Loading } from '../../../../common/loading'

const Index = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(InvoiceContext)
  const { animate, confirm, modal } = useModal()
  return (
    <>
      {!!pageState?.loading && <Loading/>}
      <RightSightPopup
        className={'product-group-modal_right-side'}
        openModal={pageState.open_modal}
        confirmBeforeClose={true}
        clickClose={modal.handleClose}
        disableSubmit={modal.checkBeforeSubmit}
        animationClose={animate}
        header={{
          title: 'Chỉnh sửa phiếu chi',
        }
        }
        body={[
          {
            item: <ModalContent/>
          }
        ]}
        footer={
          {
            cancel: {
              width: 74,
              title: t('general_cancel')
            },
            save: {
              width: 110,
              title: t('save')
            },
          }
        }
        acceptance={() => modal.handleAccept()}
      />
      <ConfirmModal
        openModal={pageState.modal_confirm}
        body={<Confirm t={t}/>}
        footer={
          {
            cancel: {
              width: 110,
              title: t('general_cancel'),

            },
            acceptance: {
              width: 110,
              title: t('confirm')
            },
          }
        }
        footerProps={
          { className: 'product-group-modal_dismiss' }
        }
        closeModal={() => confirm.handleCancelConfirm()}
        acceptance={() => confirm.handleAcceptConfirm()}
      />
    </>

  )
}
export default Index
const Confirm = ({ t }) => {
  return (
    <div style={{ marginBottom: '24px' }}>
      <Text
        as={'p'}
        fontSize={20}
        fontWeight={600}
        style={{ marginBottom: '16px' }}
      >{t('general_confirm_leaving')}</Text>
      <Text as='p' className='product-group-modal_txt'>{t('general_confirm_leaving_content')}</Text>
    </div>
  )
}