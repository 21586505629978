import { memo } from "react";
import useFilterUserManagement from "../../hooks/useFilterUserManagement";
import { SCRIPT } from "../../interfaces/~script";
import { Text } from "../../../../common/text";
import { THEME_SEMANTICS } from "../../../../common/theme/_semantics";
import { useTranslation } from "react-i18next";
import { DISPLAY_NAME_MENU } from "../../../../const/display_name_menu";
import styled from 'styled-components'
import { GLOBAL_ICONS } from '../../../../interface/icon'


export const UserTags = memo(() => {
    const { t } = useTranslation()
    const { searchFilter, groupStatus, groupEmployee, functions } = useFilterUserManagement()

    const shouldShowResetAll = [
        searchFilter?.active !== '' && searchFilter?.active !== undefined,
        groupStatus?.activeValue.length !== 0,
        groupEmployee?.activeValue.length !== 0
    ].includes(true)
    return (
        <StyledOrderTags>
            {(searchFilter?.active !== '' && searchFilter?.active !== undefined) && (
                <OrderTag onDelete={() => functions.filterTagDelete('keyword')} >
                    {t('Người dùng')}: {t(searchFilter?.active)}
                </OrderTag>
            )}
            {Object.keys(groupStatus?.activeValue).length !== 0 && (
                <OrderTag onDelete={() => functions.filterTagDelete('status')} >
                    {t(SCRIPT.PANELS.STATUS)}: {t(groupStatus?.activeValue?.name)}
                </OrderTag>
            )}
            {Object.keys(groupEmployee?.activeValue).length !== 0 && (
                <OrderTag onDelete={() => functions.filterTagDelete('employee')} >
                    {t(SCRIPT.PANELS.GROUP)}: {t(groupEmployee?.activeValue?.name)}
                </OrderTag>
            )}
            {shouldShowResetAll && (
                <Text
                    as="b"
                    color={THEME_SEMANTICS.delivering}
                    lineHeight={28}
                    style={{ marginBottom: 12, cursor: 'pointer' }}
                    onClick={() => functions.handleDeleteAll()}
                >
                    {t(DISPLAY_NAME_MENU.GENERAL.RESET_TO_DEFAULT)}
                </Text>
            )}
        </StyledOrderTags>
    )
})


export const StyledOrderTags = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

export const OrderTag = ({ onDelete, ...props }) => {
    return (
        <StyledOrderTag {...props}>
            {props?.children && <Text>{props.children}</Text>}
            <div className="order-tag__delete" onClick={onDelete}>
                {GLOBAL_ICONS.x}
            </div>
        </StyledOrderTag>
    )
}

const StyledOrderTag = styled.li`
    position: relative;
    margin: 0 12px 12px 0;
    padding: 4px 28px 4px 12px;
    display: inline-block;
    background: #d1dffd;
    border-radius: 6px;

    .order-tag {
        &__delete {
            position: absolute;
            top: 6px;
            right: 8px;
            width: 16px;
            height: 16px;
            border-radius: 6px;
            transition: all 0.25s;
            cursor: pointer;

            &:hover {
                background: ${THEME_SEMANTICS.failed};
                svg {
                    color: #fff;

                    path[stroke] {
                        stroke: #fff;
                    }

                    path[fill] {
                        fill: #fff;
                    }
                }
            }
        }
    }
`
