import React from 'react';
import {AlternativeAutoComplete} from "common/form/autoComplete/_alternativeAutoComplete";
import { Option } from 'common/form/autoComplete/_option';
import {useQrEventTab} from "../../../../hook/useQrEventTab";

export const FilterEvent = () => {
  const {eventFilter} = useQrEventTab()
  return (
    <AlternativeAutoComplete
      className="store-upos-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Sự kiện', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 110,
        placeholder: 'Chọn sự kiện',
        readOnly: true,
        value: eventFilter?.value?.title || '',
        onIconClick: () => eventFilter.onChange(null),
      }}
      // menu
      menuProps={{
        empty: eventFilter?.list?.length <= 0 
                ? 'Không tìm thấy sự kiện'
                : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm sự kiện',
        // value: eventFilter.keyword,
        onChange: eventFilter?.onKeywordChange,
      }}
    >
      {eventFilter?.list?.length > 0 &&
        eventFilter?.list?.map(item => (
        <Option
          key={item.id}
          className="store-upos-filter-form__option-text"
          data-active={item.id === eventFilter.value?.id}
          onClick={() => eventFilter.onChange(item)}
        >
          {item.title}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}
