import React, { memo, useState } from 'react'
import { StyledUseManagementFilter } from '../~styled'
import { Button } from '../../../../common/button'
import Box from '@mui/material/Box'
import UserStatus from './~userStatus'
import UserRole from './~userRole'
import useFilterUserManagement from '../../hooks/useFilterUserManagement'
import { UserTags } from './~userTags'
import { useTranslation } from 'react-i18next'
import { DISPLAY_NAME_MENU } from '../../../../const/display_name_menu'
import {CategoryInput} from "../../../../common/form/input/_categoryInput";

export const UserFilter = memo(() => {
    const { t } = useTranslation()
    const { search, searchFilter, functions } = useFilterUserManagement()
    return (
        <StyledUseManagementFilter>
            <Box
                component="div"
                noValidate
                autoComplete="off"
                className={'user-management-filter'}
            >
                <div className="user-management-filter__group">
                    <CategoryInput
                        categoryValue={{ name: 'Người dùng', value: 'aaa' }}
                        categoryWidth={95}
                        className="user-management-filter__input-wide"
                        placeholder={"Tên đăng nhập, họ tên, email, số điện thoại"}
                        value={searchFilter?.value}
                        onChange={e => searchFilter.onChange(e)}
                    />
                    <div className={'user-management-filter__input-wide'}>
                        <UserStatus />
                    </div>
                    <div className={'user-management-filter__input-wide'} style={{ marginRight: 8 }}>
                        <UserRole />
                    </div>
                    <Button
                        appearance="secondary"
                        size="md-"
                        disabled={!functions.canSubmitOtherFilter}
                        onClick={() => functions.canSubmitOtherFilter && functions.applyUserOtherFilter()}
                    >
                        {t(DISPLAY_NAME_MENU.GENERAL.APPLY)}
                    </Button>
                </div>
            </Box>
            <div className="user-management-filter-form__group" style={{ marginBottom: 4 }}>
                <UserTags />
            </div>
        </StyledUseManagementFilter>
    )
})