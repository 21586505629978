import React, {
  Suspense,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from 'react'
import {useTranslation} from 'react-i18next'
import CONFIG from '../../config.js'
import './LoginForm.css'

export default function LoginWrapper(props) {
  const {notify} = props

  const {t} = useTranslation()
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const [width, height] = size
  return (
    <Suspense fallback={'loading'}>
      <div className={'Login-Wrapper'}>
        {props.children}
        {/*<div className={'login-header'}>*/}
        {/*  <p>*/}
        {/*    Nếu bạn chưa có tài khoản?{' '}*/}
        {/*    <a href="https://upos.vn/vn/bang-gia.html" target="_blank">*/}
        {/*      Đăng ký ngay*/}
        {/*    </a>*/}
        {/*  </p>*/}
        {/*</div>*/}
        <div className={`login-header success-reset-pass hide ${notify?.active ? 'fadeInRight' : 'fadeInLeft' }`}>
          <img src={'/svg/CheckCircle.svg'} alt="" />
          <p>{notify?.message}</p>
        </div>
        {/*<div className={'phone-number'}>*/}
        {/*  <div className={'number-cell-phone'}>*/}
        {/*    <img*/}
        {/*      className={'cell-phone'}*/}
        {/*      src={'/svg/cell-phone.svg'}*/}
        {/*      alt={'cell-phone'}*/}
        {/*    />*/}
        {/*    {CONFIG.PHONE}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Suspense>
  )
}
