import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";
import {USER_PROFILE} from "../../../../../../Component/Icons";

export const Password = () => {
    const {password, typeInput} = useCreateTeacherForm()
    const {methods} = password
    return (
        <Input
            label={
                <>
                    Mật khẩu <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={password?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={password?.message}
            validateType={password?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập mật khẩu'}
            maxLength={32}
            type={typeInput?.password}
            icon={typeInput?.password === 'password' ? <i>{USER_PROFILE.not_eye}</i> :
                <i>{USER_PROFILE.eye}</i>}
            onIconClick={() => password?.methods?.onChangeTypePassword()}
            autoComplete={'new-password'}
        />
    )
}