import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ProfileContext } from '../provider/~context'
import { profileActions } from '../provider/~action'
import { postData, sendRequestAuth } from '../../../api/api'
import config from '../../../config'
// import { removeVietnameseTones } from '../../../util/checkPasswordVN'
// import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAlert from "../../../hook/useAlert";
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'
// import { removeAcent } from '../../../common/fieldText/_functions'
import useGlobalContext from 'containerContext/storeContext'

const useProfileUpdate = () => {
    const { t } = useTranslation()
    const { showAlert } = useAlert()
    const [debounceSubmitCreate, setDebounceSubmitCreate, state, setState] = useState(true)
    const { pageState, pageDispatch } = useContext(ProfileContext)
    const [globalState, globalDispath] = useGlobalContext()

    const userData = pageState?.userData
    const commission = pageState?.commission
    const formValidate = pageState?.formValidate
    const submitQueries = {
        "avatar": userData?.avatar || '',
        "address": userData?.address || '',
        "school_name": userData?.school_name || '',
        "teaching_subject": userData?.teaching_subject || '',
    }

    const handleChangeAddress = value => {
        pageDispatch({ type: profileActions.FORM_CHANGE_VALUE, payload: {address : value} })
    }
    const handleChangeSchoolName = value => {
        pageDispatch({ type: profileActions.FORM_CHANGE_VALUE, payload: {school_name : value} })
    }
    const handleChangeTeachingSubject = value => {
        pageDispatch({ type: profileActions.FORM_CHANGE_VALUE, payload: {teaching_subject : value} })
    }
    const handleHover = hover => {
        pageDispatch({ type: profileActions.HOVER_AVATAR, payload: hover })
    }

    const handleUploadAvatar = async (val) => {
        let image = val.target.files[0]
        const formData = new FormData()
        formData.append('image', image)
        formData.get('image')
        try {
            const response = await sendRequestAuth('post', `${config.API}/account/upload`, formData)
            if (response?.data?.success) {
                showAlert({ type: 'success', content: response?.data?.message })
                pageDispatch({ type: profileActions.FORM_UPLOAD_AVATAR, payload: response?.data?.file_url })
            }
            else showAlert({ type: 'danger', content: response?.data?.errors[0]?.message })
        } catch (e) {
            console.log(e)
        }
    }
    const handleSubmit = async () => {
        if (debounceSubmitCreate) {
            pageDispatch({
                type: profileActions.CHANGE_LOADING,
                payload: true
            })
            setDebounceSubmitCreate(false)
            setTimeout(() => setDebounceSubmitCreate(true), 2000)
        }

        const response = await sendRequestAuth(
            'post',
            `${config.API}/account/update-profile`, JSON.stringify({ ...submitQueries}),
        ).catch(() => showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR) }))

        if (response?.data?.success) {
            globalDispath({
                type: 'UPDATE_USER',
                payload: {
                    avatar : userData?.avatar,
                    address : userData?.address,
                    school_name : userData?.school_name,
                    teaching_subject : userData?.teaching_subject,
                }
            })
            showAlert({ type: 'success', content: response?.data?.message })
        } else {
            errorResponse(response?.data?.errors)
            setTimeout(() => {
                showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT) })
            }, 100)
        }
        pageDispatch({
            type: profileActions.CHANGE_LOADING,
            payload: false
        })
    }
    const errorResponse = response => {
        let errors = [];
        response?.map(res => {
            errors[res.field] = { status: true, message: res.message};
        })

        pageDispatch({
            type: profileActions.VALIDATE_FORM_CHANGE,
            payload: {...errors}
        })
    }

    // const canSubmit = [
    //     formValidate?.address?.status
    // ].includes(true)

    const canSubmit = false

    return {
        value: {
            userData,
            formValidate,
            canSubmit,
            loading: pageState.loading,
            avatarHover : pageState.avatarHover,
            commission
        },
        functions: {
            onChangeAddress: handleChangeAddress,
            onChangeSchoolName: handleChangeSchoolName,
            onChangeTeachingSubject: handleChangeTeachingSubject,
            onUploadAvatar: handleUploadAvatar,
            submit: handleSubmit,
            setHover: handleHover,
        }
    }
}

export default useProfileUpdate