import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import { useHistoryOrderFilterForm } from '../../../../hooks/useHistoryOrderFilterForm'

export const OrderStatus = () => {
  const {orderStatus} = useHistoryOrderFilterForm()

  return (
    <AlternativeAutoComplete
      className="order-filter-form__input-wide"
      // main input
      bottom={true}
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Trạng thái đơn hàng', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 142,
        placeholder: 'Chọn trạng thái đơn hàng',
        readOnly: true,
        value: orderStatus.value?.name || '',
        onIconClick: () => orderStatus.onChange(null),
      }}
      // menu
      menuProps={{
        empty: orderStatus.list.length <= 0 ? 'Không tìm thấy trạng thái đơn hàng' : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm trạng thái đơn hàng',
        value: orderStatus.keyword,
        onChange: orderStatus.onKeywordChange,
      }}
    >
      {orderStatus.list.length > 0 &&
        orderStatus.list.map(item => (
          <Option
            key={item.value}
            className="order-filter-form__option-text"
            data-active={item.value === orderStatus.value?.value}
            onClick={() => orderStatus.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
