import {formatDatetime} from 'common/form/datePicker/_functions'
import {
   INVOICE_FILTER_TAG_FIELDS
} from '../interfaces/_constants'
import {getDateFromNow} from '../utils/date'

export const invoiceActions = {
   TABLE_AMOUNT_UPDATE: 'TABLE_AMOUNT_UPDATE',
   UPDATE_LOADING: 'UPDATE_LOADING',
   //  FILTER
   FILTER_ADVANCED_SEARCH_UPDATE: 'FILTER_ADVANCED_SEARCH_UPDATE',
   FILTER_DATE_TIME_UPDATE: 'FILTER_DATE_TIME_UPDATE',
   FILTER_ACTIVE_DATE_TIME_UPDATE: 'FILTER_ACTIVE_DATE_TIME_UPDATE',
   FILTER_DATE_TIME_TRIGGER_UPDATE: 'FILTER_DATE_TIME_TRIGGER_UPDATE',
   FILTER_STATUS_VALUE_UPDATE: 'FILTER_STATUS_VALUE_UPDATE',
   FILTER_INVOICE_TYPE_VALUE_UPDATE: 'FILTER_INVOICE_TYPE_VALUE_UPDATE',
   FILTER_INVOICE_TYPE_ACTIVE_VALUE_UPDATE: 'FILTER_INVOICE_TYPE_ACTIVE_VALUE_UPDATE',
   FILTER_STATUS_ACTIVE_VALUE_UPDATE: 'FILTER_STATUS_ACTIVE_VALUE_UPDATE',
   FILTER_DUPLICATE_UPDATE: 'FILTER_DUPLICATE_UPDATE',
   FILTER_TEACHER_UPDATE: 'FILTER_TEACHER_UPDATE',
   FILTER_TEACHER_CATEGORY_UPDATE: 'FILTER_TEACHER_CATEGORY_UPDATE',
   FILTER_TEACHER_KEYWORD_UPDATE: 'FILTER_TEACHER_KEYWORD_UPDATE',
   FILTER_TEACHER_TAB_UPDATE: 'FILTER_TEACHER_TAB_UPDATE',
   FILTER_ORIGIN_DATA_UPDATE: 'FILTER_ORIGIN_DATA_UPDATE',
   FILTER_MONTH_TIME_VALUE_UPDATE: 'FILTER_MONTH_TIME_VALUE_UPDATE',
   FILTER_MONTH_TIME_ACTIVE_VALUE_UPDATE: 'FILTER_MONTH_TIME_ACTIVE_VALUE_UPDATE',
   FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
   FILTER_PAYMENT_METHOD_KEYWORD_UPDATE:
      'FILTER_PAYMENT_METHOD_KEYWORD_UPDATE',
   FILTER_PAYMENT_METHOD_UPDATE: 'FILTER_PAYMENT_METHOD_UPDATE',
   FILTER_PRINCIPAL_KEYWORD_UPDATE: 'FILTER_PRINCIPAL_KEYWORD_UPDATE',
   FILTER_PRINCIPAL_UPDATE: 'FILTER_PRINCIPAL_UPDATE',
   FILTER_TEACHER_LIST_UPDATE: 'FILTER_TEACHER_LIST_UPDATE',
   FILTER_INVOICE_INFO_UPDATE: 'FILTER_INVOICE_INFO_UPDATE',
   FILTER_SHIPPING_STATUS_KEYWORD_UPDATE:
      'FILTER_SHIPPING_STATUS_KEYWORD_UPDATE',
   FILTER_SHIPPING_STATUS_TAB_UPDATE: 'FILTER_SHIPPING_STATUS_TAB_UPDATE',
   FILTER_SHIPPING_STATUS_UPDATE: 'FILTER_SHIPPING_STATUS_UPDATE',
   FILTER_SOURCE_KEYWORD_UPDATE: 'FILTER_SOURCE_KEYWORD_UPDATE',
   FILTER_SOURCE_UPDATE: 'FILTER_SOURCE_UPDATE',
   FILTER_INVOICE_STATUS_KEYWORD_UPDATE: 'FILTER_INVOICE_STATUS_KEYWORD_UPDATE',
   FILTER_INVOICE_STATUS_UPDATE: 'FILTER_INVOICE_STATUS_UPDATE',
   OTHER_FILTER_APPLY: 'OTHER_FILTER_APPLY',
   DELETE_TAG_UPDATE: 'DELETE_TAG_UPDATE',
   TAG_FILTER_DELETE: 'TAG_FILTER_DELETE',
   // NOTIFICATIONS
   NOTIFICATIONS_LIST_UPDATE: 'NOTIFICATIONS_LIST_UPDATE',
   // PANELS
   PANELS_UPDATE: 'PANELS_UPDATE',
   PRINT_DETAIL_UPDATE: 'PRINT_DETAIL_UPDATE',
   // TABLE
   TABLE_DISPLAY_DATA_UPDATE: 'TABLE_DISPLAY_DATA_UPDATE',
   TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
   TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
   TABLE_DISPLAY_LOADING_UPDATE: 'TABLE_DISPLAY_LOADING_UPDATE',
   TABLE_PAGINATION_UPDATE: 'TABLE_PAGINATION_UPDATE',
   TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',

   //selective export excel
   UPDATE_SELECTED_LIST_ACTIVE: 'UPDATE_SELECTED_LIST_ACTIVE',
   SEARCH_SELECTED_LIST_ACTIVE: 'SEARCH_SELECTED_LIST_ACTIVE',
   UPDATE_DRAG_SELECTED_LIST: 'UPDATE_DRAG_SELECTED_LIST',

   // EDIT INVOICE
   UPDATE_ORIGIN_DATA_FORM_EDIT: 'UPDATE_ORIGIN_DATA_FORM_EDIT',
   FORM_INVOICE_UPDATE: 'FORM_INVOICE_UPDATE',
   UPDATE_FORM_EDIT_DETAILS: 'UPDATE_FORM_EDIT_DETAILS',
   UPDATE_FORM_EDIT_DETAILS_TOTAL: 'UPDATE_FORM_EDIT_DETAILS_TOTAL',
   DISPLAY_FORM_EDIT: 'DISPLAY_FORM_EDIT',

   //CREATE INVOICE

   CREATE_INVOICE_PRINCIPAL_KEYWORD_UPDATE: 'CREATE_INVOICE_PRINCIPAL_KEYWORD_UPDATE',
   CREATE_INVOICE_PRINCIPAL_UPDATE: 'CREATE_INVOICE_PRINCIPAL_UPDATE',
   CREATE_INVOICE_LIST_PRINCIPAL_UPDATE: 'CREATE_INVOICE_LIST_PRINCIPAL_UPDATE',
   CREATE_INVOICE_FORM_UPDATE: 'CREATE_INVOICE_FORM_UPDATE',
   CREATE_INVOICE_FORM_VALIDATE_UPDATE: 'CREATE_INVOICE_FORM_VALIDATE_UPDATE',
   VALIDATE_CREATE_INVOICE_FORM_UPDATE: 'VALIDATE_CREATE_INVOICE_FORM_UPDATE',
   CREATE_INVOICE_PAYMENT_METHOD_UPDATE: 'CREATE_INVOICE_PAYMENT_METHOD_UPDATE',
   CREATE_INVOICE_PAYMENT_METHOD_EXTRA_UPDATE: 'CREATE_INVOICE_PAYMENT_METHOD_EXTRA_UPDATE',

}

export const dateTimeDefaultValue = [
   getDateFromNow(-7, {type: 'start'}),
   getDateFromNow(0, {type: 'end'}),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
   dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const invoiceReducer = (state, action) => {
   switch (action.type) {

      case invoiceActions.DISPLAY_FORM_EDIT:
         return {
            ...state,
            open_modal: action.payload
         }

      case invoiceActions.FORM_INVOICE_UPDATE:
         return {
            ...state,
            formEdit: {
               ...state.formEdit,
               ...action.payload
            },
         }
      case invoiceActions.FILTER_ADVANCED_SEARCH_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               advancedSearch: {
                  ...state.filter.advancedSearch,
                  invoice: {
                     ...state.filter.advancedSearch.invoice,
                     keyword:
                        typeof action.payload?.invoice?.keyword === 'string'
                           ? action.payload.invoice.keyword
                           : state.filter.advancedSearch.invoice.keyword,
                     value:
                        typeof action.payload?.invoice?.value === 'string'
                           ? action.payload.invoice.value
                           : state.filter.advancedSearch.invoice.value,
                  },
                  liveVideoId:
                     typeof action.payload?.liveVideoId === 'string'
                        ? action.payload.liveVideoId
                        : state.filter.advancedSearch.liveVideoId,
               },
            },
         }
      case invoiceActions.FILTER_DATE_TIME_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  end: action.payload?.end,
                  start: action.payload?.start,
                  type: action.payload?.type,
                  value: action.payload?.value,
               },
            },
         }

      case invoiceActions.FILTER_ACTIVE_DATE_TIME_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  activeValue: {
                     ...state.filter.dateTime.activeValue,
                     end: action.payload?.end,
                     start: action.payload?.start,
                     type: action.payload?.type,
                     value: action.payload?.value,
                  },
               },
            },
         }

      case invoiceActions.FILTER_DATE_TIME_TRIGGER_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  trigger: action.payload?.trigger,
               },
            },
         }

      case invoiceActions.FILTER_STATUS_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               status: {
                  ...state.filter.status,
                  value: action.payload,
               },
            },
         }

      case invoiceActions.FILTER_INVOICE_TYPE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               invoiceType: {
                  ...state.filter.invoiceType,
                  value: action.payload,
               },
            },
         }
      case invoiceActions.FILTER_INVOICE_TYPE_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               invoiceType: {
                  ...state.filter.invoiceType,
                  activeValue: action.payload,
               },
            },
         }

      case invoiceActions.FILTER_MONTH_TIME_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               monthTime: {
                  ...state.filter.monthTime,
                  value: action.payload,
               },
            },
         }
      case invoiceActions.FILTER_MONTH_TIME_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               monthTime: {
                  ...state.filter.monthTime,
                  activeValue: action.payload,
               },
            },
         }
      case invoiceActions.FILTER_STATUS_ACTIVE_VALUE_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               status: {
                  ...state.filter.status,
                  activeValue: action.payload,
               },
            },
         }
      case invoiceActions.FILTER_ORIGIN_DATA_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               principal: {
                  ...state.filter.principal,
                  list: action.payload?.principal?.list || [],
                  listOrigin: action.payload?.principal?.list || [],
               },
               teacher: {
                  ...state.filter.teacher,
                  list: action.payload?.teacher?.list || [],
                  listOrigin: action.payload?.teacher?.list || [],
               },
            },
         }
      case invoiceActions.FILTER_SEARCH_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               search: {
                  ...state.filter.search,
                  value: action.payload.value || '',
               },
            },
         }

      case invoiceActions.FILTER_INVOICE_INFO_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               invoiceInfo: {
                  ...state.filter.invoiceInfo,
                  ...action.payload,
               },
            },
         }

      //  principals

      case invoiceActions.FILTER_PRINCIPAL_KEYWORD_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               principal: {
                  ...state.filter.principal,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }

      case invoiceActions.FILTER_PRINCIPAL_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               principal: {
                  ...state.filter.principal,
                  value: action.payload?.value || null,
               },
            },
         }
      //  teachers

      case invoiceActions.FILTER_TEACHER_KEYWORD_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               teacher: {
                  ...state.filter.teacher,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }

      case invoiceActions.FILTER_TEACHER_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               teacher: {
                  ...state.filter.teacher,
                  value: action.payload?.value || null,
               },
            },
         }

      case invoiceActions.FILTER_TEACHER_LIST_UPDATE:
         return {
            ...state,
            filter: {
               ...state.filter,
               teacher: {
                  ...state.filter.teacher,
                  list: action.payload?.teacher?.list || [],
                  listOrigin: action.payload?.teacher?.list || [],
               },
            },
         }

      case invoiceActions.NOTIFICATIONS_LIST_UPDATE:
         return {
            ...state,
            notifications: {
               ...state.notifications,
               list: action.payload?.notifications?.list || [],
            },
         }

      case invoiceActions.OTHER_FILTER_APPLY:
         return {
            ...state,
            filter: {
               ...state.filter,
               dateTime: {
                  ...state.filter.dateTime,
                  activeValue: {
                     end: state.filter.dateTime.end,
                     start: state.filter.dateTime.start,
                     type: state.filter.dateTime.type,
                     value: state.filter.dateTime.value,
                  },
               },
               status: {
                  ...state.filter.status,
                  activeValue: state.filter.status.value,
               },
               invoiceType: {
                  ...state.filter.invoiceType,
                  activeValue: state.filter.invoiceType.value,
               },
               monthTime: {
                  ...state.filter.monthTime,
                  activeValue: state.filter.monthTime.value,
               },
               principal: {
                  ...state.filter.principal,
                  activeValue: state.filter.principal.value,
               },
               invoiceInfo: {
                  ...state.filter.invoiceInfo,
                  activeValue: state.filter.invoiceInfo.value,
               },
               teacher: {
                  ...state.filter.teacher,
                  activeValue: state.filter.teacher.value,
               },
            },
            table: {
               ...state.table,
               display: {
                  ...state.table.display,
                  list: action.payload?.display?.list || [],
               },
               pagination: {
                  ...state.table.pagination,
                  active: action.payload?.pagination?.active,
                  amount: action.payload?.pagination?.amount,
                  total: action.payload?.pagination?.total,
                  totalItems: action.payload?.pagination?.totalItems,
               },
            },
         }
      case invoiceActions.DELETE_TAG_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state.table.display,
                  list: action.payload?.display?.list || [],
               },
               pagination: {
                  ...state.table.pagination,
                  active: action.payload?.pagination?.active,
                  amount: action.payload?.pagination?.amount,
                  total: action.payload?.pagination?.total,
                  totalItems: action.payload?.pagination?.totalItems,
               },
            },
         }

      case invoiceActions.PANELS_UPDATE:
         return {
            ...state,
            panels: {
               ...state.panels,
               codTotal: action.payload?.panels?.codTotal,
               invoiceTotal: action.payload?.panels?.invoiceTotal,
               invoiceValueTotal: action.payload?.panels?.invoiceValueTotal,
               shippingFeeTotal: action.payload?.panels?.shippingFeeTotal,
            },
            table: {
               ...state.table,
               pagination: {
                  ...state.table.pagination,
                  total: Math.ceil(
                     action.payload.pagination.totalItems /
                     state.table.pagination.amount,
                  ),
                  totalItems: action.payload.pagination.totalItems,
               },
            },
         }

      case invoiceActions.PRINT_DETAIL_UPDATE:
         return {
            ...state,
            printDetail: action.payload
         }

      case invoiceActions.TABLE_AMOUNT_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state.table.display,
                  list: action.payload?.display?.list || [],
               },
               pagination: {
                  ...state.table.pagination,
                  active: action.payload?.pagination?.active,
                  amount: action.payload?.pagination?.amount,
                  total: action.payload?.pagination?.total,
                  totalItems: action?.payload?.pagination?.totalItems,
               },
            },
         }

      case invoiceActions.TABLE_DISPLAY_DATA_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state.table.display,
                  list: action.payload?.display?.list || [],
               },
            },
         }

      case invoiceActions.TABLE_DISPLAY_DETAIL_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               detail: {
                  ...state.table.detail,
                  active: action.payload?.active || null,
                  list: action.payload?.list || [],
               },
            },
         }

      case invoiceActions.TABLE_DISPLAY_DETAIL_ID_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               detail: {
                  ...state.table.detail,
                  id: action.payload?.id || null,
               },
            },
         }

      case invoiceActions.TABLE_DISPLAY_LOADING_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state.table.display,
                  loading: action.payload?.table?.display?.loading,
               },
            },
         }

      case invoiceActions.TABLE_PAGINATION_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               display: {
                  ...state.table.display,
                  list: action.payload?.display?.list || [],
               },
               pagination: {
                  ...state.table.pagination,
                  active: action.payload?.pagination?.active,
               },
            },
         }

      case invoiceActions.TABLE_SELECTED_LIST_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               selected: {
                  ...state.table.selected,
                  list: action.payload?.selected?.list || [],
               },
            },
         }

      case invoiceActions.TAG_FILTER_DELETE:
         switch (action.payload?.type) {
            case 'dateTime.current':
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     dateTime: {
                        ...state.filter.dateTime,
                        activeValue: {
                           end: null,
                           start: null,
                           type: '',
                           value: null,
                        },
                        end: null,
                        start: null,
                        type: '',
                        value: null,
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[1]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     dateTime: {
                        ...state.filter.dateTime,
                        activeValue: {
                           end: null,
                           start: null,
                           type: '',
                           value: null,
                        },
                        end: null,
                        start: null,
                        type: '',
                        value: null,
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[0]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     invoiceInfo: {
                        ...state.filter.invoiceInfo,
                        activeValue: null,
                        value: null,
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[4]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     monthTime: {
                        ...state.filter.monthTime,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[5]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     status: {
                        ...state.filter.status,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[6]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     invoiceType: {
                        ...state.filter.invoiceType,
                        activeValue: null,
                        value: null
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[2]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     principal: {
                        ...state.filter.principal,
                        activeValue: null,
                        keyword: '',
                        value: null,
                     },
                  },
               }
            case INVOICE_FILTER_TAG_FIELDS[3]:
               return {
                  ...state,
                  filter: {
                     ...state.filter,
                     teacher: {
                        ...state.filter.teacher,
                        activeValue: null,
                        keyword: '',
                        value: null,
                     },
                  },
               }
            default:
               return {...state}
         }
      case invoiceActions.UPDATE_SELECTED_LIST_ACTIVE:
         return {
            ...state,
            exportExcel: {
               ...state.exportExcel,
               activeList: action.payload.active,
               list: action.payload.list,
               selectedList: action.payload.selected,
            }
         }
      case invoiceActions.SEARCH_SELECTED_LIST_ACTIVE:
         return {
            ...state,
            exportExcel: {
               ...state.exportExcel,
               activeList: action.payload.activeList,
               keyword: action.payload.keyword,
            }
         }
      case invoiceActions.UPDATE_DRAG_SELECTED_LIST:
         return {
            ...state,
            exportExcel: {
               ...state.exportExcel,
               selectedList: action.payload,
            }
         }

      case invoiceActions.UPDATE_ORIGIN_DATA_FORM_EDIT:
         return {
            ...state,
            formEdit: {
               ...state.formEdit,
               ...action.payload,
            }
         }

      case invoiceActions.UPDATE_FORM_EDIT_DETAILS:
         return {
            ...state,
            formEdit: {
               ...state.formEdit,
               details: action.payload,
            }
         }

      case invoiceActions.UPDATE_FORM_EDIT_DETAILS_TOTAL:
         return {
            ...state,
            formEdit: {
               ...state.formEdit,
               invoice: {
                  ...state.formEdit.invoice,
                  ...action.payload,
               }
            }
         }

      // QR  HISTORY ORDER

      case invoiceActions.UPDATE_QR_HISTORY_TABLE:
         return {
            ...state,
            table: {
               ...state.table,
               qrHistory: {
                  ...state.table.qrHistory,
                  detail: {
                     ...state.table.qrHistory.detail,
                     list: action.payload,
                     loading: false,
                  },
               }
            }
         }

      // Payment method

      case invoiceActions.FILTER_HISTORY_PAYMENT_METHOD_KEYWORD_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     paymentMethod: {
                        ...state.table.orderHistory.filter.paymentMethod,
                        keyword: action.payload.keyword,
                        list: action.payload.list,
                     },
                  },
               }
            }
         }

      case invoiceActions.FILTER_HISTORY_PAYMENT_METHOD_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     paymentMethod: {
                        ...state.table.orderHistory.filter.paymentMethod,
                        value: action.payload?.value || null,
                     },
                  },
               }
            }
         }
      // Payment status

      case invoiceActions.FILTER_HISTORY_PAYMENT_STATUS_KEYWORD_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     paymentStatus: {
                        ...state.table.orderHistory.filter.paymentStatus,
                        keyword: action.payload.keyword,
                        list: action.payload.list,
                     },
                  },
               }
            }
         }
      case invoiceActions.FILTER_HISTORY_PAYMENT_STATUS_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     paymentStatus: {
                        ...state.table.orderHistory.filter.paymentStatus,
                        value: action.payload?.value || null,
                     },
                  },
               }
            }
         }

      //product type

      case invoiceActions.FILTER_HISTORY_PRODUCT_TYPE_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     productType: {
                        ...state.table.orderHistory.filter.productType,
                        value: action.payload?.value || null,
                     },
                  },
               }
            }
         }

      case invoiceActions.FILTER_HISTORY_PRODUCT_TYPE_KEYWORD_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     productType: {
                        ...state.table.orderHistory.filter.productType,
                        keyword: action.payload.keyword,
                        list: action.payload.list,
                     },
                  },
               }
            }
         }

      // order status

      case invoiceActions.FILTER_HISTORY_ORDER_STATUS_KEYWORD_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     orderStatus: {
                        ...state.table.orderHistory.filter.orderStatus,
                        keyword: action.payload.keyword,
                        list: action.payload.list,
                     },
                  },
               }
            }
         }

      case invoiceActions.FILTER_HISTORY_ORDER_STATUS_UPDATE:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  filter: {
                     ...state.table.orderHistory.filter,
                     orderStatus: {
                        ...state.table.orderHistory.filter.orderStatus,
                        value: action.payload?.value || null,
                     },
                  },
               }
            }
         }

      case invoiceActions.OTHER_FILTER_HISTORY_APPLY:
         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  detail: {
                     ...state.table.orderHistory.detail,
                     list: action.payload?.list || [],
                  },
               }
            }
         }

      case invoiceActions.HISTORY_TABLE_DISPLAY_LOADING_UPDATE:

         return {
            ...state,
            table: {
               ...state.table,
               orderHistory: {
                  ...state.table.orderHistory,
                  detail: {
                     ...state.table.orderHistory.detail,
                     loading: action.payload,
                  },
               }
            }
         }

      case invoiceActions.HISTORY_QRCODE_TABLE_DISPLAY_LOADING_UPDATE:

         return {
            ...state,
            table: {
               ...state.table,
               qrHistory: {
                  ...state.table.qrHistory,
                  detail: {
                     ...state.table.qrHistory.detail,
                     loading: action.payload,
                  },
               }
            }
         }

      //CREATE INVOICE ACTION

      case invoiceActions.CREATE_INVOICE_PRINCIPAL_KEYWORD_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               principal: {
                  ...state.formCreate.principal,
                  keyword: action.payload.keyword,
                  list: action.payload.list,
               },
            },
         }

      case invoiceActions.CREATE_INVOICE_PRINCIPAL_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               principal: {
                  ...state.formCreate.principal,
                  value: action.payload || null,
               },
            },
         }

      case invoiceActions.CREATE_INVOICE_LIST_PRINCIPAL_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               principal: {
                  ...state.formCreate.principal,
                  list: action.payload || [],
                  listOrigin: action.payload || [],
               },
            },
         }

      case invoiceActions.CREATE_INVOICE_FORM_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               ...action.payload
            },
         }

      case invoiceActions.CREATE_INVOICE_PAYMENT_METHOD_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               paymentMethod: {
                  ...state.formCreate.paymentMethod,
                  value: action.payload || null,
               },
            },
         }

      case invoiceActions.CREATE_INVOICE_PAYMENT_METHOD_EXTRA_UPDATE:
         return {
            ...state,
            formCreate: {
               ...state.formCreate,
               paymentMethod: {
                  ...state.formCreate.paymentMethod,
                  extra: {
                     ...state.formCreate.paymentMethod.extra,
                     ...action.payload
                  }
               },
            },
         }
      case invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE:
         return {
            ...state,
            validateFormCreate: {
               ...state.validateFormCreate,
               ...action.payload
            },
         }
      case invoiceActions.UPDATE_LOADING:
         return {
            ...state,
            loading: action.payload
         }
      default:
         throw new Error()
   }
}
