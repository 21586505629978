import React from "react";

export const USER_ROLE = {
  back_header: (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.77145 12.6036C8.96671 12.7988 9.28329 12.7988 9.47855 12.6036C9.67382 12.4083 9.67382 12.0917 9.47855 11.8964L8.77145 12.6036ZM6 9.125L5.64645 8.77145C5.45118 8.96671 5.45118 9.28329 5.64645 9.47855L6 9.125ZM9.47855 6.35355C9.67382 6.15829 9.67382 5.84171 9.47855 5.64645C9.28329 5.45118 8.96671 5.45118 8.77145 5.64645L9.47855 6.35355ZM15.5 16C15.5 16.2761 15.7239 16.5 16 16.5C16.2761 16.5 16.5 16.2761 16.5 16H15.5ZM15.5913 11.1725L15.1458 11.3995L15.5913 11.1725ZM13.9525 9.53373L13.7255 9.97923L13.9525 9.53373ZM9.47855 11.8964L6.35355 8.77145L5.64645 9.47855L8.77145 12.6036L9.47855 11.8964ZM6.35355 9.47855L9.47855 6.35355L8.77145 5.64645L5.64645 8.77145L6.35355 9.47855ZM16.5 16V15.125H15.5V16H16.5ZM10 8.625H6V9.625H10V8.625ZM16.5 15.125C16.5 14.0832 16.5004 13.2717 16.4472 12.6213C16.3936 11.9651 16.283 11.4289 16.0368 10.9455L15.1458 11.3995C15.3082 11.7184 15.402 12.1083 15.4506 12.7027C15.4996 13.303 15.5 14.0667 15.5 15.125H16.5ZM10 9.625C11.0583 9.625 11.822 9.62539 12.4223 9.67443C13.0167 9.72299 13.4066 9.81677 13.7255 9.97923L14.1795 9.08822C13.6961 8.84196 13.1599 8.73137 12.5037 8.67775C11.8533 8.62461 11.0418 8.625 10 8.625V9.625ZM16.0368 10.9455C15.6293 10.1459 14.9791 9.49568 14.1795 9.08822L13.7255 9.97923C14.337 10.2908 14.8342 10.788 15.1458 11.3995L16.0368 10.9455Z" fill="#3264FF"/>
    </svg>
  ),
  tick: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8ZM7.54347 11.424L12.1493 5.66613L11.1234 4.80655L7.42857 9.4816L4.84069 7.35771L3.92533 8.4096L7.54347 11.424Z" fill="#00AB56"/>
    </svg>
  ),
  circle_down: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7513_39494)">
        <path d="M9.55044 14.8174L5.17544 10.4462C5.0616 10.3284 4.9986 10.1707 5.00002 10.0069C5.00145 9.84321 5.06718 9.68659 5.18306 9.57081C5.29894 9.45503 5.4557 9.38935 5.61957 9.38793C5.78344 9.38651 5.94132 9.44945 6.05919 9.5632L9.99232 13.4929L13.9254 9.5632C13.9831 9.50356 14.0521 9.45598 14.1283 9.42326C14.2046 9.39053 14.2866 9.3733 14.3696 9.37258C14.4526 9.37186 14.5349 9.38766 14.6117 9.41906C14.6885 9.45046 14.7583 9.49683 14.8169 9.55546C14.8756 9.61409 14.922 9.68381 14.9535 9.76056C14.9849 9.8373 15.0007 9.91953 15 10.0024C14.9993 10.0854 14.982 10.1673 14.9493 10.2435C14.9165 10.3197 14.8689 10.3886 14.8092 10.4462L10.4342 14.8174C10.317 14.9345 10.158 15.0003 9.99232 15.0003C9.82659 15.0003 9.66765 14.9345 9.55044 14.8174Z" fill="#3264FF"/>
        <path d="M9.55044 11.6949L5.17544 7.32363C5.0616 7.20585 4.9986 7.04811 5.00002 6.88438C5.00145 6.72065 5.06718 6.56403 5.18306 6.44825C5.29894 6.33247 5.4557 6.26679 5.61957 6.26537C5.78344 6.26395 5.94132 6.32689 6.05919 6.44064L9.99232 10.3704L13.9254 6.44064C13.9831 6.381 14.0521 6.33343 14.1283 6.3007C14.2046 6.26797 14.2866 6.25074 14.3696 6.25002C14.4526 6.2493 14.5349 6.2651 14.6117 6.2965C14.6885 6.3279 14.7583 6.37427 14.8169 6.4329C14.8756 6.49153 14.922 6.56126 14.9535 6.638C14.9849 6.71474 15.0007 6.79697 15 6.87989C14.9993 6.9628 14.982 7.04475 14.9493 7.12093C14.9165 7.19712 14.8689 7.26602 14.8092 7.32363L10.4342 11.6949C10.317 11.8119 10.158 11.8777 9.99232 11.8777C9.82659 11.8777 9.66765 11.8119 9.55044 11.6949Z" fill="#3264FF"/>
      </g>
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" transform="matrix(-4.37114e-08 1 1 4.37114e-08 -2.18557e-08 2.18557e-08)" stroke="#3264FF"/>
      <defs>
        <clipPath id="clip0_7513_39494">
          <rect width="10" height="10" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 5 5)"/>
        </clipPath>
      </defs>
    </svg>
  ),
  circle_up: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7513_256838)">
        <path d="M10.4496 5.18258L14.8246 9.55381C14.9384 9.67159 15.0014 9.82933 15 9.99306C14.9986 10.1568 14.9328 10.3134 14.8169 10.4292C14.7011 10.545 14.5443 10.6106 14.3804 10.6121C14.2166 10.6135 14.0587 10.5506 13.9408 10.4368L10.0077 6.50707L6.07455 10.4368C6.0169 10.4964 5.94794 10.544 5.87168 10.5767C5.79543 10.6095 5.71342 10.6267 5.63043 10.6274C5.54744 10.6281 5.46514 10.6123 5.38833 10.5809C5.31152 10.5495 5.24174 10.5032 5.18306 10.4445C5.12438 10.3859 5.07797 10.3162 5.04654 10.2394C5.01512 10.1627 4.9993 10.0805 5.00002 9.99755C5.00074 9.91464 5.01799 9.8327 5.05074 9.75651C5.0835 9.68032 5.13111 9.61142 5.1908 9.55381L9.56581 5.18258C9.68301 5.06551 9.84195 4.99975 10.0077 4.99975C10.1734 4.99975 10.3324 5.06551 10.4496 5.18258Z" fill="#3264FF"/>
        <path d="M10.4496 8.30514L14.8246 12.6764C14.9384 12.7941 15.0014 12.9519 15 13.1156C14.9986 13.2793 14.9328 13.436 14.8169 13.5518C14.7011 13.6675 14.5443 13.7332 14.3804 13.7346C14.2166 13.7361 14.0587 13.6731 13.9408 13.5594L10.0077 9.62962L6.07455 13.5594C6.0169 13.619 5.94794 13.6666 5.87168 13.6993C5.79543 13.732 5.71342 13.7493 5.63043 13.75C5.54744 13.7507 5.46514 13.7349 5.38833 13.7035C5.31152 13.6721 5.24174 13.6257 5.18306 13.5671C5.12438 13.5085 5.07797 13.4387 5.04654 13.362C5.01512 13.2853 4.9993 13.203 5.00002 13.1201C5.00074 13.0372 5.01799 12.9553 5.05074 12.8791C5.0835 12.8029 5.13111 12.734 5.1908 12.6764L9.56581 8.30514C9.68301 8.18807 9.84195 8.12231 10.0077 8.12231C10.1734 8.12231 10.3324 8.18807 10.4496 8.30514Z" fill="#3264FF"/>
      </g>
      <rect x="-0.5" y="-0.5" width="19" height="19" rx="9.5" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 19 19)" stroke="#3264FF"/>
      <defs>
        <clipPath id="clip0_7513_256838">
          <rect width="10" height="10" fill="white" transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 15 15)"/>
        </clipPath>
      </defs>
    </svg>
  ),
  manipulation: (
    <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.200049 1.8C0.200049 2.79411 1.00594 3.6 2.00005 3.6C2.99416 3.6 3.80005 2.79411 3.80005 1.8C3.80005 0.805887 2.99416 -3.52265e-08 2.00005 -7.86805e-08C1.00594 -1.22135e-07 0.200049 0.805887 0.200049 1.8Z" fill="#7C88A6"/>
      <path d="M0.200049 8.99922C0.200049 9.99333 1.00594 10.7992 2.00005 10.7992C2.99416 10.7992 3.80005 9.99333 3.80005 8.99922C3.80005 8.00511 2.99416 7.19922 2.00005 7.19922C1.00594 7.19922 0.200049 8.00511 0.200049 8.99922Z" fill="#7C88A6"/>
      <path d="M0.200049 16.2004C0.200049 17.1945 1.00594 18.0004 2.00005 18.0004C2.99416 18.0004 3.80005 17.1945 3.80005 16.2004C3.80005 15.2063 2.99416 14.4004 2.00005 14.4004C1.00594 14.4004 0.200049 15.2063 0.200049 16.2004Z" fill="#7C88A6"/>
    </svg>
  ),
  edit: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 2.99919H5.1C3.83988 2.99919 3.20982 2.99919 2.72852 3.24443C2.30516 3.46014 1.96095 3.80435 1.74524 4.22772C1.5 4.70902 1.5 5.33908 1.5 6.59919V12.8992C1.5 14.1593 1.5 14.7894 1.74524 15.2707C1.96095 15.694 2.30516 16.0382 2.72852 16.254C3.20982 16.4992 3.83988 16.4992 5.1 16.4992H11.4C12.6601 16.4992 13.2902 16.4992 13.7715 16.254C14.1948 16.0382 14.539 15.694 14.7548 15.2707C15 14.7894 15 14.1593 15 12.8992V9.74919M5.99998 11.9992H7.25589C7.62277 11.9992 7.80622 11.9992 7.97885 11.9577C8.1319 11.921 8.27822 11.8604 8.41243 11.7782C8.5638 11.6854 8.69352 11.5557 8.95294 11.2963L16.125 4.12419C16.7463 3.50287 16.7463 2.49551 16.125 1.87419C15.5037 1.25287 14.4963 1.25287 13.875 1.87419L6.70293 9.04625C6.4435 9.30568 6.31378 9.43539 6.22102 9.58677C6.13878 9.72098 6.07817 9.86729 6.04143 10.0203C5.99998 10.193 5.99998 10.3764 5.99998 10.7433V11.9992Z" stroke="#6E6D7A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  danger: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.5V3.9C12 3.05992 12 2.63988 11.8365 2.31901C11.6927 2.03677 11.4632 1.8073 11.181 1.66349C10.8601 1.5 10.4401 1.5 9.6 1.5H8.4C7.55992 1.5 7.13988 1.5 6.81901 1.66349C6.53677 1.8073 6.3073 2.03677 6.16349 2.31901C6 2.63988 6 3.05992 6 3.9V4.5M7.5 8.625V12.375M10.5 8.625V12.375M2.25 4.5H15.75M14.25 4.5V12.9C14.25 14.1601 14.25 14.7902 14.0048 15.2715C13.789 15.6948 13.4448 16.039 13.0215 16.2548C12.5402 16.5 11.9101 16.5 10.65 16.5H7.35C6.08988 16.5 5.45982 16.5 4.97852 16.2548C4.55516 16.039 4.21095 15.6948 3.99524 15.2715C3.75 14.7902 3.75 14.1601 3.75 12.9V4.5" stroke="#FF424E" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  reload: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58333 17.417C13.4954 17.417 16.6667 14.2457 16.6667 10.3337C16.6667 7.97904 15.5178 5.89277 13.75 4.60482M10.4167 19.0003L8.75 17.3337L10.4167 15.667M9.58333 3.25033C5.67132 3.25033 2.5 6.42164 2.5 10.3337C2.5 12.6883 3.64889 14.7746 5.41667 16.0625M8.75 5.00033L10.4167 3.33366L8.75 1.66699"
        stroke="#3264FF"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  plus: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4V16M4 10H16"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}