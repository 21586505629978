import React from 'react';
import { Button } from "../../../common/button";
import { PATH } from "../../../const/path";
import { useNavigate } from "react-router-dom";
import useProfileUpdate from '../hooks/useProfileUpdate'
import { useTranslation } from "react-i18next";
import { DISPLAY_NAME_MENU } from "../../../const/display_name_menu";
import { Loading } from '../../../common/loading'

const ActionFormBrnList = () => {
    const { t } = useTranslation()
    const { value, functions } = useProfileUpdate()
    const navigate = useNavigate()

    return (
        <div style={{ textAlign: 'right', height: 48 }}>
            <Button appearance="ghost"
                onClick={() => navigate(PATH.ADMIN_DASHBOAR)}
                style={{ width: 74 }}
            >
                {t(DISPLAY_NAME_MENU.GENERAL.CLOSE)}
            </Button>
            <Button
                onClick={() => functions.submit()}
                style={{ marginLeft: 12, padding: '0 16px', width: 108 }}
                disabled={value.canSubmit}
            >
                {t(DISPLAY_NAME_MENU.GENERAL.SAVE)}
            </Button>
            {!!value.loading && <Loading />}
        </div>
    )
}

export default ActionFormBrnList;