import useCommissionFilterForm from 'Pages/commission/commissionPrincipal/hooks/useCommissionFilterForm'
import { CategoryInput } from '../../../../../common/form/input/_categoryInput'

export const CommissionCustomerInfo = () => {
  const { customerInfo } = useCommissionFilterForm()
  return (
    <CategoryInput
      className={'commission-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Tên khách hàng/Số điện thoại', value: '' }}
      categoryWidth={200}
      placeholder='Nhập Tên khách hàng/Số điện thoại'
      value={customerInfo?.value || ''}
      onChange={customerInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}
