import {useContext} from "react";
import {TeacherContext} from "../provider/_context";
import {useTeacherAction} from "../provider/_reducer";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import useAlert from "../../../hook/useAlert";
import {useNavigate, useParams} from "react-router-dom";
import {removeVietnameseTones} from "../../../util/checkPasswordVN";

export const useTeacherTable = () => {
    const {pageState, pageDispatch} = useContext(TeacherContext)
    const {filter, table} = pageState

    const {showAlert} = useAlert()

    const statusValue = filter?.status?.value
    const queries = {
        per_page: table?.pagination?.amount || 20,
        keyword: filter?.search?.value || '',
        status: statusValue?.value || '',
        start: 0
    }
    const fetchTeacherByFilter = async (qs, opt) => {
        pageDispatch({
            type: useTeacherAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/teacher/teachers${queryString}`),
        ])
        if (response[0]?.data?.success) {
            pageDispatch({
                type: useTeacherAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = response[0]?.data
            pageDispatch({
                type: useTeacherAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            pageDispatch({
                type: useTeacherAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: 0,
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }
    }
    const handleStatus = async (id, type) => {
        const url = type === "inactive" ? 'unlock' : 'lock'
        const response = await sendRequestAuth('post', `${config.API}/principal/teacher/${url}/${id}`)
        if (response?.data?.success) {
            showAlert({type: 'success', content: response?.data?.message})
            fetchTeacherByFilter(queries)
        } else showAlert({type: 'danger', content: response?.data?.message})
    }
    const {deleteConfirm} = pageState
    const valueLastPassword = deleteConfirm?.value
    const statusLastPassword = deleteConfirm?.status
    const messageLastPassword = deleteConfirm?.message

    const handleOpenConfirm = (boo, id) => {
        pageDispatch({
            type: useTeacherAction.FORM_DELETE_OPEN_CONFIRM, payload: {
                open: boo,
                id: id
            }
        })
    }

    const handleChangePassword = data => pageDispatch({
        type: useTeacherAction.FORM_DELETE_UPDATE, payload: {
            value: removeVietnameseTones(data),
            status: false,
            message: ''
        }
    })
    const handleBlurPassword = data => {
        if (!!!removeVietnameseTones(data)) pageDispatch({
            type: useTeacherAction.FORM_DELETE_UPDATE, payload: {
                value: removeVietnameseTones(data),
                status: true,
                message: 'Mật khẩu không được để trống'
            }
        })
        else if (removeVietnameseTones(data).length < 8) pageDispatch({
            type: useTeacherAction.FORM_DELETE_UPDATE, payload: {
                value: removeVietnameseTones(data),
                status: true,
                message: 'Mật khẩu phải có ít nhất 8 ký tự'
            }
        })
        else pageDispatch({
                type: useTeacherAction.FORM_DELETE_UPDATE, payload: {
                    value: removeVietnameseTones(data),
                    status: false,
                    message: ''
                }
            })
    }


    const handleUpdatePassword = async () => {
        if (!!valueLastPassword) {
            const data = {
                password: valueLastPassword
            }
            const response = await sendRequestAuth('post', `${config.API}/principal/teacher/update-password/${deleteConfirm?.id}`, data)
            if (response?.data?.success) {
                showAlert({type: 'success', content: response?.data?.message})
            } else showAlert({type: 'danger', content: 'Cập nhật mật khẩu thất bại'})
            handleOpenConfirm(false)
        } else pageDispatch({
            type: useTeacherAction.FORM_DELETE_UPDATE, payload: {
                value: '',
                status: true,
                message: 'Mật khẩu không được để trống'
            }
        })
    }

    return {
        methods: {
            onChangeStatus: handleStatus
        },
        deleteConfirm: {
            open: deleteConfirm?.open,
            value: valueLastPassword,
            status: statusLastPassword,
            message: messageLastPassword,
            methods: {
                onOpen: handleOpenConfirm,
                onChange: handleChangePassword,
                onBlur: handleBlurPassword,
                onSave: handleUpdatePassword
            }
        }
    }
}