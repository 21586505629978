import React from 'react';
import {Button} from "common/button";
import {PATH} from "const/path";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useInvoiceCreate from '../../hooks/useInvoiceCreate'

const ActionFormBrnList = () => {
  const { functions } = useInvoiceCreate()
  const navigate = useNavigate()

  return (
    <div style={{textAlign: 'right', height: 60}}>
      <Button appearance="ghost"
              onClick={() => navigate(PATH.COMMISSION_VOUCHER)}
              style={{width: 74}}
      >
        Đóng
      </Button>
      <Button
        onClick={() => functions.onCreate()}
        style={{marginLeft: 12, padding: '0 16px', width: 108}}
        // disabled={value.canSaveProduct}
      >
        Lưu
      </Button>
    </div>
  )
}

export default ActionFormBrnList;