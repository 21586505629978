import React, {memo, useEffect} from "react";
import PageHeader from "./component/header/index";
import useCreateTeacher from "./hook/useCreateTeacher";
import {CreateTeacherProvider} from './provider'
import {GridLayout} from "../../../../layouts/gridLayout";
import {InfoPerson} from "./component/infoPerson";
import {InfoBank} from "./component/infoBank";
import ActionFormBrnList from "./component/actionFormBrnList";
import {useParams} from 'react-router-dom'
import {Text} from "../../../../common/text";
import styled from 'styled-components'
import ReactImageFallback from "react-image-fallback";
import {useCreateTeacherForm} from "./hook/useCreateTeacherForm";
import UserAccount from "./component/userAccount";
import {StatusPerson} from "./component/infoPerson/_statusPerson";
import {StatusEditTeacher} from "./component/infoPerson/_statusEditPerson";
import {useCreateTeacherTab} from "./hook/useCreateTeacherTab";
import {InfoCommission} from "./component/infoComission";
import {ProductTeachers} from "./component/productTeacher";

export const TeacherCreate = memo(() => {
   const {provider, fetch} = useCreateTeacher()
   const {state, dispatch} = provider
   const {teacherId} = useParams()
   useEffect(() => {
      if (!!teacherId) fetch.origin(teacherId)
   }, [teacherId])
   return (
      <CreateTeacherProvider value={{pageState: state, pageDispatch: dispatch}}>
         {
            !!teacherId ? <TeacherEdit/>
               :
               <GridLayout
                  header={
                     <PageHeader/>
                  }
                  grid={[
                     {
                        width: 70,
                        sections: [
                           {
                              title: 'Thông tin cá nhân',
                              props: {
                                 style: {
                                    position: 'relative',
                                    padding: 24
                                 },
                                 children: <InfoPerson/>,
                              },
                           },
                           {
                              title: 'Thông tin hoa hồng',
                              props: {
                                 style: {
                                    position: 'relative',
                                    padding: 24
                                 },
                                 children: <InfoCommission/>,
                              },
                           },
                           {
                              title: 'Thông tin ngân hàng',
                              props: {
                                 style: {
                                    position: 'relative',
                                    padding: 24
                                 },
                                 children: <InfoBank/>,
                              },
                           },
                           {
                              type: 'sticky-bottom-transparent',
                              props: {
                                 style: {
                                    position: 'sticky',
                                    bottom: -44,
                                    marginBottom: 0,
                                    zIndex: 10,
                                    padding: "12px 24px 0 12px"
                                 },
                                 children: <ActionFormBrnList/>,
                              },
                           },
                        ],
                        props: {style: {position: 'relative'}},
                     },
                     {
                        width: 30,
                        sections: [
                           {
                              title: 'Thông tin tài khoản',
                              props: {
                                 style: {
                                    position: 'relative',
                                    padding: 24
                                 },
                                 children: <UserAccount/>,
                              },
                           }
                        ],
                        props: {style: {position: 'relative'}},
                     },
                  ]}
                  data-model="container"
               />
         }

      </CreateTeacherProvider>

   )
})

export const TAB_CREATE_PRINCIPAL_EDIT = [
   {name: 'Thông tin giáo viên', tab: 'teacher'},
   {name: 'Sản phấm bán', tab: 'products'},
]

const TeacherEdit = () => {
   const {avatar, detail} = useCreateTeacherForm()
   const fullName = `${detail?.last_name || ''} ${detail?.name || ''}`
   const {tab} = useCreateTeacherTab()
   const {active, onChange} = tab
   return (
      <StyleTeacherEdit>
         <PageHeader/>
         <GridLayout
            grid={[
               {
                  width: 100,
                  sections: [
                     {
                        title: 'Thông tin tài khoản',
                        props: {
                           style: {
                              position: 'relative',
                              padding: 24
                           },
                           children:
                              <div className='create-teacher-avatar'>
                                 <div className='create-teacher-avatar_teacher'>
                                    {/*<input*/}
                                    {/*    title=" "*/}
                                    {/*    type='file'*/}
                                    {/*    accept="image/png, image/jpeg"*/}
                                    {/*    onChange={onUploadAvatar}*/}
                                    {/*    className='create-teacher_update_image'/>*/}
                                    <ReactImageFallback
                                       src={avatar?.value || '/img/useravatar.jpg'}
                                       // alt={data?.shopname}
                                       fallbackImage='/img/avatar.png'
                                       style={{width: 102, height: 102, borderRadius: 8}}
                                    />
                                 </div>
                                 <div style={{
                                    marginLeft: 20,
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '80%'
                                 }}>
                                    <div style={{marginRight: 16, height: 90}}>
                                       <Text as={'p'} fontWeight={600} color={'rgba(0, 0, 0, 0.50)'}
                                             style={{marginBottom: 8}}>Tên đăng
                                          nhập: </Text>
                                       <Text as={'p'} fontWeight={600} color={'rgba(0, 0, 0, 0.50)'}
                                             style={{marginBottom: 8}}>Giáo
                                          viên: </Text>
                                       <Text as={'p'} fontWeight={600} color={'rgba(0, 0, 0, 0.50)'}
                                             style={{marginBottom: 8}}>Trạng
                                          thái:
                                       </Text>
                                    </div>
                                    <div style={{maxWidth: '60%'}}>
                                       <Text as={'p'} fontWeight={600}
                                             style={{marginBottom: 8}}>{detail?.username}</Text>
                                       <Text as={'p'} fontWeight={600}
                                             style={{marginBottom: 8}}>{fullName}</Text>
                                       <StatusEditTeacher/>
                                    </div>
                                 </div>
                              </div>
                           ,
                        },
                     },
                  ],
                  props: {style: {position: 'relative'}},
               },
            ]}
            data-model="container"
         />

         <div className='create-teacher-tabs'>
            {
               TAB_CREATE_PRINCIPAL_EDIT?.map((map, i) => <div
                  className='create-teacher-tab'
                  data-active={map?.tab === active?.tab}
                  key={i} onClick={() => onChange(map)}>
                  <Text>{map?.name}</Text>
               </div>)
            }

         </div>
         {active?.tab === 'teacher' && <GridLayout
            grid={[
               {
                  width: 100,
                  sections: [
                     {
                        title: 'Thông tin cá nhân',
                        props: {
                           style: {
                              position: 'relative',
                              padding: 24
                           },
                           children: <InfoPerson/>,
                        },
                     },
                     {
                        title: 'Thông tin hoa hồng',
                        props: {
                           style: {
                              position: 'relative',
                              padding: 24
                           },
                           children: <InfoCommission/>,
                        },
                     },
                     {
                        title: 'Thông tin ngân hàng',
                        props: {
                           style: {
                              position: 'relative',
                              padding: 24
                           },
                           children: <InfoBank/>,
                        },
                     },
                     {
                        type: 'sticky-bottom-transparent',
                        props: {
                           style: {
                              position: 'sticky',
                              bottom: -44,
                              marginBottom: 0,
                              zIndex: 10,
                              padding: "12px 24px 0 12px"
                           },
                           children: <ActionFormBrnList/>,
                        },
                     },
                  ],
                  props: {style: {position: 'relative'}},
               },
            ]}
            data-model="container"
         />
         }
         {
            active?.tab === 'products' &&
            <div className='create-principal-tabs_content'>
               <ProductTeachers/>
            </div>
         }
      </StyleTeacherEdit>
   )
}

const StyleTeacherEdit = styled.div`
    max-width: 1366px;
    margin: 0 auto;
    .create-teacher{
       &-tabs{
            display: flex;
        }
        &-tab{
            height: 36px;
            padding: 10px 32px;
            border-radius: 8px 8px 0px 0px;
            background: #E2EAF8;
            cursor: pointer;
            margin-right: 4px;
            &[data-active = true]{
               background: #ffffff;
            }
           
        }
        &-tabs_content{
            padding-top: 24px;
            background: #ffffff;
            max-width: 1360px;
        }
        &-avatar{
            //height: 150px;
            background: #ffffff;   
            //margin-bottom: 24px;   
            border-radius: 8px;  
            display: flex;
            //padding: 24px;
        }
        &-avatar_teacher{
            position: relative;
            margin-right: 32px;
        }
        &_update_image{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .grid-layout__section {
        margin: 0px 9px 24px;
        &:nth-child(1){
            border-radius: 0px 8px 8px 8px;
        }    
    }
`
