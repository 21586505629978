import {useEffect, useReducer} from 'react'
import {sendRequestAuth} from 'api/api'
import {
    useCreateTeacherAction,
    useCreateTeacherReducer,
} from '../provider/_reducer'
import config from "../../../../../config";
import {formatDatetimeV2} from "../../../../../common/form/datePicker/_functions";
import {CREATE_TEACHER_GENDER, STATUS_TEACHER, TAB_CREATE_TEACHER_EDIT} from "../interfaces/constant";
import {useParams, useSearchParams} from "react-router-dom";
import {useCreateTeacherInitialState} from "../provider/_initState";
import {useCreateProductAction, useModalProductAction} from "../provider/_action";

const useCreateTeacher = () => {
    const [state, dispatch] = useReducer(useCreateTeacherReducer, useCreateTeacherInitialState)
    const [searchParams] = useSearchParams()
    const {teacherId} = useParams()
    useEffect(() => {
        if (searchParams.get('tab') === 'products')
            dispatch({type: useCreateTeacherAction.FORM_CREATE_TAB_CHANGE, payload: TAB_CREATE_TEACHER_EDIT[1]})
    }, [searchParams])

    const handleOriginFetch = async (id) => {
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/teacher/detail/${id}`),
        ])
        if (response[0]?.data?.success) {
            const detail = response[0]?.data?.data
            dispatch({
                type: useCreateTeacherAction.FORM_CREATE_GET_DETAIL,
                payload: {
                    info: {
                        userName: {
                            value: detail?.username || '',
                            status: false,
                            message: ''
                        },
                        schoolName: {
                            value: detail?.school_name || '',
                            status: false,
                            message: ''
                        },
                        phone: {
                            value: detail?.phone || '',
                            status: false,
                            message: ''
                        },
                        email: {
                            value: detail?.email || '',
                            status: false,
                            message: ''
                        },
                        name: {
                            value: detail?.name || '',
                            status: false,
                            message: ''
                        },
                        lastName: {
                            value: detail?.last_name || '',
                            status: false,
                            message: ''
                        },
                        birthday: {
                            value: !!detail?.birthday ? formatDatetimeV2(detail?.birthday) : '',
                            status: false,
                            message: ''
                        },
                        identify: {
                            value: detail?.identity_number || '',
                            status: false,
                            message: ''
                        },
                        commission: {
                            value: detail?.commission_rate || '',
                            status: false,
                            message: ''
                        },
                        teachingSubject: {
                            value: detail?.teaching_subject || '',
                            status: false,
                            message: ''
                        },
                        gender: {
                            value: CREATE_TEACHER_GENDER?.find(find => find.value === detail?.gender),
                            status: false,
                            message: ''
                        },
                        address: {
                            value: detail?.address,
                            status: false,
                            message: ''
                        },
                        password: {
                            value: '',
                            status: false,
                            message: ''
                        },
                        statusPerson: {
                            value: STATUS_TEACHER?.find(find => find.value === detail?.status),
                            status: false,
                            message: ''
                        },
                        accountNumber: {
                            value: detail?.bank_number,
                            status: false,
                            message: ''
                        },
                        accountOwner: {
                            value: detail?.bank_owner,
                            status: false,
                            message: ''
                        },
                        bankName: {
                            value: detail?.bank_name,
                            status: false,
                            message: ''
                        },
                        avatar: {
                            value: detail?.avatar || ''
                        },
                        detail: detail
                    }
                }
            })
        }

        const res = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/teacher/products/${teacherId}?keyword&category_id&status&start=0&per_page=20`),
            sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`),
        ])
        if (res[0]?.data?.success) {
            dispatch({
                type: useCreateProductAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const products = res[0]?.data
            dispatch({
                type: useCreateProductAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: products?.data,
                    listDefault: products?.data,
                    loading: false
                }
            })
            dispatch({
                type: useCreateProductAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: Math.ceil(products?.meta?.start / products?.meta?.per_page),
                    amount: products?.meta?.per_page,
                    total: Math.ceil(products?.meta?.total / products?.meta?.per_page),
                    totalItems: products?.meta?.total,
                }
            })
        }
        if(res[1]?.data?.length > 0) {
            dispatch({
                type: useCreateProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
                payload: {
                    list: res[1]?.data,
                    listOrigin: res[1]?.data
                }
            })
            dispatch({
                type: useModalProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
                payload: {
                    list: res[1]?.data,
                    listOrigin: res[1]?.data
                }
            })
        }
    }


    return {
        fetch: {
            origin: handleOriginFetch
        },
        provider: {
            state,
            dispatch
        },

    }
}
export default useCreateTeacher