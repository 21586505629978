import {USER_ROLE} from './~icon'

export const USER_ROLE_BREADCRUMB = [
  {id: 1, name: 'configuration_and_settings', url: '#'},
  {id: 2, name: 'user_page_user_role', url: '#'},
]

export const USER_ROLE_HEADER_ACTIONS = [
  {
    id: 1,
    name: null,
    appearance: 'secondary',
    icon: USER_ROLE.reload,
  },
  {
    id: 2,
    name: 'user_page_create_role',
    appearance: 'primary',
    icon: USER_ROLE.plus,
    // href: '/users/create',
  },
]

export const POPOVER_USER = [
  { id: '1', name: 'edit', icon: USER_ROLE.edit, action: 1 },
  { id: '2', name: 'delete', icon: USER_ROLE.danger, action: 2 }
]