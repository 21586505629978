import { Input } from 'common/form/input';
import { Textarea } from 'common/form/textarea';
import { Text } from 'common/text';
import { THEME_SEMANTICS } from 'common/theme/_semantics';
import { SwitchStatus } from 'Component/SwitchStatus/SwitchStatus';
import { useModal } from 'Pages/productGroup/hook/useModal';
import { ProductGroup } from 'Pages/productGroup/provider/_context';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { StyledProductGroupContentModal } from './_styled';
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete';
import { Tooltip } from 'common/tooltipv2';
import {useTranslation} from "react-i18next";
import { AutoCompleteSingleOption } from '../autocompleteSingleOption'

const Index = ({ ...props }) => {
    const {t} = useTranslation()
    const { pageState, pageDispatch } = useContext(ProductGroup)
    const { onPickup, field_name, field_code, onChangeNote, status_product, search, onBlurNoteProduct } = useModal()
    const disabled = pageState.disabled
    return (
        <StyledProductGroupContentModal>
            <div className={'product-group-content'}>
                <div className='product-group-content_group'>
                    <Input
                        {...props}
                        label={
                            <>
                                {t('product_page_name_group_product')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                            </>
                        }
                        autoComplete={'false'}
                        maxLength={80}
                        value={pageState.name_product}
                        placeholder= {t('product_page_enter_name_group_product')}
                        validateText={pageState.valid.filde_name.status ? pageState.valid.filde_name.message : null}
                        validateType={!pageState.valid.filde_name.status ? 'success' : 'danger'}
                        onChange={(e) => field_name.onChangeNameProduct(e)}
                        onBlur={(e) => field_name.onBlurNameProduct(e)}
                    />
                </div>
                <div className='product-group-content_group'>
                    <AlternativeAutoComplete
                        {...props}
                        inputProps={{
                            categoryList: [], // menu list in category dropdown
                            categoryValue: { name: '', value: '' }, // if not exist this value -> default category: categoryList[0]
                            // categoryWidth: 140,
                            categoryHidden: true,
                            label: (
                                <>
                                    {t('product_page_parent_group_product')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                                </>
                            ),
                            placeholder: t('product_page_select_parent_group_product'),
                            readOnly: true,
                            disabled,
                            value: pageState.item_category.item ? pageState.item_category.item : '',

                        }}
                        menuProps={{
                            empty: pageState.category_list.length <= 0 ? t('product_page_empty_group_product') : '',
                        }}
                        searchInputProps={{
                            placeholder: t('product_page_find_group_product'),
                            value: pageState.keyword || '',
                            onChange: search.searchkeyWord,
                        }}
                        className={disabled && 'product-group-content_alternative'}
                        validateText={pageState.valid.filde_parent.status ? pageState.valid.filde_parent.message : null}
                        validateType={!pageState.valid.filde_parent.status ? 'success' : 'danger'}
                    >
                        {pageState.category_list && disabled === false &&
                            pageState.category_list.map(item =>{
                                if(pageState.check_parent === item.item){
                                    return(
                                        <AutoCompleteSingleOption
                                            key={item.value}
                                            data-active={item.item === pageState.item_category?.item}
                                            onClick={(e) => e.stopPropagation()}
                                            className={'product-group-content_alternative-option'}
                                        >
                                            <Tooltip className='tooltip_select' title={item.item} baseOn='height' placement='top-center'>
                                                    {item.item}
                                            </Tooltip>
                                        </AutoCompleteSingleOption>
                                    )
                                }else{
                                    return (
                                        <AutoCompleteSingleOption
                                            key={item.value}
                                            data-active={item.item === pageState.item_category?.item}
                                            onClick={() => onPickup(item.item, item.id)}
                                        >
                                            <Tooltip className='tooltip_select' title={item.item} baseOn='height' placement='top-center'>
                                                    {item.item}
                                            </Tooltip>
                                        </AutoCompleteSingleOption>
                                    )
                                }
                            }

                            )}
                    </AlternativeAutoComplete>
                </div>
                {/* <div className='product-group-content_group product-group-content_group-textArea'>
                    <Textarea
                        {...props}
                        label={
                            <>
                                {t('general_note')}
                            </>
                        }
                        placeholder={t('general_enter_note')}
                        value={pageState.note_product}
                        onChange={e => onChangeNote(e.target.value)}
                        validateText={pageState.valid.filde_note.status ? pageState.valid.filde_note.message : null}
                        validateType={!pageState.valid.filde_note.status ? 'success' : 'danger'}
                    />
                </div> */}

                {/* <div className='product-group-content_switch'>
                    <SwitchStatus status={status_product.status} handleChange={status_product.onChangeStatus} />
                    <Text as='p'>{t('general_active_or_inactive')}</Text>
                </div> */}
            </div>

        </StyledProductGroupContentModal>

    )
}
export default Index;