import { Input } from 'common/form/input'
import React from 'react'
import { StyledProductGroupContentModal } from './_styled'
import { useModal } from '../../hooks/useModal'
import { DateNote } from './_dateNote'

const Index = ({ ...props }) => {
  const { paymentReference, paymentReason } = useModal()
  return (
    <StyledProductGroupContentModal>
      <div className={'product-group-content'}>
        <div className='product-group-content_group'>
          <DateNote/>
        </div>
        <div className='product-group-content_group'>
          <Input
            {...props}
            label={'Chứng từ tham chiếu'}
            autoComplete={'false'}
            maxLength={80}
            value={paymentReference.value}
            placeholder={'Vui lòng nhập chứng từ tham chiếu'}
            validateText={paymentReference?.message ? paymentReference?.message : null}
            validateType={!paymentReference?.message ? 'success' : 'danger'}
            onChange={(e) => paymentReference.methods.onChange(e.target.value)}
            // onBlur={(e) => paymentReference.onBlurNameProduct(e)}
          />
        </div>
        <div className='product-group-content_group'>
          <Input
            {...props}
            label={'Lý do chi'}
            autoComplete={'false'}
            maxLength={80}
            value={paymentReason.value}
            placeholder={'Vui lòng nhập lý do chi'}
            validateText={paymentReason?.methods ? paymentReason?.message : null}
            validateType={!paymentReason?.message ? 'success' : 'danger'}
            onChange={(e) => paymentReason.methods.onChange(e.target.value)}
            // onBlur={(e) => field_name.onBlurNameProduct(e)}
          />
        </div>
      </div>

    </StyledProductGroupContentModal>

  )
}
export default Index