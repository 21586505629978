import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { CustomerFilterForm } from './components/customerFilterForm'
import { CustomerTBody } from './components/customerTable/_customerTBody'
import { CustomerTHead } from './components/customerTable/_customerTHead'
import {
  CUSTOMER_BREADCRUMB,
} from './interfaces/_constants'
import { CustomerProvider } from './provider'
import './components/customerTable/index.scss'
import useCustomer from './hooks/useCustomer'
import { CustomerNotifications } from './components/customerNotifications'
import { PageHeader } from './components/customerPageHeader'
import PopupNotPermission from '../permision/popupNotPermission'
import { ModalExport } from '../../common/modalExport'
import { GLOBAL_ICONS } from '../../interface/icon'
import useAlert from '../../hook/useAlert'
import useCustomerFilterForm from './hooks/useCustomerFilterForm'

export const CustomerPage = () => {
  const { fetch, pagination, provider } = useCustomer()

  const { state, dispatch } = provider
  const { table } = state

  useEffect(() => {
    fetch.origin()
  }, [])
  return (
    <CustomerProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <Header/>
      <CustomerNotifications/>
      <TableLayout
        header={
          <>
            <CustomerFilterForm/>
          </>
        }
        table={{
          tHead: <CustomerTHead/>,
          tBody: <CustomerTBody/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </CustomerProvider>
  )
}

const Header = () => {
  const [showPermission, setShowPermission] = useState(false)
  const [exportModalData, setExportModalData] = useState(null)
  const  {queries, pageState} = useCustomerFilterForm()
  const {showAlert} = useAlert()

  const handleExportClick = () => {
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...queries,
      per_page: '',
      start: '',
    })) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    handleLargeExport(
      {
        ...queries,
        per_page: '',
        start: '',
      }
    )
  }

  const handleLargeExport = (q) => {
    if (pageState.table.pagination.totalItems <= 0) {
      showAlert({ type: 'info', content: `Số lượng khách hàng phải lớn hơn  0` })
      return
    }

    setExportModalData({
      data: {
        query: q,
        total: pageState.table.pagination.totalItems,
      },
      onClose: () => setExportModalData(null),
    })
  }

  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
      <PageHeader
        breadcrumbLinks={CUSTOMER_BREADCRUMB}
        breadcrumbTitle="Quản lý khách hàng"
        actions={[
          {
            name: 'Xuất excel',
            icon: GLOBAL_ICONS.export,
            onClick: handleExportClick,
            appearance: 'secondary',
          }
        ]}
      />

      {!!exportModalData && <ModalExport data={exportModalData} title={'Khách hàng'} api={`/principal/customer/export-customers`}/>}
    </>
  )
}
