import React from "react";
import {Input} from "Component/Input";
import {Text} from "common/text";
import {THEME_SEMANTICS} from "common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";
import {Tooltip} from "../../../../../../common/tooltip";
import {GLOBAL_ICONS} from "../../../../../../interface/icon";

export const AccountNumber = () => {
   const {commission} = useCreateTeacherForm()
   const {methods} = commission
   return (
      <Input
         label={
            <>
               % hoa hồng <Text color={THEME_SEMANTICS.failed}>*  </Text>
               <Tooltip title={'Hoa hồng cho giáo viên được trích từ % hoa hồng của bạn.'}>
                  {GLOBAL_ICONS.question}
               </Tooltip>
            </>
         }
         value={commission?.value}
         onChange={e => methods.onChange(e.target?.value)}
         validateText={commission?.message}
         validateType={commission?.status ? 'danger' : ''}
         placeholder={'Vui lòng nhập % hoa hồng của giáo viên'}
         maxLength={32}
      />
   )
}