import React from 'react'
import { Box, Modal } from '@mui/material'
import styled from 'styled-components'
import { Text } from 'common/text'
import { Button } from 'common/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAlert from 'hook/useAlert'
import { GLOBAL_ICONS } from '../../../../interface/icon'
import { useModal } from '../../hooks/useModal'

export const ResetPassword = () => {
  const { data, functions } = useModal()
  const { t } = useTranslation()
  const { shopId } = useParams()

  return (
    <Modal open={data?.resetPasswordModal?.resetPassword}
           onClose={() => functions.onDisplayResetPasswordModal(false)}
    >
      <Box>
        <StyledConfirmOrder>
          <Text as={'p'} fontWeight={600} fontSize={20}>Đổi mật khẩu người dùng</Text>
          <Text as={'p'} style={{ marginTop: 24 }}>Mật khẩu của người dùng sẽ được đặt lại theo mật khẩu ngẫu nhiên từ
            hệ thống, bạn có chắc chắn muốn thực hiện?</Text>
          <div style={{ marginTop: 24, textAlign: 'right' }}>
            <Button size={'sm'}
                    appearance={'ghost'}
                    style={{ width: 110, marginRight: 8 }}
                    onClick={() => functions.onDisplayResetPasswordModal(false)}
            >{t('general_cancel')}</Button>
            <Button size={'sm'}
                    style={{ width: 110 }}
                    onClick={() => {
                      functions.onResetPassword({
                        id: shopId,
                        user_id: data?.resetPasswordModal?.resetPassword
                      })
                      functions.onDisplayResetPasswordModal(false)
                    }}
            >{t('general_confirm')}</Button>
          </div>
        </StyledConfirmOrder>
      </Box>
    </Modal>
  )
}


export const ResetPasswordSuccess = () => {
  const { data, functions } = useModal()
  const { t } = useTranslation()
  const {showAlert} = useAlert()
  const copyOrderCode = data => {
    navigator.clipboard.writeText(data)
    showAlert({ content: 'Đã sao chép mật khẩu', type: 'success' })
  }
  return (
    <Modal open={data?.resetPasswordModal?.resetSuccess?.display}
           onClose={() => functions.onDisplayResetPasswordSuccessModal(false)}
    >
      <Box>
        <StyledConfirmOrder>
          <Text as={'p'} fontWeight={600} fontSize={20}>Đổi mật khẩu thành công</Text>
          <Text as={'p'} style={{ marginTop: 24, marginBottom: 8 }}>Bạn đã đổi mật khẩu thành công cho tài khoản {data?.resetPasswordModal?.resetSuccess?.user?.fullname}</Text>
          <div onClick={() => copyOrderCode(data?.resetPasswordModal?.resetSuccess?.user?.password|| ' ')} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
            <Text as={'p'}>Mật khẩu mới: </Text>
            <Text as={'p'} fontWeight={600} >&nbsp; {" "}{data?.resetPasswordModal?.resetSuccess?.user?.password} &nbsp; {" "}</Text>
            {GLOBAL_ICONS.copy03_x}
          </div>
          <div style={{ marginTop: 24, textAlign: 'right' }}>
            <Button size={'sm'}
                    style={{ width: 110, marginRight: 8 }}
                    onClick={() => functions.onDisplayResetPasswordSuccessModal(false)}
            >{t('close')}</Button>
          </div>
        </StyledConfirmOrder>
      </Box>
    </Modal>
  )
}

export const StyledConfirmOrder = styled.div`
  width: 480px;
  height: 204px;
  
  margin: auto;
  margin-top: 300px;
  padding: 24px;
  position: relative;
  background: #FFFFFF;
  /* Style 1 */
  
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  
  @media screen and (max-height: 700px){
    margin-top: 200px;
  }
  button{
    &:focus{
      border: 1px solid red;
    }
  }
`
  