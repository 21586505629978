import { format, formatDistanceToNow } from 'date-fns';
import addDays from "date-fns/addDays"
import startOfWeek from "date-fns/startOfWeek"
import endOfWeek from "date-fns/endOfWeek"
import subDays from "date-fns/subDays"
import startOfMonth from "date-fns/startOfMonth"
import addMonths from "date-fns/addMonths"
import endOfMonth from "date-fns/endOfMonth"

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fDateTimeDMY(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}


export function fDateTimeCustom(date, otp={}) {
  return format(new Date(date), otp?.format ? otp.format : 'dd/MM/yyyy');
}

export function fDateTimeApi(date) {
  return format(new Date(date), 'yyyy/MM/dd HH:mm');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function ftoDate(date = 0) {
  switch(date)
  {
    case 1: {date="CN"; break;}
    case 2: {date="T2"; break;}
    case 3: {date="T3"; break;}
    case 4: {date="T4"; break;}
    case 5: {date="T5"; break;}
    case 6: {date="T6"; break;}
    case 7: {date="T7"; break;}
    default: date = 0; break;
  }
  return date
}
export const predefinedRanges = ({option}) => {

  return [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
}
export const getDateFromNow = (n,otp = {type: 'start'}) => {
  let date = new Date()
  if(otp && otp?.type == 'start')
    date.setHours(0,0,0,0);
  if(otp && otp?.type == 'end')
    date.setHours(23,59,0,0);
  const res = date.setTime(date.getTime() + n * 24 * 60 * 60 * 1000)
  return new Date(res)
}

export const getMonthFromNow = (n) => {
  let date = new Date()
  return (+date.getMonth() + 1)
}

export const getYearFromNow = _ => {
  let date = new Date()
  return date.getFullYear()
}

export const daysInMonth =  (month, year) => {
  return new Date(year, month, 0).getDate();
}