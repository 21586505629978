import React, {useContext} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {TeacherContext} from "../../provider/_context";
import {Tr} from "../../../../layouts/tableLayout/_tr";
import {Text} from "../../../../common/text";
import {Td} from "../../../../layouts/tableLayout/_td";
import {Button} from "../../../../common/button";
import {formatDatetime} from "../../../../common/form/datePicker/_functions";
import {TEACHER_GROUP_ICON} from "../../interface/icon";
import {Tooltip as TooltipV2} from "../../../../common/tooltipv2";
import {Tooltip} from "../../../../common/tooltip";
import ReactImageFallback from "react-image-fallback";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {fNumber} from "../../../../util/formatNumber";
import {ConfirmModalV2} from "../../../../Component/ConfirmModal";
import {useTeacherTable} from "../../hook/useTeacherTable";
import {Input} from "../../../../Component/Input";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";

const Tbody = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(TeacherContext)
   const {table} = pageState
   const {deleteConfirm} = useTeacherTable()

   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount
   const paginationTotalItems = table.pagination.totalItems
   const paginationActive = table.pagination.active
   return (
      <StyledTBody>
         {displayLoading ? (
            Array.from(Array(paginationAmount), (e, i) => (
               <TeacherPlaceholder key={i}/>
            ))
         ) : paginationTotalItems > 0 ? (
            displayList.map((item, key) => <TeacherTr t={t} key={item.id}
                                                      stt={key + (+paginationAmount * +paginationActive)}
                                                      data={item}/>)
         ) : (
            <TeacherEmpty t={t} list={displayListDefault}/>
         )}
         {
            !!deleteConfirm?.open && <ConfirmModalV2
               open={deleteConfirm?.open}
               width={480}
               body={
                  <BodyConfirm/>
               }
               footerButton={{
                  cancel: {
                     title: 'Hủy',
                     action: () => deleteConfirm.methods?.onOpen(false, '')
                  },
                  confirm: {
                     title: 'Lưu',
                     action: () => deleteConfirm.methods?.onSave(),
                     disabled: deleteConfirm?.status
                  }
               }}
            />
         }
      </StyledTBody>
   )
}

export default Tbody

const BodyConfirm = ({fullName, userName}) => {
   const {deleteConfirm} = useTeacherTable()
   const {methods} = deleteConfirm
   console.log(deleteConfirm)
   return (
      <div className='create-teacher_confirm-body'>
         <Text fontSize={20} fontWeight={600}>Đặt mật khẩu : {fullName}</Text>
         <div style={{margin: '24px 0'}}>
            <Text>Mật khẩu người dùng {userName} sẽ được thay đổi ngay sau khi bạn đặt mật khẩu.</Text>
         </div>
         <Input
            label={
               <>
                  Mật khẩu <Text color={THEME_SEMANTICS.failed}>*</Text>
               </>
            }
            value={deleteConfirm?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={deleteConfirm?.message}
            validateType={deleteConfirm?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập mật khẩu'}
            maxLength={32}
         />

      </div>
   )
}
const TeacherPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="teacher-table__row">
         {Array.from(Array(8), (e, i) => (
            <Td key={i} className="teacher-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background:
                        'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const TeacherEmpty = ({list, t, ...props}) => {
   return (
      <StyledTeacherEmpty {...props}>
         <img
            className="teacher-empty__banner"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có danh sách giáo viên nào') : t('no_matching_data')}
         </Text>
         {!!!list && (
            <Button href={'/upos/shop/create'} icon={GLOBAL_ICONS.plus}>{t('add_new_shop')}</Button>
         )}
      </StyledTeacherEmpty>
   )
}

const TeacherTr = ({data, t, stt, ...props}) => {
   const fullName = `${data?.last_name || ''} ${data?.name || ''}`
   const {deleteConfirm} = useTeacherTable()
   return (
      <>
         <Tr
            {...props}
            className="teacher-table__row"
         >
            <Td className="teacher-table__cell" data-type="td">
               <ReactImageFallback
                  src={data?.avatar || '/img/useravatar.jpg'}
                  // alt={data?.shopname}
                  fallbackImage='/img/useravatar.jpg'
                  style={{width: 36, height: 36, borderRadius: 50, marginRight: 8}}
               />
               <TooltipV2 title={fullName} baseOn={'height'} className='teacher-table__cell-tooltip'>
                  <Text as={'p'}>{fullName || '---'}</Text>
                  <Text as={'p'}>{data?.username || '---'}</Text>
               </TooltipV2>
            </Td>
            <Td className="teacher-table__cell" data-type="td">
               <TooltipV2 title={data?.email} baseOn={'height'} className='teacher-table__cell-tooltip'>
                  <Text>{data?.email || '---'}</Text>
                  <Text as={'p'} color={'rgba(0, 0, 0, 0.55)'}>{data?.phone || '---'}</Text>
               </TooltipV2>
            </Td>
            <Td className="teacher-table__cell" data-type="td">
               <Text>{data?.commission_rate || 0}</Text>
            </Td>
            <Td className="teacher-table__cell" data-type="td">
               <Text
                  href={`/teacher/${data?.id}?tab=products`}
                  as={'a'}
                  color={'#0F62F9'}
               >
                  {fNumber(data?.total_products || 0)}
               </Text>
            </Td>
            <Td className="teacher-table__cell" data-type="td">
               <Text>
                  {fNumber(data?.total_customers || 0)}
               </Text>
            </Td>
            <Td className="teacher-table__cell" data-type="td">
               {formatDatetime(data?.created_at)}
            </Td>
            <Td className="teacher-table__cell" data-type="td">
               {data?.status !== 'inactive'
                  ? <Text className={'teacher-table__cell--success'}>{data?.status_name}</Text>
                  : <Text className={'teacher-table__cell--danger'}>{data?.status_name}</Text>
               }
            </Td>
            <Td
               className="teacher-table__cell"
               data-menu="true"
               data-type="td"
               onClick={e => e.stopPropagation()}
            >
               <Tooltip title={'Chỉnh sửa'}>
                  <Text
                     className="teacher-table__edit"
                     // data-active={row.shouldOpenDetail}
                     href={`/teacher/${data?.id}`}
                     as={'a'}
                  >
                     {TEACHER_GROUP_ICON.edit}
                  </Text>
               </Tooltip>
               <Tooltip title={'Khôi phục mật khẩu'}>
                  <Text
                     className="teacher-table__delete"
                     onClick={() => deleteConfirm.methods?.onOpen(true, data?.id)}
                  >
                     {data?.status !== 'inactive'
                        ? TEACHER_GROUP_ICON.lock
                        : TEACHER_GROUP_ICON.unlock
                     }
                  </Text>
               </Tooltip>
            </Td>
         </Tr>
      </>
   )
}

export const StyledTBody = styled.div`
  .teacher-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
  
      width: 100vw;
      height: 100vh;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      background: rgba(0, 0, 0, 0.25);
  
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    &__row {
      &:hover {
        .teacher-table__delete {
          display: block;
        }
        .teacher-table__edit {
          display: block;
        }
      }
    }
    &__cell-tooltip{
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-word;
    }
    &__cell {
      cursor: pointer;
      margin: 0 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }
  
      &[data-type='th'] {
        &[data-selected='true'] {
          display: flex;
          flex: 1;
          align-items: center;
          height: 44px;
        }
      }
  
      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        flex: 1;
      }
      &:nth-child(2) {
        width: 15%;
        justify-content: left;
      }
  
      &:nth-child(3) {
        width: 10%;
        justify-content: center;
      }
  
      &:nth-child(4) {
        width: 10%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
  
      &:nth-child(5) {
        width: 12%;
        justify-content: center;
      }
  
      &:nth-child(6) {
        width: 10%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
  
      &:nth-child(7) {
        width: 10%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
      &:nth-child(8) {
        width: 100px;
        padding-left: 20px;
      }
  
    }
  
    &__delete {
      width: 20px;
      height: 20px !important;
      display: none;
  
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
    }
  
    &__edit {
      width: 20px;
      height: 20px !important;
      display: none;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;
  
      font-size: 12px !important;
      line-height: 24px !important;
  
      cursor: pointer;
  
      &[data-active='true'] {
        display: block !important;
      }
    }
  
    &__selected-action-dropdown {
      position: relative;
  
      margin-left: 12px;
    }
  
    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;
  
      border-radius: 14px !important;
  
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  
    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
  
      width: 100vw;
      height: 100vh;
    }
  
    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;
  
      width: 150px;
      padding: 8px;
  
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__selected-action-menu-item {
      padding: 8px;
  
      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
  
      transition: color 0.25s;
  
      cursor: pointer;
  
      &:hover {
        color: #3264FF;
      }
    }
  
    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledTeacherEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .teacher-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
