import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {useState} from 'react'
import {NOTIFICATION_ICONS} from './_icons'
import {StyledNotificationBar} from './_styled'

/**
 * @type {TypeType}  = 'danger' | 'info' | 'success' | 'warning'
 */
/**
 * @param {canClose} ?: Boolean       <Have toggle to close>
 * @param {type}     ?: TypeType      <Appearances of notification>
 */
export const NotificationBar = ({
  type = 'info', // danger | info | success | warning
  collapse,
  onClose,
  ...props
}) => {
  const [shouldOpen, setShouldOpen] = useState(true)
  const [shouldCollapse, setShouldCollapse] = useState(false)

  const selectedType = ['danger', 'info', 'success', 'warning'].includes(type)
    ? type
    : 'info'

  const handleClose = () => {
    if (onClose) onClose()

    setShouldOpen(false)
  }

  if (!shouldOpen) return <></>

  return (
    <StyledNotificationBar
      {...props}
      style={{maxHeight: !collapse || shouldCollapse ? 'unset' : 80}}
      data-type={selectedType}
      data-close-toggle={!!onClose}
    >
      {NOTIFICATION_ICONS[selectedType]}
      <Text>
        <Text
          className="notification-bar__content"
          data-expand={collapse && !shouldCollapse}
        >
          {props?.children}
        </Text>
        {collapse && (
          <div className="notification-bar__collapse">
            <i
              data-expand={shouldCollapse}
              onClick={() => setShouldCollapse(!shouldCollapse)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L8 8L12 12"
                  stroke="#1A94FF"
                  strokewidth="1.2"
                  strokelinecap="round"
                  strokelinejoin="round"
                />
                <path
                  d="M4 8L8 4L12 8"
                  stroke="#1A94FF"
                  strokewidth="1.2"
                  strokelinecap="round"
                  strokelinejoin="round"
                />
              </svg>
            </i>
            <Text
              color={THEME_SEMANTICS.delivering}
              style={{cursor: 'pointer'}}
              onClick={() => setShouldCollapse(!shouldCollapse)}
            >
              {shouldCollapse ? 'Rút gọn' : 'Xem thêm'}
            </Text>
          </div>
        )}
      </Text>
      {onClose && (
        <button className="notification-bar__delete-btn" onClick={handleClose}>
          {NOTIFICATION_ICONS.delete}
        </button>
      )}
    </StyledNotificationBar>
  )
}
