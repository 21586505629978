import { sendRequestAuth } from 'api/api'
import { removeAcent } from 'common/fieldText/_functions'
import { convertDateTimeToApiFormat, formatDatetimeV2 } from 'common/form/datePicker/_functions'
import config from 'config'
import { useCallback, useContext, useState } from 'react'
import { InvoiceContext } from '../provider/_context'
import { invoiceActions } from '../provider/_reducer'
import { generatePaymentPeriods } from '../utils/transform'
import { fNumber } from '../../../util/formatNumber'
import toast from '../../../Component/Toast'
import { replaceAllCustom } from '../../../util/functionUtil'
import { useNavigate } from 'react-router-dom'
import useAlert from '../../../hook/useAlert'
import { PATH } from '../../../const/path'

const useInvoiceCreate = () => {
  const { pageState, pageDispatch } = useContext(InvoiceContext)
  const nav = useNavigate()
  const { showAlert } = useAlert()
  const { formCreate } = pageState
  const { validateFormCreate } = pageState

  //principal

  const { principal } = formCreate
  const principalList = principal?.list
  const principalListOrigin = principal?.listOrigin
  const principalValue = principal?.value
  const principalKeyword = principal?.keyword

  const handlePrincipalChange = value => {
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_PRINCIPAL_UPDATE,
      payload: value
    })
    pageDispatch({ type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE, payload: { principal: '' } })
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        monthTime: '',
        monthTimeInfo: {},
      }
    })

    // update payment info

    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_PAYMENT_METHOD_EXTRA_UPDATE,
      payload: {
        bank_name: value?.bank_name,
        bank_number: value?.bank_number,
        bank_owner: value?.bank_owner,
      }
    })
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        trackingTitle: ''
      }
    })
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        paymentValue: ''
      }
    })
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_PAYMENT_METHOD_UPDATE,
      payload: {name: '', value: ''}
    })
  }

  const handlePrincipalKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const principalListData = principalListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent((!!item?.last_name ? (item.last_name + " " + item.name)  : item.name).toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_PRINCIPAL_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: principalListData,
      },
    })
  }

  //TRACKING CODE

  const { trackingCode } = formCreate

  const handleTrackingCodeChange = value => {
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        trackingCode: value
      }
    })
  }

  const { monthTime } = formCreate
  const { monthTimeInfo } = formCreate

  const handleMonthTimeChange = async (value) => {
    pageDispatch({
      type: invoiceActions.UPDATE_LOADING,
      payload: true
    })
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        monthTime: value
      }
    })
    pageDispatch({ type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE, payload: { monthTime: '' } })

    const response = await sendRequestAuth('get',
      `${config.API}/commission/month/detail-filter?recipient_id=${principalValue?.id}&month=${value}`
    )

    if (!!response?.data?.success) {

      pageDispatch({
        type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
        payload: {
          monthTimeInfo: response?.data?.data
        }
      })
    } else {

      pageDispatch({
        type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
        payload: {
          monthTimeInfo: {}
        }
      })
    }

    setTimeout(() => {
      pageDispatch({
        type: invoiceActions.UPDATE_LOADING,
        payload: false
      })
    }, 200)
    // call api get monthTimeInfo

  }

  const { monthId } = formCreate
  const { trackingTitle } = formCreate
  const handleTrackingTitleChange = value => {
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        trackingTitle: value
      }
    })
  }

  const handleTrackingTitleBlur = _ => {
    pageDispatch({
      type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
      payload: {
        trackingTitle: trackingTitle.trim() === '' ? 'Đợt thanh toán không được bỏ trống' : ''
      }
    })
  }

  const { paymentValue } = formCreate
  const handlePaymentValueChange = value => {
    const paymentRemain = monthTimeInfo?.month_remaining || 0
    const currentValue = fNumber(value.toString().replace(/[^0-9]/g, ''))
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: {
        paymentValue: +value.toString().replace(/[^0-9]/g, '') < paymentRemain ? currentValue : fNumber(paymentRemain)
      }
    })
  }

  const handlePaymentValueBlur = _ => {
    pageDispatch({
      type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
      payload: {
        paymentValue: +paymentValue.trim() === 0 ? 'Giá trị chi phải lớn hơn 0' : ''
      }
    })
  }

  // const { paymentMethod } = formCreate
  // const listPaymentMethod = paymentMethod?.list
  // const valuePaymentMethod = paymentMethod?.value
  // const extraPaymentMethod = paymentMethod?.extra
  //
  // const handlePaymentMethodChange = value => {
  //   pageDispatch({
  //     type: invoiceActions.CREATE_INVOICE_PAYMENT_METHOD_UPDATE,
  //     payload: value
  //   })
  // }

  //=======paymentDate========
  const { paymentDate } = formCreate

  const handleChangePaymentDate = data => {
    const dateTime = formatDatetimeV2(data)
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE, payload: {
        paymentDate: dateTime
      }
    })
  }

  const { paymentReference } = formCreate

  const handlePaymentReferenceChange = value => {
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: { paymentReference: value }
    })
  }

  const { paymentReason } = formCreate

  const scrollToItem = (location) => {
    const wrapper = document.querySelector('#content-wrap')
    wrapper.scrollTo({
      top: location,
      behavior: 'smooth'
    })
  }

  const validateFormBeforeSubmit = _ => {
    let success = true
    let location = 70
    if (!!!principalValue?.id) {
      pageDispatch({
        type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
        payload: { principal: 'Hiệu trưởng không được bỏ trống' }
      })
      success = false
      location = 70
    }
    if (!!!monthTime) {
      pageDispatch({
        type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
        payload: { monthTime: 'Kỳ thanh toán không được bỏ trống' }
      })
      success = false
      location = 200
    }
    if (!!!monthTimeInfo?.id) {
      pageDispatch({
        type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
        payload: { monthTimeInfo: 'Không tìm thấy thông tin chi tiết kỳ thanh toán' }
      })
      success = false
      location = 200
    }
    if (!!!trackingTitle) {
      pageDispatch({
        type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
        payload: { trackingTitle: 'Đợt thanh toán không được bỏ trống' }
      })
      success = false
      location = 500
    }
    if (!!!paymentValue || +paymentValue === 0) {
      pageDispatch({
        type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
        payload: { paymentValue: 'Giá trị chi không được bỏ trống' }
      })
      success = false
      location = 500
    }
    // if (!!!valuePaymentMethod?.id) {
    //   pageDispatch({
    //     type: invoiceActions.VALIDATE_CREATE_INVOICE_FORM_UPDATE,
    //     payload: { paymentValue: 'Phương thức thanh toán không được bỏ trống' }
    //   })
    //   success = false
    //   location = 500
    // }

    setTimeout(() => {
      scrollToItem(location)
    }, 100)
    return success
  }

  const [debounceSubmitCreate, setDebounceSubmitCreate] = useState(true)

  const handleCreateCommission = async () => {
    //validate
    if (debounceSubmitCreate && validateFormBeforeSubmit()) {
      setDebounceSubmitCreate(false)
      pageDispatch({
        type: invoiceActions.UPDATE_LOADING,
        payload: true
      })
      const data = {
        'recipient_id': principalValue?.id,
        'recipient_name': principalValue?.last_name + ' ' + principalValue?.name,
        'tracking_code': trackingCode || '',
        'tracking_title': trackingTitle,
        'month_id': monthTimeInfo?.id,
        'month_time': monthTime,
        'payment_value': paymentValue.length > 3 ? replaceAllCustom(paymentValue, ',', '') : paymentValue,
        'payment_method': 'CASH',
        // 'payment_information': valuePaymentMethod?.id === 1 ? extraPaymentMethod : '',
        'payment_reference': paymentReference,
        'payment_date': convertDateTimeToApiFormat(paymentDate),
        'payment_reason': paymentReason
      }
      const response = await sendRequestAuth('post',
        `${config.API}/commission/tracking/create`,
        data
      )
      if (response?.data?.success) {
        showAlert({
          type: 'success',
          content: 'Tạo mới phiếu chi thành công'
        })
        nav(PATH.COMMISSION_VOUCHER)
        setDebounceSubmitCreate(true)
        pageDispatch({
          type: invoiceActions.UPDATE_LOADING,
          payload: false
        })
      } else {
        setDebounceSubmitCreate(true)
        pageDispatch({
          type: invoiceActions.UPDATE_LOADING,
          payload: false
        })
        showAlert({
          type: 'danger',
          content: 'Tạo mới phiếu chi thất bại'
        })
      }

    }

    return true
  }

  const handlePaymentReasonChange = value => {
    pageDispatch({
      type: invoiceActions.CREATE_INVOICE_FORM_UPDATE,
      payload: { paymentReason: value }
    })
  }
  return {
    loading: pageState?.loading,
    principal: {
      keyword: principalKeyword,
      list: principalList,
      value: principalValue,
      onChange: handlePrincipalChange,
      onKeywordChange: handlePrincipalKeywordChange,
    },
    trackingCode: {
      value: trackingCode,
      onChange: handleTrackingCodeChange,
      // onBlur: handleValidateTrackingCodeChange
    },
    monthTime: {
      value: monthTime,
      list: generatePaymentPeriods(),
      onChange: handleMonthTimeChange,
      info: monthTimeInfo
    },
    trackingTitle: {
      value: trackingTitle,
      onChange: handleTrackingTitleChange,
      onBlur: handleTrackingTitleBlur
    },
    paymentValue: {
      value: paymentValue,
      onChange: handlePaymentValueChange,
      onBlur: handlePaymentValueBlur
    },
    // paymentMethod: {
    //   list: listPaymentMethod,
    //   value: valuePaymentMethod,
    //   extra: extraPaymentMethod,
    //   onChange: handlePaymentMethodChange,
    // },
    paymentDate: {
      value: paymentDate,
      onChange: handleChangePaymentDate
    },
    paymentReference: {
      value: paymentReference,
      onChange: handlePaymentReferenceChange
    },
    paymentReason: {
      value: paymentReason,
      onChange: handlePaymentReasonChange
    },
    validateFormCreate,
    functions: {
      onCreate: handleCreateCommission
    }
  }
}

export default useInvoiceCreate
