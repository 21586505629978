import React from 'react';
import useTeacherFilter from "../../hook/useTeacherFilter";
import {CategoryInput} from "../../../../common/form/input/_categoryInput";

const Search = () => {
  const {search} = useTeacherFilter()
  return (
    <CategoryInput
      categoryValue={{name:'Giáo viên', value:''}}
      categoryWidth={95}
      className="teacher-filter-form__input-wide"
      placeholder={"Tìm kiếm theo tên đăng nhập / Tên giáo viên / Email / Số điện thoại"}
      value={search.value}
      onChange={e => search.onChange(e)}
    />
  )
}

export default Search