import React, {useEffect} from 'react'
import { PageHeader } from '../../../layouts/pageHeader'
import { GridLayout } from '../../../layouts/gridLayout'
import { CREATE_USER_CONSTANTS } from '../interfaces/~contants'
// import { Tooltip } from '../../../common/tooltip'
// import { USER_ICON } from '../icon/icon'
import UserInfor from './section/userInfor'
import UserAccount from './section/userAccount'
import useUserManagement from '../hooks/useUserManagement'
import { UserManagementProvider } from '../provider'
import { ActionFormBtnList } from './section/actionFormBtnList'
import { StyledCreateUseManagement } from '../components/~styled'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'

export const CreateUser = () => {
    const { fetch, provider } = useUserManagement()
    const { state, dispatch } = provider
    const { userId } = useParams()
    useEffect(() => {
        if(!!userId) fetch.origin(userId)
    },[userId])

    return (
        <UserManagementProvider value={{ pageState: state, pageDispatch: dispatch }}>
            <PageUserSingle />
        </UserManagementProvider>
    )
}

export const PageUserSingle = ({ ...props }) => {
    const { t } = useTranslation()
    return (
        <StyledCreateUseManagement>
            <GridLayout
                {...props}
                header={
                    <PageHeader
                        breadcrumbLinks={CREATE_USER_CONSTANTS.header.breadcrumb}
                        breadcrumbTitle={t(DISPLAY_NAME_MENU.GENERAL.USER_MANAGEMENT)}
                    />
                }
                grid={[
                    {
                        width: 100,
                        sections: [
                            {
                                title: (
                                    <>
                                        <span>{t(DISPLAY_NAME_MENU.GENERAL.USER_PROFILE)}</span>
                                    </>
                                ),
                                props: {
                                    style: { position: 'relative' },
                                    children: <UserInfor />,
                                },
                            },
                            {
                                title: t(DISPLAY_NAME_MENU.USER_PAGE.INFO),
                                props: {
                                    children: <UserAccount />,
                                },
                            },
                            {
                                type: 'sticky-bottom-transparent',
                                props: {
                                    style: {
                                        position: 'sticky',
                                        bottom: -44,
                                        marginBottom: 0,
                                        zIndex: 999,
                                        padding: '24px'
                                    },
                                    children: <ActionFormBtnList />,
                                },
                            },
                        ],
                        props: { style: { position: 'relative' } },
                    },
                ]}
                data-model="container"
            />
        </StyledCreateUseManagement>
    )
}