import {Button} from 'common/button'
import useReceiptFilterForm from 'Pages/receipt/hooks/useReceiptFilterForm'
import {memo, useContext, useEffect} from 'react'
import {ReceiptTags} from '../receiptTags'
import {ReceiptDateTime} from './_dateTime'
import {StyledReceiptFilterForm} from './_styled'
import {ReceiptInfo} from './_info'
import {GLOBAL_ICONS} from '../../../../interface/icon'
import {ReceiptStatus} from './_status'
import {PaymentType} from './_type'
import {PaymentSchedule} from './_paymentSchedule'
import useGlobalContext from "../../../../containerContext/storeContext";

export const ReceiptFilterForm = memo(({...props}) => {
    const {canSubmitOtherFilter, functions} = useReceiptFilterForm()
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type

    return (
        <StyledReceiptFilterForm {...props}>
            <div
                className="receipt-filter-form__group receipt-filter-form__collapse"
                data-collapse={true}
            >
                <ReceiptInfo/>
                <ReceiptDateTime/>
                <PaymentSchedule/>
                <ReceiptStatus/>
                {+userType !== 3 && <PaymentType/>}
                <div className="receipt-filter-form__input-wide flex">
                    <Button
                        appearance="secondary"
                        disabled={!canSubmitOtherFilter}
                        size="md-"
                        onClick={() =>
                            canSubmitOtherFilter && functions.applyReceiptOtherFilter()
                        }
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        appearance="secondary"
                        size="md-"
                        onClick={functions.refresh}
                        className={'receipt-filter-form__btn-refresh'}
                    >
                        {GLOBAL_ICONS.repeat}
                    </Button>
                </div>
            </div>
            <div className="receipt-filter-form__group" style={{marginBottom: 4}}>
                <ReceiptTags/>
            </div>
        </StyledReceiptFilterForm>
    )
})
