import { Text } from '../../../../../common/text'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Dashboard } from '../../provider/_context'
import { useDashboardAction } from '../../provider/_reducer'
import Skeleton from '@mui/material/Skeleton'

export const ReportTitle = () => {

  const { pageState, pageDispatch } = useContext(Dashboard)
  const { dateTime } = pageState
  const { loading } = pageState

  const onChangeDatetime = data => {
    pageDispatch({
      type: useDashboardAction.IS_LOADING,
      payload: true
    })
    pageDispatch({
      type: useDashboardAction.UPDATE_DATETIME,
      payload: {
        value: data
      }
    })
  }
  return (
    <StyleTittle>
      <Text as={'p'} fontWeight={600} fontSize={22}>Báo cáo</Text>
      <div className={'datetime-content'}>
        {!!!loading
          ?
          <Text fontSize={16}>{pageState.dateTime.time}</Text>
          :
          <Skeleton variant="text" width={200} height={34}/>
        }
        <div className={'datetime-content__right'}>
          {
            dateTime.list.map(item => (
              <div className={'datetime-content__right-items'}
                   onClick={() => onChangeDatetime(item)}
                   data-active={item?.value === dateTime?.value?.value}
                   key={item?.value}>{item?.name}</div>
            ))
          }
        </div>
      </div>
    </StyleTittle>
  )
}

const StyleTittle = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 16px;
.datetime-content{  
  display: flex;
  align-items: center;
  &__right{
    margin-left: 16px;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ebeef5;
    padding: 8px 4px;
    border-radius: 4px;
    &-items{
      padding: 0 16px;
      cursor: pointer;
      border-right: 2px solid #ebeef5;
      &:last-child{
        border: none;
      }
      &[data-active=true]{
        color: #3264FF;
      }
    }
  }
}
`
