export const useDashboardAction = {
  IS_LOADING: 'IS_LOADING',
  IS_LOADING_REVENUE: 'IS_LOADING_REVENUE',
  IS_LOADING_COMMISSION: 'IS_LOADING_COMMISSION',
  IS_LOADING_TEACHER: 'IS_LOADING_TEACHER',
  IS_LOADING_USER: 'IS_LOADING_USER',
  IS_LOADING_PANEL: 'IS_LOADING_PANEL',
  UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
  UPDATE_DATETIME: 'UPDATE_DATETIME',
}

export const useDashboardReducer = (state, action) => {
  switch (action.type) {
    case useDashboardAction.IS_LOADING_PANEL:
      return {
        ...state,
        loading: action.payload,
      }
    case useDashboardAction.IS_LOADING:
      return {
        ...state,
        loadingRevenue: action.payload,
        loadingCommission: action.payload,
        loadingTeacher: action.payload,
        loadingUser: action.payload
      }
    case useDashboardAction.IS_LOADING_REVENUE:
      return {
        ...state,
        loadingRevenue: action.payload
      }
    case useDashboardAction.IS_LOADING_COMMISSION:
      return {
        ...state,
        loadingCommission: action.payload
      }
    case useDashboardAction.IS_LOADING_TEACHER:
      return {
        ...state,
        loadingTeacher: action.payload
      }
    case useDashboardAction.IS_LOADING_USER:
      return {
        ...state,
        loadingUser: action.payload
      }
    case useDashboardAction.UPDATE_DASHBOARD:
      return {
        ...state,
        ...action.payload
      }
    case useDashboardAction.UPDATE_DATETIME:
      return {
        ...state,
        dateTime: {
          ...state.dateTime,
          ...action.payload
        }
      }

    default:
      return { ...state }
  }
}