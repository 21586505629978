import { sendRequestAuth } from 'api/api'
import config from 'config'
import useAlert from 'hook/useAlert'
import { useContext, useState } from 'react'
import { StudentContext } from '../provider/_context'
import {studentActions, useProductAction} from '../provider/_reducer'

const useStudentRow = data => {
  const { showAlert } = useAlert()

  const { pageState, pageDispatch } = useContext(StudentContext)
  const { table } = pageState

  const [shouldOpenSubmitPaymentModal, setShouldOpenSubmitPaymentModal] =
    useState(false)

  const detailActive = table.detail.active
  const detailActiveId = table.detail.id
  const detailList = table.detail.list

  const selectedList = table.selected.list

  const isSelected = !!selectedList.find(item => item?.id === data?.zalo_id)

  const codeStudenthaveInventory = data?.has_inventory === '1'

  const shouldOpenDetail = data?.zalo_id && detailActiveId === data.zalo_id

  // ==================== ROW ========================================
  const copyStudentCode = () => {
    navigator.clipboard.writeText(data?.billcode)
    showAlert({ content: 'Đã sao chép mã vận khách', type: 'success' })
  }

  const fetchRowDetail = async () => {
    const response = await sendRequestAuth(
      'get',
      `${config.API}/teacher/customer/detail/${data.zalo_id}`,
    )

    if (!!response?.data?.success) {
      const newItem = response?.data?.data
      pageDispatch({
        type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: newItem },
      })
      return true
    }
    return false
  }

  const exportStudentExcel = async () => {
    const response = await sendRequestAuth(
      'post',
      `${config.API}/teacher/customer/export-detail/${data.zalo_id}`,
    )
    if (response?.data?.success && response?.data?.data?.url) {
      showAlert({ content: 'Xuất excel thành công', type: 'success' })
      return response.data.data.url
    } else {
      showAlert({ content: 'Xuất excel thất bại', type: 'danger' })
      return '#'
    }
  }

  const rowCheckboxChange = () =>
    pageDispatch({
      type: studentActions.TABLE_SELECTED_LIST_UPDATE,
      payload: {
        selected: {
          list: isSelected
            ? selectedList.filter(item => item?.id !== data?.zalo_id)
            : [...selectedList, data],
        },
      },
    })

  const rowDetailToggle = async () => {
    const res = await fetchRowDetail()

    if (!res) {
      showAlert({
        type: 'danger',
        content: 'Bạn chỉ xem được thông tin khách hàng đang liên kết với Giáo viên này'
      })
      return
    }
    if (!data?.zalo_id) return
    if (data.zalo_id === detailActiveId) {
      pageDispatch({
        type: studentActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
        payload: { id: null },
      })
      pageDispatch({
        type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })
      return
    }

    pageDispatch({
      type: studentActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: data.zalo_id },
    })

    const findDetail = detailList.find(item => item?.id === data.zalo_id)
    if (findDetail) {
      pageDispatch({
        type: studentActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: findDetail },
      })
    }
    pageDispatch({
      type: useProductAction.TABLE_LOADING_DISPLAY,
      payload: true,
    })

    const responseProduct = await Promise.all([
      sendRequestAuth('get',
         `${config.API}/teacher/customer/detail/${data?.zalo_id}/purchased-products?product_name=&category_id=&start=0&per_page=999`
      ),
      sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`),
    ])

    if (responseProduct[0]?.data?.success) {
      const qrProduct = responseProduct[0]?.data
      pageDispatch({
        type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: qrProduct?.data,
          listDefault: qrProduct?.data,
          loading: false
        }
      })
    }
    if (responseProduct[1]?.data?.length > 0) {
      pageDispatch({
        type: useProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
        payload: {
          list: responseProduct[1]?.data,
          listOrigin: responseProduct[1]?.data
        }
      })
    }
  }

  // ==================== CELL ========================================
  // CODE STUDENT
  const cellCodeStudentFormatDateTime = dateTimeParam => {
    const dateTimeSplit = dateTimeParam ? dateTimeParam.split(' ') : []
    const ymd = dateTimeSplit[0] ? dateTimeSplit[0].split('-') : []
    const dmy = `${ymd[2] || '--'}/${ymd[1] || '--'}/${ymd[0] || '--'}`
    const hms = dateTimeSplit[1] ? dateTimeSplit[1].split(':') : []
    const hm = `${hms[0]}:${hms[1]}`
    return `${dmy} ${hm}`.trim()
  }

  // PAYMENT
  const cellPaymentGetStatus = () => {
    const amount = Number(data?.total_amount) || 0
    const payment = Number(data?.total_payment) || 0
    if (amount <= payment) return 'success'
    if (payment > 0 && amount > payment) return 'warning'
    return 'danger'
  }

  return {
    cell: {
      codeStudent: {
        dateTime: cellCodeStudentFormatDateTime(data?.date),
        haveInventory: codeStudenthaveInventory,
      },
      payment: { status: cellPaymentGetStatus() },
    },
    detail: {
      id: detailActiveId,
      active: detailActive,
      tabs: { payment: { formatDateTime: cellCodeStudentFormatDateTime } },
    },
    row: {
      data,
      isSelected,
      shouldOpenDetail,
      shouldOpenSubmitPaymentModal,
      onCheckboxChange: rowCheckboxChange,
      onCloseSubmitPaymentModal: () => setShouldOpenSubmitPaymentModal(false),
      onCopyStudentCode: copyStudentCode,
      onExportStudentExcel: exportStudentExcel,
      onFetchDetail: fetchRowDetail,
      onOpenSubmitPaymentModal: () => setShouldOpenSubmitPaymentModal(true),
      onToggleDetail: rowDetailToggle,
    },
  }
}

export default useStudentRow
