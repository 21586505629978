import useProfileUpdate from '../hooks/useProfileUpdate'
import { Grid } from '@mui/material'
import { Input } from '../../../common/form/input'
import styled from 'styled-components'
import React from 'react'

const InfoBank = () => {
    const { value } = useProfileUpdate()
    const data = value?.userData;

    return (
        <StyledInfoBank>
            <Grid container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={'Số tài khoản'}
                            disabled={true}
                            value={data?.bank_number}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={'Chủ tài khoản'}
                            disabled={true}
                            value={data?.bank_owner}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={'Ngân hàng'}
                            disabled={true}
                            value={data?.bank_name}
                        />
                    </div>
                </Grid>
            </Grid>
        </StyledInfoBank>
    )
}

export default InfoBank

export const StyledInfoBank = styled.div``