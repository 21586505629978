import React from "react";
import {Input} from "../../../../../../Component/Input";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const AccountNumber = ()=>{
    const {accountNumber} = useCreateTeacherForm()
    const {methods} = accountNumber
    return (
        <Input
            label={
                <>
                    Số tài khoản
                </>
            }
            value={accountNumber?.value}
            onChange={e => methods.onChange(e.target?.value)}
            validateText={accountNumber?.message}
            validateType={accountNumber?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập số tài khoản ngân hàng'}
            maxLength={32}
        />
    )
}