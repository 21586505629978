import React from "react";
import {Text} from "common/text";
import {THEME_SEMANTICS} from "common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";
import {Option} from "common/form/autoComplete/_option";
import {useTranslation} from "react-i18next";
import {STATUS_TEACHER} from "../../interfaces/constant";
import {AlternativeAutoComplete} from "../../../../../../common/form/autoComplete/_alternativeAutoComplete";

export const StatusEditTeacher = ()=>{
    const {t} = useTranslation()
    const {statusPerson} = useCreateTeacherForm()
    const {methods} = statusPerson
    return (
        <AlternativeAutoComplete
            // main input
            inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryHidden: true,
                readOnly: true,
                categoryValue: { name: t('Vui lòng chọn trạng thái'), value: '' },
                placeholder: t('Vui lòng chọn trạng thái'),
                value: statusPerson?.value?.title,
            }}
            hideSearchBar={true}
            // search input in dropdown menu
            menuProps={{
                empty: '',
            }}
            // search input in dropdown menu
            validateText={statusPerson?.message}
            validateType={statusPerson?.status ? 'danger' : 'success'}
        >
            {
                STATUS_TEACHER?.map((item, index) => (
                    <Option
                        key={index}
                        className="teacher__option-text"
                        data-active={statusPerson?.value?.value === item.value}
                        onClick={() => methods.onChange(item)}
                        style={{marginTop: 16, cursor:'pointer'}}
                    >
                        {item.title}
                    </Option>
                ))}
        </AlternativeAutoComplete>
    )
}