export const AUTO_COMPLETE_ICONS = {
  chevronLeft: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 8L10.0002 12L6.00024 8"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  search: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.505 18.495C17.7784 18.7683 18.2216 18.7683 18.495 18.495C18.7683 18.2216 18.7683 17.7784 18.495 17.505L17.505 18.495ZM14.6283 13.6384C14.3549 13.365 13.9117 13.365 13.6384 13.6384C13.365 13.9117 13.365 14.3549 13.6384 14.6283L14.6283 13.6384ZM18.495 17.505L14.6283 13.6384L13.6384 14.6283L17.505 18.495L18.495 17.505ZM15.5222 9.11111C15.5222 12.6519 12.6519 15.5222 9.11111 15.5222V16.9222C13.4251 16.9222 16.9222 13.4251 16.9222 9.11111H15.5222ZM9.11111 15.5222C5.57035 15.5222 2.7 12.6519 2.7 9.11111H1.3C1.3 13.4251 4.79715 16.9222 9.11111 16.9222V15.5222ZM2.7 9.11111C2.7 5.57035 5.57035 2.7 9.11111 2.7V1.3C4.79715 1.3 1.3 4.79715 1.3 9.11111H2.7ZM9.11111 2.7C12.6519 2.7 15.5222 5.57035 15.5222 9.11111H16.9222C16.9222 4.79715 13.4251 1.3 9.11111 1.3V2.7Z"
        fill="#3264FF"
      />
    </svg>
  ),
  x: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1673 5.8335L5.83398 14.1668M5.83398 5.8335L14.1673 14.1668"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
