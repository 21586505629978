import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import useStudentFilterForm from 'Pages/student/hooks/useStudentFilterForm'
import {STUDENT_STATUS} from 'Pages/student/interfaces/_constants'

export const StudentPrincipal = () => {
    const { principal } = useStudentFilterForm()

    return (
        <AlternativeAutoComplete
            className="student-filter-form__input-wide"
            // main input
            inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryValue: { name: 'Trạng thái', value: '' }, // if not exist this value -> default category: categoryList[0]
                categoryWidth: 90,
                placeholder: 'Chọn trạng thái',
                readOnly: true,
                value: principal.value?.name || '',
                // onIconClick: () => principal.onChange(null),
            }}
            // menu
            menuProps={{
                empty: STUDENT_STATUS.length <= 0  ? 'Không tìm thấy trạng thái' : '',
            }}
            hideSearchBar = {true}
            // search input in dropdown menu
            // searchInputProps={{
            //     placeholder: 'Tìm kiếm trạng thái',
            //     value: principal.keyword,
            //     onChange: principal.onKeywordChange,
            // }}
        >
            {STUDENT_STATUS.length > 0 &&
                STUDENT_STATUS.map(item => (
                    <Option
                        key={item.value}
                        className="student-filter-form__option-text"
                        data-active={item.value === principal.value?.value}
                        onClick={() => principal.onChange(item)}
                    >
                        {item.name}
                    </Option>
                ))}
        </AlternativeAutoComplete>
    )
}
