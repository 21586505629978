import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useCustomerFilterForm from 'Pages/customer/hooks/useCustomerFilterForm'
import { CUSTOMER_FILTER_TAG_FIELDS } from 'Pages/customer/interfaces/_constants'
import { StyledCustomerTags } from './_styled'
import { CustomerTag } from './_tag'

export const CustomerTags = ({ ...props }) => {
  const {
    dateTime,
    customerInfo,
    status,
    customerType,
    teacher,
    functions,
  } = useCustomerFilterForm()

  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledCustomerTags {...props}>
      {customerInfo.activeValue && (
        <CustomerTag
          onDelete={() => functions.filterTagDelete(CUSTOMER_FILTER_TAG_FIELDS[0])}
        >
          Tên khách hàng/Số điện thoại: {customerInfo.activeValue}
        </CustomerTag>
      )}

      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <CustomerTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </CustomerTag>
      )}
      {status?.activeValue?.name && (
        <CustomerTag
          onDelete={() => functions.filterTagDelete(CUSTOMER_FILTER_TAG_FIELDS[2])}
        >
          Trạng thái: {status.activeValue.name}
        </CustomerTag>
      )}
      {customerType?.activeValue?.name && (
        <CustomerTag
          onDelete={() => functions.filterTagDelete(CUSTOMER_FILTER_TAG_FIELDS[3])}
        >
          Loại khách hàng: {customerType.activeValue.name}
        </CustomerTag>
      )}
      {teacher?.activeValue?.name && (
        <CustomerTag
          onDelete={() => functions.filterTagDelete(CUSTOMER_FILTER_TAG_FIELDS[5])}
        >
          Giáo viên: {teacher.activeValue.name}
        </CustomerTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledCustomerTags>
  )
}
