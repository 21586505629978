import React, { useState } from 'react'
import Search from './_search'
import styled from 'styled-components'
import TeacherStatus from './_status'
import { useTranslation } from 'react-i18next'
import {Button} from "../../../../common/button";
import {THEME_COLORS} from  "../../../../common/theme/_colors"
import useTeacherFilter from "../../hook/useTeacherFilter";
import {OrderTag} from "./_tag";
import {TAG_DELETE} from "../../interface";
import {Text} from "../../../../common/text";
const FilterForm = ({ ...props }) => {
  const { t } = useTranslation()
  const { methods, canSubmitOtherFilter, badge, statusFilter, search } = useTeacherFilter()
  return (
    <StyledBookManagementFilterForm {...props}>
      <div className="teacher-filter-form__group">
        <Search/>
        <TeacherStatus/>
        <Button
          appearance="secondary"
          disabled={!canSubmitOtherFilter}
          size="md-"
          style={{ width: 101, marginLeft: '16px' }}
          onClick={() =>
            canSubmitOtherFilter && methods.applyOtherFilter()
          }
        >
          Tìm kiếm
        </Button>
      </div>
      {
        +badge?.others > 0 && <div>
          {
            search?.active && <OrderTag onDelete={()=>methods.filterTagDelete(TAG_DELETE[0])}>
                Giáo viên:{' '}
              {search?.active || '---'}
            </OrderTag>
          }
          {
            statusFilter?.activeValue && <OrderTag onDelete={()=>methods.filterTagDelete(TAG_DELETE[1])}>
              Trạng thái liên kết:{' '}
              {statusFilter?.activeValue?.name || '---'}
            </OrderTag>
          }
          <Text
            onClick={methods?.filterTagDeleteAll}
            style={{cursor:'pointer'}}
            color={THEME_COLORS?.primary_300}
          >Đặt lại mặc định</Text>
        </div>
      }
    </StyledBookManagementFilterForm>
  )
}

export default FilterForm

export const StyledBookManagementFilterForm = styled.div`

margin-bottom: -16px;
.teacher-filter-form {
    &__group {
      width: calc(100% + 180px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__input-wide {
      &:nth-child(1){
        width: calc(45% - 12px);
      }
      &:nth-child(2){
        width: calc(30% - 12px);
      }
      &:nth-child(3){
        width: calc(25% - 12px);
      }
      margin: 0 6px;
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .store-upos-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
`
