import useReceiptFilterForm from 'Pages/receipt/hooks/useReceiptFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const ReceiptInfo = () => {
  const { receiptInfo } = useReceiptFilterForm()
  return (
    <CategoryInput
      className={'receipt-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Mã phiếu thu', value: '' }}
      categoryWidth={102}
      placeholder='Nhập mã phiếu thu'
      value={receiptInfo?.value || ''}
      onChange={receiptInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}
