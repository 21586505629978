import React from 'react';
import {USER_ROLE_BREADCRUMB, USER_ROLE_HEADER_ACTIONS} from "../../interfaces/~contants";
import {PageHeader} from "../../../../layouts/pageHeader";
import {useNavigate} from "react-router-dom";
import useTableBody from "../../hooks/useTableBody";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Index = () => {
  // const { functions } = useTableBody()
    const {t} = useTranslation()
  // const navigate = useNavigate()
  // const actions = [() => functions.handleRefresh(), () => navigate('/user/groups/create')]
  return (
    <StyledUseRoleHeader>
      <div className={"user-role-header"}>
        <PageHeader
          // actions={USER_ROLE_HEADER_ACTIONS.map((item, i) => ({
          //   ...item,
          //   onClick: actions[i],
          // }))}
          breadcrumbLinks={USER_ROLE_BREADCRUMB}
          breadcrumbTitle={t('user_management_role_title')}
        />
      </div>
    </StyledUseRoleHeader>
  );
};

const StyledUseRoleHeader = styled.div`
  .user-role-header {
    .page-header__actions {
      .page-header__action-item:nth-child(2) button {
        width: fit-content;
      } 
    }
  }
`
export default Index;