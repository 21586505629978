import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import styled from 'styled-components'
import { GLOBAL_ICONS } from '../../../../../interface/icon'

export const CommissionTag = ({onDelete, ...props}) => {
  return (
    <StyledCommissionTag {...props}>
      {props?.children && <Text>{props.children}</Text>}
      <div className="commission-tag__delete" onClick={onDelete}>
        {GLOBAL_ICONS.x}
      </div>
    </StyledCommissionTag>
  )
}

const StyledCommissionTag = styled.li`
  position: relative;

  margin: 0 12px 12px 0;
  padding: 4px 28px 4px 12px;

  display: inline-block;

  
  background: #d1dffd;
  bcommission-radius: 6px;

  .commission-tag {
    &__delete {
      position: absolute;
      top: 6px;
      right: 8px;

      width: 16px;
      height: 16px;

      bcommission-radius: 6px;

      transition: all 0.25s;

      cursor: pointer;

      &:hover {
        background: ${THEME_SEMANTICS.failed};

        svg {
          color: #fff;

          path[stroke] {
            stroke: #fff;
          }

          path[fill] {
            fill: #fff;
          }
        }
      }
    }
  }
`
