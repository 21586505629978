import React from 'react'
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import { useTranslation } from 'react-i18next'
import { useCreateProductTable } from '../../../hook/useCreateProductTable'

export const STORE_UPOS_FILTER_FORM_STATUS = [
   {id: 1, name: 'Hết hàng', value: 'sold_out'},
   {id: 2, name: 'Đang mở bán', value: 'enable'},
   {id: 3, name: 'Không kinh doanh', value: 'disable'},
]

export const ProductStatus = () => {
  const { t } = useTranslation()
  const { statusFilter } = useCreateProductTable()
  return (
    <AlternativeAutoComplete
      className="store-upos-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: t('general_status'), value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 90,
        placeholder: t('general_select_status'),
        readOnly: true,
        value: t(statusFilter?.value?.name) || '',
        onIconClick: () => statusFilter.onChange(null),
      }}
      // menu
      menuProps={{
        // empty: source.list.length <= 0 ? 'Không tìm thấy trạng thái' : '',
      }}
      hideSearchBar={true}
    >
      {STORE_UPOS_FILTER_FORM_STATUS?.map(item => (
        <Option
          key={item.id}
          className="store-upos-filter-form__option-text"
          data-active={item.id === statusFilter?.value?.id}
          onClick={() => statusFilter.onChange(item)}
        >
          {t(item.name)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

