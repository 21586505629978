import React from "react";
import styled from 'styled-components'
import {Grid} from "@mui/material";
import {Phone} from "./_phone";
import {Email} from "./_email";
import {NamePerson} from "./_name";
import {LastName} from "./_lastName";
import {Identity} from "./_identity";
import {Address} from "./_address";
import {BirthDay} from "./_birthday";
import {Gender} from "./_gender";
import {useParams} from "react-router-dom";
import {Text} from "../../../../../../common/text";
import {useCreateTeacherPassword} from "../../hook/useCreateTeacherPassword";
import {ConfirmModalV2} from "../../../../../../Component/ConfirmModal";
import {Input} from "../../../../../../Component/Input";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {TeachingSubject} from "./_subject";

export const InfoPerson = () => {
    const {teacherId} = useParams()
    const {deleteConfirm} = useCreateTeacherPassword()
    return (
        <StyleInfoPerson>
            <Grid container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <LastName/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <NamePerson/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Email/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Phone/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Identity/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <BirthDay/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <TeachingSubject/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <Gender/>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} item>
                    <Address/>
                </Grid>
            </Grid>

            {
                !!teacherId && <div
                    style={{marginTop: 20, width: 'fit-content', cursor: 'pointer'}}
                    onClick={() => deleteConfirm.methods?.onOpen(true)}
                >
                    <Text color={'#2150B7'}>Đặt lại mật khẩu</Text>
                </div>
            }
            {
                !!deleteConfirm?.open && <ConfirmModalV2
                    open={deleteConfirm?.open}
                    width={480}
                    body={
                        <BodyConfirm/>
                    }
                    footerButton={{
                        cancel: {
                            title: 'Hủy',
                            action: () => deleteConfirm.methods?.onOpen(false)
                        },
                        confirm: {
                            title: 'Lưu',
                            action: () => deleteConfirm.methods?.onSave(),
                            disabled: deleteConfirm?.status
                        }
                    }}
                />
            }
        </StyleInfoPerson>
    )
}
const BodyConfirm = ({fullName, userName}) => {
    const {deleteConfirm} = useCreateTeacherPassword()
    const {methods} = deleteConfirm
    return (
        <div className='create-teacher_confirm-body'>
            <Text fontSize={20} fontWeight={600}>Đặt mật khẩu : {fullName}</Text>
            <div style={{margin: '24px 0'}}>
                <Text>Mật khẩu người dùng {userName} sẽ được thay đổi ngay sau khi bạn đặt mật khẩu.</Text>
            </div>
            <Input
                label={
                    <>
                        Mật khẩu <Text color={THEME_SEMANTICS.failed}>*</Text>
                    </>
                }
                value={deleteConfirm?.value}
                onChange={e => methods.onChange(e.target?.value)}
                onBlur={e => methods.onBlur(e.target?.value)}
                validateText={deleteConfirm?.message}
                validateType={deleteConfirm?.status ? 'danger' : ''}
                placeholder={'Vui lòng nhập mật khẩu'}
                maxLength={32}
            />

        </div>
    )
}
const StyleInfoPerson = styled.div`
    .create-teacher{
      &_confirm-body{
        
      }
    }
    .auto-complete__option-container{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

`