import React, {useContext, useState} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Text} from 'common/text'
import ReactImageFallback from "react-image-fallback";
import {StudentContext} from "../../../provider/_context";
import {fDateTimeSuffix} from "../../../../../util/formatTime";
import {GLOBAL_ICONS} from "../../../../../interface/icon";
import {Tooltip} from "../../../../../common/tooltip";
import {useProductAction} from "../../../provider/_reducer";

const TbodyProduct = () => {
   const {t} = useTranslation()
   const {pageState} = useContext(StudentContext)
   const {productTab} = pageState.table
   const {table} = productTab
   const displayList = table?.display?.list
   const displayListDefault = table?.display?.listDefault
   const displayLoading = table.display.loading
   const paginationAmount = table.pagination.amount
   const paginationTotalItems = displayList.length
   return (
      <>
         {displayLoading ? (
            Array.from(Array(paginationAmount), (e, i) => (
               <ProductPlaceholder key={i}/>
            ))
         ) : paginationTotalItems > 0 ? (
            displayList.map((item, key) => <ProductTr t={t} key={item.id} stt={key} data={item}/>)
         ) : (
            <ProductEmpty t={t} list={displayListDefault}/>
         )}
      </>
   )
}

export default TbodyProduct

const ProductPlaceholder = ({...props}) => {
   return (
      <div {...props} className="row-tab-detail__tr" style={{ background: '#fff' }}>
        {Array.from(Array(4), (e, i) => (
           <div key={i} className="row-tab-detail__td" data-type="td">
             <Skeleton
                sx={{
                  width: '100%',
                  height: 33,
                }}
             />
           </div>
        ))}
      </div>
   )
}

const ProductEmpty = ({list, t, ...props}) => {
   return (
      <StyledProductEmpty {...props}>
         <img
            className="create-teacher-table-empty__banner"
            src="/img/order/order-empty.png"
            alt="empty"
         />
         <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
            {!!!list ? t('Bạn chưa có danh sách giáo viên nào') : t('no_matching_data')}
         </Text>
      </StyledProductEmpty>
   )
}

const ProductTr = ({data, t, stt, ...props}) => {


   const {pageDispatch} = useContext(StudentContext)

   const showOrdersModal = () => {
      pageDispatch({type: useProductAction.UPDATE_MODAL_ORDER, payload: data.product_id})
   }

   return (
      <div key={data.id} className="row-tab-detail__tr">
         <div
            className="row-tab-detail__td"
         >
            <div className="create-teacher-table__cell-image">
               <ReactImageFallback
                  src={data?.product_image}
                  fallbackImage={'/img/grid-default.png'}
                  alt="img bottm img"
               />
            </div>
            <div>
               <Text as={'p'} fontWeight={600}>{data?.product_sku || '---'}</Text>
               <Text as={'p'} color={"#2150B7"}>{data?.product_name || '---'}</Text>
            </div>
         </div>
         <div className="row-tab-detail__td">
            <Text as={'p'}>
               {+data?.grand_total === 0  ? 'SP trải nghiệm' : 'SP mua'}
            </Text>
            {+data?.grand_total === 0 && !!data?.activate_at && <Text as={'p'}>
               <Text color={'#7C88A6'}>{!!data?.activate_at ? fDateTimeSuffix(data?.activate_at) : '---'}</Text>
            </Text>}
         </div>
         <div className="row-tab-detail__td">
            <Text>
               {data?.total_purchase || '---'}
            </Text>
         </div>
         <div className="row-tab-detail__td">
            <Text color={'#7C88A6'}>{!!data?.created_at ? fDateTimeSuffix(data?.created_at) : '---'}</Text>
         </div>
         <div className="row-tab-detail__td" onClick={showOrdersModal}>
            <Tooltip title={"Xem các đơn hàng đã mua sản phẩm này"}>
               {GLOBAL_ICONS.eyes}
            </Tooltip>
         </div>
      </div>
   )
}

export const StyledProductEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
