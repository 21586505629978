import styled from 'styled-components';

export const StyleConfirmModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .confirm-modal{
    &_main{
      height: auto;
      padding: 24px;
      border-radius: 8px;
      background: #ffffff;
    }
    &_footer{
        margin-top: 32px;
        text-align: right;
    }
    &_button{
        width: 110px;
        height: 32px;
        padding: 0;
        margin-left: 8px;
    }
  }
`