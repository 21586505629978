import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import styled from 'styled-components'
import {formatMoney} from 'util/functionUtil'
import {checkPermission} from 'Component/NavBar/until'
import {THEME_COLORS} from 'common/theme/_colors'
import {fDateTimeCustom} from 'util/formatTime'
import React, {useContext, useEffect, useState} from 'react'
import {sendRequestAuth} from '../../../../../api/api'
import config from '../../../../../config'
import {Skeleton} from '@mui/material'
import useGlobalContext from "../../../../../containerContext/storeContext";

export const RowTabOrderHistory = ({data, rowData, ...props}) => {
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type
    const {detail} = rowData
    const validPaymentPermission = checkPermission('receipt', 'payment')
    const [historyDetail, setHistoryDetail] = useState([])
    const [historyDetailLoading, setHistoryDetailLoading] = useState(true)

    useEffect(() => {
        const getOrderHistory = async () => {
            const response = await sendRequestAuth('get',
                `${config.API}/receipt/month-detail/${data?.month_id}`
            )
            return response
        }
        const res = getOrderHistory()
        res.then(value => {
            if (!!value.data.success) {
                setHistoryDetail(value.data?.data)
            }
            setHistoryDetailLoading(false)
        })
    }, [])

    return (
        <StyledRowTabPayment {...props}>

            <div className="row-tab-detail__content">
                <div className="row-tab-detail__content-group">
                    <Text as="h4" fontSize={16} lineHeight={22}>
                        Thông tin chung
                    </Text>
                    <div className={'row-tab-detail__content-group--items'}>
                        <Text as="p" color="#7C88A6">
                            Kỳ thanh toán
                        </Text>
                        <Text>
                            {detail?.active?.month_time || '---'}
                        </Text>
                    </div>
                </div>
                {
                    +userType === 3
                        ?
                        <div className="row-tab-detail__content-group" style={{width: '20%'}}>
                            <Text as="h4" fontSize={16} lineHeight={22}>
                                Thông tin thanh toán
                            </Text>
                            <div className={'row-tab-detail__content-group--items'}>
                                <Text as="p" color="#7C88A6">
                                    Tổng phải thu trong kỳ
                                </Text>
                                <Text>
                                    {formatMoney(historyDetail?.month_value)}
                                </Text>
                            </div>
                        </div>
                        :
                        <>
                            <div className="row-tab-detail__content-group" style={{width: '20%'}}>
                                <Text as="h4" fontSize={16} lineHeight={22}>
                                    Thông tin thanh toán
                                </Text>
                                <div className={'row-tab-detail__content-group--items'}>
                                    <Text as="p" color="#7C88A6">
                                        Tổng phải thu trong kỳ
                                    </Text>
                                    <Text>
                                        {formatMoney(historyDetail?.month_value)}
                                    </Text>
                                </div>
                                <div className={'row-tab-detail__content-group--items'}>
                                    <Text as="p" color="#7C88A6">
                                        Tổng phải thu của hiệu trưởng
                                    </Text>
                                    <Text>
                                        {formatMoney(historyDetail?.principal_commission)}
                                    </Text>
                                </div>
                            </div>
                            <div className="row-tab-detail__content-group">
                                <Text as="h4" fontSize={16} lineHeight={22}>
                                    &nbsp;
                                </Text>
                                <div className={'row-tab-detail__content-group--items'}>
                                    <Text as="p" color="#7C88A6">
                                        Tổng phải thu cho giáo viên
                                    </Text>
                                    <Text>
                                        {formatMoney(historyDetail?.teacher_commission)}
                                    </Text>
                                </div>
                            </div>
                        </>
                }
            </div>
            <div className="row-tab-detail__contents" style={{minHeight: 180}}>
                <div className="row-tab-detail__table">
                    <div className="row-tab-detail__thead">
                        <div className="row-tab-detail__tr">
                            <div className="row-tab-detail__th">Đợt thanh toán</div>
                            <div className="row-tab-detail__th">Ngày tạo</div>
                            <div className="row-tab-detail__th">Giá trị thu</div>
                            <div className="row-tab-detail__th">Giá trị còn lại</div>
                            <div className="row-tab-detail__th">Trạng thái</div>
                        </div>
                    </div>
                    <div className="row-tab-detail__tbody">
                        {
                            historyDetailLoading ? (
                                    Array.from(Array(6), (e, i) => (
                                        <ReceiptPlaceholder key={i}/>
                                    ))
                                )
                                :
                                Array.isArray(historyDetail?.trackings) && historyDetail?.trackings?.length > 0 ?
                                    historyDetail?.trackings.map(item => (
                                        <div key={item.id} className="row-tab-detail__tr">
                                            <div
                                                className="row-tab-detail__td"
                                                title={item?.id}
                                                style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {item?.tracking_title || '---'}
                                            </div>
                                            <div className="row-tab-detail__td">
                                                <Text>
                                                    {!!item?.created_at ? fDateTimeCustom(item?.created_at, {format: 'HH:mm dd/MM/yyyy'}) : '---'}
                                                </Text>
                                            </div>
                                            <div className="row-tab-detail__td">
                                                {formatMoney(item?.payment_value || 0)}
                                            </div>
                                            <div className="row-tab-detail__td">
                                                {formatMoney(item?.month_remaining || 0)}
                                            </div>
                                            <div className="row-tab-detail__td">
                                                <Text>{item.payment_status_name}</Text>
                                            </div>
                                        </div>
                                    ))
                                    : <div className="row-tab-detail__empty flex">
                                        <Text as={'p'}>Không tìm thấy chi tiết kỳ thanh toán</Text>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </StyledRowTabPayment>
    )
}

const ReceiptPlaceholder = ({...props}) => {
    return (
        <div {...props} className="row-tab-detail__tr" style={{background: '#fff'}}>
            {Array.from(Array(5), (e, i) => (
                <div key={i} className="row-tab-detail__td" data-type="td">
                    <Skeleton
                        sx={{
                            width: '100%',
                            height: 33,
                        }}
                    />
                </div>
            ))}
        </div>
    )
}
const StyledRowTabPayment = styled.div`
position: relative;
    .row-tab-detail{
        &__empty{
        align-items: center;
        justify-content: center;
        min-height: 200px;
        p{
        width: 100%!important;
        text-align: center;
        }
      }
    }
.row-tab-detail__content{
display: flex;
&-group{
width: 33.33%;
h4{
margin-bottom: 24px;
}
&--items{
margin-bottom: 16px;
p{
margin-bottom: 8px;
}
}
}
}
.row-tab-payment {
&__list-item {
margin-bottom: 12px;
padding: 12px 24px;

border-left: 3px solid ${THEME_SEMANTICS.delivered};

&:last-child {
margin-bottom: 0;
}
}

&__list-item-toggle {
display: flex;
align-items: center;

cursor: pointer;

&[data-active='true'] {
svg {
transform: rotate(0deg);
}
}

svg {
margin-left: 8px;

transform: rotate(180deg);
transition: transform 0.6s;
}
}

&__list-item-content {
max-height: 0;

display: flex;
flex-wrap: wrap;
overflow: hidden;

transition: max-height 0.5s;

&[data-active='true'] {
max-height: 75vh;
}
}

&__list-item-figure {
width: 250px;
margin-top: 8px;
}
}
.row-tab-detail {
&__table {
margin-top: 24px;
margin-bottom: 12px;

overflow: hidden;

border: 1px solid #e2eaf8;
border-radius: 8px;
}

&__thead {
.row-tab-detail__tr {
background: #f7f9fd;
}
}

&__tr {
display: flex;
}

&__th {
min-height: 44px;
padding: 12px;

color: ${THEME_COLORS.secondary_100};
font-size: 14px;
font-weight: 600;
line-height: 20px;

&:nth-child(1) {
width: 13%;
}
&:nth-child(2) {
flex: 1;
}
&:nth-child(3) {
width: 16%;
}
&:nth-child(4) {
width: 16%;
text-align: right;
}
&:nth-child(5) {
width: 16%;
text-align: center;
}
&:nth-child(6) {
width: 16%;
text-align: center;
}
}

&__td {
min-height: 56px;
padding: 18px 12px;

border-top: 1px solid #e2eaf8;

color: ${THEME_COLORS.secondary_100};
font-size: 14px;
font-weight: 400;
line-height: 20px;

&:nth-child(1) {
width: 13%;
}
&:nth-child(2) {
flex: 1;

color: ${THEME_SEMANTICS.delivering};
}
&:nth-child(3) {
width: 16%;
}
&:nth-child(4) {
width: 16%;
text-align: right;
}
&:nth-child(5) {
width: 16%;
display: flex;
justify-content: center;
}
&:nth-child(6) {
width: 16%;
text-align: center;
}
}

}
`
