import React from "react";
import {Modal} from "@mui/material";
import {StyleConfirmModal} from "./_styled";
import {Button} from "../../common/button";

export const ConfirmModalV2 = ({...props})=>{
    const {open, width, body, footerButton} = props
    return(
        <Modal
            open={open}
        >
            <StyleConfirmModal>
                <div
                    className='confirm-modal_main'
                    style={{width: `${width}px`}}
                >
                    {
                        !!body && body
                    }
                    <div
                        className='confirm-modal_footer'
                    >
                       <Button
                        size={'sm'}
                        className='confirm-modal_button'
                        appearance={'ghost'}
                        onClick={footerButton?.cancel?.action}
                       >
                           {footerButton?.cancel?.title || 'Hủy'}
                       </Button>
                        <Button
                          size={'sm'}
                          className='confirm-modal_button'
                          onClick={footerButton?.confirm?.action}
                          disabled={footerButton?.confirm?.disabled || false}
                        >
                            {footerButton?.confirm?.title || 'Xác nhận'}
                        </Button>
                    </div>
                </div>
            </StyleConfirmModal>
        </Modal>
    )
}