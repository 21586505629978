import useInvoiceFilterForm from 'Pages/invoice/hooks/useInvoiceFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const InvoiceInfo = () => {
  const { invoiceInfo } = useInvoiceFilterForm()
  return (
    <CategoryInput
      className={'invoice-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Mã phiếu chi', value: '' }}
      categoryWidth={102}
      placeholder='Nhập mã phiếu chi'
      value={invoiceInfo?.value || ''}
      onChange={invoiceInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}
