import { PageHeader } from "layouts/pageHeader";
import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    return (
        <StyledDashboardHeader>
            <div className={"product-group-header"} >
                <PageHeader
                    breadcrumbTitle={t("Dashboard")}
                    breadcrumbLinks={[
                      {id: 1, name: 'Trang chủ', url: '/'},
                      {id: 3, name: 'Dashboard', url: '/'},
                    ]}
                />
            </div>
        </StyledDashboardHeader>
    )
}
export default Index;
const StyledDashboardHeader = styled.div`
    .product-group-header{

    }
`