import { PageHeader } from "layouts/pageHeader";
import { PRODUCT_BREADCUM, PRODUCT_BUTTON_ACTION } from "Pages/productGroup/interface";
import { ProductGroup } from "Pages/productGroup/provider/_context";
import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    const { pageState, pageDispatch } = useContext(ProductGroup)
    const { PRODUCT_ACTION_BUTTON} = PRODUCT_BUTTON_ACTION(pageState, pageDispatch,t)

    return (
        <StyledProductGroupHeader>
            <div className={"product-group-header"} >
                <PageHeader
                    actions={PRODUCT_ACTION_BUTTON}
                    breadcrumbLinks={PRODUCT_BREADCUM}
                    breadcrumbTitle={t("Quản lý danh mục")}
                />
            </div>
        </StyledProductGroupHeader>
    )
}
export default Index;
const StyledProductGroupHeader = styled.div`
    .product-group-header{

    }
`