import { Th } from 'layouts/tableLayout/_th'
import { Tr } from 'layouts/tableLayout/_tr'
import useStudentTHead from 'Pages/student/hooks/useStudentTHead'

export const StudentTHead = ({ ...props }) => {
    const { globalLoading } = useStudentTHead()

    return (
        <>
            <Tr {...props} type="tHead" style={{ height: '44px' }}>
                <Th className="student-table__cell">Học sinh</Th>
                <Th className="student-table__cell" style={{textAlign:'center'}}>Tổng đơn hàng</Th>
                <Th className="student-table__cell" style={{textAlign:'center'}}>Tổng tiền</Th>
                <Th className="student-table__cell" style={{textAlign:'center'}}>Tổng hoa hồng</Th>
                <Th className="student-table__cell">Ngày quét mã</Th>
                <Th className="student-table__cell">Trạng thái</Th>
                {/* <Th className="student-table__cell" style={{ display: 'flex' }}/> */}
            </Tr>
            {globalLoading.value && (
                <div className="student-table__loading">
                    <img src="/img/loading.gif" />
                </div>
            )}
        </>
    )
}