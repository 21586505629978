import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const Email = ()=>{
    const {email} = useCreateTeacherForm()
    const {methods} = email
    return (
        <Input
            label={
                <>
                    Email <Text color={THEME_SEMANTICS.failed}>*</Text>
                </>
            }
            value={email?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={email?.message}
            validateType={email?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập email'}
            maxLength={201}
        />
    )
}