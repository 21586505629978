export const INPUT_ICONS = {
  chevronLeft: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8L10 12L6 8"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  question: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.5C11.866 14.5 15 11.366 15 7.5C15 3.63401 11.866 0.5 8 0.5C4.13401 0.5 1 3.63401 1 7.5C1 11.366 4.13401 14.5 8 14.5Z"
        stroke="#808089"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12.167C8.48325 12.167 8.875 11.7752 8.875 11.292C8.875 10.8087 8.48325 10.417 8 10.417C7.51675 10.417 7.125 10.8087 7.125 11.292C7.125 11.7752 7.51675 12.167 8 12.167Z"
        fill="#808089"
      />
      <path
        d="M7.99967 8.66667V8.08333C8.40348 8.08333 8.79821 7.96359 9.13396 7.73925C9.46971 7.51491 9.7314 7.19605 9.88593 6.82298C10.0405 6.44991 10.0809 6.0394 10.0021 5.64336C9.92333 5.24731 9.72888 4.88352 9.44335 4.59799C9.15782 4.31246 8.79403 4.11801 8.39798 4.03923C8.00194 3.96045 7.59143 4.00088 7.21836 4.15541C6.8453 4.30994 6.52643 4.57163 6.30209 4.90738C6.07775 5.24313 5.95801 5.63786 5.95801 6.04167"
        stroke="#808089"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
