export const NOTIFICATION_ICONS = {
  danger: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C6.1339 17 3 13.8661 3 10C3 6.1339 6.1339 3 10 3C13.8661 3 17 6.1339 17 10C17 13.8661 13.8661 17 10 17ZM10 9.0102L8.0204 7.0299L7.0299 8.0204L9.0102 10L7.0299 11.9796L8.0204 12.9701L10 10.9898L11.9796 12.9701L12.9701 11.9796L10.9898 10L12.9701 8.0204L11.9796 7.0299L10 9.0102Z"
        fill="#EE4064"
      />
    </svg>
  ),
  delete: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 5.25L5.25 12.75M5.25 5.25L12.75 12.75"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  info: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C13.8385 17 17.0068 13.8317 17.0068 10C17.0068 6.16828 13.8317 3 9.99323 3C6.16151 3 3 6.16828 3 10C3 13.8317 6.16828 17 10 17ZM9.83752 11.3946C9.39749 11.3946 9.15377 11.1915 9.15377 10.7853V10.7108C9.15377 10.0745 9.51934 9.71567 10.0203 9.36364C10.6161 8.95068 10.9072 8.72727 10.9072 8.294C10.9072 7.82689 10.5484 7.51547 9.99323 7.51547C9.58704 7.51547 9.28917 7.71857 9.06576 8.06383C8.84913 8.30754 8.74758 8.51741 8.34139 8.51741C8.00967 8.51741 7.73888 8.30077 7.73888 7.96228C7.73888 7.82689 7.76596 7.70503 7.81335 7.58317C8.03675 6.91973 8.84913 6.37814 10.0609 6.37814C11.3201 6.37814 12.383 7.04836 12.383 8.22631C12.383 9.03868 11.9362 9.44487 11.2321 9.89845C10.7853 10.1896 10.528 10.4333 10.5077 10.7988C10.5077 10.8191 10.501 10.853 10.501 10.8801C10.4807 11.1712 10.2302 11.3946 9.83752 11.3946ZM9.83075 13.5609C9.37041 13.5609 8.9913 13.2292 8.9913 12.7824C8.9913 12.3356 9.36364 12.0039 9.83075 12.0039C10.2911 12.0039 10.6634 12.3356 10.6634 12.7824C10.6634 13.236 10.2843 13.5609 9.83075 13.5609Z"
        fill="#1A94FF"
      />
    </svg>
  ),
  success: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_279_14327)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 10C3 8.14348 3.7375 6.36301 5.05025 5.05025C6.36301 3.7375 8.14348 3 10 3C11.8565 3 13.637 3.7375 14.9497 5.05025C16.2625 6.36301 17 8.14348 17 10C17 11.8565 16.2625 13.637 14.9497 14.9497C13.637 16.2625 11.8565 17 10 17C8.14348 17 6.36301 16.2625 5.05025 14.9497C3.7375 13.637 3 11.8565 3 10ZM9.60053 12.996L13.6307 7.95787L12.7329 7.20573L9.5 11.2964L7.2356 9.438L6.43467 10.3584L9.60053 12.996Z"
          fill="#00AB56"
        />
      </g>
      <defs>
        <clipPath id="clip0_279_14327">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  warning: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99215 15.0821L8.84622 3.24329C9.22503 2.58899 9.41443 2.26184 9.66154 2.15196C9.87709 2.05612 10.1232 2.05612 10.3387 2.15196C10.5858 2.26184 10.7752 2.58899 11.154 3.24329L18.0081 15.0821C18.3883 15.7388 18.5783 16.0671 18.5503 16.3366C18.5257 16.5716 18.4026 16.7852 18.2115 16.9242C17.9923 17.0835 17.613 17.0835 16.8542 17.0835H3.14605C2.38728 17.0835 2.0079 17.0835 1.78876 16.9242C1.59763 16.7852 1.47449 16.5716 1.44999 16.3366C1.42189 16.0671 1.61198 15.7388 1.99215 15.0821Z"
        fill="#FF9F41"
      />
      <path
        d="M9.55273 8V12M9.55273 14.6667H9.56107"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
