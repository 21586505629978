import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { ReceiptFilterForm } from './components/receiptFilterForm'
import { ReceiptTBody } from './components/receiptTable/_Body'
import { ReceiptTHead } from './components/receiptTable/_Thead'
import {
  RECEIPT_BREADCRUMB,
} from './interfaces/_constants'
import { ReceiptProvider } from './provider'
import './components/receiptTable/index.scss'
import useReceipt from './hooks/useReceipt'
import { PageHeader } from './components/receiptPageHeader'
import PopupNotPermission from '../permision/popupNotPermission'
import { useNavigate } from 'react-router-dom'
import { GLOBAL_ICONS } from '../../interface/icon'
import { PATH } from '../../const/path'

export const ReceiptPage = () => {
  const { fetch, pagination, provider } = useReceipt()

  const { state, dispatch } = provider
  const { table } = state

  useEffect(() => {
    fetch.origin()
  }, [])
  return (
    <ReceiptProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <Header/>
      <TableLayout
        header={
          <>
            <ReceiptFilterForm/>
          </>
        }
        table={{
          tHead: <ReceiptTHead/>,
          tBody: <ReceiptTBody/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </ReceiptProvider>
  )
}

const Header = () => {
  const [showPermission, setShowPermission] = useState(false)
  const nav = useNavigate()
  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
      <PageHeader
        breadcrumbLinks={RECEIPT_BREADCRUMB}
        breadcrumbTitle="Quản lý phiếu thu hoa hồng"
        actions={[
          {
            name: 'Thêm mới phiếu thu',
            icon: GLOBAL_ICONS.plus,
            onClick: () => nav(PATH.CREATE_COMMISSION_VOUCHER)
          }
        ]}
      />
    </>
  )
}
