import { DISPLAY_NAME_MENU } from "../../../const/display_name_menu";

export const CREATE_PRODUCT_CONSTANTS = {
    header: {
        breadcrumb: [
            { id: 1, name: 'Trang chủ', url: '#' },
            { id: 2, name: 'Thông tin người dùng', url: '#' },
        ],
    },
}

export const EDIT_PRODUCT_CONSTANTS = {
    header: {
        breadcrumb: [
            { id: 1, name: DISPLAY_NAME_MENU.PRODUCT, url: '#' },
            { id: 2, name: 'Thông tin người dùng', url: '#' },
        ],
    },
}

export const CREATE_PRODUCT_REDIRECT_CONSTANTS = [
    { id: 'basic', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO, location: 70, active: true },
    { id: 'price', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO, location: 420, active: false },
    { id: 'product', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS, location: 700, active: false },
]

export const EDIT_SINGLE_PRODUCT_REDIRECT_CONSTANTS = [
    { id: 'basic', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO, location: 70, active: true },
    { id: 'price', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO, location: 420, active: false },
    { id: 'product', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS, location: 700, active: false },
]

export const EDIT_MULTIPLE_PRODUCT_REDIRECT_CONSTANTS = [
    { id: 'basic', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.BASIC_INFO, location: 70, active: true },
    { id: 'price', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRICE_INFO, location: 420, active: false },
    { id: 'product', name: DISPLAY_NAME_MENU.PRODUCT_PAGE.PRODUCT_SPECIFICATIONS, location: 700, active: false },
]