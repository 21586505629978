import React from "react";
import styled from 'styled-components'
import {Grid} from "@mui/material";
import {BankName} from "./_bankName";
import {AccountNumber} from "./_accountNumber";
import {AccountOwner} from "./_accountOwner";

export const InfoBank = () => {
    return (
        <StyleInfoBank>
            <Grid container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <AccountNumber/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <AccountOwner/>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <BankName/>
                </Grid>

            </Grid>
        </StyleInfoBank>
    )
}
const StyleInfoBank = styled.div``