import {Breadcrumb} from 'common/breadcrumb'
import {StyledReceiptPageHeader} from "./_styled";

export const PageHeader = ({
  actions,
  breadcrumbLinks,
  breadcrumbSubTitle,
  breadcrumbTitle,
  ...props
}) => {
  return (
    <StyledReceiptPageHeader {...props}>
      <div className="receipt-page-header__breadcrumb">
        <Breadcrumb
          links={breadcrumbLinks}
          subTitle={breadcrumbSubTitle}
          title={breadcrumbTitle}
        />
      </div>
    </StyledReceiptPageHeader>
  )
}
