import { Text } from '../../../../common/text'
import { useContext } from 'react'
import { InvoiceContext } from '../../provider/_context'
import { fNumber } from '../../../../util/formatNumber'
import { fDateTimeCustom } from '../../../../util/formatTime'
import { formatMoney } from '../../../../util/functionUtil'

export const PrintInvoiceDetail = () => {
  const { pageState } = useContext(InvoiceContext)
  const { printDetail } = pageState
  return (
    <div style={{ display: 'none' }}>
      <div id={'invoice-detail-print'}>
        <div style={{ textAlign: 'center', margin: '24px 0' }}>
          <Text fontWeight={600} fontSize={24}>CHI TIẾT ĐƠN ĐẶT HÀNG</Text>
        </div>
        <table style={{ width: '100%' }}>
          <tbody style={{ width: '100%' }}>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text fontSize={16} fontWeight={600}>Thông
              tin khách hàng</Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text fontSize={16} fontWeight={600}>Thông
              tin người nhận</Text>
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Mã khách hàng: <Text
              fontWeight={600}>#{printDetail?.id || '---'}</Text></Text>
            </td>
            <td
              style={{ padding: '0 8px', textAlign: 'left' }}></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Tên khách
              hàng: <Text fontWeight={600}>{printDetail?.invoice_name || '---'}</Text></Text>
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Ngày mua
              hàng: <Text
                fontWeight={600}>{!!printDetail?.created_at ? fDateTimeCustom(printDetail?.created_at, { format: 'HH:mm dd/MM/yyyy' }) : '---'}</Text></Text>
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Số điện
              thoại: <Text fontWeight={600}>{printDetail?.invoice_phone || '---'}</Text></Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Ghi chú: <Text
              fontWeight={600}></Text>{printDetail?.note || '---'}</Text>
            </td>
            <td style={{
              padding: '0 8px',
              textAlign: 'left',
              width: '20%'
            }}></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Email: <Text
              fontWeight={600}>{printDetail?.invoice_email || '---'}</Text></Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>

          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text></Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text
              color={'rgb(26, 148, 255)'}></Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}><Text>Địa chỉ: <Text
              fontWeight={600}>{printDetail?.invoice_address || '---'}</Text></Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left' }}></td>
          </tr>
          </tbody>
        </table>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '16px' }}>
          <thead style={{ width: '100%' }}>
          <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'center' }}><Text
            fontWeight={600}>Tên
            sản phẩm</Text>
          </th>
          <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'center' }}><Text
            fontWeight={600}>Số
            lượng</Text>
          </th>
          <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'right' }}><Text
            fontWeight={600}>Đơn
            giá</Text>
          </th>
          <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'right' }}><Text
            fontWeight={600}>Giảm
            giá</Text></th>
          <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'right' }}><Text
            fontWeight={600}>Thành
            tiền</Text></th>
          </thead>
          <tbody style={{ width: '100%' }}>
          {!!printDetail?.details && printDetail.details.map((item) => (
            <tr style={{ width: '100%' }}>
              <td style={{
                border: '1px solid rgb(0, 8, 29)',
                padding: '8px',
                textAlign: 'center'
              }}>{item?.product_name}</td>
              <td
                style={{
                  border: '1px solid rgb(0, 8, 29)',
                  padding: '8px',
                  textAlign: 'center'
                }}>{item?.quantity}</td>
              <td style={{
                border: '1px solid rgb(0, 8, 29)',
                padding: '8px',
                textAlign: 'right'
              }}>{formatMoney(item?.price)}</td>
              <td
                style={{
                  border: '1px solid rgb(0, 8, 29)',
                  padding: '8px',
                  textAlign: 'right'
                }}>{formatMoney(+item?.price - +item?.price_sale)}</td>
              <td style={{
                border: '1px solid rgb(0, 8, 29)',
                padding: '8px',
                textAlign: 'right'
              }}>{formatMoney(+item?.price_sale * +item?.quantity)}</td>
            </tr>
          ))
          }
          </tbody>
        </table>

        {
          <div style={{ textAlign: 'right', marginTop: '48px' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td style={{ width: '60%' }}></td>
                <td>
                  <table style={{ width: '100%' }}>
                    <tr className="row-tab-detail__result-item">
                      <td className="row-tab-detail__result-label">
                        <b>Tổng khách giá</b>
                      </td>
                      <td className="row-tab-detail__result-value">
                        <b>{formatMoney(+printDetail?.total_amount)}</b>
                      </td>
                    </tr>
                    <tr className="row-tab-detail__result-item">
                      <td className="row-tab-detail__result-label">
                        <b>Tổng giảm giá</b>
                      </td>
                      <td className="row-tab-detail__result-value">
                        <b>{formatMoney(+printDetail?.discount || 0)}</b>
                      </td>
                    </tr>
                    <tr className="row-tab-detail__result-item">
                      <td className="row-tab-detail__result-label">
                        <b>Thành tiền</b>
                      </td>
                      <td className="row-tab-detail__result-value">
                        <b>{formatMoney(+printDetail?.sub_total)}</b>
                      </td>
                    </tr>
                    <tr className="row-tab-detail__result-item">
                      <td className="row-tab-detail__result-label">
                        <b>Phí vận chuyển</b>
                      </td>
                      <td className="row-tab-detail__result-value">
                        <b>{formatMoney(+printDetail?.shipping_fee || 0)}</b>
                      </td>
                    </tr>
                    <tr className="row-tab-detail__result-item">
                      <td className="row-tab-detail__result-label">
                        <b>Tổng tiền</b>
                      </td>
                      <td className="row-tab-detail__result-value">
                        <b>{formatMoney(+printDetail?.grand_total)}</b>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        }
      </div>
    </div>
  )
}
