import React, { useContext, useEffect, useReducer, useState } from 'react'
import { ProductGroup } from 'Pages/productGroup/provider/_context'
import './index.scss'
import { Tr } from 'layouts/tableLayout/_tr'
import { Td } from 'layouts/tableLayout/_td'
import { Text } from 'common/text'
import Skeleton from '../../skeleton/index'
import { useProductAction } from 'Pages/productGroup/provider/_reducer'
import { PRODUCT_GROUP_ICON } from 'Pages/productGroup/interface/icon'
import EmptyProduct from '../../empty/index'
import { useModal } from '../../../hook/useModal'
import { Tooltip } from 'common/tooltipv2'
import { useTranslation } from 'react-i18next'
import { GLOBAL_ICONS } from '../../../../../interface/icon'

const Index = () => {
  const { t } = useTranslation()
  const { pageState, pageDispatch } = useContext(ProductGroup)
  const list = pageState.listCategory
  const load = pageState.loading
  const [toggle, setToggle] = useState([])
  const { getDetailProduct } = useModal()
  const handleEditCategory = (e,id) => {
    e.preventDefault()
    e.stopPropagation()
    getDetailProduct(id)
    setTimeout(() => {
      pageDispatch({ type: useProductAction.OPEN_MODAL, payload: true })
      pageDispatch({ type: useProductAction.GET_ID, payload: id })
      pageDispatch({ type: useProductAction.DISABLE_SELECT, payload: true })
    }, 300)
  }
  const handleDeleteCategory = (e,id) => {
    e.preventDefault()
    e.stopPropagation()
    pageDispatch({ type: useProductAction.GET_ID, payload: id })
    pageDispatch({ type: useProductAction.CHECK_CONFIRM_DELETE, payload: !pageState.check_confirm_delete })
  }
  const handleShowChild = id => {
    if (toggle.length > 0) {
      let newAction = true
      const categoryTarget = toggle.map(item => {
        if(item.id === id){
          item.show = !item.show
          newAction = false
        }
        return item
      })
      setToggle(!!newAction ? [...toggle, {id: id,show: true}] : categoryTarget)
    } else {
      setToggle([{ id: id, show: true }])
    }
  }

  const show = () => {
    if (list) {
      return list.map((item, index) => {
        return (
          <>
            <div className='parent-category__container'
                 data-toggle={toggle.length > 0 ? !!toggle.find(tg => tg.id === item.id)?.show : false}>
              <Tr key={index} className='product-group-table-body' onClick={() => handleShowChild(item.id)}>
                <Td className='product-group-table-body-name'>
                  <Tooltip className="product-group-table-body_tooltip" baseOn="height" placement='top-center'
                           title={item.title}>
                    <Text>{item.title}</Text>
                  </Tooltip>
                  {item?.childs?.length > 0 && <Text>&nbsp; ({item.childs.length} subitems)</Text>}
                </Td>
                <Td className='product-group-table-body-setting'>
                  &nbsp;
                  <Text data-toggle={toggle.length > 0 ? !!toggle.find(tg => tg.id === item.id)?.show : false}>{GLOBAL_ICONS.up}</Text>
                  {/*<Text onClick={(e) => handleEditCategory(e,item.id)}*/}
                  {/*      style={{ marginRight: '12px' }}>{GLOBAL_ICONS.edit}</Text>*/}
                  {/*<Text onClick={(e) => handleDeleteCategory(e,item.id)}>{GLOBAL_ICONS.recycle}</Text>*/}
                </Td>
              </Tr>
              {item.childs && item.childs.map((conent, index) => {
                return <Tr key={index} className='product-group-table-body product-group-table-body__child'>
                  <Td className='product-group-table-body-name'>
                    <Tooltip className="product-group-table-body_tooltip" baseOn="height" placement='top-center'
                             title={conent.title}>
                      <Text style={{ paddingTop: '5px' }}>{PRODUCT_GROUP_ICON.group}</Text>
                      <Text>{conent.title}</Text>
                    </Tooltip>
                  </Td>
                  <Td className='product-group-table-body-setting'>
                    <Text onClick={(e) => handleEditCategory(e,conent.id)}
                          style={{ marginRight: '8px' }}>{GLOBAL_ICONS.edit}</Text>
                    <Text onClick={(e) => handleDeleteCategory(e,conent.id)}>{GLOBAL_ICONS.recycle}</Text>
                  </Td>
                </Tr>
              })}
            </div>
          </>

        )
      })
    }
  }
  const showBody = () => {
    if (list.length > 0 && load) return (<>{show()}</>)
    else if (!load) return <Skeleton numb={list ? list.length : 20}/>
    else return <EmptyProduct t={t}/>
  }
  return (
    <>
      {showBody()}
    </>
  )
}
export default Index