import { formatDatetime } from 'common/form/datePicker/_functions'
import { getDateFromNow } from 'util/date'
// import useGlobalContext from '../../../containerContext/storeContext'

export const dateTimeDefaultValue = [
    getDateFromNow(0, { type: 'start' }),
    getDateFromNow(7, { type: 'end' }),
]
export const formatDateTimeDefaultValue = `${formatDatetime(
    dateTimeDefaultValue[0],
)} - ${formatDatetime(dateTimeDefaultValue[1])}`

export const profileInitialState = {
    loading : false,
    avatarHover : false,
    userData: {
        avatar: '',
        email: '',
        username: '',
        name: '',
        last_name: '',
        phone: '',
        commission: '',
    },
    formValidate : {
        name: {
            status: false,
            message: ''
        },
        address: {
            status: false,
            message: ''
        },
        school_name: {
            status: false,
            message: ''
        },
        teaching_subject: {
            status: false,
            message: ''
        }
    },
}