import {Td} from "layouts/tableLayout/_td";
import {Tr} from "layouts/tableLayout/_tr";
import {PRODUCT_HEADER_TITLE} from "Pages/productGroup/interface";
import React, {useState} from "react";
import {Text} from 'common/text'
import {StyledProductTableHeader} from "./_styled";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    const list = PRODUCT_HEADER_TITLE
    const show = () => {
        if (list) {
            return list.map((item, index) => {
                return (
                    <Td key={index} className={'product-group-table-header-' + item.class}>
                        <Text fontWeight={600}>
                            {t(item.name)}
                        </Text>
                    </Td>
                )
            })
        }
    }
    return (
        <StyledProductTableHeader>
            {/*<div className={"product-group-table-header"}>*/}
            {/*    <Tr type='tHead'>{show()}</Tr>*/}
            {/*</div>*/}

        </StyledProductTableHeader>


    )
}
export default Index;
