import { Button } from 'common/button'
import { Text } from 'common/text'
import { StyledReceiptEmpty } from './_styled'
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const ReceiptEmpty = ({ ...props }) => {
  return (
    <StyledReceiptEmpty {...props}>
      <img
        className="receipt-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}> Không tìm thấy dữ liệu </Text>
    </StyledReceiptEmpty>
  )
}
