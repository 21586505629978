import React, { useContext, useEffect, useState } from "react";
import { UserManagementContext } from "Pages/userManagement/provider/_context";
import { StyledUserTableBody } from "./_styled";
import { Tr } from "layouts/tableLayout/_tr";
import { Td } from "layouts/tableLayout/_td";
import CheckBoxConsignment from "Component/CheckBoxConsignment/CheckBoxConsignment";
import Row from "./row"
import { SwitchStatus } from "Component/SwitchStatus/SwitchStatus";
import { Text } from "common/text";
import {Tooltip as Tooltipv1} from "common/tooltip";
import { Tooltip } from "common/tooltipv2";
import RowOrderExtra from "./TabDetail"
import { useDetailRow } from "Pages/userManagement/hooks/useDetailRow";
import { useTableBody } from "Pages/userManagement/hooks/useTableBody";
import Skeleton from "../../skeleton/index"
import { userManagementActions } from "Pages/userManagement/provider/_reducer";
import useGlobalContext from "containerContext/storeContext";
import Empty from "../../empty/index"
import { useCheckBoxTable } from "Pages/userManagement/hooks/useCheckBoxTableBody";
import { useTranslation } from "react-i18next";
import { fDateTime, fDateTimeApi } from '../../../../../util/formatTime'
import {USER_ICON} from "../../../icon/icon";

const Index = () => {
    const { t } = useTranslation()
    const { pageState, pageDispatch } = useContext(UserManagementContext)
    const [GlobalState,] = useGlobalContext()
    const userIdGlobal = GlobalState.user.user_id
    const [list, setList] = useState()
    const [useId, setUseId] = useState()
    const { changeStatus, isActive, disable } = useTableBody()
    const { is_check } = useCheckBoxTable()
    useEffect(() => {
        setList(pageState.listUser)
    }, [pageState.listUser])
    const [isTableHover, setIsTableHover] = useState(-1)

    const detailRow = useDetailRow(useId)
    const { detailActive, shouldOpenDetail, rowDetailToggle } = detailRow
    let check = pageState.isCheck
    let load = pageState.loading
    const handleActionApply = (action, id) => {
        console.log('id', id)
        switch (action) {
            case 1:
                pageDispatch({ type: userManagementActions.MODAL_USER_INFOR, payload: true })
                break
            case 2:
                pageDispatch({ type: userManagementActions.UPDATE_RESET_PASSWORD_MODAL, payload: { resetPassword: id } })
                break
            case 3:
                pageDispatch({ type: userManagementActions.RESET_USER_UPDATE, payload: useId })
                break
            case 4:
                pageDispatch({ type: userManagementActions.MODAL_USER_ROLE, payload: true })
                break
            default:
                break
        }
    }

    const show = () => {
        if (load && list?.length > 0) return list?.map(item => {
            return (
                <>
                    <Tr key={item.id} className='user-management-table_body-row'
                        onMouseEnter={() => setIsTableHover(item.id)}
                        onMouseLeave={() => setIsTableHover()}
                        extra={
                            shouldOpenDetail && item.id === useId && <RowOrderExtra
                                active={shouldOpenDetail}
                                data={detailActive}
                                rowData={detailRow}
                            />
                        }
                        data-active={shouldOpenDetail && item.id === useId}
                        onClick={() => {
                            setUseId(item.id)
                            // rowDetailToggle(item.id)
                        }}
                    >
                        <Td className='user-management-table_body-fullname'>
                            <Tooltip placement='top-center' title={item.username} baseOn="height" className='tooltipv2'>
                                <Text>{item.username}</Text>
                            </Tooltip>
                        </Td>
                        <Td className='user-management-table_body-email'>
                            <Tooltip placement='top-center' title={item.email} baseOn="width" className='tooltip_email'>
                                <Text>
                                    {item.email}
                                </Text>
                            </Tooltip>
                        </Td>
                        <Td className='user-management-table_body-fullname'>
                            <Tooltip placement='top-center' title={item.name} baseOn="height" className='tooltipv2'>
                                <Text>{item?.last_name} {item.name}</Text>
                            </Tooltip>
                        </Td>
                        <Td className='user-management-table_body-managment'>
                            {item?.group_name}
                        </Td>
                        <Td className='user-management-table_body-phone'>
                            <Text>
                                {!!item?.created_at ? fDateTimeApi(item?.created_at) : '---'}
                            </Text>
                        </Td>
                        <Td className='user-management-table_body-status' data-type="td">
                            { item?.status !== 'inactive'
                                ? <Text className={'user-management-table_body-status--success'}>{item?.status_name}</Text>
                                : <Text className={'user-management-table_body-status--danger'}>{item?.status_name}</Text>
                            }
                        </Td>
                        <Td
                            className="user-management-table_body-action"
                            data-menu="true"
                            data-type="td"
                            onClick={e => e.stopPropagation()}
                        >
                            <Tooltipv1 title={'Chỉnh sửa'}>
                                <Text
                                    className="icon__edit"
                                    // data-active={row.shouldOpenDetail}
                                    href={`/users/edit/${item?.id}`}
                                    as={'a'}
                                >
                                {USER_ICON.edit}
                                </Text>
                            </Tooltipv1>
                            <Tooltipv1 title={item?.status !== 'inactive' ? 'Khóa người dùng' : ' Mở khóa người dùng'} >
                                <Text
                                    className="icon__delete"
                                    onClick={() => changeStatus( item?.id, item?.status)}
                                >
                                {item?.status !== 'inactive'
                                    ? USER_ICON.lock
                                    : USER_ICON.unlock
                                }
                                </Text>
                            </Tooltipv1>
                        </Td>
                        {/* {(+item?.id === +userIdGlobal) || (+item?.is_shop_manage !== 1) ? (
                            <Td className='user-management-table_body-setting' onClick={e => {
                                e.stopPropagation()
                                setUseId(item.id)
                            }}>
                                {isTableHover === item.id && <div className="settingShow">
                                    <div className="user-management-table_body-toggle"
                                        data-active={shouldOpenDetail && item.id === useId}
                                        onClick={() => {
                                            setUseId(item.id)
                                            rowDetailToggle(item.id)
                                        }}
                                        onMouseEnter={() => setIsTableHover(item.id)}
                                    >
                                        <img src={'/svg/arrow.svg'} alt={'arrow'} />
                                    </div>
                                </div>}
                                <Row
                                    dataRow={item}
                                    id_user={item.id}
                                    onActionClick={handleActionApply}
                                    isTableHover={isTableHover}
                                />
                            </Td>
                        ) : (
                            <Td className='user-management-table_body-setting'> </Td>
                        )} */}
                    </Tr>
                </>

            )
        })
        else if (!load) return <Skeleton numb={20} />
        else return <Empty />
    }
    return (
        <StyledUserTableBody>
            <div className={'user-management-table_body'}>
                {show()}
            </div>
        </StyledUserTableBody>
    )
}
export default Index;