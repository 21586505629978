import styled from "styled-components";

export const StyledUserTableBody = styled.div`
    .tr__container {
        cursor: pointer;
        &:hover {
            .icon__delete {
                display: block;
            }
            .icon__edit {
                display: block;
            }
        }
    }
    .user-management-table_body{
        border-bottom: 1px solid #e2eaf8;
        &-checkbox{
           
        }
        .checkbox_hover{
            cursor: not-allowed;
        }
        &-fullname{
            width: 15%;
            margin-left:24px;
            .tooltipv2 {
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &-phone{
            width: 10%;
            margin-left:24px;
        }
        &-email{
            width: 15%;
            margin-left:24px;
            .tooltip_email {
                max-width: 100%;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &-managment{
            width: 25%;
            margin-left:24px;
            flex:1;
            .tooltipv2-text {
                display: flex;
                align-items: center;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .tooltipv2 {
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &-status{
            justify-content: center;
            width: 14%;
            margin-left:24px;
            .switch-status{
                width: fit-content;
                margin: 0 auto;
            }
            .switch-opacity{
                opacity:0.5;
            }
            &--success {
                color: #0B74E5 !important;
                font-size: 12px !important;
                padding: 4px 12px;
                background: #ECF4FE;
                border-radius: 4px;
            }
            &--danger {
                color: #FF424E !important;
                font-size: 12px !important;
                padding: 4px 12px;
                background: #FFEBEC;
                border-radius: 4px;
            }
        }
        &-action{
            width: 100px;
            margin: 0 8px;
            position: relative;
            justify-content: flex-end;
            .icon__delete {
                width: 20px;
                height: 20px !important;
                display: none;
                background: transparent;
                border: none;
                border-radius: 12px !important;
                font-size: 12px !important;
                line-height: 24px !important;
                cursor: pointer;
            }
            .icon__edit {
                width: 20px;
                height: 20px !important;
                display: none;
                margin-right: 16px;
                background: transparent;
                border: none;
                border-radius: 12px !important;
                font-size: 12px !important;
                line-height: 24px !important;
                cursor: pointer;
                &[data-active='true'] {
                    display: block !important;
                }
            }
        }
        &-toggle{
            position: absolute;
            top: 54%;
            right: 42px;
            transform: translateY(-50%) rotate(0deg);
            cursor: pointer;
            &[data-active='true'] {
                position: absolute;
                top: 45%;
                right: 42px;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    @media screen and (max-width : 1559px){
        .user-management-table_body{
            &-fullname{
                width: 12.52rem;
            }
            &-phone{
                width: 9.27rem;
            }
            &-email{
                width: 15.39rem;
            }
            &-managment{
                width: 22.64rem;
            }
            &-status{
                width: 10.52rem;
            }
            &-action{
                width: 3.75rem;
            }
        }
    }
    @media only screen and (max-width : 1366px){
        .user-management-table_body{
            &-fullname{
                width: 19.52rem;
            }
            &-phone{
                width: 9.27rem;
            }
            &-email{
                width: 15.39rem;
            }
            &-managment{
                width: 22.64rem;
            }
            &-status{
                width: 8.52rem;
            }
            &-action{
                width: 4.75rem;
            }
        }
    }
`