import {useEffect, useState} from "react"
import {useContext} from "react"
import {ProductGroup} from "../provider/_context"
import {useProductAction} from "../provider/_reducer"
import {SCRIPT_NOTE_PRODUCT} from "../interface/script";
import {getData, postData} from "../../../api/api";
import {createProductGroup, detailProductGroup, updateProductGroup} from "../../../api/url";
import toast from "../../../Component/Toast";
import StringUtils from "../util/string";
import {useTranslation} from "react-i18next";

export const useModal = () => {
    const {t}=useTranslation()
    const {pageState, pageDispatch} = useContext(ProductGroup)
    const [animate, setAnimate] = useState(false)
    const [status, setStatus] = useState(pageState.status_product)
    const handleClose = () => {
        if (!pageState.change_modal) {
            setAnimate(true)
            setTimeout(() => {
                pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
                setAnimate(false)
            }, 300)
            pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: ''})
            pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
            pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
            pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
            pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: '', id: ''}})
            pageDispatch({
                type: useProductAction.VALID_CODE_PRODUCT,
                payload: {status: false, message: ''}
            })
            pageDispatch({
                type: useProductAction.VALID_NAME_PRODUCT,
                payload: {status: false, message: ''}
            })
            pageDispatch({
                type: useProductAction.VALID_NOTE,
                payload: {status: false, message: ''}
            })
            pageDispatch({type: useProductAction.GET_ID, payload: ''})
            pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
        } else {

            pageDispatch({type: useProductAction.MODAL_CONFIRM, payload: true})
        }
    }
    const checkBeforeSubmit = [
        pageState.valid?.filde_name?.status,
        pageState.valid?.filde_parent?.status,
    ].includes(true)
    const handleAccept = async () => {
        if(!!!pageState.id_product) {
            if(!!!pageState?.item_category?.id){
                pageDispatch({
                    type: useProductAction.VALID_PARENT_CATEGORY,
                    payload: {status: true, message: 'Danh mục cha không được để trống'}
                })
                return false
            }
        }
        if (!checkBeforeSubmit) {
            const new_product = {
                title: pageState.name_product,
                parent: pageState.item_category?.id,
                // note: pageState.note_product,
                // status: pageState.status_product
            }
            if (new_product.title === '') {
                pageDispatch({
                    type: useProductAction.VALID_NAME_PRODUCT,
                    payload: {
                        status: new_product.title === '' ? true : false,
                        message: t(SCRIPT_NOTE_PRODUCT.VALID_NAME.EMPTY_NAME)
                    }
                })
            } else {
                try {
                    if (pageState.id_product) {
                        const res = await postData(updateProductGroup(pageState.id_product), new_product)
                        if (res.data.success) {
                            setAnimate(true)
                            setTimeout(() => {
                                pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
                                setAnimate(false)
                            }, 300)
                            pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
                            pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
                            pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
                            pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: '', id: ''}})
                            toast.success({title: t(SCRIPT_NOTE_PRODUCT.UPDATE_SUCCESS)})
                            pageDispatch({type: useProductAction.IS_LOADING, payload: false})
                            pageDispatch({type: useProductAction.GET_ID, payload: ''})
                            pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
                        } else {
                            errorResponse(res?.data?.errors)
                        }
                    } else {
                        const res = await postData(createProductGroup(), new_product)
                        if (res.data.success) {
                            setAnimate(true)
                            setTimeout(() => {
                                pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
                                setAnimate(false)
                            }, 300)
                            pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
                            pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
                            pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
                            pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: '', id: ''}})
                            toast.success({title: t(SCRIPT_NOTE_PRODUCT.CREATE_SUCEESS)})
                            pageDispatch({type: useProductAction.IS_LOADING, payload: false})
                            pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
                            pageDispatch({type:useProductAction.SET_SEARCH,payload:''})
                        } else {
                            errorResponse(res?.data?.errors)
                        }
                    }

                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
    const errorResponse = response => {
        response?.map(res => {
            switch (res.field) {
                case 'title':
                    pageDispatch({
                        type: useProductAction.VALID_NAME_PRODUCT,
                        payload: { status: true, message: res.message }
                    })
                    break
                case 'parent':
                    pageDispatch({
                        type: useProductAction.VALID_PARENT_CATEGORY,
                        payload: {status: true, message: res.message}
                    })
                    break
                default:
                    break
            }
        })
    }
    const getDetailProduct = async productId => {
        try {
            const res = await getData(detailProductGroup(pageState.id_product || productId))
            if (res.data.success) {
                let detail = res.data.data
                const item = pageState.category_list.find(find => find.id == detail.parent)
                const list = pageState.listCategory
                const findParent = list.find(item=>{
                     if(item.title === detail.title) return item
                })
                if(findParent?.childs.length > 0) pageDispatch({type:useProductAction.DISABLE_SELECT,payload:true})
                else pageDispatch({type:useProductAction.CHECK_PARENT,payload:detail.title})
                if (item !== undefined) pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: item.item, id: item.id}})
                pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: detail.title})
                pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: detail.status})
                pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: detail?.note|| ''})
                pageDispatch({type: useProductAction.VALID_PARENT_CATEGORY, payload: {status: false, message: ''}})
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleCancelConfirm = () => {
        pageDispatch({type: useProductAction.MODAL_CONFIRM, payload: false})
    }
    const handleAcceptConfirm = () => {
        pageDispatch({type: useProductAction.MODAL_CONFIRM, payload: false})
        pageDispatch({type: useProductAction.CHANGE_MODAL, payload: false})
        setAnimate(true)
        setTimeout(() => {
            pageDispatch({type: useProductAction.OPEN_MODAL, payload: false})
            setAnimate(false)
        }, 300)
        pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: ''})
        pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: ''})
        pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
        pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: ''})
        pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: '', id: ''}})
        pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: false, message: ''}
        })
        pageDispatch({
            type: useProductAction.VALID_NAME_PRODUCT,
            payload: {status: false, message: ''}
        })
        pageDispatch({
            type: useProductAction.VALID_NOTE,
            payload: {status: false, message: ''}
        })
        pageDispatch({type: useProductAction.GET_ID, payload: ''})
        pageDispatch({type: useProductAction.DISABLE_SELECT, payload: false})
    }
    const onPickup = (item, id) => {
        pageDispatch({type: useProductAction.PICK_ITEM_CATEGORY, payload: {item: item, id: id}})
        pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
        pageDispatch({type: useProductAction.VALID_PARENT_CATEGORY, payload: {status: false, message: ''}})
    }
    const onChangeCodeProduct = (e) => {
        pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
        pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: false, message: ''}
        })
        pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: e.target.value})

    }
    const onBlurCodePrduct = e => {
        const {value} = e.target;
        // const regex = REGEX_CUSTOMER_CODE

        const regex = /^[a-zA-Z0-9_.-]*$/
        if (value == '') {
            pageDispatch({
                type: useProductAction.VALID_CODE_PRODUCT,
                payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_CODE.EMPTY_CODE)}
            })
        } else if (regex.test(value) ){
            pageDispatch({
                type: useProductAction.VALID_CODE_PRODUCT,
                payload: {status: false, message: ''}
            })
            pageDispatch({type: useProductAction.GET_CODE_PRODUCT, payload: value})
        }
        else if (value.length > 80) pageDispatch({
            type: useProductAction.VALID_CODE_PRODUCT,
            payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_CODE.MAX_CODE)}
        })
        else pageDispatch({
                type: useProductAction.VALID_CODE_PRODUCT,
                payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_CODE.SPECIAL_CODE)}
            })
    }

    const onChangeNameProduct = (e) => {
        let {value} = e.target;
        pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
        pageDispatch({
            type: useProductAction.VALID_NAME_PRODUCT,
            payload: {status: false, message: ''}
        })
        pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: value})
    }
    const onBlurNameProduct = (e) => {
        const {value} = e.target;
        if (value == '') pageDispatch({
            type: useProductAction.VALID_NAME_PRODUCT,
            payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_NAME.EMPTY_NAME)}
        })
        else pageDispatch({type: useProductAction.GET_NAME_PRODUCT, payload: value.trim()})
    }

    const onChangeNote = value => {
        pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
        if(value.length > 255){
            pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: value})
            pageDispatch({
                type: useProductAction.VALID_NOTE,
                payload: {status: true, message: t(SCRIPT_NOTE_PRODUCT.VALID_NOTE.MAX_NOTE)}})
        }
        else{
            pageDispatch({type: useProductAction.GET_NOTE_PRODUCT, payload: value})
            pageDispatch({
                type: useProductAction.VALID_NOTE,
                payload: {status: false, message: ''}})
        }
    }
    const onBlurNoteProduct =(value)=>{

    }
    const onChangeStatus = () => {
        pageDispatch({type: useProductAction.CHANGE_MODAL, payload: true})
        if (status) {
            setStatus(!status)
            pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: -1})
        } else {
            setStatus(!status)
            pageDispatch({type: useProductAction.GET_STATUS_PRODUCT, payload: 1})
        }
    }

    const searchkeyWord = (data) => {
        const formatDataValue = data?.value
            ? StringUtils.removeAcent(data?.value?.toLowerCase())
            : ''
        const listData = pageState.arr_category?.filter(item => {
            const formatNameItem = item?.item
                ? StringUtils.removeAcent(item?.item.toLowerCase())
                : ''
            if (formatNameItem.includes(formatDataValue.trim())) return true
            return false
        })
        pageDispatch({type: useProductAction.CATEGORY_LIST, payload: listData})
        pageDispatch({type: useProductAction.KEY_WORD, payload: formatDataValue})
    }
    return {
        modal: {
            handleAccept,
            handleClose,
            checkBeforeSubmit,
        },
        confirm: {
            handleCancelConfirm,
            handleAcceptConfirm,
        },
        animate,
        onPickup,
        field_name: {
            onChangeNameProduct,
            onBlurNameProduct,
        },
        field_code: {
            onChangeCodeProduct,
            onBlurCodePrduct
        },
        onChangeNote,
        onBlurNoteProduct,
        status_product: {
            status,
            onChangeStatus,
        },
        getDetailProduct,
        search: {
            searchkeyWord,
        }
    }
}