import React, { createContext, memo, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { PATH } from '../../const/path'
import { Modal, Box } from '@mui/material'
import Popover from '@mui/material/Popover'

import css from './index.module.scss'
import cls from 'clsx'
import Tab from './Tab'
import { general } from '../Account/account'
import { getUrlLogOut, getUrlUserInfo } from '../../api/url'
import { getData } from '../../api/api'
import useGlobalContext from '../../containerContext/storeContext'
import { calculateDate, UposLogFunc } from '../../util/functionUtil'
import { TAB_USER_PROFILE } from '../Icons'
import { CustomToolTip } from '../tooltip/CustomTooltip'
import { Button } from '../../common/button'
import config from 'config'
import { Text } from 'common/text'
import { ConfirmModal } from 'layouts/rightSightPopup/confirm'

export default memo(function Index({ ...props }) {
    const [open, setOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [avatar] = useState('')
    const [toggle, setToggle] = useState(false)
    const [bolUpdate, setBolUpdate] = useState(false)
    const [aniModalClose, setAniModalClose] = useState(false)
    const [state, dispatch] = useGlobalContext()
    const [tab, setTab] = useState('user')
    const { user, userInfoPopup } = state
    const data = user

    const [fieldPass, setFieldPass] = useState(false)
    const [passDisabled, setPassDisabled] = useState(false)
    const nav = useNavigate()
    const handleClick = value => {
        setAnchorEl(null)
        switch (value) {
            case 'user_information': {
                nav('/profile')
                break
            }
            case 'change_password': {
                setOpen(true)
                setAniModalClose(false)
                setTab('password')
                break
            }
            case 'log_out': {
                const originOrderActive = JSON.parse(
                    window.localStorage.getItem('origin_order_active'),
                )
                window.localStorage.clear()
                if (!!originOrderActive && Array.isArray(originOrderActive))
                    window.localStorage.setItem(
                        'origin_order_active',
                        JSON.stringify(originOrderActive),
                    )
                const url = getUrlLogOut()
                getData(url)
                    .then(() => {
                        dispatch({ type: 'SET_LOGOUT' })
                        dispatch({ type: 'SET_USER', payload: {} })
                        localStorage.setItem(`fbssls_${config.REACT_APP_FACEBOOK_APP_ID}`)
                        sessionStorage.setItem(`fbssls_${config.REACT_APP_FACEBOOK_APP_ID}`)

                        return <Navigate to={PATH.LOGIN} />
                    })
                    .catch(() => {
                        console.log('error logout')
                    })
                break
            }
            default:
                break
        }
    }
    const [anchorEl, setAnchorEl] = useState(null)
    const handleShowProfile = e => setAnchorEl(e.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const openProfile = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const isUpdate = bol => (bol ? setBolUpdate(true) : setBolUpdate(false))

    useEffect(() => {
        window.addEventListener('click', function (e) {
            const isPopup = document
                .getElementById('btn_user_profile')
                ?.contains(e.target)
            const isPopupBtn = document
                .getElementById('btn_duration')
                ?.contains(e.target)
            if (!isPopup && !isPopupBtn) {
                setToggle(false)
            }
        })
        return function cleanupListener() {
            window.removeEventListener('click', () => { })
        }
    }, [])


    return (
        <>
            <div className={cls(css.wrapper)} style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <img
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleShowProfile}
                    className={cls(css.icon_avatar)}
                    src={
                        (state.user && state.user.avatar) ||
                        '/img/avatar_default.png'
                    }
                    style={{ borderRadius: '50%' }}
                    alt={'avatar'}
                />
                <Popover
                    sx={{ top: 14 }}
                    id={id}
                    open={openProfile}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={`${cls(css.account)}`}>
                        <div className={cls(css.group)} style={{display: 'flex', alignItems: 'center'}}>
                            {avatar ? (
                                <img src={avatar} alt={'avatar'} />
                            ) : (
                                <img src={
                                    (state.user && state.user.avatar) ||
                                    '/img/avatar_default.png'
                                } alt={'avatar'} style={{ borderRadius: '50%' }} />
                            )}
                            <div className={cls(css.inner)}>
                                <p className={cls(css.name)}>{data?.last_name} {data.name}</p>
                                <p className={cls(css.email)}>{data.username}</p>
                                {/*<CustomToolTip*/}
                                {/*    title={<p className={cls(css.email)}>{data.email}</p>}*/}
                                {/*    arrow*/}
                                {/*>*/}
                                {/*    <p className={cls(css.email)}>{data.email}</p>*/}
                                {/*</CustomToolTip>*/}
                            </div>
                        </div>
                        <hr style={{ marginTop: 0 }} />
                        <ul>
                            {general.map(item => {
                                return (
                                    <li
                                        key={item.class}
                                        className={cls(css[item.class])}
                                        onClick={() => handleClick(item.value)}
                                    >
                                        {item.label}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Popover>
                <Modal
                    open={open}
                    onClose={() => {
                        if (!bolUpdate) {
                            setAniModalClose(true)
                            setTimeout(() => {
                                setOpen(false)
                                setToggle(false)
                            }, 300)
                        } else {
                            setConfirm(true)
                        }
                    }}
                    clickClose = {() => setOpen(false)}
                    id={'modal_user_profile'}
                >
                    <Box
                        className={`${cls(css.box_modal)} ${aniModalClose && cls(css.modal_custom)
                            }`}
                    >
                        <div
                            className={cls(css.dismiss)}
                            onClick={() => {
                                if (!bolUpdate) {
                                    setAniModalClose(true)
                                    setTimeout(() => {
                                        setOpen(false)
                                    }, 300)
                                } else {
                                    setConfirm(true)
                                }
                            }}
                        >
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 1L1 13M1 1L13 13"
                                    stroke="#F4F7FC"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <div className={cls(css.general)}>
                            <Tab
                                isUpdate={isUpdate}
                                setOpen={setOpen}
                            />
                        </div>
                    </Box>
                </Modal>
                <ConfirmModal
                    openModal={confirm}
                    body={
                        <>
                            <Text fontSize={20} fontWeight={600}>
                                Xác nhận rời khỏi trang
                            </Text>
                            <Text
                                style={{ marginTop: 24 }}
                                as="p"
                                className="setting-management-modal_txt"
                            >
                                Một số thông tin đã thay đổi, bạn có chắc chắn muốn rời khỏi
                                trang khi thay đổi chưa được lưu?
                            </Text>
                        </>
                    }
                    stylePopup={'setting-management-modal_confirm'}
                    footer={{
                        cancel: {
                            width: 110,
                            title: 'Huỷ',
                        },
                        acceptance: {
                            width: 110,
                            title: 'Xác nhận',
                        },
                    }}
                    footerProps={{ className: 'setting-management-modal_dismiss' }}
                    closeModal={() => {
                        setConfirm(false)
                    }}
                    acceptance={() => {
                        setConfirm(false)
                        setAniModalClose(true)
                        setTimeout(() => {
                            setOpen(false)
                            setBolUpdate(false)
                        }, 300)
                    }}
                />
            </div>
        </>
    )
})
