import styled from 'styled-components'
import {Text} from "../../../../common/text";
import React from "react";
import {TAB_QR_AFFILIATE} from "../../interface";
import {useQrAffiliateTab} from "../../hook/useQrAffiliateTab";
import {QrCategoryTable} from "../section/qrCategory";
import {QrProductTable} from "../section/qrProduct";
import {QrEventTable} from "../section/qrEvent";

export const QrAffiliateContent = () => {

   const {tab} = useQrAffiliateTab()
   const {active, onChange} = tab

   return (
      <StyledCategory>

         <div className='qr-affiliate-tabs'>
            {
               TAB_QR_AFFILIATE?.map((map, i) => <div
                  className='qr-affiliate-tab'
                  data-active={map?.tab === active?.tab}
                  key={i} onClick={() => onChange(map)}>
                  <Text>{map?.name}</Text>
               </div>)
            }
         </div>
         <div className='qr-affiliate-content'>
            {
              active.tab === 'category' && <QrCategoryTable/>
            }
            {
              active.tab === 'product' && <QrProductTable/>
            }
            {
               active.tab === 'event' && <QrEventTable/>
            }
         </div>


      </StyledCategory>
   )
}

const StyledCategory = styled.div`

.qr-affiliate{
  &-content{
    margin-top: -1px;
    border-top: 1px solid #E2EAF8;
    border-right: 1px solid #E2EAF8;
    border-left: 1px solid #E2EAF8;
  }
   &-tabs{
        display: flex;
    }
  &-tab{
      height: 36px;
      padding: 10px 32px;
      border-radius: 8px 8px 0px 0px;
      background: #E2EAF8;
      cursor: pointer;
      margin-right: 4px;
      border-top: 1px solid #E2EAF8;
      border-right: 1px solid #E2EAF8;
      border-left: 1px solid #E2EAF8;
      
      &:first-child{
        border-radius: 0px 8px 0px 0px;
      }
      &[data-active = true]{
         background: #ffffff;
      }
     
  }
  &-tabs_content{
      padding-top: 24px;
      background: #ffffff;
      max-width: 1360px;
  }
  }

`