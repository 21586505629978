import useStudentFilterForm from 'Pages/student/hooks/useStudentFilterForm'
import { CategoryInput } from '../../../../common/form/input/_categoryInput'

export const StudentInfo = () => {
  const { studentInfo } = useStudentFilterForm()
  return (
    <CategoryInput
      className={'student-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{ name: 'Học sinh', value: '' }}
      categoryWidth={90}
      placeholder='Tên học sinh/Số điện thoại'
      value={studentInfo?.value || ''}
      onChange={studentInfo.onChange}
      style={{ position: 'relative' }}
    />
  )
}
