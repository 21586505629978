import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useReceiptFilterForm from 'Pages/receipt/hooks/useReceiptFilterForm'
import { RECEIPT_FILTER_TAG_FIELDS } from 'Pages/receipt/interfaces/_constants'
import { StyledReceiptTags } from './_styled'
import { ReceiptTag } from './_tag'

export const ReceiptTags = ({ ...props }) => {
  const {
    dateTime,
    receiptInfo,
    status,
    receiptType,
    monthTime,
    functions,
  } = useReceiptFilterForm()

  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledReceiptTags {...props}>
      {receiptInfo.activeValue && (
        <ReceiptTag
          onDelete={() => functions.filterTagDelete(RECEIPT_FILTER_TAG_FIELDS[0])}
        >
          Mã phiếu thu: {receiptInfo.activeValue}
        </ReceiptTag>
      )}

      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <ReceiptTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </ReceiptTag>
      )}
      {!!monthTime?.activeValue && (
        <ReceiptTag
          onDelete={() => functions.filterTagDelete(RECEIPT_FILTER_TAG_FIELDS[4])}
        >
          Kỳ thanh toán: {monthTime.activeValue}
        </ReceiptTag>
      )}
      {status?.activeValue?.name && (
        <ReceiptTag
          onDelete={() => functions.filterTagDelete(RECEIPT_FILTER_TAG_FIELDS[5])}
        >
          Trạng thái: {status.activeValue.name}
        </ReceiptTag>
      )}
      {receiptType?.activeValue?.name && (
        <ReceiptTag
          onDelete={() => functions.filterTagDelete(RECEIPT_FILTER_TAG_FIELDS[6])}
        >
          Hình thức thanh toán: {receiptType.activeValue.name}
        </ReceiptTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledReceiptTags>
  )
}
