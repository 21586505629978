import { useContext, useState } from 'react'
import {CreateTeacherContext} from '../provider/_context'
import { useCreateProductAction, useModalProductAction } from '../provider/_action'
import { sendRequestAuth } from '../../../../../api/api'
import config from '../../../../../config'
import { useParams } from 'react-router-dom'
import { removeAcent } from '../../../../../common/fieldText/_functions'
import useAlert from '../../../../../hook/useAlert'

export const useAddProductModal = () => {
  const { pageState, pageDispatch } = useContext(CreateTeacherContext)

  const { teacherId } = useParams()

  const { showAlert } = useAlert()
  const { productTab } = pageState
  const { modalProduct } = productTab
  const { filter } = modalProduct
  const listProduct = modalProduct.table.display?.list

  const searchActiveValue = filter.search.activeValue
  const searchValue = filter.search.value

  const handleSearchChange = value => {
    pageDispatch({
      type: useModalProductAction.FILTER_SEARCH_UPDATE,
      payload: value,
    })
  }

// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // CATEGORY FILTER
  // ===== ===== ===== ===== =====
  const categoryList = filter?.category?.list
  const categoryListOrigin = filter?.category?.listOrigin
  const categoryValue = filter?.category?.value
  const categoryActiveValue = filter?.category?.activeValue

  const handleCategoryChange = value => {
    pageDispatch({
      type: useModalProductAction.FILTER_CATEGORY_VALUE_UPDATE,
      payload: value
    })
  }

  const handleCategoryKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const categoryListData = categoryListOrigin.filter(item => {
      const formatNameItem = item?.title
        ? removeAcent(item?.title.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    pageDispatch({
      type: useModalProductAction.FILTER_CATEGORY_LIST_UPDATE,
      payload: categoryListData
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  const queries = {
    category_id: categoryValue?.id || '',
    keyword: searchValue || '',
    per_page: modalProduct?.table?.pagination?.amount || 200,
    start: (modalProduct?.table?.pagination?.active * modalProduct?.table?.pagination?.amount) || 0,
  }

  const applyOtherFilter = async () => {
    const qs = {
      ...queries,
      per_page: modalProduct?.table?.pagination?.amount,
      start: 0
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchProductByFilter(queryString)
  }

  const handleAmountChange = async n => {
    const qs = { ...queries, per_page: n, start: 0 }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchProductByFilter(queryString)

  }

  const handlePageChange = async page => {
    const amount = modalProduct?.table?.pagination?.amount || 200
    const qs = {
      ...queries,
      per_page: modalProduct?.table?.pagination?.amount,
      start: page * amount
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchProductByFilter(queryString)
  }

  const fetchProductByFilter = async (qs) => {
    pageDispatch({
      type: useModalProductAction.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/principal/teacher/filter-products/${teacherId}${qs}`)
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: useModalProductAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const tearchers = response[0]?.data
      pageDispatch({
        type: useModalProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: tearchers?.data,
          listDefault: tearchers?.data,
          loading: false
        }
      })
      pageDispatch({
        type: useModalProductAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(tearchers?.meta?.start / tearchers?.meta?.per_page),
          amount: tearchers?.meta?.per_page,
          total: Math.ceil(tearchers?.meta?.total / tearchers?.meta?.per_page),
          totalItems: tearchers?.meta?.total,
        }
      })
      pageDispatch({
        type: useModalProductAction.TABLE_SELECTED_LIST_UPDATE,
        payload: {selected: {list: []}},
      })
    }
  }

  const addProductToPrincipal = async (id) => {
    const response = await sendRequestAuth('post', `${config.API}/principal/teacher/add-products/${teacherId}`,
      {
        'product_ids': id
      }
    )
    if(!!response?.data?.success){
      showAlert({
        type: 'success',
        content: 'Đã thêm sản phẩm thành công'
      })
      // thêm sản phẩm thành công

      const newProducts = listProduct.map(item => {
        if(item.id === id){
          item.teacher_id = teacherId
        }
        return item
      })

      pageDispatch({
        type: useModalProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: newProducts,
          listOrigin: newProducts
        }
      })
    }else{
      showAlert({
        type: 'danger',
        content: response?.data?.message || 'Thêm sản phẩm thất bại'
      })
    }
  }
  const removeProductToPrincipal = async (id) => {
    const response = await sendRequestAuth('post', `${config.API}/principal/teacher/delete-products/${teacherId}`,
      {
        'product_ids': id
      }
    )
    if(!!response?.data?.success){
      showAlert({
        type: 'success',
        content: 'Đã xóa sản phẩm thành công'
      })
      // Xóa sản phẩm thành công
      const newProducts = listProduct.map(item => {
        if(item.id === id){
          item.teacher_id = null
        }
        return item
      })

      pageDispatch({
        type: useModalProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: newProducts,
          listOrigin: newProducts
        }
      })
    }else{
      showAlert({
        type: 'danger',
        content: response?.data?.message || 'Xóa sản phẩm thất bại'
      })
    }
  }


  // handel select product Table

  const {table} = modalProduct

  const displayList = table.display.list
  const selectedList = table.selected.list
  // CHECKBOX
  const shouldActiveCheckbox = selectedList.length > 0

  const isActive =
    displayList.length <= 0
      ? false
      : selectedList.length < displayList.length
      ? false
      : !!!displayList.find(
        item => !!!selectedList.find(find => find?.id === item?.id),
      )

  const handleCheckboxChange = () => {
    let newSelectedList = []
    if (isActive) {
      newSelectedList = selectedList.filter(
        item => !!!displayList.find(find => find?.id === item?.id),
      )
    } else {
      let addingList = []
      displayList.forEach(item => {
        if (!!!selectedList.find(find => find?.id === item?.id))
          addingList = [...addingList, item]
      })
      newSelectedList = [...selectedList, ...addingList]
    }
    pageDispatch({
      type: useModalProductAction.TABLE_SELECTED_LIST_UPDATE,
      payload: {selected: {list: newSelectedList}},
    })
  }


  // SELECTED ACTION DROPDOWN
  const [shouldOpenSelectedActionMenu, setShouldOpenSelectedActionMenu] =
    useState(false)


  const handleDeleteProduct = async () => {
    //dispatch show
    const response = await sendRequestAuth('post', `${config.API}/principal/teacher/delete-products/${teacherId}`,
      {
        'product_ids': selectedList.map(item => item.id).join(',')
      }
    )
    return response
  }

  const handleAddProduct = async () => {
    //dispatch show
    const response = await sendRequestAuth('post', `${config.API}/principal/teacher/add-products/${teacherId}`,
      {
        'product_ids': selectedList.map(item => item.id).join(',')
      }
    )
    return response
  }


  return {
    table: table,
    queries,
    search: {
      value: searchValue,
      activeValue: searchActiveValue,
      onChange: handleSearchChange,
    },
    categoryFilter: {
      value: categoryValue,
      activeValue: categoryActiveValue,
      list: categoryList,
      onChange: handleCategoryChange,
      onKeywordChange: handleCategoryKeywordChange
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    },

    methods: {
      applyOtherFilter,
      removeProductToPrincipal,
      addProductToPrincipal
    },


    checkbox: {
      checked: shouldActiveCheckbox,
      onClick: handleCheckboxChange,
    },
    selected: {
      actionMenu: {
        open: shouldOpenSelectedActionMenu,
        onToggle: setShouldOpenSelectedActionMenu,
        actions: [
          () => handleDeleteProduct(),
          () => handleAddProduct(),
        ],
      },
      list: selectedList,
    },
  }
}