import React from 'react';
import styled from "styled-components";
import {Text} from "../../common/text";
import config from "../../config";

const NotPermission = () => {
  return (
    <StyledNotPermission>
      <img src={'/img/no-connection/security.png'} alt={'img'} className={'not-licensed-img-primary'}/>
      <Text fontWeight={600} fontSize={24} as={'p'} className={'not-licensed-text not-licensed-text-first'}>Không có quyền truy cập vào tính năng này</Text>
      <Text fontSize={18} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-text-second'}>Tài khoản của bạn không được cấp quyền truy cập. Vui lòng thực hiện cấp quyền</Text>
      <Text fontSize={18} as={'p'} color={'#7C88A6'} className={'not-licensed-text'}>cho tài khoản để bắt đầu sử dụng tính năng nhé!</Text>
      <Text fontSize={16} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-hotline'}>Hãy liên hệ với Admin để được hỗ trợ</Text>
    </StyledNotPermission>
  )
}

export default NotPermission

export const StyledNotPermission = styled.div`
  height: 90vh;
  text-align: center;
  .not-licensed {
    &-img-primary {
      margin-top: 10vh;
      max-width: 40%;
    }
    &-text {
      text-align: center;
      width: 100% !important;
      &-second {
        margin-top: 24px;
      }
      &-phone {
        margin-right: 16px;
        & svg {
          vertical-align: middle;
        }
        & a {
          color: #3264FF;
        }
      }
    }
    &-hotline {
      margin: 32px 0 12px 0;
    }
  }
`
