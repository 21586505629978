import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { OrderDateTime } from './_orderDateTime'
import { OrderSearch } from './_orderSearch'
import { OrderPaymentMethod } from './_orderPaymentMethod'
import { PaymentStatus } from './_paymentStatus'
import { OrderStatus } from './_orderStatus'
import { StyledOrderFilterForm } from './_styled'
import { GLOBAL_ICONS } from 'interface/icon'
import { useHistoryStudentFilterForm } from '../../../../hooks/useHistoryStudentFilterForm'
import { ProductType } from './_productType'
import { ProductSearch } from './_productSearch'

export const OrderHistoryFilterForm = memo(({ data,...props }) => {
  const { canSubmitOtherFilter, functions } = useHistoryStudentFilterForm(data)

  return (
    <StyledOrderFilterForm {...props}>
      <div
        className="order-filter-form__group order-filter-form__collapse"
        data-collapse={true}
      >
        <OrderSearch/>
        <ProductSearch/>
        {/*<ProductType/>*/}
        <PaymentStatus/>
        <OrderPaymentMethod/>
        <OrderStatus/>
        <OrderDateTime/>
        <div className="order-filter-form__input-wide flex">
          <Button
            // appearance="secondary"
            size="md-"
            onClick={() => functions.applyStudentOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          {/*<Button*/}
          {/*  appearance="secondary"*/}
          {/*  size="md-"*/}
          {/*  onClick={functions.refresh}*/}
          {/*  className={'order-filter-form__btn-refresh'}*/}
          {/*>*/}
          {/*  {GLOBAL_ICONS.repeat}*/}
          {/*</Button>*/}
        </div>
      </div>
    </StyledOrderFilterForm>
  )
})
