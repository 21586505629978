import {useContext} from "react";
import { CreateTeacherContext} from "../provider/_context";
import {useCreateTeacherAction} from "../provider/_reducer";

export const useCreateTeacherTab = ()=>{
    const {pageState, pageDispatch} = useContext(CreateTeacherContext)
    const {tab} = pageState

    const handleChangeTab = (data) =>
        pageDispatch({type: useCreateTeacherAction.FORM_CREATE_TAB_CHANGE,payload: data})

    return{
        tab:{
            list: tab?.list,
            active: tab?.activeTab,
            onChange: handleChangeTab
        }
    }

}