import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import { PAYMENT_TYPE } from '../../interfaces/_constants'
import useReceiptFilterForm from '../../hooks/useReceiptFilterForm'

export const PaymentSchedule = () => {
  const { t } = useTranslation()
  const { monthTime } = useReceiptFilterForm()
  return (
    <AlternativeAutoComplete
      className="receipt-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: 'Kỳ thanh toán', value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 108,
        placeholder: t('Chọn kỳ thanh toán'),
        readOnly: true,
        value: t(monthTime?.value) || '',
        onIconClick: () => monthTime.onChange(null),
      }}
      hideSearchBar={true}
    >
      {monthTime?.list?.map(item => (
        <Option
          key={item}
          className="receipt-filter-form__option-text"
          data-active={item === monthTime?.value}
          onClick={() => monthTime.onChange(item)}
        >
          {t(item)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

