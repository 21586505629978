import useProfileUpdate from '../hooks/useProfileUpdate'
import { Grid } from '@mui/material'
import { Input } from '../../../common/form/input'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { fDateTimeCustom, fDateTimeDMY } from '../../../util/formatTime'

const InfoBasic = () => {
    const { t } = useTranslation()
    const { value, functions } = useProfileUpdate()
    const data = value?.userData;

    return (
        <StyledInfoBasic>
            <Grid container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={'Email'}
                            disabled={true}
                            value={data?.email}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={t('Username')}
                            disabled={true}
                            value={data?.username}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={'Họ'}
                            disabled={true}
                            value={data?.last_name}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={t('Tên')}
                            disabled={true}
                            value={data?.name}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={t('phone')}
                            disabled={true}
                            value={data?.phone}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={t('Số CMND/CCCD')}
                            disabled={true}
                            value={data?.identity_number}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={t('Ngày sinh')}
                            disabled={true}
                            value={data?.birthday ? fDateTimeDMY(data?.birthday) : ''}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input ">
                        <Input
                            label={t('Giới tính')}
                            disabled={true}
                            value={data?.gender == 'unknow' ? 'Khác' :  (data?.gender== 'female' ? 'Nữ' : 'Nam')}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="product-info-basic__form-input">
                        <Input
                            label={t('Địa chỉ')}
                            value={data?.address}
                            onChange={e => functions.onChangeAddress(e.target?.value)}
                            // onBlur={e => functions.onBlurLastName(e.target?.value)}
                            validateText={value?.formValidate?.address?.message}
                            validateType={value?.formValidate?.address?.status ? 'danger' : 'success'}
                            placeholder={t('Vui lòng nhập địa chỉ')}
                            maxLength={200}
                        />
                    </div>
                </Grid>
                { data?.user_type == 2 ? 
                    <>
                        <Grid xs={6} sm={6} md={6} lg={6} item>
                            <div className="product-info-basic__form-input">
                                <Input
                                    label={'Tên trường'}
                                    value={data?.school_name}
                                    onChange={e => functions.onChangeSchoolName(e.target?.value)}
                                    // onBlur={e => functions.onBlurName(e.target?.value)}
                                    validateText={value?.formValidate?.school_name?.message}
                                    validateType={value?.formValidate?.school_name?.status ? 'danger' : 'success'}
                                    placeholder={t('Vui lòng tên trường giảng dạy')}
                                    maxLength={200}
                                />
                            </div>
                        </Grid>
                    </>
                :   <>
                        <Grid xs={6} sm={6} md={6} lg={6} item>
                            <div className="product-info-basic__form-input">
                                <Input
                                    label={'Bộ môn'}
                                    value={data?.teaching_subject}
                                    onChange={e => functions.onChangeTeachingSubject(e.target?.value)}
                                    // onBlur={e => functions.onBlurName(e.target?.value)}
                                    validateText={value?.formValidate?.teaching_subject?.message}
                                    validateType={value?.formValidate?.teaching_subject?.status ? 'danger' : 'success'}
                                    placeholder={t('Vui lòng nhập bộ môn giảng dạy')}
                                    maxLength={200}
                                />
                            </div>
                        </Grid>
                    </>
                }
            </Grid>
        </StyledInfoBasic>
    )
}

export default InfoBasic

export const StyledInfoBasic = styled.div``