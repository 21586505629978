import styled from 'styled-components'
import { Text } from 'common/text'
import ReactApexChart from 'react-apexcharts'
import React, { useContext, useEffect } from 'react'
import { fNumber } from 'util/formatNumber'
import { Dashboard } from '../../provider/_context'
import { sendRequestAuth } from '../../../../../api/api'
import config from '../../../../../config'
import { useDashboardAction } from '../../provider/_reducer'
import { LoadingChart } from './loadingChart'

export const StatisticTeacher = () => {
  const { pageState, pageDispatch } = useContext(Dashboard)
  const { loadingTeacher } = pageState
  const { dateTime } = pageState
  const { user } = pageState
  const { title } = user
  const { principal } = user
  const { teacher } = user
  const chartTeacherOption = {

    series: [{
      name: 'Giáo viên',
      data: teacher
    }],
    options: {
      colors: ['#0ce50f'],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      grid: {
        borderColor: '#EBEEF5',
        strokeDashArray: 3,
      },
      legend: {
        show: false,
      },
      toolbar: {
        show: false,
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: title,
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      yaxis: {
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 200,
          style: {
            fontSize: '12px',
            fontWeight: 500,
            cssClass: 'apexcharts-yaxis-label',
            lineHeight: '140%',
            color: '#808089',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            if (+value === 0 || +value < 10000000) return fNumber(value)
          },
        },
      },
      tooltip: {
        custom: function ({ series, dataPointIndex, w }) {
          let html = '<div class="arrow_box">'
          if (series[0] && series[0].length > 0)
            html += '<div class="item-tooltip"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<circle cx="6" cy="6" r="4.5" stroke="#0ce50f" stroke-width="3"/>\n' +
              '</svg>\n<p >Giáo viên: &nbsp; </p>' +
              '<span>' + fNumber(series[0][dataPointIndex]) + '</span> </div>'
          return html
        }
      },
    },

  }

  useEffect(
    () => {
      const getChartTeacher = async () => {
        const response = await sendRequestAuth('get', `${config.API}/dashboard/principal/teachers?date_type=${dateTime.value.value}`)
        if (!!response?.data?.success) {
          pageDispatch({
            type: useDashboardAction.UPDATE_DASHBOARD,
            payload: {
              user: {
                title: response?.data?.data?.column,
                teacher: response?.data?.data?.value,
              }
            }
          })
          pageDispatch({
            type: useDashboardAction.IS_LOADING_TEACHER,
            payload: false
          })
        }
      }
      getChartTeacher()
    }
    , [dateTime.value])

  return (
    <StyledContent>
      <div className={'content-report'}>
        {
          !loadingTeacher ?
            <div className={'content-report__wrapper'}>
              <div className={'content-report__legend'}>
                <div className={'content-report__legend-item'}>
                  <Text as={'p'} className={'content-report__legend-icon'}></Text>
                  <Text>Giáo viên</Text>
                </div>
                <div className={'content-report__legend-item'}></div>
              </div>
              <ReactApexChart
                options={chartTeacherOption.options}
                series={chartTeacherOption.series}
                type="bar"
                height={411}/>
            </div>
            : <LoadingChart/>
        }
      </div>
    </StyledContent>
  )
}

const StyledContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  .mixed-chart_series{
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    &-legend-box{
      display: flex;
      align-items: center;
      div{
        margin-right: 44px;
      }
    }
  }
  .content-report{
    margin-bottom: 8px;
    
    &__legend{
      display: flex;
      align-items: center;
      justify-content: center;
      &-icon{
        background: #0ce50f;
        width: 40px!important;
        height: 16px!important;
        margin-right: 8px;
        border-radius: 4px;
      }
      &-nicon{
        background: #dc2121;
        width: 40px!important;
        height: 16px!important;
        margin-right: 8px;
        border-radius: 4px;
      }
      &-item{
        margin-right: 24px;
        display: flex;
        align-items: center;
      }
    }
    &__title{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__wrapper{
      width: 100%;
      display: block;
      //margin: 0 -24px;
      &-image{
        width: 100%;
      }
    }
    
  }
  .arrow_box{
    width: 200px!important;
  }
  .arrow_box_user{
    width: 200px!important;
  }
  .content-report__wrap{
    img{
      width: 100%;
      height: 411px;
    }
  }
  .content-report__wrapper{
    margin-bottom: -24px;
  }
`