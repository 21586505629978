import React, { useContext, useEffect } from 'react'
import { StyledDashboardPanels } from './_styled'
import { Dashboard } from '../../provider/_context'
import { DashboardPanel } from './_dashboardPanel'
import { sendRequestAuth } from '../../../../../api/api'
import config from '../../../../../config'
import { useDashboardAction } from '../../provider/_reducer'

export const DashboardPanels = ({ ...props }) => {
  const { pageState, pageDispatch } = useContext(Dashboard)
  const { panels } = pageState

  useEffect(
    () => {
      pageDispatch({
        type: useDashboardAction.IS_LOADING_PANEL,
        payload: true
      })
      const getOverview = async () => {
        const response = await sendRequestAuth('get', `${config.API}/dashboard/principal/overview`)
        if (!!response?.data?.success) {
          pageDispatch({
            type: useDashboardAction.UPDATE_DASHBOARD,
            payload: {
              panels: response?.data?.data
            }
          })

          pageDispatch({
            type: useDashboardAction.IS_LOADING_PANEL,
            payload: false
          })
        }

      }
      getOverview()
    }
    , [])
  return (
    <StyledDashboardPanels {...props}>
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng doanh thu"
        value={panels?.total_revenue || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng hoa hồng"
        value={panels?.total_commission || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng hoa hồng đã nhận"
        value={panels?.commission_received || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng hoa hồng chưa nhận"
        value={panels?.commission_unreceived || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency=""
        title="Tổng giáo viên"
        value={panels?.total_teacher || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng hoa hồng phải trả giáo viên"
        value={panels?.teacher_commission || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng hoa hồng đã thanh toán"
        value={panels?.teacher_commission_paid || 0}
      />
      <DashboardPanel
        className="order-panels__item"
        currency="đ"
        title="Tổng hoa hồng chưa thanh toán"
        value={panels?.teacher_commission_unpaid || 0}
      />
    </StyledDashboardPanels>
  )
}
