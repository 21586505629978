import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { sendRequestAuth } from "../../../api/api";
import config from "../../../config";
import { UserManagementContext } from "../provider/_context";
import { userManagementActions } from "../provider/_reducer";
import { PATH } from "../../../const/path";
import { replaceVN } from "../../../util/checkPasswordVN";
import { useTranslation } from 'react-i18next'
import { DISPLAY_NAME_MENU } from '../../../const/display_name_menu'
import useAlert from "../../../hook/useAlert";

const useCreateUserManagement = () => {
    const { t } = useTranslation()
    const { showAlert } = useAlert()
    const { userId } = useParams()
    const { pageState, pageDispatch } = useContext(UserManagementContext)

    const name = pageState?.form?.userInfo?.name?.value
    const last_name = pageState?.form?.userInfo?.last_name?.value
    const phone = pageState?.form?.userInfo?.phone?.value
    const email = pageState?.form?.userInfo?.email?.value
    const staff_id = pageState?.form?.userInfo?.staff_id?.value

    const status = pageState?.form?.userInfo?.status
    const group = pageState?.form?.userInfo?.group
    const department = pageState?.form?.userInfo?.department

    const username = pageState?.form?.accountInfo?.username?.value
    const password = pageState?.form?.accountInfo?.password?.value
    const confirmPassword = pageState?.form?.accountInfo?.confirmPassword?.value

    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const [typePassword, setTypePassword] = useState('password')
    const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')
    const navigate = useNavigate();

    // useEffect(() => {
    //     handleUserManagement()
    // }, [])

    const handleChangeName = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { name: { value: value } }
        })
        if (!!value.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    name: { status: false, message: '' }
                }
            })
        }
    }
    const handleChangeLastName = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { last_name: { value: value } }
        })
        if (!!value.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    last_name: { status: false, message: '' }
                }
            })
        }
    }
    const handleChangePhone = value => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            pageDispatch({
                type: userManagementActions.FORM_UPDATE_USER_INFO,
                payload: { phone: { value: value.trim() } }
            })
            if (!!value.trim()) {
                pageDispatch({
                    type: userManagementActions.VALID_FORM_USER,
                    payload: {
                        phone: { status: false, message: '' }
                    }
                })
            }
        }
    }
    const handleChangeEmail = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { email: { value: value.trim() } }
        })
        if (!!value.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    email: { status: false, message: '' }
                }
            })
        }
    }
    const handleChangeStaffId = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { staff_id: { value: value } }
        })
        if (!!value.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    staff_id: { status: false, message: '' }
                }
            })
        }
    }
    const handleSelectedStatus = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { status: value }
        })
        if (!!value) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    status: { status: false, message: '' }
                }
            })
        }
    }
    const handleSelectedGroup = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { group: value }
        })
        if (!!value) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    group: { status: false, message: '' }
                }
            })
        }
    }
    const handleSelectedDepartment = value => {
        pageDispatch({
            type: userManagementActions.FORM_UPDATE_USER_INFO,
            payload: { department: value }
        })
    }

    // const handleUserManagement = async () => {
    //     const response = await Promise.all([
    //         sendRequestAuth('get', `${config.API}/user/groups?keyword=`),
    //     ])
    //     if (response[0]?.data?.success && !!response[0]?.data?.data) {
    //         setGroupEmployee(response[0]?.data?.data?.data)
    //     }
    // }
    const handleChangeUserName = value => {
        const userNameFormat = value.replace(/[^\w\s]/gi, '').replaceAll(' ', '')
        pageDispatch({
            type: userManagementActions.FORM_USER_NAME_UPDATE,
            payload: { value: userNameFormat.trim() }
        })
        if (!!userNameFormat.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_USER_NAME,
                payload: { status: false, message: '' }
            })
        }
    }
    const handleChangePassword = value => {
        const passwordFormat = value.replace(replaceVN, '').replaceAll(' ', '')
        pageDispatch({
            type: userManagementActions.FORM_PASSWORD_UPDATE,
            payload: { value: passwordFormat.trim() }
        })
        if (!!passwordFormat.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_PASSWORD,
                payload: { status: false, message: '' }
            })
        }
    }
    const handleChangeConfirmPassword = value => {
        const confirmPasswordFormat = value.replace(replaceVN, '').replaceAll(' ', '')
        pageDispatch({
            type: userManagementActions.FORM_CONFIRM_PASSWORD_UPDATE,
            payload: { value: confirmPasswordFormat.trim() }
        })
        if (!!confirmPasswordFormat.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_CONFIRM_PASSWORD,
                payload: { status: false, message: '' }
            })
        }
    }
    const handleChangeTypePassword = () => setTypePassword(typePassword === 'password' ? 'text' : 'password')
    const handleChangeTypeConfirmPassword = () => setTypeConfirmPassword(typeConfirmPassword === 'password' ? 'text' : 'password')

    // VALIDATE
    const handleBlurName = () => {
        if (!!!name.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    name: { status: true, message: t('Hãy nhập tên của nhân viên') }
                }
            })
        } else {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    name: { status: false, message: '' }
                }
            })
        }
    }
    const handleBlurLastName = () => {
        if (!!!last_name.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    last_name: { status: true, message: t('Hãy nhập họ của nhân viên') }
                }
            })
        } else {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    last_name: { status: false, message: '' }
                }
            })
        }
    }
    const handleBlurPhone = () => {
        if (phone !== '' && (phone.length < 10 || phone.length > 11)) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    phone: { status: true, message: t('Số điện thoại không hợp lệ') }
                }
            })
        } else {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    phone: { status: false, message: '' }
                }
            })
        }
    }
    const handleBlurEmail = () => {
        if (!!!email) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    email: { status: true, message: t('Hãy nhập Email của nhân viên') }
                }
            })
        } else if (!!!email.toLowerCase().match(regexEmail) && !!email) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    email: { status: true, message: t(DISPLAY_NAME_MENU.USER_PAGE.EMAIL_FORMAT) }
                }
            })
        } else {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    email: { status: false, message: '' }
                }
            })
        }
    }
    const handleBlurStaffId = () => {
        if (!!!staff_id.trim()) {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    staff_id: { status: true, message: t('Hãy nhập mã số nhân viên') }
                }
            })
        } else {
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    staff_id: { status: false, message: '' }
                }
            })
        }
    }
    const handleBlurUserName = () => {
        if (!!!username) {
            pageDispatch({ type: userManagementActions.VALID_USER_NAME, payload: { status: true, message: t(DISPLAY_NAME_MENU.USER_PAGE.NAME_EMPTY) } })
        } else {
            pageDispatch({ type: userManagementActions.VALID_USER_NAME, payload: { status: false, message: '' } })
        }
    }
    const handleBlurPassword = value => {
        if (!!!password) {
            pageDispatch({
                type: userManagementActions.VALID_PASSWORD,
                payload: { status: true, message: t(DISPLAY_NAME_MENU.USER_PAGE.PASS_EMPTY) }
            })
            return
        }
        if (value.trim().length < 8) {
            pageDispatch({
                type: userManagementActions.VALID_PASSWORD,
                payload: { status: true, message: t('Mật khẩu tối thiểu phải có 8 ký tự') }
            })
        }
        if (password.length > 32) {
            pageDispatch({
                type: userManagementActions.VALID_PASSWORD,
                payload: { status: true, message: t('Mật khẩu chỉ được phép tối đa 32 ký tự') }
            })
        }
        if (value.trim() === confirmPassword && confirmPassword.length <= 32) {
            pageDispatch({
                type: userManagementActions.VALID_CONFIRM_PASSWORD,
                payload: { status: false, message: '' }
            })
        }
        if (value.trim() !== confirmPassword) {
            pageDispatch({
                type: userManagementActions.VALID_CONFIRM_PASSWORD,
                payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.INVALID.NOT_MATCH_PASSWORD) }
            })
        }
    }
    const handleBlurConfirmPassword = value => {
        if (!!!confirmPassword) {
            pageDispatch({
                type: userManagementActions.VALID_CONFIRM_PASSWORD,
                payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.CONFIRM_PASS) }
            })
            return
        }
        if (value.trim() !== password) {
            pageDispatch({
                type: userManagementActions.VALID_CONFIRM_PASSWORD,
                payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.INVALID.NOT_MATCH_PASSWORD) }
            })
        }
    }

    const validateBeforeSubmit = () => {
        let isValid = true;
        if (!!!name) {
            isValid = false
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    name: { status: true, message: t('Hãy nhập tên của nhân viên') }
                }
            })
        }
        if (!!!last_name) {
            isValid = false
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    last_name: { status: true, message: t('Hãy nhập họ của nhân viên') }
                }
            })
        }
        if (!!phone && (phone.length < 10 || phone.length > 11)) {
            isValid = false
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    phone: { status: true, message: t('Số điện thoại không hợp lệ') }
                }
            })
        }
        if (!!!email) {
            isValid = false
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    email: { status: true, message: t('Hãy nhập Email của nhân viên') }
                }
            })
        }
        if (!!!status?.id) {
            isValid = false
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    status: { status: true, message: 'Hãy chọn trạng thái cho tài khoản' }
                }
            })
        }
        if (!!!group?.id) {
            isValid = false
            pageDispatch({
                type: userManagementActions.VALID_FORM_USER,
                payload: {
                    group: { status: true, message: 'Hãy chọn vai trò nhân viên' }
                }
            })
        }
        if(!!!userId){
            if (!!!username) {
                isValid = false
                pageDispatch({
                    type: userManagementActions.VALID_USER_NAME,
                    payload: { status: true, message: t(DISPLAY_NAME_MENU.USER_PAGE.NAME_EMPTY) }
                })
            }
            if (!!!password) {
                isValid = false
                pageDispatch({
                    type: userManagementActions.VALID_PASSWORD,
                    payload: { status: true, message: t(DISPLAY_NAME_MENU.USER_PAGE.PASS_EMPTY) }
                })
            }
            if (!!!confirmPassword) {
                isValid = false
                pageDispatch({
                    type: userManagementActions.VALID_CONFIRM_PASSWORD,
                    payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.EMPTY.CONFIRM_PASS) }
                })
            }
            if (password !== confirmPassword) {
                isValid = false
                pageDispatch({
                    type: userManagementActions.VALID_CONFIRM_PASSWORD,
                    payload: { status: true, message: t(DISPLAY_NAME_MENU.VALIDATE.INVALID.NOT_MATCH_PASSWORD) }
                })
            }
        }
        return isValid
    }
    const handleSubmit = async () => {
        if (validateBeforeSubmit()) {
            if(!!!userId){
                const data = {
                    "name": pageState?.form?.userInfo?.name?.value?.trim() || '',
                    "last_name": pageState?.form?.userInfo?.last_name?.value?.trim() || '',
                    "phone": pageState?.form?.userInfo?.phone?.value?.trim() || '',
                    "email": pageState?.form?.userInfo?.email?.value?.trim() || '',
                    "staff_id": pageState?.form?.userInfo?.staff_id?.value?.trim() || '',
                    "department": pageState?.form?.userInfo?.department?.id || '',
                    "group": pageState?.form?.userInfo?.group?.id || '',
                    "status": pageState?.form?.userInfo?.status?.id || '',
                    "username": pageState?.form?.accountInfo?.username?.value?.trim() || '',
                    "password": pageState?.form?.accountInfo?.password?.value?.trim() || '',
                }
                const url = `${config.API}/user/create`
                const response = await sendRequestAuth('post', url, JSON.stringify({ ...data }),
                ).catch(() => showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR) }))
                if (response?.data?.success) {
                    showAlert({ type: 'success', content: response?.data?.message })
                    navigate(PATH.USER_MANAGEMENT)
                } else {
                    errorResponse(response?.data?.errors)
                    setTimeout(() => {
                        showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT) })
                    }, 100)
                }
            }else{
                const data = {
                    "name": pageState?.form?.userInfo?.name?.value?.trim() || '',
                    "last_name": pageState?.form?.userInfo?.last_name?.value?.trim() || '',
                    "phone": pageState?.form?.userInfo?.phone?.value?.trim() || '',
                    "email": pageState?.form?.userInfo?.email?.value?.trim() || '',
                    "staff_id": pageState?.form?.userInfo?.staff_id?.value?.trim() || '',
                    "department": pageState?.form?.userInfo?.department?.id || '',
                    "group": pageState?.form?.userInfo?.group?.id || '',
                    "status": pageState?.form?.userInfo?.status?.id || '',
                }
                const url = `${config.API}/user/update/${userId}`
                const response = await sendRequestAuth('post', url, JSON.stringify({ ...data }),
                ).catch(() => showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.API_ERROR) }))
                if (response?.data?.success) {
                    showAlert({ type: 'success', content: response?.data?.message })
                    navigate(PATH.USER_MANAGEMENT)
                } else {
                    errorResponse(response?.data?.errors)
                    setTimeout(() => {
                        showAlert({ type: 'danger', content: t(DISPLAY_NAME_MENU.GENERAL.CHECK_INFO_BEFORE_SUBMIT) })
                    }, 100)
                }
            }
        }
    }
    const errorResponse = response => {
        let errors = [];
        response?.map(res => {
            errors[res.field] = { status: true, message: res.message};
        })

        pageDispatch({
            type: userManagementActions.VALID_FORM_USER,
            payload: {...errors}
        })
    }

    const canSubmit = [
        pageState?.validate?.name?.status,
        pageState?.validate?.last_name?.status,
        pageState?.validate?.email?.status,
        pageState?.validate?.staff_id?.status,
        pageState?.validate?.username?.status,
        pageState?.validate?.password?.status,
        pageState?.validate?.confirmPassword?.status,
    ].includes(true)

    const canSubmitUpdate = [
        pageState?.validate?.name?.status,
        pageState?.validate?.last_name?.status,
        pageState?.validate?.email?.status,
        pageState?.validate?.staff_id?.status
    ].includes(true)

    return {
        valueForm: {
            name: name,
            last_name: last_name,
            status: status,
            phone: phone,
            email: email,
            staff_id: staff_id,
            group: group,
            department: department,
            username: username,
            password: password,
            confirmPassword: confirmPassword
        },
        validateForm: pageState?.validate,
        typeInput: {
            password: typePassword,
            confirmPassword: typeConfirmPassword,
        },
        validate: {
            errorUserName: pageState?.validate?.username,
            onBlurUserName: handleBlurUserName,
            errorPassword: pageState?.validate?.password,
            onBlurPassword: handleBlurPassword,
            errorConfirmPassword: pageState?.validate?.confirmPassword,
            onBlurConfirmPassword: handleBlurConfirmPassword,
            errorCheckRules: pageState?.validate?.checkRule,
        },
        functions: {
            onSelectedStatus: handleSelectedStatus,
            onSelectedGroup: handleSelectedGroup,
            onSelectedDepartment: handleSelectedDepartment,
            onChangeName: handleChangeName,
            onBlurName: handleBlurName,
            onChangeLastName: handleChangeLastName,
            onBlurLastName: handleBlurLastName,
            onChangePhone: handleChangePhone,
            onBlurPhone: handleBlurPhone,
            onBlurEmail: handleBlurEmail,
            onChangeEmail: handleChangeEmail,
            onBlurStaffId: handleBlurStaffId,
            onChangeStaffId: handleChangeStaffId,
            onChangeUserName: handleChangeUserName,
            onChangePassword: handleChangePassword,
            onChangeTypePassword: handleChangeTypePassword,
            onChangeTypeConfirmPassword: handleChangeTypeConfirmPassword,
            onChangeConfirmPassword: handleChangeConfirmPassword,
            submit: handleSubmit
        },
        canSubmit,
        canSubmitUpdate
    }
}

export default useCreateUserManagement;