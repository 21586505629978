import {useReducer} from 'react'
import {useQrAffiliateReducer} from '../provider/_reducer'
import {useQrAffiliateInitialState} from '../provider/_initState'
import {useQrCategoryAction, useQrEventAction, useQrProductAction} from '../provider/_action'
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";

const useQrAffiliate = () => {
  const [state, dispatch] = useReducer(useQrAffiliateReducer, useQrAffiliateInitialState)

  const handleOriginFetch = async () => {
    // xử lý API hiệu trưởng

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/qrcode/categories?parent_id=&start=0&per_page=20`),
      sendRequestAuth('get', `${config.API}/product-category/group-categories?type=digital&is_parent=0`),
      sendRequestAuth('get', `${config.API}/qrcode/events?event_id=&start=0&per_page=20`),
    ])

    if (response[0].data.success) {
      dispatch({
        type: useQrCategoryAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const qrCategories = response[0]?.data
      dispatch({
        type: useQrCategoryAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: qrCategories?.data,
          listDefault: qrCategories?.data,
          loading: false
        }
      })
      dispatch({
        type: useQrCategoryAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(qrCategories?.meta?.start / qrCategories?.meta?.per_page),
          amount: qrCategories?.meta?.per_page,
          total: Math.ceil(qrCategories?.meta?.total / qrCategories?.meta?.per_page),
          totalItems: qrCategories?.meta?.total,
        }
      })
    }
    if (response[1]?.data?.length > 0) {
      dispatch({
        type: useQrCategoryAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE,
        payload: {
          list: response[1]?.data,
          listOrigin: response[1]?.data
        }
      })
    }

    if (response[2].data.success) {
      dispatch({
        type: useQrEventAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const qrEvent = response[2]?.data
      dispatch({
        type: useQrEventAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: qrEvent?.data,
          listDefault: qrEvent?.data,
          loading: false
        }
      })
      dispatch({
        type: useQrEventAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(qrEvent?.meta?.start / qrEvent?.meta?.per_page),
          amount: qrEvent?.meta?.per_page,
          total: Math.ceil(qrEvent?.meta?.total / qrEvent?.meta?.per_page),
          totalItems: qrEvent?.meta?.total,
        }
      })

      dispatch({
        type: useQrEventAction.FILTER_EVENT_LIST_ORIGIN_UPDATE,
        payload: {
          list: qrEvent?.data,
          listOrigin: qrEvent?.data,
        }
      })
    }
    const res = await sendRequestAuth('get', `${config.API}/qrcode/products?category_id=&start=0&per_page=20`)
    if (!!res?.data?.success) {
      dispatch({
        type: useQrProductAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const qrProduct = res?.data
      dispatch({
        type: useQrProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: qrProduct?.data,
          listDefault: qrProduct?.data,
          loading: false
        }
      })
      dispatch({
        type: useQrProductAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(qrProduct?.meta?.start / qrProduct?.meta?.per_page),
          amount: qrProduct?.meta?.per_page,
          total: Math.ceil(qrProduct?.meta?.total / qrProduct?.meta?.per_page),
          totalItems: qrProduct?.meta?.total,
        }
      })
    }
  }

  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    },
  }
}
export default useQrAffiliate