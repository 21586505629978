import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { Option } from 'common/form/autoComplete/_option'
import { RECEIPT_STATUS } from '../../interfaces/_constants'
import useReceiptFilterForm from '../../hooks/useReceiptFilterForm'

export const ReceiptStatus = () => {
  const { t } = useTranslation()
  const { status } = useReceiptFilterForm()
  return (
    <AlternativeAutoComplete
      className="receipt-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: { name: t('general_status'), value: '' }, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 82,
        placeholder: t('general_select_status'),
        readOnly: true,
        value: t(status?.value?.name) || '',
        onIconClick: () => status.onChange(null),
      }}
      hideSearchBar={true}
    >
      {RECEIPT_STATUS?.map(item => (
        <Option
          key={item.value}
          className="receipt-filter-form__option-text"
          data-active={item.value === status?.value?.value}
          onClick={() => status.onChange(item)}
        >
          {t(item.name)}
        </Option>
      ))}
    </AlternativeAutoComplete>
  )
}

