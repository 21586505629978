import React from 'react'
import { useContext } from 'react'
import { StyledProductGroupContentModal } from './_styled'
import { AlternativeAutoComplete } from 'common/form/autoComplete/_alternativeAutoComplete'
import { CustomerContext } from '../../provider/_context'
import { Text } from '../../../../common/text'
import { Option } from '../../../../common/form/autoComplete/_option'
import { useMoveCustomerModal } from '../../hooks/useMoveCustomerModal'
import { THEME_SEMANTICS } from '../../../../common/theme/_semantics'

const Index = ({ ...props }) => {

  const { pageState } = useContext(CustomerContext)
  const { dataCurrent } = pageState.moveCustomer
  const { principal, teacher } = useMoveCustomerModal()
  return (
    <StyledProductGroupContentModal>
      <div className={'product-group-content'}>
        <div className={'product-group-content__top'}>
          <div className='product-group-content_group'>
            <Text color={'#2150B7'} fontSize={18} fontWeight={700} as={'i'}>Giáo viên giới thiệu hiện tại</Text>
            <Text as={'p'} style={{ marginBottom: 16, marginTop: 24 }}>Hiệu trưởng: <Text
              fontWeight={600}>{dataCurrent?.principal_name}</Text></Text>
            <Text as={'p'} style={{ marginBottom: 16 }}>Giáo viên: <Text
              fontWeight={600}>{dataCurrent?.teacher_name}</Text></Text>
          </div>
        </div>
        <div className={'product-group-content__bottom'}>
          <div className='product-group-content_group'>
            <Text color={'#2150B7'} fontSize={18} fontWeight={700} as={'i'}>Chuyển đến giáo viên</Text>
          </div>
          <div className='product-group-content_group'>
            <AlternativeAutoComplete
              className="customer-filter-form__input-wide"
              // main input
              inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryValue: { name: '', value: '' }, // if not exist this value -> default category: categoryList[0]
                placeholder: 'Chọn hiệu trưởng',
                categoryHidden: true,
                readOnly: true,
                label: (
                  <>
                    Hiệu trưởng <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </>
                ),
                value: principal.value?.name || '',
              }}
              // menu
              menuProps={{
                empty:
                  principal.list.length <= 0
                    ? 'Không tìm thấy hiệu trưởng'
                    : '',
              }}
              // search input in dropdown menu
              searchInputProps={{
                placeholder: 'Tìm kiếm hiệu trưởng',
                value: principal.keyword,
                onChange: principal.onKeywordChange,
              }}
              validateText={pageState.moveCustomer.validate.principal}
              validateType={!pageState.moveCustomer.validate.principal ? 'success' : 'danger'}
            >
              {principal.list.length > 0 &&
              principal.list.map(item => (
                <Option
                  key={item.value}
                  className="customer-filter-form__option-text"
                  data-active={item.value === principal.value?.value}
                  onClick={() => principal.onChange(item)}
                >
                  {item.name}
                </Option>
              ))}
            </AlternativeAutoComplete>
          </div>
          <div className='product-group-content_group'>
            <AlternativeAutoComplete
              className="customer-filter-form__input-wide"
              // main input
              inputProps={{
                categoryList: [], // menu list in category dropdown
                categoryValue: { name: 'Giáo viên', value: '' }, // if not exist this value -> default category: categoryList[0]
                categoryWidth: 82,
                categoryHidden: true,
                placeholder: 'Chọn giáo viên', label: (
                  <>
                    Giáo viên <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </>
                ),
                readOnly: true,
                value: teacher.value?.name || '',
              }}
              // menu
              menuProps={{
                empty:
                  teacher.listOrigin.length <= 0
                    ? 'Vui lòng chọn hiệu trưởng'
                    : teacher.list.length <= 0 ? 'Không tìm thấy giáo viên' : '',
              }}
              // search input in dropdown menu
              searchInputProps={{
                placeholder: 'Tìm kiếm giáo viên',
                value: teacher.keyword,
                onChange: teacher.onKeywordChange,
              }}
              validateText={pageState.moveCustomer.validate.teacher}
              validateType={!pageState.moveCustomer.validate.teacher ? 'success' : 'danger'}
            >
              {teacher.list.length > 0 &&
              teacher.list.map(item => (
                <Option
                  key={item.value}
                  className="customer-filter-form__option-text"
                  data-active={item.value === teacher.value?.value}
                  onClick={() => teacher.onChange(item)}
                >
                  {item.name}
                </Option>
              ))}
            </AlternativeAutoComplete>
          </div>
        </div>
      </div>

    </StyledProductGroupContentModal>

  )
}
export default Index