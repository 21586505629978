import { useReducer } from 'react'
import {  sendRequestAuth } from 'api/api'
import { useTeacherAction, useTeacherInitialState, useTeacherReducer } from '../provider/_reducer'
import { useEffect } from 'react'
import config from "../../../config";
const useTeacher = () => {
    const [state, dispatch] = useReducer(useTeacherReducer, useTeacherInitialState)

    const handleOriginFetch = async () => {
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/teacher/teachers?keyword=&status=&start=0&per_page=20`),
        ])
        if(response[0]?.data?.success) {
            const books = response[0]?.data
            dispatch({
                type: useTeacherAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            dispatch({
                type: useTeacherAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: 0,
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }

    }


    const querySearch = {
        per_page: state?.table?.pagination?.amount || 20,
        start: (state?.table?.pagination?.active * state?.table?.pagination?.amount) || 0,
        keyword: state?.filter?.search?.value || '',
        status: state?.filter?.status?.value?.value || ''
    }

    const handleAmountChange = async n => {
        const qs = {...querySearch, per_page: n, start: 0}
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        fetchTeacherByFilter(queryString)

        dispatch({
            type: useTeacherAction.TABLE_SELECTED_LIST_UPDATE,
            payload: {selected: {list: []}},
        })
    }

    const handlePageChange = async page => {
        const amount = state?.table?.pagination?.amount || 20
        const qs = {
            ...querySearch,
            per_page: state?.table?.pagination?.amount,
            start: page * amount
        }
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        fetchTeacherByFilter(queryString)
    }

    const fetchTeacherByFilter = async (qs) => {
        dispatch({
            type: useTeacherAction.TABLE_LOADING_DISPLAY,
            payload: true
        })
        const response = await Promise.all([
            sendRequestAuth('get', `${config.API}/principal/teacher/teachers${qs}`)
        ])
        if (response[0]?.data?.success) {
            dispatch({
                type: useTeacherAction.TABLE_LOADING_DISPLAY,
                payload: false
            })
            const books = response[0]?.data
            dispatch({
                type: useTeacherAction.TABLE_UPDATE_DISPLAY_LIST,
                payload: {
                    list: books?.data,
                    listDefault: books?.data,
                    loading: false
                }
            })
            dispatch({
                type: useTeacherAction.TABLE_UPDATE_PAGINATION,
                payload: {
                    active: Math.ceil(books?.meta?.start / books?.meta?.per_page),
                    amount: books?.meta?.per_page,
                    total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
                    totalItems: books?.meta?.total,
                }
            })
        }
    }
    return {
        fetch: {
            origin: handleOriginFetch
        },
        provider: {
            state,
            dispatch
        },
        pagination: {
            onAmountChange: handleAmountChange,
            onPageChange: handlePageChange,
        }
    }
}
export default useTeacher