import { DISPLAY_NAME_MENU } from "../../../const/display_name_menu";

export const SCRIPT = {
    BREADCRUMB: {
        TITLE: DISPLAY_NAME_MENU.CONFIG_AND_SETTING_PAGE.HEADER,
        USER_ROLE: DISPLAY_NAME_MENU.USER_PAGE.USER_ROLE,
        ROOT: DISPLAY_NAME_MENU.GENERAL.USER_MANAGEMENT
    },
    BUTTON: {
        CREATE: DISPLAY_NAME_MENU.USER_PAGE.CREATE
    },
    INPUT: {
        PLACEHOLDER_SEARCH: DISPLAY_NAME_MENU.USER_PAGE.SEARCH
    },
    SELECT: {
        ROLE: {
            TITLE: DISPLAY_NAME_MENU.USER_PAGE.ROLE,
            PLACEHOLDER: DISPLAY_NAME_MENU.USER_PAGE.SELECT_ROLE,
            EMPTY: DISPLAY_NAME_MENU.USER_PAGE.EMPTY_ROLE,
            SEARCH: DISPLAY_NAME_MENU.USER_PAGE.SEARCH_ROLE,
        },
        STATUS: {
            TITLE: DISPLAY_NAME_MENU.USER_PAGE.STATUS,
            PLACEHOLDER: DISPLAY_NAME_MENU.GENERAL.SELECT_STATUS,
            OPTION_ACTIVE: DISPLAY_NAME_MENU.GENERAL.ACTIVATED,
            OPTION_INACTIVE: DISPLAY_NAME_MENU.USER_PAGE.INACTIVE,
        }
    },
    ERROR: {
        SEARCH: DISPLAY_NAME_MENU.USER_PAGE.SEARCH_FAILED,
    },
    PANELS: {
        STATUS: DISPLAY_NAME_MENU.USER_PAGE.STATUS,
        GROUP: DISPLAY_NAME_MENU.USER_PAGE.ROLE,
    },
    NOTIFICATION: {
        USER_ACTIVE: DISPLAY_NAME_MENU.USER_PAGE.ACTIVE_SUCCESS,
        USER_DISACTIVE: DISPLAY_NAME_MENU.USER_PAGE.ACTIVE_FAIL,
    }
}