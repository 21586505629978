import { TableLayout } from 'layouts/tableLayout'
import { useContext, useEffect, useRef, useState } from 'react'
import { CommissionFilterForm } from './components/commissionFilterForm'
import { CommissionTBody } from './components/commissionTable/_commissionTBody'
import { CommissionTHead } from './components/commissionTable/_commissionTHead'
import {
  COMMISSION_BREADCRUMB,
} from './interfaces/_constants'
import { CommissionProvider } from './provider'
import './components/commissionTable/index.scss'
import useCommission from './hooks/useCommission'
import { PageHeader } from './components/commissionPageHeader'
import { GLOBAL_ICONS } from '../../../interface/icon'
import { CommissionPanels } from './components/commissionPanels'
import { PageOption } from './components/pageOption'
import useAlert from '../../../hook/useAlert'
import useCommissionFilterForm from './hooks/useCommissionFilterForm'
import { ModalExport } from '../../../common/modalExport'

export const CommissionReport = () => {
  const { fetch, pagination, provider } = useCommission()

  const { state, dispatch } = provider
  const { table } = state

  useEffect(() => {
    fetch.origin()
  }, [])
  return (
    <CommissionProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <Header/>
      <TableLayout
        header={
          <>
            <CommissionFilterForm/>
            <CommissionPanels/>
            <PageOption/>
          </>
        }
        table={{
          tHead: <CommissionTHead/>,
          tBody: <CommissionTBody/>,
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </CommissionProvider>
  )
}

const Header = () => {
  const [showPermission, setShowPermission] = useState(false)
  const  {queries, pageState} = useCommissionFilterForm()
  const  {pageOption} = pageState
  const [exportModalData, setExportModalData] = useState(null)
  const {showAlert} = useAlert()

  const handleExportClick = () => {
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...queries,
      per_page: '',
      start: '',
    })) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    handleLargeExport(
      {
        ...queries,
        per_page: '',
        start: '',
      }
    )
  }

  const handleLargeExport = (q) => {
    if (pageState.table.pagination.totalItems <= 0) {
      showAlert({ type: 'info', content: `Số lượng ${pageOption?.name} phải lớn hơn  0` })
      return
    }

    setExportModalData({
      data: {
        query: q,
        total: pageState.table.pagination.totalItems,
      },
      onClose: () => setExportModalData(null),
    })
  }
  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
      <PageHeader
        breadcrumbLinks={COMMISSION_BREADCRUMB}
        breadcrumbTitle="Báo cáo hoa hồng"
        actions={[
          {
            name: 'Xuất excel',
            icon: GLOBAL_ICONS.export,
            onClick: handleExportClick,
            appearance: 'secondary',
          }
        ]}
      />
      {!!exportModalData && <ModalExport data={exportModalData} title={pageOption?.name} api={`/report/commission/${pageOption.value}-export`}/>}
    </>
  )
}
