import { sendRequestAuth } from 'api/api'
import { removeAcent } from 'common/fieldText/_functions'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import {
  RECEIPT_FILTER_TAG_FIELDS,
} from '../interfaces/_constants'
import { ReceiptContext } from '../provider/_context'
import { receiptActions } from '../provider/_reducer'
import { debounce } from '@mui/material'
import { receiptInitialState } from 'Pages/receipt/provider/_initState'
import { useSearchParams } from 'react-router-dom'
import { generatePaymentPeriods } from '../utils/transform'

const useReceiptFilterForm = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageState, pageDispatch } = useContext(ReceiptContext)
  const { filter, table } = pageState

  // ===== ===== ===== ===== =====
  // SEARCH
  // ===== ===== ===== ===== =====
  const searchValue = filter.search.value
  const querySearch = searchParams.get('search') || ''

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // DATE TIME
  // ===== ===== ===== ===== =====
  const { afterToday } = DateRangePicker
  const dateTimeActiveValue = filter.dateTime.activeValue
  const dateTimeEnd = filter.dateTime.end
  const dateTimeStart = filter.dateTime.start
  const dateTimeDefaultValue = [dateTimeStart,dateTimeEnd]
  const dateTimeType = filter.dateTime.type
  const dateTimeValue = filter.dateTime.value
  const dateTimeTrigger = filter.dateTime.trigger

  const handleDateTimeChange = data =>
    pageDispatch({
      type: receiptActions.FILTER_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })


  // ===== ===== ===== ===== =====
  // principal
  // ===== ===== ===== ===== =====
  const principalActiveValue = filter.principal.activeValue
  const principalKeyword = filter.principal.keyword
  const principalList = filter.principal.list
  const principalListOrigin = filter.principal.listOrigin
  const principalValue = filter.principal.value

  const handlePrincipalChange = async (data) => {
    pageDispatch({
      type: receiptActions.FILTER_PRINCIPAL_UPDATE,
      payload: { value: data },
    })
    // get list employee
    const response = await sendRequestAuth('get', `${config.API}/order/filter/employees?principal_id=${data?.value}`)
    pageDispatch({
      type: receiptActions.FILTER_EMPLOYEE_LIST_UPDATE,
      payload: {
        employee: {
          list: response.data?.map(item => ({
            name: item?.name || '',
            value: item?.id || '',
          })),
        }
      },
    })

  }

  const handlePrincipalKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const principalListData = principalListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: receiptActions.FILTER_PRINCIPAL_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: principalListData,
      },
    })
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Employee
  // ===== ===== ===== ===== =====
  const employeeActiveValue = filter.employee.activeValue
  const employeeKeyword = filter.employee.keyword
  const employeeList = filter.employee.list
  const employeeListOrigin = filter.employee.listOrigin
  const employeeValue = filter.employee.value

  const handleEmployeeChange = data =>
    pageDispatch({
      type: receiptActions.FILTER_EMPLOYEE_UPDATE,
      payload: { value: data },
    })

  const handleEmployeeKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const employeeListData = employeeListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: receiptActions.FILTER_EMPLOYEE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: employeeListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // RECEIPT INFO
  // ===== ===== ===== ===== =====
  const receiptInfoValue = filter.receiptInfo.value
  const receiptInfoActiveValue = filter.receiptInfo.activeValue

  const handleReceiptInfoChange = value => {
    if (value === ' ') value = ''
    pageDispatch({
      type: receiptActions.FILTER_RECEIPT_INFO_UPDATE,
      payload: { value: value },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // STATUS FILTER
  // ===== ===== ===== ===== =====
  const statusValue = filter?.status?.value
  const statusActiveValue = filter?.status?.activeValue

  const handleStatusChange = value => {
    pageDispatch({
      type: receiptActions.FILTER_STATUS_VALUE_UPDATE,
      payload: value
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // RECEIPT TYPE FILTER
  // ===== ===== ===== ===== =====
  const receiptTypeValue = filter?.receiptType?.value
  const receiptTypeActiveValue = filter?.receiptType?.activeValue

  const handleReceiptTypeChange = value => {
    pageDispatch({
      type: receiptActions.FILTER_RECEIPT_TYPE_VALUE_UPDATE,
      payload: value
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // RECEIPT TYPE FILTER
  // ===== ===== ===== ===== =====
  const monthTimeValue = filter?.monthTime?.value
  const monthTimeActiveValue = filter?.monthTime?.activeValue

  const handleMonthTimeChange = value => {
    pageDispatch({
      type: receiptActions.FILTER_MONTH_TIME_VALUE_UPDATE,
      payload: value
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  const canSubmitOtherFilter = [
    dateTimeActiveValue.value !== dateTimeValue ||
    JSON.stringify(dateTimeActiveValue.type) !== JSON.stringify(dateTimeType),
    JSON.stringify(principalActiveValue) !== JSON.stringify(principalValue),
    JSON.stringify(employeeActiveValue) !== JSON.stringify(employeeValue),
    JSON.stringify(statusActiveValue) !== JSON.stringify(statusValue),
    JSON.stringify(receiptTypeActiveValue) !== JSON.stringify(receiptTypeValue),
    JSON.stringify(monthTimeActiveValue) !== JSON.stringify(monthTimeValue),
    JSON.stringify(receiptInfoActiveValue) !== JSON.stringify(receiptInfoValue),
  ].includes(true)

  const queries = {
    date_type: dateTimeActiveValue?.type?.value || '',
    start_date:
      dateTimeActiveValue?.start && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[0])
        : '',
    end_date:
      dateTimeActiveValue?.end && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[1])
        : '',
    principal_id: principalActiveValue?.value || '',
    user_id: employeeActiveValue?.value || '',
    payment_status: statusActiveValue?.value || '',
    payment_method: receiptTypeActiveValue?.value || '',
    month_time: monthTimeActiveValue || '',
    tracking_code: receiptInfoActiveValue || '',
    per_page: table?.pagination?.amount || 20,
    start: 0,
  }

  const debounceReceiptByFilter = useCallback(debounce((keyword, queries) => {
    fetchReceiptByFilter(
      { ...queries, keyword: keyword.trim() },
      { forceLoading: true },
    )
  }, 500), [queries])

  const applyReceiptOtherFilter = async () => {
    const collection = {
      ...queries,
      date_type: dateTimeType?.value || '',
      start_date:
        dateTimeStart && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[0])
          : '',
      end_date:
        dateTimeEnd && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[1])
          : '',
      principal_id: principalValue?.value || '',
      payment_status: statusValue?.value || '',
      payment_method: receiptTypeValue?.value || '',
      month_time: monthTimeValue || '',
      user_id: employeeValue?.value || '',
      tracking_code: receiptInfoValue || '',
    }
    fetchReceiptByFilter(collection, { forceLoading: true })
  }

  const fetchReceiptByFilter = async (qs, opt) => {
    setSearchParams('')
    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: receiptActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: true } } },
      })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/receipt/receipts${queryString}`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: receiptActions.OTHER_FILTER_APPLY,
        payload: {
          display: {
            list: response[0].data.data
          },
          pagination: {
            active: opt?.activePage || 0,
            amount: table.pagination.amount,
            total: response[0]?.data?.meta?.total
              ? Math.ceil(
                response[0]?.data?.meta?.total / table.pagination.amount,
              )
              : 0,
            totalItems: response[0]?.data?.meta?.total || 0,
          },
        },
      })
    }

    if (!!!opt?.notClearDetail)
      pageDispatch({
        type: receiptActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })

    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: receiptActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })

    pageDispatch({
      type: receiptActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: null },
    })
  }
  const fetchReceiptByTagDelete = async (qs, opt) => {
    setSearchParams('')
    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: receiptActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: true } } },
      })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/receipt/receipts${queryString}`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: receiptActions.DELETE_TAG_UPDATE,
        payload: {
          display: {
            list: response[0].data.data
          },
          pagination: {
            active: opt?.activePage || 0,
            amount: table.pagination.amount,
            total: response[0]?.data?.meta?.total
              ? Math.ceil(
                response[0]?.data?.meta?.total / table.pagination.amount,
              )
              : 0,
            totalItems: response[0]?.data?.meta?.total || 0,
          },
        },
      })
    }

    if (!!!opt?.notClearDetail)
      pageDispatch({
        type: receiptActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })

    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: receiptActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })

    pageDispatch({
      type: receiptActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: null },
    })
  }

  const filterTagDelete = t => {
    pageDispatch({
      type: receiptActions.TAG_FILTER_DELETE,
      payload: { type: t, isSingle: true },
    })

    let tmpCollection = {}
    switch (t) {
      case 'dateTime.current':
        tmpCollection = {
          ...tmpCollection,
          date_type: '',
          start_date: '',
          end_date: '',
        }

        pageDispatch({
          type: receiptActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
          payload: { trigger: null },
        })

        break

      case RECEIPT_FILTER_TAG_FIELDS[1]:
        tmpCollection = {
          ...tmpCollection,
        }
        break

      case RECEIPT_FILTER_TAG_FIELDS[0]:
        tmpCollection = { ...tmpCollection, keyword: '' }
        break

      case RECEIPT_FILTER_TAG_FIELDS[4]:
        tmpCollection = { ...tmpCollection, month_time: '' }
        break
      case RECEIPT_FILTER_TAG_FIELDS[5]:
        tmpCollection = { ...tmpCollection, payment_status: '' }
        break
      case RECEIPT_FILTER_TAG_FIELDS[6]:
        tmpCollection = { ...tmpCollection, payment_method: '' }
        break

      case RECEIPT_FILTER_TAG_FIELDS[2]:
        tmpCollection = { ...tmpCollection, principal_id: '' }
        break

      case RECEIPT_FILTER_TAG_FIELDS[3]:
        tmpCollection = { ...tmpCollection, user_id: '' }
        break

      default:
        break
    }

    const collection = { ...queries, ...tmpCollection }

    fetchReceiptByTagDelete(collection, { forceLoading: true })
  }

  const filterTagDeleteAll = isSoft => {
    RECEIPT_FILTER_TAG_FIELDS.forEach(item =>
      pageDispatch({
        type: receiptActions.TAG_FILTER_DELETE,
        payload: { type: item },
      }),
    )

    pageDispatch({
      type: receiptActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
      payload: {
        trigger: dateTimeTrigger === null ? true : !dateTimeTrigger,
      },
    })

    if (isSoft) return

    const collection = {
      ...queries,
      date_type: 'created',
      start_date: '',
      end_date: '',
      principal_id: '',
      user_id: '',
      tracking_code: '',
      payment_status: '',
      payment_method: '',
      month_time:''
    }

    fetchReceiptByFilter(collection, { forceLoading: true })
  }

  return {
    dateTime: {
      activeValue: dateTimeActiveValue,
      defaultValue: dateTimeDefaultValue,
      disabledDate: afterToday(),
      triggerDefault: dateTimeTrigger,
      start: filter.dateTime.start,
      end: filter.dateTime.end,
      value: dateTimeValue,
      onChange: handleDateTimeChange,
    },
    receiptInfo: {
      value: receiptInfoValue,
      activeValue: receiptInfoActiveValue,
      onChange: handleReceiptInfoChange,
    },
    status: {
      value: statusValue,
      activeValue: statusActiveValue,
      onChange: handleStatusChange,
    },
    receiptType: {
      value: receiptTypeValue,
      activeValue: receiptTypeActiveValue,
      onChange: handleReceiptTypeChange,
    },
    monthTime: {
      value: monthTimeValue,
      list: generatePaymentPeriods(),
      activeValue: monthTimeActiveValue,
      onChange: handleMonthTimeChange,
    },
    principal: {
      activeValue: principalActiveValue,
      keyword: principalKeyword,
      list: principalList,
      value: principalValue,
      onChange: handlePrincipalChange,
      onKeywordChange: handlePrincipalKeywordChange,
    },
    employee: {
      activeValue: employeeActiveValue,
      keyword: employeeKeyword,
      list: employeeList,
      listOrigin: employeeListOrigin,
      value: employeeValue,
      onChange: handleEmployeeChange,
      onKeywordChange: handleEmployeeKeywordChange,
    },
    canSubmitOtherFilter,
    queries,
    functions: {
      hasFilter: () => [
        JSON.stringify(dateTimeActiveValue?.value) !==
        JSON.stringify(receiptInitialState.filter.dateTime.activeValue?.value),
        !!principalActiveValue?.name,
        !!employeeActiveValue?.name,
        !!statusActiveValue?.name,
        !!receiptTypeActiveValue?.name,
        !!monthTimeActiveValue,
        !!receiptInfoActiveValue,
      ].includes(true),
      applyReceiptOtherFilter,
      refresh: () =>
        fetchReceiptByFilter(
          {
            ...queries,
            start: table.pagination.active * table.pagination.amount || 0,
          },
          { activePage: table.pagination.active, forceLoading: true },
        ),
      fetchUpdateData: () =>
        fetchReceiptByFilter(
          {
            ...queries,
            start: table.pagination.active * table.pagination.amount,
          },
          { activePage: table.pagination.active, notClearDetail: true },
        ),
      filterTagDelete,
      filterTagDeleteAll,
    },
  }
}

export default useReceiptFilterForm
