import {useWindowSize} from 'Component/ListMenu/listMenu'
import useGlobalContext from 'containerContext/storeContext'
import {createContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import SIDEBAR_COMPONENTS from './_components'
import {SIDEBAR_BOTTOM_ITEM, SIDEBAR_MENU_ITEMS, SIDEBAR_MENU_ITEMS_TEACHER} from './_constants'
import {SIDEBAR_MENU_ICONS} from './_icons'
import {StyledSidebar} from './_styled'

const {SidebarMenuItem} = SIDEBAR_COMPONENTS

export const SidebarContext = createContext(null)

export const Sidebar = ({...props}) => {
    const location = useLocation()
    const defaultActiveId = SIDEBAR_MENU_ITEMS.find(
        item =>
            (item?.path) ||
            item.list
                .map(child => child?.path && location.pathname.includes(child?.path))
                .includes(true),
    )?.label

    const [globalState, globalDispatch] = useGlobalContext()
    const {shouldMinimizeSidebar} = globalState
    const {user} = globalState

    const [activeId, setActiveId] = useState(defaultActiveId)

    const handleSidebarToggle = () => globalDispatch({type: 'TOGGLE_SIDEBAR'})

    const [width] = useWindowSize()

    useEffect(() => {
        if (width <= 1440) globalDispatch({type: 'TOGGLE_SIDEBAR', payload: {toggle: true}})
        else globalDispatch({type: 'TOGGLE_SIDEBAR', payload: {toggle: false}})
    }, [width])

    useEffect(() => {
        // close submenu when minimize sidebar
        if (shouldMinimizeSidebar) setActiveId(null)
    }, [shouldMinimizeSidebar])
    return (
        <SidebarContext.Provider value={{activeId, setActiveId}}>
            <StyledSidebar
                data-minimize={globalState?.shouldMinimizeSidebar}
                {...props}
            >
                <button
                    className={`sidebar__toggle ${globalState?.shouldMinimizeSidebar ? 'sidebar__toggle__minimize' : ''} ${width > 1440 ? 'sidebar__toggle__minimizeHover' : ''}`}
                    data-rotate={globalState?.shouldMinimizeSidebar}
                    onClick={handleSidebarToggle}
                >
                    {SIDEBAR_MENU_ICONS.doubleArrow}
                </button>
                <ul
                    className="sidebar__menu"
                    data-scrollable={!globalState?.shouldMinimizeSidebar} // when minimize sidebar will have enough height - no need scrollable
                >
                    {+user?.user_type === 2
                        ? SIDEBAR_MENU_ITEMS.map(item => (
                            <SidebarMenuItem key={item.id} data={item}/>
                        ))
                        :
                        SIDEBAR_MENU_ITEMS_TEACHER.map(item => (
                            <SidebarMenuItem key={item.id} data={item}/>
                        ))
                    }
                </ul>
                {/*<ul className="sidebar__bottom">*/}
                {/*  <SidebarMenuItem data={SIDEBAR_BOTTOM_ITEM} size="lg" />*/}
                {/*</ul>*/}
            </StyledSidebar>
        </SidebarContext.Provider>
    )
}
