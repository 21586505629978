import {DISPLAY_NAME_MENU} from 'const/display_name_menu'
import {PATH} from 'const/path'
import {ROLE} from 'const/role'
import {SIDEBAR_MENU_ICONS} from './_icons'

export const SIDEBAR_MENU_ITEMS = [
    {
        id: 1,
        label: DISPLAY_NAME_MENU.OVERVIEW,
        path: PATH.ADMIN_DASHBOAR,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.overview,
        list: [],
    },
    {
        id: 5,
        label: 'Quản lý giáo viên',
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.customer,
        path: PATH.TEACHER,
    },
    {
        id: 8,
        label: 'Quản lý khách hàng',
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.customer2,
        path: PATH.CUSTOMER,
    },
    {
        id: 6,
        label: 'Hoa hồng',
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.revenue,
        path: PATH.COMMISSION,
    },
    {
        id: 7,
        label: 'Kế toán',
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.accountant,
        list: [
            {
                id: 1,
                label: 'Phiếu chi hoa hồng',
                path: PATH.COMMISSION_VOUCHER,
            },
            {
                id: 2,
                label: 'Phiếu thu hoa hồng',
                path: PATH.PAYMENT_VOUCHER,
            },
        ],
    },
]


export const SIDEBAR_MENU_ITEMS_TEACHER = [
    {
        id: 1,
        label: DISPLAY_NAME_MENU.OVERVIEW,
        path: PATH.ADMIN_DASHBOAR,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.overview,
        list: [],
    },
    {
        id: 2,
        label: "Mã QR affiliate",
        path: PATH.QR_AFFILIATE,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.qrcode,
        list: [],
    },
    {
        id: 5,
        label: 'Quản lý học sinh',
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.customer,
        path: PATH.STUDENT,
    },
    {
        id: 6,
        label: 'Hoa hồng',
        path: PATH.COMMISSION,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.revenue,
    },
    {
        id: 7,
        label: 'Phiếu thu hoa hồng',
        path: PATH.PAYMENT_VOUCHER,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.accountant,
    },
]

