import React, { useEffect, useRef } from 'react';
import { Grid } from "@mui/material";
import { Text } from "../../../../common/text";
import { THEME_SEMANTICS } from "../../../../common/theme/_semantics";
import { Input } from "../../../../common/form/input";
import useCreateUserManagement from "../../hooks/useCreateUserManagement";
import { useTranslation } from "react-i18next";
import { DISPLAY_NAME_MENU } from "../../../../const/display_name_menu";
import {STATUS_LIST, GROUP_LIST, DEPARTMENT_LIST} from "../../interfaces/~contants";
import {AlternativeAutoComplete} from "common/form/autoComplete/_alternativeAutoComplete";
import {AutoCompleteSingleOption} from "common/form/autocompleteSingleOption";

const UserInfor = ({ ...props }) => {
    const { t } = useTranslation()
    const { functions, valueForm, validateForm, validate } = useCreateUserManagement()
    const datepickerRef = useRef(null)

    useEffect(() => {
        const calendarTitle = document.getElementsByClassName('rs-calendar-table-cell-content')
        for (let i = 0; i < calendarTitle.length; i++) {
            calendarTitle[i].removeAttribute('title')
        }
        window.addEventListener('mouseover', function (e) {
            for (let i = 0; i < calendarTitle.length; i++) {
                calendarTitle[i].removeAttribute('title')
            }
        })
        return function cleanupListener() {
            window.removeEventListener('mouseover', () => { })
        }
    }, [])

    return (
        <>
            <Grid container spacing={2} className="create-user-info__group-role">
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group create-user-info__form-group--full-name">
                        <Input
                            {...props}
                            label={
                                <>
                                    {t('Tên')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                                </>
                            }
                            placeholder={'Vui lòng nhập tên, ví dụ: Tuấn Khánh'}
                            maxLength={200}
                            value={valueForm.name}
                            validateText={validateForm?.name?.status ? validateForm?.name?.message : null}
                            validateType={!validateForm.name?.status ? 'success' : 'danger'}
                            onChange={e => functions.onChangeName(e.target.value)}
                            onBlur={() => functions.onBlurName()}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group create-user-info__form-group--full-name">
                        <Input
                            {...props}
                            label={
                                <>
                                    {t('Họ')} <Text color={THEME_SEMANTICS.failed}>*</Text>
                                </>
                            }
                            placeholder={'Vui lòng nhập họ. Ví dụ: Nguyễn'}
                            maxLength={200}
                            value={valueForm.last_name}
                            validateText={validateForm?.last_name?.status ? validateForm?.last_name?.message : null}
                            validateType={!validateForm.last_name?.status ? 'success' : 'danger'}
                            onChange={e => functions.onChangeLastName(e.target.value)}
                            onBlur={() => functions.onBlurLastName()}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group">
                        <Input
                            {...props}
                            label={
                                <>
                                    {t(DISPLAY_NAME_MENU.GENERAL.PHONE)}
                                </>
                            }
                            placeholder={t(DISPLAY_NAME_MENU.USER_PAGE.ENTER_PHONE)}
                            maxLength={11}
                            value={valueForm.phone}
                            validateText={validateForm?.phone?.status ? validateForm?.phone?.message : null}
                            validateType={!validateForm.phone?.status ? 'success' : 'danger'}
                            onChange={e => functions.onChangePhone(e.target.value)}
                            onBlur={() => functions.onBlurPhone()}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group">
                        <Input
                            {...props}
                            label={
                                <>
                                    {'Email'} <Text color={THEME_SEMANTICS.failed}>*</Text>
                                </>
                            }
                            placeholder={'Vui lòng nhập email của nhân viên'}
                            maxLength={200}
                            value={valueForm.email}
                            validateText={validateForm?.email?.status ? validateForm?.email?.message : null}
                            validateType={!validateForm.email?.status ? 'success' : 'danger'}
                            onChange={e => functions.onChangeEmail(e.target.value)}
                            onBlur={() => functions.onBlurEmail()}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group">
                        <Input
                            {...props}
                            label={
                                <>
                                    {'Staff ID'} <Text color={THEME_SEMANTICS.failed}>*</Text>
                                </>
                            }
                            placeholder={'Vui lòng nhập mã số nhân viên'}
                            maxLength={200}
                            value={valueForm.staff_id}
                            validateText={validateForm?.staff_id?.status ? validateForm?.staff_id?.message : null}
                            validateType={!validateForm.staff_id?.status ? 'success' : 'danger'}
                            onChange={e => functions.onChangeStaffId(e.target.value)}
                            onBlur={() => functions.onBlurStaffId()}
                        />
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group">
                        <AlternativeAutoComplete
                            // main input
                            inputProps={{
                                categoryList: [], // menu list in category dropdown
                                categoryHidden: true,
                                readOnly: true,
                                categoryValue: {name: t('Vui lòng chọn phòng ban'), value: ''},
                                label: <>
                                    <div>{'Phòng ban'}</div>
                                </>,
                                placeholder: t('Vui lòng chọn phòng ban'),
                                value: valueForm?.department?.name,
                            }}
                            hideSearchBar={true}
                            // search input in dropdown menu
                            menuProps={{
                                empty: '',
                            }}
                            // search input in dropdown menu
                            // validateText={statusPerson?.message}
                            // validateType={statusPerson?.status ? 'danger' : 'success'}
                        >
                            {
                                DEPARTMENT_LIST?.map((item, index) => (
                                    <AutoCompleteSingleOption
                                        key={item.id}
                                        className={"user-management-filter-form__option-text"}
                                        data-active={item.id === valueForm?.department?.id}
                                        onClick={() => functions.onSelectedDepartment(item)}
                                    >
                                        {t(item.name)}
                                    </AutoCompleteSingleOption>
                                ))
                            }
                        </AlternativeAutoComplete>
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group">
                        <AlternativeAutoComplete
                            // main input
                            inputProps={{
                                categoryList: [], // menu list in category dropdown
                                categoryHidden: true,
                                readOnly: true,
                                categoryValue: {name: t('Vui lòng chọn vai trò'), value: ''},
                                label: <>
                                    <div>{'Vai trò'} <Text color={THEME_SEMANTICS.failed}>*</Text></div>
                                </>,
                                placeholder: t('Vui lòng chọn vai trò'),
                                value: valueForm?.group?.name,
                            }}
                            hideSearchBar={true}
                            // search input in dropdown menu
                            menuProps={{
                                empty: '',
                            }}
                            // search input in dropdown menu
                            validateText={validateForm?.group?.status ? validateForm?.group?.message : null}
                            validateType={!validateForm.group?.status ? 'success' : 'danger'}
                        >
                            {
                                GROUP_LIST?.map((item, index) => (
                                    <AutoCompleteSingleOption
                                        key={item.id}
                                        className={"user-management-filter-form__option-text"}
                                        data-active={item.id === valueForm?.group?.id}
                                        onClick={() => functions.onSelectedGroup(item)}
                                    >
                                        {t(item.name)}
                                    </AutoCompleteSingleOption>
                                ))
                            }
                        </AlternativeAutoComplete>
                    </div>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <div className="create-user-info__form-group">
                        <AlternativeAutoComplete
                            // main input
                            inputProps={{
                                categoryList: [], // menu list in category dropdown
                                categoryHidden: true,
                                readOnly: true,
                                categoryValue: { name: t('Vui lòng chọn trạng thái'), value: '' },
                                label: <>
                                    <div>{'Trạng thái'} <Text color={THEME_SEMANTICS.failed}>*</Text></div>
                                </>,
                                placeholder: t('Vui lòng chọn trạng thái'),
                                value: valueForm?.status?.name,
                            }}
                            hideSearchBar={true}
                            // search input in dropdown menu
                            menuProps={{
                                empty: '',
                            }}
                            // search input in dropdown menu
                            validateText={validateForm?.status?.status ? validateForm?.status?.message : null}
                            validateType={!validateForm.status?.status ? 'success' : 'danger'}
                        >
                            {
                                STATUS_LIST?.map((item, index) => (
                                    <AutoCompleteSingleOption
                                        key={item.id}
                                        className={"user-management-filter-form__option-text"}
                                        data-active={item.id === valueForm?.status?.id}
                                        onClick={() => functions.onSelectedStatus(item)}
                                    >
                                        {t(item.name)}
                                    </AutoCompleteSingleOption>
                                ))
                            }
                        </AlternativeAutoComplete>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default UserInfor;