import { PATH } from '../const/path'
import UpdateProfile from '../Pages/updateProfile'
import { UserPage } from '../Pages/userManagement'
import { CreateUser } from '../Pages/userManagement/createUser'
import { UserRole } from '../Pages/userRole'
import { UserRoleCreate } from '../Pages/userRole/create'
import ComingSoon from '../Pages/comingSoon'
import Category from '../Pages/productGroup'
import NotPermission from '../Pages/permision/notPermission'
import { TeacherComponent } from '../Pages/teacher'
import {StudentPage} from "../Pages/student";
import {TeacherCreate} from "../Pages/teacher/component/createPrincipal";
import {InvoicePage} from "../Pages/invoice";
import {CreateInvoicePage} from "../Pages/invoice/create";
import {ReceiptPage} from "../Pages/receipt";
import {CommissionComponent} from "../Pages/commission";
import {QrAffiliateComponent} from "../Pages/qrAffiliate";
import {CustomerPage} from "../Pages/customer";

export const userRoleGroup = [
  {path: PATH.USER_MANAGEMENT, component: UserPage, prefix: "publish"},
  {path: PATH.CREATE_USER, component: CreateUser, prefix: "publish"},
  {path: PATH.UPDATE_USER, component: CreateUser, prefix: "publish"},
  {path: PATH.USER_ROLE, component: UserRole, prefix: "publish"},
  {path: PATH.USER_ROLE_CREATE, component: UserRoleCreate, prefix: "publish"},
  {path: PATH.USER_ROLE_EDIT, component: UserRoleCreate, prefix: "publish"},
  {path: PATH.CONFIG_PAYMENT, component: ComingSoon, prefix: "publish"},
  {path: PATH.CONFIG_NOTIFICATION, component: ComingSoon, prefix: "publish"},
]
export const profileGroup = [
  {path: PATH.UPDATE_PROFILE, component: UpdateProfile, prefix: "publish"},
]
export const categoryGroup = [
  {path: PATH.CATEGORY, component: Category},
]
export const customerGroup = [
  {path: PATH.TEACHER, component: TeacherComponent , prefix: "principal"},
  {path: PATH.CREATE_TEACHER, component: TeacherCreate , prefix: "principal"},
  {path: PATH.DETAIL_TEACHER, component: TeacherCreate , prefix: "principal"},
  {path: PATH.CUSTOMER, component: CustomerPage , prefix: "principal"},
  {path: PATH.STUDENT, component: StudentPage , prefix: "teacher"},
  {path: PATH.CREATE_STUDENT, component: ComingSoon , prefix: "teacher"},
  {path: PATH.DETAIL_STUDENT, component: ComingSoon , prefix: "teacher"},
  {path: PATH.QR_AFFILIATE, component: QrAffiliateComponent , prefix: "publish"},
]

export const statisticalGroup = [
  {path: PATH.REVENUE, component: ComingSoon, prefix: "publish"},
  {path: PATH.DETAIL_REVENUE, component: ComingSoon, prefix: "publish"},
  {path: PATH.COMMISSION, component: CommissionComponent, prefix: "publish"},
  {path: PATH.DETAIL_COMMISSION, component: ComingSoon, prefix: "publish"},
]
export const accountantGroup = [
  {path: PATH.PAYMENT_VOUCHER, component: ReceiptPage, prefix: "publish"},
  {path: PATH.COMMISSION_VOUCHER, component: InvoicePage, prefix: "publish"},
  {path: PATH.CREATE_COMMISSION_VOUCHER, component: CreateInvoicePage, prefix: "publish"},
]