import {Box, Modal} from '@material-ui/core';
import {Button} from 'common/button';
import {Text} from 'common/text';
import {useDeleteproduct} from 'Pages/productGroup/hook/useDeleteProduct';
import {ProductGroup} from 'Pages/productGroup/provider/_context';
import {useProductAction} from 'Pages/productGroup/provider/_reducer';
import React from 'react';
import {useContext} from 'react';
import "./index.scss";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation()
    const {pageState, pageDispatch} = useContext(ProductGroup)
    const handleCloseConfirm = () => {
        pageDispatch({type: useProductAction.CHECK_CONFIRM_DELETE, payload: !pageState.check_confirm_delete})
        pageDispatch({type: useProductAction.GET_ID, payload: ''})
    }
    const {handleDelete} = useDeleteproduct()
    return (
        <Modal
            open={pageState.check_confirm_delete}
            onClose={handleCloseConfirm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="product-group">
                <Text
                    as="p"
                    fontSize={20}
                    fontWeight={600}
                    lineHeight={28}
                    className='product-group_title'
                >{t('product_category_delete_confirm')}</Text>
                <Text as="p"
                      lineHeight={19}
                      className='product-group_text'
                >
                    {t('product_category_delete_confirm_content')}
                </Text>
                <Text
                    as="p"
                    style={{marginTop: 10}}
                    lineHeight={19}
                    className='product-group_text'
                >
                    {t('product_category_delete_confirm_question')}
                </Text>

                <div className='product-group_button'>
                    <Button className='product-group_cancel' appearance='ghost'
                            onClick={handleCloseConfirm}>{t('cancel')}</Button>
                    <Button className='product-group_acept' badgeType='danger' onClick={handleDelete}>{t('delete')}</Button>
                </div>
            </Box>

        </Modal>
    )
}
export default Index;