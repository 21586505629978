export const TEACHER_GROUP_ICON = {
    group: (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.57568 16.6816L7.57568 5.318" stroke="#7C88A6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.57568 11L17.0454 11" stroke="#7C88A6" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    ),
    plus: (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1.39648V13.3965M1 7.39648H13" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    ),
    delete: (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4.5V3.9C12 3.05992 12 2.63988 11.8365 2.31901C11.6927 2.03677 11.4632 1.8073 11.181 1.66349C10.8601 1.5 10.4401 1.5 9.6 1.5H8.4C7.55992 1.5 7.13988 1.5 6.81901 1.66349C6.53677 1.8073 6.3073 2.03677 6.16349 2.31901C6 2.63988 6 3.05992 6 3.9V4.5M7.5 8.625V12.375M10.5 8.625V12.375M2.25 4.5H15.75M14.25 4.5V12.9C14.25 14.1601 14.25 14.7902 14.0048 15.2715C13.789 15.6948 13.4448 16.039 13.0215 16.2548C12.5402 16.5 11.9101 16.5 10.65 16.5H7.35C6.08988 16.5 5.45982 16.5 4.97852 16.2548C4.55516 16.039 4.21095 15.6948 3.99524 15.2715C3.75 14.7902 3.75 14.1601 3.75 12.9V4.5" stroke="#FF424E" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    ),
    import:(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 10V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V10M13.3333 5.83333L10 2.5M10 2.5L6.66667 5.83333M10 2.5V12.5" stroke="#3264FF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    ),
    lock:(
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4663 7.64808H13.252V3.64808C13.252 2.38558 12.2288 1.36237 10.9663 1.36237H6.53774C5.27524 1.36237 4.25202 2.38558 4.25202 3.64808V7.64808H3.03774C2.72167 7.64808 2.46631 7.90344 2.46631 8.21951V15.0767C2.46631 15.3927 2.72167 15.6481 3.03774 15.6481H14.4663C14.7824 15.6481 15.0377 15.3927 15.0377 15.0767V8.21951C15.0377 7.90344 14.7824 7.64808 14.4663 7.64808ZM5.53774 3.64808C5.53774 3.09629 5.98595 2.64808 6.53774 2.64808H10.9663C11.5181 2.64808 11.9663 3.09629 11.9663 3.64808V7.64808H5.53774V3.64808ZM13.752 14.3624H3.75202V8.9338H13.752V14.3624ZM8.25202 11.8802V12.8267C8.25202 12.9052 8.31631 12.9695 8.39488 12.9695H9.10917C9.18774 12.9695 9.25202 12.9052 9.25202 12.8267V11.8802C9.39943 11.7744 9.50945 11.6245 9.56626 11.4522C9.62306 11.2798 9.62372 11.0939 9.56813 10.9212C9.51254 10.7484 9.40358 10.5978 9.25692 10.4909C9.11027 10.3841 8.93349 10.3265 8.75202 10.3265C8.57056 10.3265 8.39378 10.3841 8.24713 10.4909C8.10047 10.5978 7.99151 10.7484 7.93592 10.9212C7.88033 11.0939 7.88099 11.2798 7.93779 11.4522C7.9946 11.6245 8.10462 11.7744 8.25202 11.8802Z" fill="#1890FF"/>
        </svg>

    ),
    unlock:(
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9644 7.64515H5.03578V3.64515C5.03578 3.09336 5.484 2.64515 6.03578 2.64515H10.4644C11.0161 2.64515 11.4644 3.09336 11.4644 3.64515V4.85944C11.4644 4.93801 11.5286 5.00229 11.6072 5.00229H12.6072C12.6858 5.00229 12.7501 4.93801 12.7501 4.85944V3.64515C12.7501 2.38265 11.7269 1.35944 10.4644 1.35944H6.03578C4.77328 1.35944 3.75007 2.38265 3.75007 3.64515V7.64515H2.53578C2.21971 7.64515 1.96436 7.90051 1.96436 8.21658V15.0737C1.96436 15.3898 2.21971 15.6452 2.53578 15.6452H13.9644C14.2804 15.6452 14.5358 15.3898 14.5358 15.0737V8.21658C14.5358 7.90051 14.2804 7.64515 13.9644 7.64515ZM13.2501 14.3594H3.25007V8.93087H13.2501V14.3594ZM7.75007 11.8773V12.8237C7.75007 12.9023 7.81436 12.9666 7.89293 12.9666H8.60721C8.68579 12.9666 8.75007 12.9023 8.75007 12.8237V11.8773C8.89748 11.7715 9.0075 11.6216 9.0643 11.4492C9.12111 11.2769 9.12176 11.091 9.06618 10.9182C9.01059 10.7455 8.90162 10.5949 8.75497 10.488C8.60831 10.3811 8.43153 10.3236 8.25007 10.3236C8.06861 10.3236 7.89183 10.3811 7.74517 10.488C7.59852 10.5949 7.48955 10.7455 7.43397 10.9182C7.37838 11.091 7.37903 11.2769 7.43584 11.4492C7.49264 11.6216 7.60267 11.7715 7.75007 11.8773V11.8773Z" fill="#1677FF" fill-opacity="0.85"/>
        </svg>

    ),
    edit:(
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.52656 10.25C2.55781 10.25 2.58906 10.2469 2.62031 10.2422L5.24844 9.78128C5.27969 9.77503 5.30938 9.76097 5.33125 9.73753L11.9547 3.11409C11.9692 3.09964 11.9807 3.08247 11.9885 3.06357C11.9963 3.04466 12.0004 3.0244 12.0004 3.00394C12.0004 2.98347 11.9963 2.96321 11.9885 2.94431C11.9807 2.92541 11.9692 2.90824 11.9547 2.89378L9.35781 0.295343C9.32813 0.265656 9.28906 0.250031 9.24688 0.250031C9.20469 0.250031 9.16563 0.265656 9.13594 0.295343L2.5125 6.91878C2.48906 6.94222 2.475 6.97034 2.46875 7.00159L2.00781 9.62972C1.99261 9.71342 1.99804 9.79957 2.02364 9.8807C2.04923 9.96184 2.09421 10.0355 2.15469 10.0953C2.25781 10.1953 2.3875 10.25 2.52656 10.25ZM3.57969 7.52503L9.24688 1.85941L10.3922 3.00472L4.725 8.67034L3.33594 8.91566L3.57969 7.52503ZM12.25 11.5625H0.75C0.473438 11.5625 0.25 11.786 0.25 12.0625V12.625C0.25 12.6938 0.30625 12.75 0.375 12.75H12.625C12.6938 12.75 12.75 12.6938 12.75 12.625V12.0625C12.75 11.786 12.5266 11.5625 12.25 11.5625Z" fill="#1677FF"/>
        </svg>

    ),
    repeat: (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.58333 17.4165C13.4954 17.4165 16.6667 14.2452 16.6667 10.3332C16.6667 7.97856 15.5178 5.89228 13.75 4.60434M10.4167 18.9998L8.75 17.3332L10.4167 15.6665M9.58333 3.24984C5.67132 3.24984 2.5 6.42115 2.5 10.3332C2.5 12.6878 3.64889 14.7741 5.41667 16.062M8.75 4.99984L10.4167 3.33317L8.75 1.6665"
                stroke="#3264FF"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    success: (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="36" height="36" rx="18" fill="#F1FFFC" />
            <path
                d="M12.4611 18.1393C11.8753 17.5536 10.9255 17.5536 10.3397 18.1393C9.75394 18.7251 9.75394 19.6749 10.3397 20.2607L12.4611 18.1393ZM16.2004 24L15.1397 25.0607C15.4533 25.3742 15.8895 25.533 16.3312 25.4943C16.773 25.4556 17.175 25.2235 17.4292 24.8602L16.2004 24ZM25.8292 12.8602C26.3043 12.1815 26.1393 11.2462 25.4606 10.7712C24.7819 10.2961 23.8466 10.4611 23.3715 11.1398L25.8292 12.8602ZM10.3397 20.2607L15.1397 25.0607L17.2611 22.9393L12.4611 18.1393L10.3397 20.2607ZM17.4292 24.8602L25.8292 12.8602L23.3715 11.1398L14.9715 23.1398L17.4292 24.8602Z"
                fill="#00E0C2"
            />
        </svg>
    ),
    failed: (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="36" height="36" rx="18" fill="#EE4064" fillOpacity="0.1" />
            <path
                d="M12 12L24 24"
                stroke="#EE4064"
                strokeWidth="3"
                strokeLinecap="round"
            />
            <path
                d="M24 12L12 24"
                stroke="#EE4064"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    ),
    danger: (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 17C6.1339 17 3 13.8661 3 10C3 6.1339 6.1339 3 10 3C13.8661 3 17 6.1339 17 10C17 13.8661 13.8661 17 10 17Z"
                fill="#EE4064"
            />
            <path
                d="M10 7V11M10 13.6667H10.0083"
                stroke="white"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
}