import {PATH} from "const/path";
import {useProductAction} from "../provider/_reducer";
import { GLOBAL_ICONS } from '../../../interface/icon'
export const PRODUCT_BREADCUM = [
    {id: 1, name: 'Trang chủ', url: PATH.SETTING},
    {id: 2, name: 'Quản lý danh mục', url: '#'}
]
export const PRODUCT_BUTTON_ACTION = (pageState, pageDispatch,t) => {
    const PRODUCT_ACTION_BUTTON = [
        {
            id: 1,
            name: null,
            appearance: 'secondary',
            icon: GLOBAL_ICONS.repeat,
            onClick: () => pageDispatch({type: useProductAction.IS_LOADING, payload: false}),
            tooltip: 'refresh_data',
        },
        {
            id: 2,
            name: 'general_create',
            appearance: 'primary',
            icon: GLOBAL_ICONS.plus,
            onClick: () => {
                pageDispatch({type: useProductAction.OPEN_MODAL, payload: true})
                pageDispatch({type: useProductAction.GET_ID, payload: ''})
            },
        },
    ]
    return {
        PRODUCT_ACTION_BUTTON,
    }
}
export const PRODUCT_HEADER_TITLE = [
    {id: 2, name: 'product_page_name_group_product', class: 'name'},
    {id: 5, name: '', class: 'setting'},
]
export const PRODUCT_GROUP_EMPTY = {
    EMPTY_PRODUCT: 'product_category_search',
    NONE_PRODUCT: 'no_matching_data'
}
export const PRODUCT_MENU_ACTION = [
    {id: '1', name: 'edit', icon: GLOBAL_ICONS.edit, action: 1},
    {id: '2', name: 'delete', icon: GLOBAL_ICONS.recycle, action: 2,isDanger: true},
]
export const PRODUCT_MODAL_HEADER_TITLE = {
    title: 'product_page_title_group_product',
    subTitle: 'product_page_subtitle_group_product',
}

