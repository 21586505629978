import {useContext} from "react";
import {CreateTeacherContext} from "../provider/_context";
import useAlert from "../../../../../hook/useAlert";
import {useNavigate, useParams} from "react-router-dom";
import {useCreateTeacherAction} from "../provider/_reducer";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import {removeVietnameseTones} from "../../../../../util/checkPasswordVN";

export const useCreateTeacherPassword = ()=>{
    const {pageState, pageDispatch} = useContext(CreateTeacherContext)
    const {deleteConfirm, information} = pageState

    const {showAlert} = useAlert()
    const navigate = useNavigate()

    const {teacherId} = useParams()

    const fullName = ``

    const valueLastPassword = deleteConfirm?.value
    const statusLastPassword = deleteConfirm?.status
    const messageLastPassword = deleteConfirm?.message

    const handleOpenConfirm = (boo) =>{
        pageDispatch({type: useCreateTeacherAction.FORM_DELETE_OPEN_CONFIRM, payload: boo})
    }

    const handleChangePassword = data => pageDispatch({type:useCreateTeacherAction.FORM_DELETE_UPDATE, payload:{
            value: data,
            status: false,
            message:''
        }})
    const handleBlurPassword = data =>{
       const password = removeVietnameseTones(data)
        if(!!!password) pageDispatch({type:useCreateTeacherAction.FORM_DELETE_UPDATE, payload:{
                value: password,
                status: true,
                message:'Mật khẩu không được để trống'
            }})
        else if(password.length < 8) pageDispatch({type:useCreateTeacherAction.FORM_DELETE_UPDATE, payload:{
                value: password,
                status: true,
                message:'Mật khẩu phải có ít nhất 8 ký tự'
            }})
        else pageDispatch({type:useCreateTeacherAction.FORM_DELETE_UPDATE, payload:{
                    value: password,
                    status: false,
                    message:''
                }})
    }


    const handleUpdatePassword = async ()=>{
        if(!!valueLastPassword){
            const data = {
                password: valueLastPassword
            }
            const response = await sendRequestAuth('post',`${config.API}/principal/teacher/update-password/${teacherId}`, data)
            if(response?.data?.success) {
                showAlert({type:'success', content: response?.data?.message})
            } else showAlert({type:'danger', content: 'Cập nhật mật khẩu thất bại'})
            handleOpenConfirm(false)
        } else pageDispatch({type:useCreateTeacherAction.FORM_DELETE_UPDATE, payload:{
                value: '',
                status: true,
                message:'Mật khẩu không được để trống'
            }})
    }
    return{
        deleteConfirm:{
            open: deleteConfirm?.open,
            value: valueLastPassword,
            status: statusLastPassword,
            message: messageLastPassword,
            methods:{
                onOpen: handleOpenConfirm,
                onChange: handleChangePassword,
                onBlur: handleBlurPassword,
                onSave: handleUpdatePassword
            }
        }
    }
}