import React, { useState } from 'react'
import { Box, Tab } from '@material-ui/core'
import cls from 'clsx'
import useGlobalContext from '../../../containerContext/storeContext'
import { USER_PROFILE } from '../../Icons'
import styled from 'styled-components'
import css from './index.module.scss'
import { checkPasswordVN, removeVietnameseTones } from '../../../util/checkPasswordVN'
import ModalConfirm from '../../ModalConfirm'
import { Button } from '../../../common/button/index'
import { Text } from '../../../common/text'
import { Input } from '../../../common/form/input'
import { Tooltip } from '../../../common/tooltip'
import { THEME_SEMANTICS } from '../../../common/theme/_semantics'
import { useTranslation } from 'react-i18next'
import { sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import useAlert from '../../../hook/useAlert'

const Index = ({ isUpdate, setOpen, ...props }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalContext()
  const { user } = state
  const { showAlert } = useAlert()
  const [confirm, setConfirm] = useState(false)
  const handleConfirm = confirm => {
    setConfirm(false)
  }
  const [dataForm, setDataForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })

  const [typePassword, setTypePassword] = useState({
    currentPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password'
  })
  const [validateForm, setValidateDataForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const handleChangeForm = data => {
    setDataForm({
      ...dataForm,
      ...data
    })
  }

  const handleValidateForm = data => {
    setValidateDataForm({
      ...validateForm,
      ...data
    })
  }
  const handleBlurCurrentPassword = _ => {
    if (dataForm.currentPassword.trim() === '') {
      handleValidateForm({
        currentPassword: 'Mật khẩu hiện tại không được để trống!'
      })
    } else if (dataForm.currentPassword.length < 8) {
      handleValidateForm({
        currentPassword: 'Mật khẩu hiện tại không được nhỏ hơn 8 ký tự!'
      })
    } else {
      handleValidateForm({
        currentPassword: ''
      })
    }
  }
  const handleBlurNewPassword = _ => {
    if (dataForm.newPassword.trim() === '') {
      handleValidateForm({
        newPassword: 'Mật khẩu mới không được để trống!'
      })
    } else if (dataForm.newPassword.length < 8) {
      handleValidateForm({
        newPassword: 'Mật khẩu mới không được nhỏ hơn 8 ký tự!'
      })
    } else if (dataForm.newPassword === dataForm.currentPassword) {
      handleValidateForm({
        newPassword: 'Mật khẩu mới không được trùng với mật khẩu hiện tại!'
      })
    } else {
      handleValidateForm({
        newPassword: ''
      })
    }
  }
  const handleBlurConfirmPassword = _ => {
    if (dataForm.confirmPassword.trim() === '') {
      handleValidateForm({
        confirmPassword: 'Mật khẩu xác nhận không được để trống!'
      })
    } else if (dataForm.newPassword !== dataForm.confirmPassword) {
      handleValidateForm({
        confirmPassword: 'Mật khẩu xác nhận không khớp với Mật khẩu mới!'
      })
    } else {
      handleValidateForm({
        confirmPassword: ''
      })
    }
  }

  const handleSubmitChangePassword = async _ => {
    handleBlurConfirmPassword()
    handleBlurNewPassword()
    handleBlurCurrentPassword()
    if (validateForm.currentPassword === '' && validateForm.confirmPassword === '' && validateForm.newPassword === '') {
      const response = await sendRequestAuth('post',
        `${config.API}/account/change-password`,
        {
          'password': dataForm.currentPassword,
          'new_password': dataForm.newPassword,
          'confirm_password': dataForm.confirmPassword
        }
      )
      if (!!response.data.success) {
        showAlert({
          type: 'success',
          content: response.data?.message || 'Cập nhật mật khẩu thành công'
        })
        setOpen(false)
      } else {
        showAlert({
          type: 'danger',
          content: response.data?.errors[0]?.message || response.data?.message
        })
      }
    }
  }

  return (
    <StyleChangePassword>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <div className={cls(css.change)}>
          <div className={cls(css.wire)}>
            <Text fontWeight={600} fontSize={20}>Thay đổi mật khẩu tài khoản</Text>
          </div>
          <div style={{ marginTop: 16, maxHeight: 530, overflow: 'auto' }}>
            <div style={{ height: 81 }}>
              <Input
                label={'Tên đăng nhập'}
                value={user?.username}
                disabled={true}
              />
            </div>
            <div style={{ height: 81 }}>
              <Input
                type={typePassword?.currentPassword}
                label={
                  <>
                    Mật khẩu hiện tại <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </>
                }
                value={dataForm?.currentPassword}
                placeholder='Nhập mật khẩu hiện tại'
                icon={typePassword?.currentPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> :
                  <i>{USER_PROFILE.eye}</i>}
                onIconClick={
                  () => setTypePassword({
                    ...typePassword,
                    currentPassword: typePassword?.currentPassword === 'password' ? '' : 'password'
                  })
                }
                validateText={validateForm?.currentPassword}
                validateType={!validateForm?.currentPassword ? 'success' : 'danger'}
                onChange={(e) => handleChangeForm({ currentPassword: removeVietnameseTones(e.target.value) })}
                onBlur={handleBlurCurrentPassword}
                maxLength={32}
              />
            </div>
            <div style={{ height: 81 }}>
              <Input
                type={typePassword?.newPassword}
                label={
                  <>
                    Mật khẩu mới <Text color={THEME_SEMANTICS.failed}>*</Text>
                    <Tooltip placement="bottom-start"
                             title={'Mật khẩu có độ dài 8 - 32 ký tự. Hãy đặt mật khẩu mạnh để bảo vệ tài khoản của mình.'}
                             className={'user-management-tooltip__user-password'}
                    >
                                                    <span
                                                      style={{
                                                        marginLeft: 6,
                                                        display: 'inline-block',
                                                        transform: 'translateY(2px)',
                                                      }}
                                                    >
                                                    </span>
                      <span className={'create-user-info__form-group-account--tooltip'}>
                                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                              d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                                              stroke="#808089" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                            <path
                                                              d="M8 12.667C8.48325 12.667 8.875 12.2752 8.875 11.792C8.875 11.3087 8.48325 10.917 8 10.917C7.51675 10.917 7.125 11.3087 7.125 11.792C7.125 12.2752 7.51675 12.667 8 12.667Z"
                                                              fill="#808089"/>
                                                            <path
                                                              d="M7.99967 9.16667V8.58333C8.40348 8.58333 8.79821 8.46359 9.13396 8.23925C9.46971 8.01491 9.7314 7.69605 9.88593 7.32298C10.0405 6.94991 10.0809 6.5394 10.0021 6.14336C9.92333 5.74731 9.72888 5.38352 9.44335 5.09799C9.15782 4.81246 8.79403 4.61801 8.39798 4.53923C8.00194 4.46045 7.59143 4.50088 7.21836 4.65541C6.8453 4.80994 6.52643 5.07163 6.30209 5.40738C6.07775 5.74313 5.95801 6.13786 5.95801 6.54167"
                                                              stroke="#808089" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                    </Tooltip>
                  </>
                }
                value={dataForm?.newPassword}
                placeholder='Nhập mật khẩu mới'
                icon={typePassword?.newPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> :
                  <i>{USER_PROFILE.eye}</i>}

                onIconClick={
                  () => setTypePassword({
                    ...typePassword,
                    newPassword: typePassword?.newPassword === 'password' ? '' : 'password'
                  })
                }
                validateText={validateForm?.newPassword}
                validateType={!validateForm?.newPassword ? 'success' : 'danger'}
                onChange={(e) => handleChangeForm({ newPassword: removeVietnameseTones(e.target.value) })}
                onBlur={handleBlurNewPassword}
                maxLength={32}
              />
            </div>
            <div style={{ height: 81 }}>
              <Input
                type={typePassword?.confirmPassword}
                label={
                  <>
                    Xác nhận mật khẩu mới <Text color={THEME_SEMANTICS.failed}>*</Text>
                  </>
                }
                value={dataForm?.confirmPassword}
                placeholder='Nhập lại mật khẩu mới'
                icon={typePassword?.confirmPassword === 'password' ? <i>{USER_PROFILE.not_eye}</i> :
                  <i>{USER_PROFILE.eye}</i>}
                onIconClick={
                  () => setTypePassword({
                    ...typePassword,
                    confirmPassword: typePassword?.confirmPassword === 'password' ? '' : 'password'
                  })
                }
                validateText={validateForm?.confirmPassword}
                validateType={!validateForm?.confirmPassword ? 'success' : 'danger'}
                onChange={(e) => handleChangeForm({ confirmPassword: removeVietnameseTones(e.target.value) })}
                onBlur={handleBlurConfirmPassword}
                maxLength={32}
              />
            </div>
          </div>
          <div className={cls(css.action)}>
            <hr/>
            <Button
              appearance='secondary' // primary | secondary | ghost
              className={cls(css.dismiss)}
              onClick={() => setOpen(false)}
            >
              {t('general_cancel')}
            </Button>
            <Button className={cls(css.save)} onClick={handleSubmitChangePassword}>
              {t('change_password')}
            </Button>
          </div>
        </div>
      </Box>
      {confirm && (
        <ModalConfirm txtConfirm={t('general_confirm')} handleConfirm={handleConfirm}/>
      )}
    </StyleChangePassword>
  )
}

const StyleChangePassword = styled.div`
  padding: 16px;
`

export default Index
