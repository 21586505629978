import styled from 'styled-components'
import {Text} from 'common/text'
import ReactApexChart from 'react-apexcharts'
import React, {useContext, useEffect} from 'react'
import {Dashboard} from '../../provider/_context'
import {sendRequestAuth} from '../../../../../api/api'
import config from '../../../../../config'
import {useDashboardAction} from '../../provider/_reducer'
import {formatMoney} from '../../../../../util/functionUtil'
import {LoadingChart} from './loadingChart'
import {fNumber} from "../../../../../util/formatNumber";

export const StatisticCommission = () => {
   const {pageState, pageDispatch} = useContext(Dashboard)
   const {loadingCommission} = pageState
   const {dateTime} = pageState
   const {rate} = pageState
   const {commission} = rate
   const {customers} = rate
   const chartCommissionOption = {

      series: commission?.total,
      options: {
         chart: {
            width: 380,
            type: 'pie',
         },
         labels: commission?.title,
         dataLabels: {enabled: false, dropShadow: {enabled: false}},
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 200
               },
               legend: {
                  position: 'bottom',
               }
            }
         }],

         stroke: {
            width: 0,
         },
         tooltip: {
            enabled: true,
            fillSeriesColor: false,
            custom: function ({series, seriesIndex, dataPoint, w}) {
               return '<div class="arrow_box">' +
                  '<div class="item-tooltip">' +
                  '<p>' + w.config.labels[seriesIndex] + '</p>' +
                  '</div>' +
                  '<div class="item-tooltip">' +
                  '<p>' + formatMoney(series[seriesIndex]) + '</p>' +
                  '</div>' +
                  '</div>'
            }
         },

         plotOptions: {
            pie: {
               donut: {
                  labels: {
                     show: false, name: {
                        show: false,
                     },
                  }
               }
            },
         },
         legend: {
            show: true,
            markers: {
               width: 12,
               height: 12,
               strokeWidth: 0,
               strokeColor: '#fff',
               fillColors: undefined,
               customHTML: undefined,
               onClick: undefined,
               offsetX: 0,
               offsetY: 0
            },
            itemMargin: {
               horizontal: 5,
               vertical: 0
            },
            onItemClick: {
               toggleDataSeries: true
            },
            onItemHover: {
               highlightDataSeries: true
            },
         }

      },


   }
   const chartCustomerOption = {

      series: customers?.total,
      options: {
         chart: {
            width: 380,
            type: 'pie',
         },
         labels: customers?.title,
         dataLabels: {enabled: false, dropShadow: {enabled: false}},
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 200
               },
               legend: {
                  position: 'bottom',
               }
            }
         }],

         stroke: {
            width: 0,
         },
         tooltip: {
            enabled: true,
            fillSeriesColor: false,
            custom: function ({series, seriesIndex, dataPoint, w}) {
               return '<div class="arrow_box">' +
                  '<div class="item-tooltip">' +
                  '<p>' + w.config.labels[seriesIndex] + '</p>' +
                  '</div>' +
                  '<div class="item-tooltip">' +
                  '<p>' + fNumber(series[seriesIndex]) + ' Khách hàng</p>' +
                  '</div>' +
                  '</div>'
            }
         },

         plotOptions: {
            pie: {
               donut: {
                  labels: {
                     show: false, name: {
                        show: false,
                     },
                  }
               }
            },
         },

         legend: {
            show: true,
            markers: {
               width: 12,
               height: 12,
               strokeWidth: 0,
               strokeColor: '#fff',
               fillColors: undefined,
               customHTML: undefined,
               onClick: undefined,
               offsetX: 0,
               offsetY: 0
            },
            itemMargin: {
               horizontal: 5,
               vertical: 0
            },
            onItemClick: {
               toggleDataSeries: true
            },
            onItemHover: {
               highlightDataSeries: true
            },
         }
      },


   }

   useEffect(
      () => {
         const getChartCommission = async () => {
            const response = await sendRequestAuth('get', `${config.API}/dashboard/principal/rate?date_type=${dateTime.value.value}`)
            if (!!response?.data?.success) {
               pageDispatch({
                  type: useDashboardAction.UPDATE_DASHBOARD,
                  payload: {
                     rate: {
                        commission: {
                           title: response?.data?.data?.commission.map(item => item?.teacher_last_name + " " + item?.teacher_name),
                           total: response?.data?.data?.commission.map(item => +item?.totals),
                        },
                        customers: {
                           title: response?.data?.data?.customers.map(item => item?.teacher_last_name + " " + item?.teacher_name),
                           total: response?.data?.data?.customers.map(item => +item?.totals),
                        }
                     }
                  }
               })
               pageDispatch({
                  type: useDashboardAction.IS_LOADING_COMMISSION,
                  payload: false
               })
            }
         }
         getChartCommission()
      }
      , [dateTime.value])

   return (
      <StyledContent>
         <div className={'content-report'}>
            {
               !loadingCommission ?
                  <div className={'content-report__wrapper'}>
                     <div className={'content-report__chart'}>
                        <div className={'content-report__chart-content'}>
                           <div className={'content-report__legend'}>
                              <div className={'content-report__legend-item'}>
                                 <Text>% Hoa hồng</Text>
                              </div>
                              <div className={'content-report__legend-item'}>
                              </div>
                           </div>
                           <ReactApexChart
                              options={chartCommissionOption.options}
                              series={chartCommissionOption.series}
                              type="pie"
                              height={300}/>
                        </div>
                        <div className={'content-report__chart-content'}>

                           <div className={'content-report__legend'}>
                              <div className={'content-report__legend-item'}>
                                 <Text>% Khách hàng</Text>
                              </div>
                              <div className={'content-report__legend-item'}>
                              </div>
                           </div>
                           <ReactApexChart
                              options={chartCustomerOption.options}
                              series={chartCustomerOption.series}
                              type="pie"
                              height={300}/>
                        </div>
                     </div>
                  </div>
                  : <LoadingChart/>
            }
         </div>
      </StyledContent>
   )
}

const StyledContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  .apx-legend-position-right{
    right: 60px!important;
  }
  .mixed-chart_series{
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    &-legend-box{
      display: flex;
      align-items: center;
      div{
        margin-right: 44px;
      }
    }
  }
  .content-report{
    margin-bottom: 8px;
     &__chart{
      display: flex;
      &-content{
        width: 50%;
      }
     }
    &__legend{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;
      &-icon{
        background: #0ce50f;
        width: 40px!important;
        height: 16px!important;
        margin-right: 8px;
        border-radius: 4px;
      }
      &-nicon{
        background: #dc2121;
        width: 40px!important;
        height: 16px!important;
        margin-right: 8px;
        border-radius: 4px;
      }
      &-item{
        margin-right: 68px;
        display: flex;
        align-items: center;
      }
    }
    &__title{
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__wrapper{
      width: 100%;
      display: block;
      //margin: 0 -24px;
      &-image{
        width: 100%;
      }
    }
    
  }
  .arrow_box{
    width: 180px!important;
  }
  .arrow_box_commission{
    width: 180px!important;
  }
  .content-report__wrap{
    img{
      width: 100%;
      height: 411px;
    }
  }
  .content-report__wrapper{
    margin-bottom: -24px;
  }
`