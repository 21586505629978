import React, {memo} from "react";
import useGlobalContext from "../../containerContext/storeContext";
import {CommissionReport} from "./commissionPrincipal";
import {CommissionTeacherReport} from "./commissionTeacher";


export const CommissionComponent = memo(() => {
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type
    if (+userType === 3)
        return <CommissionTeacherReport/>
    return <CommissionReport/>

})
