export const useDashboardAction = {

    UPDATE_QR_CODE: 'UPDATE_QR_CODE',
    IS_LOADING_PANEL: 'IS_LOADING_PANEL',
    UPDATE_DASHBOARD: 'UPDATE_DASHBOARD'
}
export const useDashboardInitialState = {
    qrCode: {
        link: null,
        image: null
    },
    modal: {
        open: false
    },
    loading: false,
    panels: null,
}

export const useDashboardReducer = (state, action) => {
    switch (action.type) {
        case useDashboardAction.UPDATE_QR_CODE:
            return {
                ...state,
                qrCode: {
                    ...state.qrCode,
                    ...action?.payload
                },
            }
        case useDashboardAction.IS_LOADING_PANEL:
            return {
                ...state,
                loading: action?.payload
            }
        case useDashboardAction.UPDATE_DASHBOARD:
            return {
                ...state,
                panels: action?.payload
            }
        default:
            break
    }
}