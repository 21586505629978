import styled from 'styled-components'

export const StyledDashboardPanels = styled.div`
  margin: 4px 0 16px 0;
  padding: 16px 12px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;

  .order-panels {
    &__item {
      width: calc(25% - 16px);
      margin: 8px 8px;
    }
  }
`
