import React from "react";
import {Input} from "../../../../../../Component/Input";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const Address = ()=>{
    const {address} = useCreateTeacherForm()
    const {methods} = address
    return (
        <Input
            label={
                <>
                    Địa chỉ
                </>
            }
            value={address?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={address?.message}
            validateType={address?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập địa chỉ'}
            maxLength={101}
        />
    )
}