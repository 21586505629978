import {Th} from 'layouts/tableLayout/_th'
import {Tr} from 'layouts/tableLayout/_tr'
import React from 'react'
import {Text} from 'common/text'

export const TheadByCustomer = ({...props}) => {

    return (
        <Tr {...props} type="tHead">
            <Th className="commission-teacher-table__cell">Khách hàng</Th>
            <Th className="commission-teacher-table__cell">Số đơn hàng</Th>
            <Th className="commission-teacher-table__cell">Đơn hàng sách</Th>
            <Th className="commission-teacher-table__cell">Đơn hàng số</Th>
            <Th className="commission-teacher-table__cell">
                <Text as={'p'} style={{width: '100%'}} fontWeight={600}>Doanh thu</Text>
                <Text as={'p'} style={{width: '100%'}}>(Sau giảm giá)</Text>
            </Th>
            <Th className="commission-teacher-table__cell">Hoa hồng</Th>
        </Tr>
    )
}
