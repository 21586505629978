import {THEME_COLORS} from "common/theme/_colors";
import styled from "styled-components";

export const StyledProductGroupContentModal = styled.div`
  .customer-filter-form {
    &__btn-refresh{
      svg{
        display: flex;
      }
      margin-left: 12px;
    }
    &__group {
      width: calc(100% + 12px);
      margin: 0 -6px 16px -6px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin: 0 6px;
      }
    }

    &__collapse {
      max-height: 0;
      margin-bottom: 0 !important;

      overflow: hidden;

      transition: all 0.25s;

      &[data-collapse='true'] {
        max-height: 50vh;
        margin-bottom: 16px;

        overflow: unset;
      }
    }

    &__option-text {
      min-height: 36px;

      display: flex;
      align-items: center;

      color: ${THEME_COLORS.gray_900};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      transition: color 0.25s;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;
      }
      &:hover {
        color: ${THEME_COLORS.primary_300};
      }
    }

    &__option-container {
      min-height: 45px;
      margin-bottom: 4px;

      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-tabs {
      position: sticky;
      top: 0;
      z-index: 1;

      width: 100%;
      height: 28px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;

      background: #fff;

      &::before {
        position: absolute;
        top: -20px;
        left: -20px;

        width: calc(100% + 40px);
        height: calc(100% + 36px);

        background: #fff;
        border-radius: 8px 8px 0 0;

        content: '';
      }
    }

    &__option-tab {
      position: relative;
      z-index: 2;

      margin-right: 16px;

      color: #808089;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      cursor: pointer;

      &[data-active='true'] {
        color: ${THEME_COLORS.primary_300};
        font-weight: 600;

        cursor: default;
      }
    }

    &__collapse {
      .customer-filter-form__input-wide {
        margin-bottom: 16px;
      }
    }
  }
  .product-group-content{
      &__top{
        border-bottom: 1px solid #DDDDE3;
      }
      &_group{
          width: 100%;
          margin-top: 24px;
        &:nth-child(4){
          div{
              height: 96px;
          }
        }
          .tooltip_select{
              display: -webkit-box;
              height: 100%;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
          }
          .product-group-content_alternative{
              input{
                  cursor: not-allowed !important;
              }
              
          }
         .product-group-content_alternative-option{
              // cursor: not-allowed;
              // color: #7c88a6 !important;
              display: none;
          }
      }
      &_select{
          margin-top: 8px;
          
      }
      &_unSelect{
          input{
              color : #7C88A6;
          }
          
      }
      &_switch{
          display: flex;
          align-item: center;
          margin-top: 32px;
          p{
              margin-left: 8px;
          }
      }
      &_option{
          margin-top:8px;
          cursor: pointer;
          &:hover{
              color:${THEME_COLORS.primary_300};
          }
          &[data-active='true'] {
              color: ${THEME_COLORS.primary_300};
              font-weight: 600;
              cursor: default;
            }
      }
       &_group-textArea{
          height:auto;
          div{
          height:auto !important;
          }
          textarea{
          resize: none;
          }
      }
  }
 
 @media screen and ( max-width: 1366px){
 .product-group-content{
          &_group{
              margin-top: 20px;
              .alternative-auto-complete__menu{
                 padding-bottom: 5rem;
              }
          } 
 }
 
  }
`