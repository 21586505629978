
export const useCreateProductAction = {
  FILTER_SEARCH_UPDATE:'PRODUCT_FILTER_SEARCH_UPDATE',
  FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'FILTER_CATEGORY_LIST_ORIGIN_UPDATE',
  FILTER_CATEGORY_VALUE_UPDATE: 'PRODUCT_FILTER_CATEGORY_VALUE_UPDATE',
  FILTER_CATEGORY_LIST_UPDATE: 'PRODUCT_FILTER_CATEGORY_LIST_UPDATE',
  FILTER_STATUS_VALUE_UPDATE: "PRODUCT_FILTER_STATUS_VALUE_UPDATE",
  TABLE_LOADING_DISPLAY: 'PRODUCT_TABLE_LOADING_DISPLAY',
  TABLE_UPDATE_DISPLAY_LIST: 'PRODUCT_TABLE_UPDATE_DISPLAY_LIST',
  TABLE_UPDATE_PAGINATION: 'PRODUCT_TABLE_UPDATE_PAGINATION',
  TABLE_SELECTED_LIST_UPDATE: "TABLE_SELECTED_LIST_UPDATE"
}
export const useModalProductAction = {
  DISPLAY_MODAL_PRODUCT:'DISPLAY_MODAL_PRODUCT',
  FILTER_SEARCH_UPDATE:'MODAL_PRODUCT_FILTER_SEARCH_UPDATE',
  FILTER_CATEGORY_LIST_ORIGIN_UPDATE: 'MODAL_FILTER_CATEGORY_LIST_ORIGIN_UPDATE',
  FILTER_CATEGORY_VALUE_UPDATE: 'MODAL_PRODUCT_FILTER_CATEGORY_VALUE_UPDATE',
  FILTER_CATEGORY_LIST_UPDATE: 'MODAL_PRODUCT_FILTER_CATEGORY_LIST_UPDATE',
  TABLE_LOADING_DISPLAY: 'MODAL_PRODUCT_TABLE_LOADING_DISPLAY',
  TABLE_UPDATE_DISPLAY_LIST: 'MODAL_PRODUCT_TABLE_UPDATE_DISPLAY_LIST',
  TABLE_UPDATE_PAGINATION: 'MODAL_PRODUCT_TABLE_UPDATE_PAGINATION',
  TABLE_SELECTED_LIST_UPDATE: 'MODAL_SELECTED_LIST_UPDATE',
  RESET_MODAL_FILTER:'RESET_MODAL_FILTER',
}