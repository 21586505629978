import React, {useContext, useState} from 'react'
import {Skeleton} from '@mui/material'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Tr} from 'layouts/tableLayout/_tr'
import {Td} from 'layouts/tableLayout/_td'
import {Text} from 'common/text'
import {QrAffiliateContext} from "../../../provider/_context";
import {RowMenuPopover} from "./_rowMenuPopover";
import {useQrAffiliateAction} from "../../../provider/_action";
import LogoQr from "../../../interface/icon.svg";
import {QRCode} from "react-qrcode-logo";
import useAlert from "../../../../../hook/useAlert";

const TbodyCategory = () => {
  const {t} = useTranslation()
  const {pageState} = useContext(QrAffiliateContext)
  const {eventTab} = pageState
  const {table} = eventTab
  const displayList = table?.display?.list
  const displayListDefault = table?.display?.listDefault
  const displayLoading = table.display.loading
  const paginationAmount = table.pagination.amount
  const paginationTotalItems = table.pagination.totalItems

  return (
    <StyledTBody>
      {displayLoading ? (
        Array.from(Array(paginationAmount), (e, i) => (
          <CategoryPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map((item, key) => <CategoryTr t={t} key={item.id} stt={key} data={item}/>)
      ) : (
        <CategoryEmpty t={t} list={displayListDefault}/>
      )}
    </StyledTBody>
  )
}

export default TbodyCategory

const CategoryPlaceholder = ({...props}) => {
  return (
    <Tr {...props} className="create-teacher-table">
      {Array.from(Array(6), (e, i) => (
        <Td key={i} className="create-teacher-table_cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const CategoryEmpty = ({list, t, ...props}) => {
  return (
    <StyledCategoryEmpty {...props}>
      <img
        className="create-teacher-table-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
        {!!!list ? t('Bạn chưa có danh sách giáo viên nào') : t('no_matching_data')}
      </Text>
    </StyledCategoryEmpty>
  )
}

const CategoryTr = ({data, t, stt, ...props}) => {
  const {showAlert} = useAlert()
  const {pageDispatch} = useContext(QrAffiliateContext)
  const shareLink = () => {
    pageDispatch({
      type: useQrAffiliateAction.SET_DISPLAY_SHARE_MODAL,
      payload: {
        open: true,
        referral: data?.miniapp_link
      }
    })
  }

  const handleDownload = () => {
    const element = document.getElementById(`qr-event-${data.id}`);
    const imgData = element.toDataURL('image/png')
    // eslint-disable-next-line
    saveAs(imgData, `qr-code__${data.id}.png`);
  };

  const copyContent = () => {
    navigator.clipboard.writeText(data?.miniapp_link)
    showAlert({
      type: 'success',
      content: "Đã copy link giới thiệu"
    })
  };

  const handleActionApply = action => {
    switch (action) {
      case 'share':
        shareLink()
        break
      case 'copy':
        copyContent()
        break
      case 'download':
        handleDownload()
        break
      default:
        break
    }
  }
  return (
    <>
      <Tr
        {...props}
        className="create-teacher-table__row"
      >
        <Td className="create-teacher-table__cell" data-type="td">
          {+stt + 1}
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text as={'p'} fontWeight={600}>{data?.title || '---'}</Text>
        </Td>
        <Td className="create-teacher-table__cell" data-type="td">
          <Text as={'a'} target={'_blank'}
                href={data?.miniapp_link}
                color={'#3264ff'}>{data?.miniapp_link || '---'}</Text>
          <div style={{display: 'none'}}>
            <QRCode
              id={`qr-event-${data.id}`}
              value={data?.miniapp_link}
              qrStyle={'dots'} level={'L'}
              eyeRadius={5}
              eyeColor={"#002140"}
              fgColor={"#002140"}
              logoImage={LogoQr} size={600}
              logoWidth={150} logoHeight={150}
            />
          </div>
        </Td>
        <Td
          className="create-teacher-table__cell"
          data-menu="true"
          data-type="td"
        >
          <RowMenuPopover
            id={data.id}
            data={data}
            onActionClick={handleActionApply}
          />
        </Td>
      </Tr>
    </>
  )
}

export const StyledTBody = styled.div`
  .create-teacher-table {
    &__loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;

      width: 100vw;
      height: 100vh;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.25);

      img {
        width: 80px;
        height: 80px;
      }
    }

    &__row {
      &:hover {
        .create-teacher-table__delete {
          display: block;
        }
        .create-teacher-table__edit {
          display: block;
        }
      }
    }

    &__cell {
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &[data-menu='true'] {
        position: relative;
      }

      &--success {
        color: #0B74E5 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ECF4FE;
        border-radius: 4px;
      }
      &--danger {
        color: #FF424E !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #FFEBEC;
        border-radius: 4px;
      }
      &:nth-child(1) {
        width: 50px;
        display: flex;
        align-items: center;
      }
      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        flex: 1;
      }
      &:nth-child(4) {
        width: 100px;
        align-items: center;
        justify-content: center;
      }

    }
    &__edit {
      width: 20px;
      height: 20px !important;
      margin-right: 16px;
      background: transparent;
      border: none;
      border-radius: 12px !important;

      font-size: 12px !important;
      line-height: 24px !important;

      cursor: pointer;
    }

    &__selected-action-dropdown {
      position: relative;

      margin-left: 12px;
    }

    &__selected-action-toggle {
      width: 88px;
      padding: 0 !important;

      border-radius: 14px !important;

      font-size: 14px !important;
      font-weight: 500 !important;
    }

    &__selected-action-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;

      width: 100vw;
      height: 100vh;
    }

    &__selected-action-menu {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 12;

      width: 150px;
      padding: 8px;

      background: #ffffff;
      border-radius: 6px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    }

    &__selected-action-menu-item {
      padding: 8px;

      color: #191d32;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      transition: color 0.25s;

      cursor: pointer;

      &:hover {
        color: #3264FF;
      }
    }

    &__tooltipV2 {
      width: 100%;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}

.tab-detail-order {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}
`
export const StyledCategoryEmpty = styled.div`
  min-height: calc(100vh - 430px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  .principal-empty__banner {
    width: 133px;
    height: 133px;
    margin-bottom: 16px;

    object-fit: contain;
    object-position: center;
  }
`
