import React, {useContext} from 'react'
import {CommissionContext} from '../../provider/_context'
import {TheadByDate} from './thead/_theadByDate'
import {TheadByOrder} from './thead/_theadByOrder'
import {TheadByCustomer} from './thead/_theadByCustomer'

export const CommissionTHead = ({...props}) => {

    const {pageState} = useContext(CommissionContext)
    const {pageOption} = pageState
    const listHeader = [
        {id: 1, value: 'date', component: <TheadByDate/>},
        {id: 2, value: 'order', component: <TheadByOrder/>},
        {id: 3, value: 'customer', component: <TheadByCustomer/>},
    ]
    return (
        <>
            {listHeader.find(item => item.value === pageOption?.value)?.component}
        </>
    )
}
