import { profileActions } from "./~action";

export const profileReducer = (state, action) => {
    switch (action.type) {
        case profileActions.UPDATE_USER_INFORMATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action?.payload?.name,
                    last_name: action?.payload?.last_name,
                    phone: action?.payload?.phone,
                    avatar: action?.payload?.avatar,
                }
            }
        case profileActions.CHANGE_LOADING:
            return {
                ...state,
                loading: action?.payload
            }
        case profileActions.FORM_INIT_USER_DATA:
            return {
                ...state,
                userData: action?.payload?.userData
            }
        case profileActions.FORM_CHANGE_VALUE:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...action?.payload
                }
            }
        case profileActions.VALIDATE_FORM_CHANGE:
            return {
                ...state,
                formValidate: {
                    ...state.formValidate,
                    ...action?.payload
                }
            }
        case profileActions.FORM_CHANGE_NAME:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    name: action?.payload
                }
            }
        case profileActions.VALIDATE_FORM_CHANGE_NAME:
            return {
                ...state,
                formValidate: {
                    ...state.formValidate,
                    name: {
                        status: action?.payload?.status,
                        message: action?.payload?.message,
                    }
                }
            }
        case profileActions.FORM_CHANGE_LAST_NAME:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    last_name: action?.payload
                }
            }
        case profileActions.VALIDATE_FORM_CHANGE_LAST_NAME:
            return {
                ...state,
                formValidate: {
                    ...state.formValidate,
                    last_name: {
                        status: action?.payload?.status,
                        message: action?.payload?.message,
                    }
                }
            }
        case profileActions.FORM_CHANGE_PHONE:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    phone: action?.payload
                }
            }
        case profileActions.VALIDATE_FORM_CHANGE_PHONE:
            return {
                ...state,
                formValidate: {
                    ...state.formValidate,
                    phone: {
                        status: action?.payload?.status,
                        message: action?.payload?.message
                    }
                }
            }
        case profileActions.FORM_UPLOAD_AVATAR:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    avatar: action?.payload
                }
            }
        case profileActions.HOVER_AVATAR:
            return {
                ...state,
                avatarHover: action?.payload
            }

        case profileActions.FORM_UPDATE_CHANGE_LAST_NAME:
            return {
                ...state,
                formCreate: {
                    ...state.formCreate,
                    basic: {
                        ...state.formCreate.basic,
                        publisher: action.payload
                    }
                }
            }
        default:
            throw new Error()
    }
}