export const transformOriginData = data => {
  return {
    paymentMethod: {
      list: data.paymentMethodListData.filter(x => x.connected).map(item => ({
        name: item?.name || '',
        value: item?.id || '',
        connected: item?.connected || false,
      })),
    },
    paymentStatus: {
      list: data.paymentStatusListData.map(item => ({
        name: item?.name || '',
        value: item?.id || '',
      })),
    },
    invoiceStatus: {
      list: data.invoiceStatusListData.map(item => ({
        name: item?.invoiceStatus_name || '',
        value: item?.id || '',
      })),
    },
  }
}


export const transformPaymentMethodData = data => ({
  data,
  name: data?.name || '---',
  value: data?.id || '',
})


export const transformQueryObjToString = data => {
  let queryString = '?'
  let i = 0
  for (const [key, value] of Object.entries(data)) {
    queryString += `${i > 0 ? '&' : ''}${key}=${value}`
    i++
  }
  return queryString
}

export const generatePaymentPeriods = () => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  let startingMonth = 7; // Tháng 7
  let endingMonth = currentMonth;
  const periods = [];
  for (let year = currentYear; year > currentYear - 1; year--) {
    for (let month = startingMonth; month <= endingMonth; month++) {
      const period = `${month < 10 ? '0' + month : month}/${year}`;
      periods.push(period);
    }
    startingMonth = 1
    endingMonth = 12
  }
  return periods;
};