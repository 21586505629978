import styled from "styled-components";

export const StyleProductGroupEmpty = styled.div`
    .product-group-empty{
        .tr__container{
            height: 640px;
            justify-content: center;
            background: #ffffff !important;
        }
        &_item{
            text-align: center;
        }
        &_text{
            margin: 1rem 0;
        }
    }
`