import React from 'react';
import {Text} from "../../common/text";
import styled from "styled-components";
import {Box, Modal} from "@mui/material";

const PopupNotPermission = ({cID, cAnchorEl, handleClose, ...props}) => {
  const open = true;
  return (
    <Modal open={open}>
      <Box>
        <StyledPopupNotPermission {...props}>
          <Text className={'not-licensed-cancel'} onClick={handleClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.22473 18.9684L5.03125 17.775L10.8062 12L5.03125 6.22497L6.22473 5.03149L11.9997 10.8065L17.7747 5.03149L18.9682 6.22497L13.1932 12L18.9682 17.775L17.7747 18.9684L11.9997 13.1934L6.22473 18.9684Z" fill="black"/>
            </svg>
          </Text>
          <img src={'/img/not-license.png'} alt={'img'} className={'not-licensed-img-primary'}/>
          <Text fontWeight={600} fontSize={18} as={'p'} className={'not-licensed-text not-licensed-text-first'}>Tài khoản của bạn không được quyền thực hiện thao tác này</Text>
          <Text fontSize={14} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-text-second'}>Vui lòng thực hiện cấp quyền cho tài khoản để bắt đầu sử dụng tính năng nhé!</Text>
        </StyledPopupNotPermission>
      </Box>
    </Modal>
  )
}

export default PopupNotPermission

export const StyledPopupNotPermission = styled.div`
  padding: 48px 24px 24px 24px;
  text-align: center;
  width: 600px;
  height: 352px;
  background: #fff;
  margin: 30vh auto auto auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  
  .not-licensed {
    &-cancel {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    &-img-primary {
      width: 150px;
      height: 108px;
    }
    &-text {
      text-align: center;
      width: 100% !important;
      &-first {
        margin-top: 32px;
      }
      &-second {
        margin-top: 8px;
      }
      &-phone {
        margin-right: 16px;
        & svg {
          vertical-align: middle;
          margin-right: 4px;
        }
        & a {
          color: #1A94FF;
        }
      }
    }
    &-hotline {
      margin: 16px 0 8px 0;
    }
    &-group-phone {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #F8FAFD;
      height: 82px;
    }
  }
`
