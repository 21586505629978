import React, {memo, useEffect} from "react";
import { TeacherProvider } from "./provider";
import PageHeader from "./component/header/index";
import { TableLayout } from "layouts/tableLayout";
import useTeacher from "./hook/useTeacher";
import FilterForm from "./component/filter";
import TeacherHeader from "./component/table/thead"
import TeacherBody from "./component/table/tbody"
import useGlobalContext from "../../containerContext/storeContext";
import NotPermission from "../permision/notPermission";
export const TeacherComponent= memo(() =>{
    const {provider, fetch, pagination} = useTeacher()
    const {state, dispatch} = provider
    const {table} = state
    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type
    if (+userType === 3) {
        return <NotPermission/>
    }
    useEffect(() => {
        fetch.origin()
    }, [])
    return (
        <TeacherProvider  value={{ pageState: state, pageDispatch: dispatch }}>
            <PageHeader/>
            <TableLayout
                header={
                    <>
                        <FilterForm />
                    </>
                }
                table={{
                    tHead: <TeacherHeader />,
                    tBody: <TeacherBody />,
                }}
                pagination={{
                    ...table.pagination,
                    onAmountChange: pagination.onAmountChange,
                    onPageChange: pagination.onPageChange,
                }}
            />
        </TeacherProvider>

    )
})