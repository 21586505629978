import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useStudentFilterForm from 'Pages/student/hooks/useStudentFilterForm'
import { StudentContext } from 'Pages/student/provider/_context'
import { studentActions } from 'Pages/student/provider/_reducer'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { StudentTags } from '../studentTags'
import { StudentDateTime } from './_studentDateTime'
import { StyledStudentFilterForm } from './_styled'
import { StudentInfo } from './_studentInfo'
import { StudentPrincipal } from './_studentPrincipal'
import { TeacherFilter } from './_studentTeacher'
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const StudentFilterForm = memo(({ ...props }) => {
    const { canSubmitOtherFilter, functions } = useStudentFilterForm()

    const { pageDispatch } = useContext(StudentContext)

    useEffect(() => {
        const fetchData = async () => {
            const response = await Promise.all([
                sendRequestAuth(
                    'get',
                    `${config.API}/principal/principals?per_page=999&start=0`,
                ),
                sendRequestAuth(
                    'get',
                    `${config.API
                    }/order/filter/digitals?keyword=&category_id=&status=&per_page=999&start=`,
                ),
                sendRequestAuth(
                    'get',
                    `${config.API
                    }/order/filter/principals?keyword=`,
                )
            ])
            pageDispatch({
                type: studentActions.FILTER_ORIGIN_DATA_UPDATE,
                payload: {
                    list: response[0].data?.data?.map(item => ({
                        name: item?.name || '',
                        value: item?.id || '',
                    })),
                    product: {
                        list: response[1]?.data?.map(item => ({
                            data: item || null,
                            name: item?.title || '',
                            value: item?.id || '',
                        })),
                    },
                    principal: {
                        list: response[2].data.map(item => ({
                            data: item || null,
                            name: (item?.last_name + " " + item?.name) || '',
                            value: item?.id || '',
                        })),
                    },
                },
            })
        }

        // fetchData()
    }, [])

    return (
        <StyledStudentFilterForm {...props}>
            <div
                className="student-filter-form__group student-filter-form__collapse"
                data-collapse={true}
            >
                <StudentInfo />
                <StudentPrincipal />
                <div className="student-filter-form__input-wide flex">
                    <Button
                        appearance="secondary"
                        disabled={!canSubmitOtherFilter}
                        size="md-"
                        onClick={() =>
                            canSubmitOtherFilter && functions.applyStudentOtherFilter()
                        }
                    >
                        Tìm kiếm
                    </Button>
                    <Button
                        appearance="secondary"
                        size="md-"
                        onClick={functions.refresh}
                        className={"student-filter-form__btn-refresh"}
                    >
                        {GLOBAL_ICONS.repeat}
                    </Button>
                </div>
            </div>
            <div className="student-filter-form__group" style={{ marginBottom: 4 }}>
                <StudentTags />
            </div>
        </StyledStudentFilterForm>
    )
})
