import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useStudentFilterForm from 'Pages/student/hooks/useStudentFilterForm'
import { STUDENT_FILTER_TAG_FIELDS } from 'Pages/student/interfaces/_constants'
import { StyledStudentTags } from './_styled'
import { StudentTag } from './_tag'

export const StudentTags = ({ ...props }) => {
    const {
        dateTime,
        studentInfo,
        student,
        principal,
        functions,
    } = useStudentFilterForm()

    const shouldShowResetAll = functions.hasFilter()
    const handleDeleteAll = () => functions.filterTagDeleteAll()

    return (
        <StyledStudentTags {...props}>
            {studentInfo.activeValue && (
                <StudentTag
                    onDelete={() => functions.filterTagDelete(STUDENT_FILTER_TAG_FIELDS[1])}
                >
                    Học sinh: {studentInfo.activeValue}
                </StudentTag>
            )}

            {principal?.activeValue?.name && (
                <StudentTag
                    onDelete={() => functions.filterTagDelete(STUDENT_FILTER_TAG_FIELDS[4])}
                >
                    Trạng thái: {principal.activeValue.name}
                </StudentTag>
            )}

            {shouldShowResetAll && (
                <Text
                    as="b"
                    color={THEME_SEMANTICS.delivering}
                    lineHeight={28}
                    style={{ marginBottom: 12, cursor: 'pointer' }}
                    onClick={handleDeleteAll}
                >
                    Đặt lại mặc định
                </Text>
            )}
        </StyledStudentTags>
    )
}
