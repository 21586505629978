import { sendRequestAuth } from 'api/api'
import { removeAcent } from 'common/fieldText/_functions'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import {
  CUSTOMER_FILTER_TAG_FIELDS,
} from '../interfaces/_constants'
import { CustomerContext } from '../provider/_context'
import { customerActions } from '../provider/_reducer'
import { debounce } from '@mui/material'
import { customerInitialState } from 'Pages/customer/provider/_initState'
import { useSearchParams } from 'react-router-dom'

const useCustomerFilterForm = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageState, pageDispatch } = useContext(CustomerContext)
  const { filter, table } = pageState


  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // DATE TIME
  // ===== ===== ===== ===== =====
  const { afterToday } = DateRangePicker
  const dateTimeActiveValue = filter.dateTime.activeValue
  const dateTimeEnd = filter.dateTime.end
  const dateTimeStart = filter.dateTime.start
  const dateTimeDefaultValue = [dateTimeStart,dateTimeEnd]
  const dateTimeType = filter.dateTime.type
  const dateTimeValue = filter.dateTime.value
  const dateTimeTrigger = filter.dateTime.trigger

  const handleDateTimeChange = data =>
    pageDispatch({
      type: customerActions.FILTER_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })


  // ===== ===== ===== ===== =====
  // principal
  // ===== ===== ===== ===== =====
  const principalActiveValue = filter.principal.activeValue
  const principalKeyword = filter.principal.keyword
  const principalList = filter.principal.list
  const principalListOrigin = filter.principal.listOrigin
  const principalValue = filter.principal.value

  const handlePrincipalChange = async (data) => {
    pageDispatch({
      type: customerActions.FILTER_PRINCIPAL_UPDATE,
      payload: { value: data },
    })
    // get list teacher
    const response = await sendRequestAuth('get', `${config.API}/order/filter/teachers?principal_id=${data?.value}`)
    pageDispatch({
      type: customerActions.FILTER_TEACHER_LIST_UPDATE,
      payload: {
        teacher: {
          list: response.data?.map(item => ({
            name: item?.last_name + (!!item?.last_name ? " "  : '' )+ item?.name || '',
            value: item?.id || '',
          })),
        }
      },
    })

  }

  const handlePrincipalKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const principalListData = principalListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_PRINCIPAL_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: principalListData,
      },
    })
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Teacher
  // ===== ===== ===== ===== =====
  const teacherActiveValue = filter.teacher.activeValue
  const teacherKeyword = filter.teacher.keyword
  const teacherList = filter.teacher.list
  const teacherListOrigin = filter.teacher.listOrigin
  const teacherValue = filter.teacher.value

  const handleTeacherChange = data =>
    pageDispatch({
      type: customerActions.FILTER_TEACHER_UPDATE,
      payload: { value: data },
    })

  const handleTeacherKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const teacherListData = teacherListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: customerActions.FILTER_TEACHER_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: teacherListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // CUSTOMER INFO
  // ===== ===== ===== ===== =====
  const customerInfoValue = filter.customerInfo.value
  const customerInfoActiveValue = filter.customerInfo.activeValue

  const handleCustomerInfoChange = value => {
    if (value === ' ') value = ''
    pageDispatch({
      type: customerActions.FILTER_CUSTOMER_INFO_UPDATE,
      payload: { value: value },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // STATUS FILTER
  // ===== ===== ===== ===== =====
  const statusValue = filter?.status?.value
  const statusActiveValue = filter?.status?.activeValue

  const handleStatusChange = value => {
    pageDispatch({
      type: customerActions.FILTER_STATUS_VALUE_UPDATE,
      payload: value
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // CUSTOMER TYPE FILTER
  // ===== ===== ===== ===== =====
  const customerTypeValue = filter?.customerType?.value
  const customerTypeActiveValue = filter?.customerType?.activeValue

  const handleCustomerTypeChange = value => {
    pageDispatch({
      type: customerActions.FILTER_CUSTOMER_TYPE_VALUE_UPDATE,
      payload: value
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  const canSubmitOtherFilter = [
    dateTimeActiveValue.value !== dateTimeValue ||
    JSON.stringify(dateTimeActiveValue.type) !== JSON.stringify(dateTimeType),
    JSON.stringify(principalActiveValue) !== JSON.stringify(principalValue),
    JSON.stringify(teacherActiveValue) !== JSON.stringify(teacherValue),
    JSON.stringify(statusActiveValue) !== JSON.stringify(statusValue),
    JSON.stringify(customerTypeActiveValue) !== JSON.stringify(customerTypeValue),
    JSON.stringify(customerInfoActiveValue) !== JSON.stringify(customerInfoValue),
  ].includes(true)

  const queries = {
    date_type: dateTimeActiveValue?.type?.value || '',
    start_date:
      dateTimeActiveValue?.start && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[0])
        : '',
    end_date:
      dateTimeActiveValue?.end && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[1])
        : '',
    principal_id: principalActiveValue?.value || '',
    teacher_id: teacherActiveValue?.value || '',
    status: statusActiveValue?.value || '',
    linked_status: customerTypeActiveValue?.value || '',
    keyword: customerInfoActiveValue || '',
    per_page: table?.pagination?.amount || 20,
    start: 0,
  }

  const debounceCustomerByFilter = useCallback(debounce((keyword, queries) => {
    fetchCustomerByFilter(
      { ...queries, keyword: keyword.trim() },
      { forceLoading: true },
    )
  }, 500), [queries])

  const applyCustomerOtherFilter = async () => {
    const collection = {
      ...queries,
      date_type: dateTimeType?.value || '',
      start_date:
        dateTimeStart && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[0])
          : '',
      end_date:
        dateTimeEnd && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[1])
          : '',
      principal_id: principalValue?.value || '',
      status: statusValue?.value || '',
      linked_status: customerTypeValue?.value || '',
      teacher_id: teacherValue?.value || '',
      keyword: customerInfoValue || '',
    }
    fetchCustomerByFilter(collection, { forceLoading: true })
  }

  const fetchCustomerByFilter = async (qs, opt) => {
    setSearchParams('')
    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: customerActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: true } } },
      })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/principal/customer/customers${queryString}&type=2`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: customerActions.OTHER_FILTER_APPLY,
        payload: {
          display: {
            list: response[0].data.data
          },
          pagination: {
            active: opt?.activePage || 0,
            amount: table.pagination.amount,
            total: response[0]?.data?.meta?.total
              ? Math.ceil(
                response[0]?.data?.meta?.total / table.pagination.amount,
              )
              : 0,
            totalItems: response[0]?.data?.meta?.total || 0,
          },
        },
      })
    }

    if (!!!opt?.notClearDetail)
      pageDispatch({
        type: customerActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })

    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: customerActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })

    pageDispatch({
      type: customerActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: null },
    })
  }

  const filterTagDelete = t => {
    pageDispatch({
      type: customerActions.TAG_FILTER_DELETE,
      payload: { type: t, isSingle: true },
    })

    let tmpCollection = {}
    switch (t) {
      case 'dateTime.current':
        tmpCollection = {
          ...tmpCollection,
          date_type: '',
          start_date: '',
          end_date: '',
        }

        pageDispatch({
          type: customerActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
          payload: { trigger: null },
        })

        break

      case CUSTOMER_FILTER_TAG_FIELDS[1]:
        tmpCollection = {
          ...tmpCollection,
        }
        break

      case CUSTOMER_FILTER_TAG_FIELDS[0]:
        tmpCollection = { ...tmpCollection, keyword: '' }
        break

      case CUSTOMER_FILTER_TAG_FIELDS[2]:
        tmpCollection = { ...tmpCollection, status: '' }
        break
      case CUSTOMER_FILTER_TAG_FIELDS[3]:
        tmpCollection = { ...tmpCollection, linked_status: '' }
        break

      case CUSTOMER_FILTER_TAG_FIELDS[4]:
        tmpCollection = { ...tmpCollection, principal_id: '' }
        break

      case CUSTOMER_FILTER_TAG_FIELDS[5]:
        tmpCollection = { ...tmpCollection, teacher_id: '' }
        break

      default:
        break
    }

    const collection = { ...queries, ...tmpCollection }

    fetchCustomerByFilter(collection, { forceLoading: true })
  }

  const filterTagDeleteAll = isSoft => {
    CUSTOMER_FILTER_TAG_FIELDS.forEach(item =>
      pageDispatch({
        type: customerActions.TAG_FILTER_DELETE,
        payload: { type: item },
      }),
    )

    pageDispatch({
      type: customerActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
      payload: {
        trigger: dateTimeTrigger === null ? true : !dateTimeTrigger,
      },
    })

    if (isSoft) return

    const collection = {
      ...queries,
      date_type: 'created',
      start_date: '',
      end_date: '',
      product_ids: '',
      principal_id: '',
      teacher_id: '',
      keyword: '',
      customer_id: '',
      status: ''
    }

    fetchCustomerByFilter(collection, { forceLoading: true })
  }

  return {
    pageState,
    dateTime: {
      activeValue: dateTimeActiveValue,
      defaultValue: dateTimeDefaultValue,
      disabledDate: afterToday(),
      triggerDefault: dateTimeTrigger,
      start: filter.dateTime.start,
      end: filter.dateTime.end,
      value: dateTimeValue,
      onChange: handleDateTimeChange,
    },
    customerInfo: {
      value: customerInfoValue,
      activeValue: customerInfoActiveValue,
      onChange: handleCustomerInfoChange,
    },
    status: {
      value: statusValue,
      activeValue: statusActiveValue,
      onChange: handleStatusChange,
    },
    customerType: {
      value: customerTypeValue,
      activeValue: customerTypeActiveValue,
      onChange: handleCustomerTypeChange,
    },
    principal: {
      activeValue: principalActiveValue,
      keyword: principalKeyword,
      list: principalList,
      value: principalValue,
      onChange: handlePrincipalChange,
      onKeywordChange: handlePrincipalKeywordChange,
    },
    teacher: {
      activeValue: teacherActiveValue,
      keyword: teacherKeyword,
      list: teacherList,
      listOrigin: teacherListOrigin,
      value: teacherValue,
      onChange: handleTeacherChange,
      onKeywordChange: handleTeacherKeywordChange,
    },
    canSubmitOtherFilter,
    queries,
    functions: {
      hasFilter: () => [
        JSON.stringify(dateTimeActiveValue?.value) !==
        JSON.stringify(customerInitialState.filter.dateTime.activeValue?.value),
        !!principalActiveValue?.name,
        !!teacherActiveValue?.name,
        !!statusActiveValue?.name,
        !!customerTypeActiveValue?.name,
        !!customerInfoActiveValue,
      ].includes(true),
      applyCustomerOtherFilter,
      refresh: () =>
        fetchCustomerByFilter(
          {
            ...queries,
            type: 2,
            start: table.pagination.active * table.pagination.amount || 0,
          },
          { activePage: table.pagination.active, forceLoading: true },
        ),
      fetchUpdateData: () =>
        fetchCustomerByFilter(
          {
            ...queries,
            type: 1,
            start: table.pagination.active * table.pagination.amount,
          },
          { activePage: table.pagination.active, notClearDetail: true },
        ),
      filterTagDelete,
      filterTagDeleteAll,
    },
  }
}

export default useCustomerFilterForm
