import {PageHeader} from "layouts/pageHeader";
import React, {useState} from "react";
import {useContext} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {TeacherContext} from "../../provider/_context";
import {INVENTORY_COLUMN_NAMES, TEACHER_BREADCUM, TEACHER_BUTTON_ACTION} from "../../interface";
import useTeacherFilter from "../../hook/useTeacherFilter";
import {ImportFileModal} from "../../../../Component/importComponent";
import {useTeacherAction} from "../../provider/_reducer";
import {ResultImport} from "../modal/_resultImport";
import useAlert from "../../../../hook/useAlert";
import {ModalExport} from "../../../../common/modalExport";

const Index = () => {
  const {t} = useTranslation()
  const [exportModalData, setExportModalData] = useState(null)
  const {pageState, pageDispatch} = useContext(TeacherContext)
  const {queries, methods} = useTeacherFilter()
  const {showAlert} = useAlert()

  const handleExportClick = () => {
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...queries,
      per_page: '',
      start: '',
    })) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    handleLargeExport(
      {
        ...queries,
        per_page: '',
        start: '',
      }
    )
  }

  const handleLargeExport = (q) => {
    if (pageState.table.pagination.totalItems <= 0) {
      showAlert({type: 'info', content: `Số lượng giáo viên phải lớn hơn  0`})
      return
    }

    setExportModalData({
      data: {
        query: q,
        total: pageState.table.pagination.totalItems,
      },
      onClose: () => setExportModalData(null),
    })
  }
  const {TEACHER_ACTION_BUTTON} = TEACHER_BUTTON_ACTION(pageState, pageDispatch, t, {
    refresh: methods?.applyOtherFilter,
    export: handleExportClick
  })
  const onCloseImport = () => {
    pageDispatch({type: useTeacherAction.MODAL_IMPORT_TEACHER, payload: false})
  }
  const handleCollectionData = (data) => {
    const sliceData = data?.slice(
      INVENTORY_COLUMN_NAMES?.startRowNumber
    )
    let qs = {
      items: sliceData?.map((map, i) => (
        {
          "row": +INVENTORY_COLUMN_NAMES?.startRowNumber + (i + 1),
          "ordering": map[0],
          "username": map[1],
          "password": map[2],
          "name": map[3],
          "last_name": map[4],
          "status": map[5],
          "school_name": map[6],
          "phone": map[7],
          "email": map[8],
          "birthday": map[9],
          "gender": map[10],
          "address": map[11],
          "identity_number": map[12],
          "bank_number": map[13],
          "bank_owner": map[14],
          "bank_name": map[15]
        }
      ))
    }
    return qs
  }
  const handleGetResult = res => {
    if (res) {
      pageDispatch({
        type: useTeacherAction.MODAL_IMPORT_RESULT_OPEN,
        payload: {
          open: true,
          data: res
        }
      })
      methods?.applyOtherFilter()
    }
  }
  const handleCloseResult = _ => {
    pageDispatch({
      type: useTeacherAction.MODAL_IMPORT_RESULT_OPEN,
      payload: {
        open: false,
        data: null
      }
    })
  }
  return (
    <StyledProductGroupHeader>
      <div className={"teacher-header"}>
        <PageHeader
          actions={TEACHER_ACTION_BUTTON}
          breadcrumbLinks={TEACHER_BREADCUM}
          breadcrumbTitle={t("Quản lý giáo viên")}
        />
        {!!exportModalData &&
        <ModalExport data={exportModalData} title={'giáo viên'} api={`/principal/teacher/export-teachers`}/>}
      </div>
      {
        pageState?.importExcel?.open && <ImportFileModal
          onClose={onCloseImport}
          columnNames={INVENTORY_COLUMN_NAMES?.columnNames}
          startRowNumber={INVENTORY_COLUMN_NAMES?.startRowNumber}
          collectedData={handleCollectionData}
          url={'teacher/import'}
          title={'Tạo giáo viên bằng Excel'}
          importFile={'/files/teacher_template.xlsx'}
          positionStart={2}
          result={handleGetResult}
        />
      }
      {
        pageState?.importExcel?.result?.open && <ResultImport
          open={pageState?.importExcel?.result?.open}
          data={pageState?.importExcel?.result?.data}
          onClose={handleCloseResult}
        />
      }
    </StyledProductGroupHeader>
  )
}
export default Index;
const StyledProductGroupHeader = styled.div`
    .teacher-header{

    }
`