import React, { useContext, useEffect, useRef, useState } from 'react'
import { InvoiceProvider } from './provider'
import './components/invoiceTable/index.scss'
import useInvoice from './hooks/useInvoice'
import { PageHeader } from './components/invoicePageHeader'
import { PATH } from '../../const/path'
import { GridLayout } from '../../layouts/gridLayout'
import InfoBasic from './components/create/general'
import { PaymentScheduleContent } from './components/create/paymentSchedule'
import { PaymentInfo } from './components/create/paymentInfo'
import ActionFormBrnList from './components/create/actionFormBrnList'
import useGlobalContext from "../../containerContext/storeContext";
import NotPermission from "../permision/notPermission";

export const CreateInvoicePage = () => {
  const { fetch, provider } = useInvoice()

  const { state, dispatch } = provider

  const [globalState, globalDispatch] = useGlobalContext()
  const {user} = globalState
  const userType = user?.user_type
  if (+userType === 3) {
    return <NotPermission/>
  }

  useEffect(() => {
    fetch.createOrigin()
  }, [])
  return (
    <InvoiceProvider value={{ pageState: state, pageDispatch: dispatch }}>
      <GridLayout
        header={
          <PageHeader
            breadcrumbLinks={
              [
                { id: 1, name: 'Trang chủ', url: '#' },
                { id: 2, name: 'Kế toán', url: '#' },
                { id: 2, name: 'Phiếu chi hoa hồng', url: PATH.COMMISSION_VOUCHER },
                { id: 2, name: 'Tạo mới phiếu chi hoa hồng', url: '#' },
              ]
            }
            breadcrumbTitle={'Quản lý phiếu chi hoa hồng'}
          />
        }
        grid={[
          {
            width: 100,
            sections: [
              {
                title: 'Thông tin chung',
                props: {
                  style: {
                    position: 'relative',
                    padding: '24px',
                    // maxHeight: '453px'
                  },
                  children: <InfoBasic/>,
                },
              },
              {
                title: 'Kỳ thanh toán',
                props: {
                  style: {
                    position: 'relative',
                    padding: '24px'
                  },
                  children: <PaymentScheduleContent/>,
                },
              },
              {
                title: 'Thông tin thanh toán',
                props: {
                  style: { position: 'relative', padding: '24px' },
                  children: <PaymentInfo/>,
                },
              },
              {
                type: 'sticky-bottom-transparent',
                props: {
                  style: {
                    position: 'sticky',
                    bottom: -44,
                    marginBottom: 0,
                    zIndex: 10,
                    // background: 'transparent'
                  },
                  children: <ActionFormBrnList />,
                },
              },
            ],
            props: { style: { position: 'relative' } },
          },
        ]}
        data-model="container"
      />
    </InvoiceProvider>
  )
}
