import { Text } from 'common/text'
import { StyledCommissionEmpty } from './_styled'

export const CommissionEmpty = ({ ...props }) => {
  return (
    <StyledCommissionEmpty {...props}>
      <img
        className="commission-empty__banner"
        src="/img/order/order-empty.png"
        alt="empty"
      />
      <Text as="b" color="#7C88A6" style={{ marginBottom: 16 }}> Không tìm thấy dữ liệu </Text>
    </StyledCommissionEmpty>
  )
}
