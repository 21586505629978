import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import styled from 'styled-components'

export const CellInvoice = ({
  id,
  name,
  phone,
  report,
  onReportClick,
  ...props
}) => {
  return (
    <div {...props}>
      {name && (
        <div>
          <Text
            as={"p"}
            target="_blank"
            color={!!phone ? THEME_SEMANTICS.delivering : ''}
            fontWeight={600}
            style={{cursor: 'pointer'}}
          >
            {name}{' '}
          </Text>
        </div>
      )}
      {phone && (
        <Text color="#7C88A6" fontSize={13} lineHeight={18}>
          {phone}
        </Text>
      )}
    </div>
  )
}

const StyledReportIcon = styled.i`
  width: 16px;
  height: 16px;
  margin-left: 4px;

  svg {
    width: 16px;
    height: 16px;

    transform: translateY(2px);

    cursor: pointer;
  }
`
