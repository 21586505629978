import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useCommissionFilterForm from 'Pages/commission/commissionTeacher/hooks/useCommissionFilterForm'
import { CommissionContext } from 'Pages/commission/commissionTeacher/provider/_context'
import { commissionActions } from 'Pages/commission/commissionTeacher/provider/_reducer'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { CommissionTags } from '../commissionTags'
import { CommissionDateTime } from './_dateTime'
import { CommissionSearch } from './_search'
import { StyledCommissionFilterForm } from './_styled'
import { CommissionCustomerInfo } from './_customerInfo'
import { GLOBAL_ICONS } from '../../../../../interface/icon'
import { OrderType } from './_orderType'

export const CommissionFilterForm = memo(({ ...props }) => {
  const { canSubmitOtherFilter, functions } = useCommissionFilterForm()
  const { pageState, pageDispatch } = useContext(CommissionContext)

  return (
    <StyledCommissionFilterForm {...props}>
      <div
        className="commission-filter-form__group commission-filter-form__collapse"
        data-collapse={true}
      >
        <CommissionSearch/>
        <CommissionCustomerInfo/>
        <OrderType/>
        <CommissionDateTime/>
        <div className="commission-filter-form__input-wide flex">
          <Button
            appearance="secondary"
            disabled={!canSubmitOtherFilter}
            size="md-"
            onClick={() =>
              canSubmitOtherFilter && functions.applyCommissionOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          <Button
            appearance="secondary"
            size="md-"
            onClick={() => functions.refresh(pageState?.pageOption?.value)}
            className={'commission-filter-form__btn-refresh'}
          >
            {GLOBAL_ICONS.repeat}
          </Button>
        </div>
      </div>
      <div className="commission-filter-form__group" style={{ marginBottom: 4 }}>
        <CommissionTags/>
      </div>
    </StyledCommissionFilterForm>
  )
})
