import React from 'react';
import {Tr} from "../../../../layouts/tableLayout/_tr"
import {Td} from "../../../../layouts/tableLayout/_td"
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const TableHeader = () => {
  const {t} = useTranslation()
  return (
    <StyledUseRoleTableHeader>
      <div className={'user-role-table_header'}>
        <Tr type="tHead">
          <Td className={"user-role-table__header-no"}>
            <p>{t('general_no')}</p>
          </Td>
          <Td className={"user-role-table__header-name"}>
            <p>{t('user_page_role')}</p>
          </Td>
          <Td className={"user-role-table__header-cell"}>
          </Td>
        </Tr>
      </div>
    </StyledUseRoleTableHeader>
  );
};

const StyledUseRoleTableHeader = styled.div`
  .user-role-table__header {
    &-no {
      font-weight: 600;
      width: 3.5rem;
      margin-left: 4px;
      min-height: 44px;
    }
    &-name {
      font-weight: 600;
      min-height: 44px;
      display: flex;
      flex: 1;
    }
    &-cell {
      min-height: 44px;
    }
  }
`

export default TableHeader;