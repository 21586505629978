import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useInvoiceFilterForm from 'Pages/invoice/hooks/useInvoiceFilterForm'
import { INVOICE_FILTER_TAG_FIELDS } from 'Pages/invoice/interfaces/_constants'
import { StyledInvoiceTags } from './_styled'
import { InvoiceTag } from './_tag'

export const InvoiceTags = ({ ...props }) => {
  const {
    dateTime,
    invoiceInfo,
    status,
    teacher,
    monthTime,
    functions,
  } = useInvoiceFilterForm()

  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledInvoiceTags {...props}>
      {invoiceInfo.activeValue && (
        <InvoiceTag
          onDelete={() => functions.filterTagDelete(INVOICE_FILTER_TAG_FIELDS[0])}
        >
          Mã phiếu chi: {invoiceInfo.activeValue}
        </InvoiceTag>
      )}

      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <InvoiceTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </InvoiceTag>
      )}
      {teacher?.activeValue?.name && (
        <InvoiceTag
          onDelete={() => functions.filterTagDelete(INVOICE_FILTER_TAG_FIELDS[3])}
        >
          Giáo viên: {teacher.activeValue.name}
        </InvoiceTag>
      )}
      {!!monthTime?.activeValue && (
        <InvoiceTag
          onDelete={() => functions.filterTagDelete(INVOICE_FILTER_TAG_FIELDS[4])}
        >
          Kỳ thanh toán: {monthTime.activeValue}
        </InvoiceTag>
      )}
      {status?.activeValue?.name && (
        <InvoiceTag
          onDelete={() => functions.filterTagDelete(INVOICE_FILTER_TAG_FIELDS[5])}
        >
          Trạng thái: {status.activeValue.name}
        </InvoiceTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledInvoiceTags>
  )
}
