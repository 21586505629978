import styled from 'styled-components'

export const StyledCommissionPanels = styled.div`
  margin: 24px -8px;

  display: flex;
  flex-wrap: wrap;

  .order-panels {
    &__item {
      width: calc(25% - 12px);
      margin: 0 6px;
    }
  }
`
