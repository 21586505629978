import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import { CommissionContext } from 'Pages/commission/commissionPrincipal/provider/_context'
import { useContext, useState } from 'react'
import { CommissionEmpty } from '../../commissionEmpty'
import { formatMoney } from 'util/functionUtil'
import { fNumber } from 'util/formatNumber'

export const TBodyByTeacher = () => {
  const { pageState } = useContext(CommissionContext)
  const { table } = pageState

  const displayList = table.display.list
  const displayLoading = table.display.loading
  const paginationTotalItems = table.pagination.totalItems
  return (
    <>
      {displayLoading ? (
        Array.from(Array(20), (e, i) => (
          <CommissionPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map(item => <CommissionTr key={item.id} data={item}/>)
      ) : (
        <CommissionEmpty/>
      )}
    </>
  )
}

const CommissionPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="commission-teacher-table__row">
      {Array.from(Array(8), (e, i) => (
        <Td key={i} className="commission-teacher-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const CommissionTr = ({ data, ...props }) => {
  return (
      <Tr
        {...props}
        className="commission-teacher-table__row"
      >
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{data?.teacher_name ? (data?.teacher_last_name + " " +  data?.teacher_name) : '---'}</Text>
        </Td>
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{fNumber(data?.count_orders)}</Text>
        </Td>
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{fNumber(data?.count_book_order)}</Text>
        </Td>
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{fNumber(data?.count_digital_order)}</Text>
        </Td>
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{formatMoney(data?.total_revenue)}</Text>
        </Td>
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{formatMoney((+data?.commission_principal + +data?.commission_teacher) || 0)}</Text>
        </Td>
        <Td className="commission-teacher-table__cell" data-type="td">
          <Text>{formatMoney(data?.commission_teacher)}</Text>
        </Td>
      </Tr>
  )
}
