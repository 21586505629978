import React from "react";
import {Input} from "Component/Input";
import {Text} from "common/text";
import {THEME_SEMANTICS} from "common/theme/_semantics";
import useProfileUpdate from "../../hooks/useProfileUpdate";

export const AccountNumber = () => {
   const {value} = useProfileUpdate()
   const {userData} = value
   return (
      <Input
         label={
            <>
               % hoa hồng <Text color={THEME_SEMANTICS.failed}>*</Text>
            </>
         }
         value={userData?.commission_rate}
         disabled={true}
         maxLength={32}
      />
   )
}