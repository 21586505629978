import {
  EXPORT_EXCEL_SELECTIVE_ACTIVE,
  EXPORT_RECEIPT_EXCEL_SELECTIVE,
  RECEIPT_FILTER_FORM_DATE_TIME_SORT_TYPES,
} from '../interfaces/_constants'

export const receiptInitialState = {
  filter: {
    receiptId: {
      value: null,
      activeValue: null
    },
    receiptInfo: {
      value: null,
      activeValue: null
    },
    dateTime: {
      activeValue: {
        end: null,
        start: null,
        type: RECEIPT_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
        value: null,
      },
      end: null,
      start: null,
      type: RECEIPT_FILTER_FORM_DATE_TIME_SORT_TYPES[0],
      trigger: true,
      value: null,
    },
    status: {
      activeValue: null,
      value: null,
    },
    receiptType: {
      activeValue: null,
      value: null,
    },
    monthTime: {
      activeValue: null,
      value: null,
    },
    search: {
      value: '',
    },
    principal: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
    employee: {
      activeValue: null,
      keyword: '',
      list: [],
      listOrigin: [],
      value: null,
    },
  },
  notifications: {
    list: [],
  },
  table: {
    display: {
      list: [],
      loading: true,
    },
    detail: {
      id: null,
      active: null,
      list: [],
    },
    qrHistory: {
      detail: {
        list: [],
        loading: true,
      },
    },
    pagination: {
      active: 0,
      amount: 20,
      total: 0,
      totalItems: 0,
    },
    selected: {
      list: [],
    },
  },
  printDetail: {},
  exportExcel: {
    list: EXPORT_RECEIPT_EXCEL_SELECTIVE,
    activeList: EXPORT_RECEIPT_EXCEL_SELECTIVE,
    selectedList: EXPORT_EXCEL_SELECTIVE_ACTIVE,
    keyword: '',
    hiddenList: EXPORT_RECEIPT_EXCEL_SELECTIVE
  },
  formEdit: {
    formId: '',
    paymentDate: {
      value: '',
      message: ''
    },
    paymentReference: {
      value: '',
      message: ''
    },
    paymentReason: {
      value: '',
      message: ''
    },
  },
  formCreate: {
    principal: {
      list: [],
      listOrigin: [],
      value: null,
      keyword: '',
    },
    trackingCode: '',
    monthTime: '',
    monthTimeInfo: {},
    monthId: '',
    trackingTitle: '',
    paymentValue: null,
    paymentMethod: {
      list: [
        { id: 1, name: 'Chuyển khoản', value: 'BANK_TRANSFER' },
        { id: 2, name: 'Tiền mặt', value: 'CASH' },
      ],
      value: null,
      extra: {
        bank_name: '',
        bank_number: '',
        bank_owner: '',
      },
    },
    paymentDate: '',
    paymentReference: '',
    paymentReason: '',
  },
  validateFormCreate: {},
  open_modal: false,
  loading: false,
}