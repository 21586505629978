import React, { useState } from 'react'
import './index.scss'
import { Box, Modal } from '@mui/material'
import {Button} from '../../common/button/index'

const Index = props => {
  const { txtConfirm } = props
  const [confirm, setConfirm] = useState(true)
  return (
    <>
      <Modal
        open={confirm}
        onClose={() => {
          props.handleConfirm(false)
          setConfirm(false)
        }}
        className={'modal-confirm'}
      >
        <Box className={'modal-confirm__box'}>
          <div>
            <p>Bạn đã thực hiện một số thay đổi. Bạn có chắc chắn muốn thoát khi dữ liệu chưa được lưu?</p>
            <div className={'modal-confirm__group-btn'}>

              <Button size='sm' className={'modal-confirm__dismiss'} appearance={'ghost'}
                onClick={() => {
                  props.handleConfirm(false)
                  setConfirm(false)
                }}
              >Hủy</Button>
              {/* <button className={'modal-confirm__dismiss'}
                      onClick={() => {
                        props.handleConfirm(false)
                        setConfirm(false)
                      }}
              >Hủy</button> */}
              <Button size='sm' className={'modal-confirm__save'}
                      onClick={() => {
                        props.handleConfirm(true)
                        setConfirm(false)
                      }}
              >{txtConfirm}</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default Index