import { Text } from 'common/text'
import { Tooltip } from 'common/tooltip'
import styled from 'styled-components'
import CountUp from 'react-countup'
import { GLOBAL_ICONS } from 'interface/icon'
import React, { useContext } from 'react'
import { DashboardContext } from '../../provider/_context'
import Skeleton from '@mui/material/Skeleton'

export const DashboardPanel = ({
  currency,
  title,
  titleTooltip,
  value,
  ...props
}) => {

  const { pageState, pageDispatch } = useContext(DashboardContext)
  const { loading } = pageState
  return (
    <StyledOrderPanel {...props}>
      <div className="order-panel__heading">
        {
          !!loading ?
            <Skeleton variant="text" width={120} height={34}/>
            : (
              <>
                <Text color="#7C88A6">{title}</Text>
                {titleTooltip && (
                  <Tooltip title={titleTooltip}>{GLOBAL_ICONS.question}</Tooltip>
                )}
              </>
            )
        }
      </div>
      {
        !!loading ?
          <Skeleton variant="text" width={120} height={34}/>
          :
          <Text as="h4" fontSize={16} lineHeight={22}>
            <CountUp start={0} end={value} duration={1} separator=","/> {currency}
          </Text>
      }
    </StyledOrderPanel>
  )
}

const StyledOrderPanel = styled.div`
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid #ebeef5;
  border-radius: 6px;

  .order-panel {
    &__heading {
      margin-bottom: 4px;

      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        margin-left: 4px;

        transform: translateY(2px);
      }
    }
  }
`
