import {useQrAffiliateAction, useQrCategoryAction, useQrEventAction, useQrProductAction} from './_action'

export const useQrAffiliateReducer = (state, action) => {
  switch (action.type) {
    case useQrAffiliateAction.UPDATE_QR_CODE:
      return {
        ...state,
        qrCode: {
          ...state.qrCode,
          ...action?.payload
        },
      }
    case useQrAffiliateAction.IS_LOADING_PANEL:
      return {
        ...state,
        loading: action?.payload
      }
    case useQrAffiliateAction.UPDATE_DASHBOARD:
      return {
        ...state,
        panels: action?.payload
      }

    case useQrAffiliateAction.SET_DISPLAY_SHARE_MODAL:
      return {
        ...state,
        shareModal: {
          ...state.shareModal,
          ...action?.payload
        }
      }


    case useQrAffiliateAction.FORM_CREATE_TAB_CHANGE:
      return {
        ...state,
        tab: {
          ...state.tab,
          activeTab: action.payload,
        },
      }

    // CATEGORY TAB

    case useQrCategoryAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        categoryTab: {
          ...state.categoryTab,
          table: {
            ...state.categoryTab.table,
            display: {
              ...state?.categoryTab?.table?.display,
              loading: action?.payload
            }
          },
        }
      }

    case useQrCategoryAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        categoryTab: {
          ...state.categoryTab,
          table: {
            ...state.categoryTab.table,
            display: {
              ...state?.categoryTab?.table?.display,
              list: action?.payload?.list || [],
              listDefault: action?.payload?.listDefault || [],
              loading: action?.payload?.loading
            }
          },
        }
      }
    case useQrCategoryAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        categoryTab: {
          ...state.categoryTab,
          table: {
            ...state.categoryTab.table,
            pagination: {
              ...state.categoryTab.table.pagination,
              active: action.payload?.active || 0,
              amount: action.payload?.amount || 20,
              total: action.payload?.total || 0,
              totalItems: action.payload?.totalItems || 0,
            },
          },
        }
      }
    case useQrCategoryAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        categoryTab: {
          ...state.categoryTab,
          filter: {
            ...state.categoryTab.filter,
            category: {
              ...state.categoryTab.filter.category,
              list: action.payload?.list,
              listOrigin: action.payload?.listOrigin,
            },
          },
        }
      }
    case useQrCategoryAction.FILTER_CATEGORY_LIST_UPDATE:
      return {
        ...state,
        categoryTab: {
          ...state.categoryTab,
          filter: {
            ...state.categoryTab.filter,
            category: {
              ...state.categoryTab.filter.category,
              list: action.payload,
            },
          },
        }
      }
    case useQrCategoryAction.FILTER_CATEGORY_VALUE_UPDATE:
      return {
        ...state,
        categoryTab: {
          ...state.categoryTab,
          filter: {
            ...state.categoryTab.filter,
            category: {
              ...state.categoryTab.filter.category,
              value: action.payload,
            },
          },
        }
      }
    case useQrCategoryAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
      return {
        ...state, categoryTab: {
          ...state.categoryTab,
          filter: {
            ...state.categoryTab.filter,
            category: {
              ...state.categoryTab.filter.category,
              activeValue: action.payload,
            },
          },
        }
      }
    // PRODUCT TAB

    case useQrProductAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            display: {
              ...state?.productTab?.table?.display,
              loading: action?.payload
            }
          },
        }
      }

    case useQrProductAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            display: {
              ...state?.productTab?.table?.display,
              list: action?.payload?.list || [],
              listDefault: action?.payload?.listDefault || [],
              loading: action?.payload?.loading
            }
          },
        }
      }
    case useQrProductAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          table: {
            ...state.productTab.table,
            pagination: {
              ...state.productTab.table.pagination,
              active: action.payload?.active || 0,
              amount: action.payload?.amount || 20,
              total: action.payload?.total || 0,
              totalItems: action.payload?.totalItems || 0,
            },
          },
        }
      }
    case useQrProductAction.FILTER_CATEGORY_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              list: action.payload?.list,
              listOrigin: action.payload?.listOrigin,
            },
          },
        }
      }
    case useQrProductAction.FILTER_SEARCH_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            search: {
              ...state.productTab.filter.search,
              value: action.payload
            },
          },
        }
      }
    case useQrProductAction.FILTER_CATEGORY_LIST_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              list: action.payload,
            },
          },
        }
      }
    case useQrProductAction.FILTER_CATEGORY_VALUE_UPDATE:
      return {
        ...state,
        productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              value: action.payload,
            },
          },
        }
      }
    case useQrProductAction.FILTER_CATEGORY_ACTIVE_VALUE_UPDATE:
      return {
        ...state, productTab: {
          ...state.productTab,
          filter: {
            ...state.productTab.filter,
            category: {
              ...state.productTab.filter.category,
              activeValue: action.payload,
            },
          },
        }
      }

     // EVENT TAB

    case useQrEventAction.TABLE_LOADING_DISPLAY:
      return {
        ...state,
        eventTab: {
          ...state.eventTab,
          table: {
            ...state.eventTab.table,
            display: {
              ...state?.eventTab?.table?.display,
              loading: action?.payload
            }
          },
        }
      }

    case useQrEventAction.TABLE_UPDATE_DISPLAY_LIST:
      return {
        ...state,
        eventTab: {
          ...state.eventTab,
          table: {
            ...state.eventTab.table,
            display: {
              ...state?.eventTab?.table?.display,
              list: action?.payload?.list || [],
              listDefault: action?.payload?.listDefault || [],
              loading: action?.payload?.loading
            }
          },
        }
      }
    case useQrEventAction.TABLE_UPDATE_PAGINATION:
      return {
        ...state,
        eventTab: {
          ...state.eventTab,
          table: {
            ...state.eventTab.table,
            pagination: {
              ...state.eventTab.table.pagination,
              active: action.payload?.active || 0,
              amount: action.payload?.amount || 20,
              total: action.payload?.total || 0,
              totalItems: action.payload?.totalItems || 0,
            },
          },
        }
      }
    case useQrEventAction.FILTER_EVENT_LIST_ORIGIN_UPDATE:
      return {
        ...state,
        eventTab: {
          ...state.eventTab,
          filter: {
            ...state.eventTab.filter,
            event: {
              ...state.eventTab.filter.event,
              list: action.payload?.list,
              listOrigin: action.payload?.listOrigin,
            },
          },
        }
      }
    case useQrEventAction.FILTER_EVENT_LIST_UPDATE:
      return {
        ...state,
        eventTab: {
          ...state.eventTab,
          filter: {
            ...state.eventTab.filter,
            event: {
              ...state.eventTab.filter.event,
              list: action.payload,
            },
          },
        }
      }
    case useQrEventAction.FILTER_EVENT_VALUE_UPDATE:
      return {
        ...state,
        eventTab: {
          ...state.eventTab,
          filter: {
            ...state.eventTab.filter,
            event: {
              ...state.eventTab.filter.event,
              value: action.payload,
            },
          },
        }
      }
    case useQrEventAction.FILTER_EVENT_ACTIVE_VALUE_UPDATE:
      return {
        ...state, eventTab: {
          ...state.eventTab,
          filter: {
            ...state.eventTab.filter,
            event: {
              ...state.eventTab.filter.event,
              activeValue: action.payload,
            },
          },
        }
      }
    default:
      break
  }
}