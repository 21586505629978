import { PageHeader } from "layouts/pageHeader";
import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {CREATE_TEACHER_BREARDCUM, EDIT_TEACHER_BREARDCUM} from "../../interfaces/constant";
import {CreateTeacherContext} from "../../provider/_context";
import {useParams} from "react-router-dom";

const Index = () => {
    const {t} = useTranslation()
    const {teacherId} = useParams()
    return (
        <StyledProductGroupHeader>
            <div className={"teacher-header"} >
                <PageHeader
                    breadcrumbLinks={!!teacherId ?  EDIT_TEACHER_BREARDCUM : CREATE_TEACHER_BREARDCUM}
                    breadcrumbTitle={ !!teacherId ? 'Chỉnh sửa giáo viên' : 'Thêm mới giáo viên'}
                />
            </div>
        </StyledProductGroupHeader>
    )
}
export default Index;
const StyledProductGroupHeader = styled.div``