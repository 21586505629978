
import React from 'react'

const LoadDisconnectImage = () => {
  return (
    <img
      style={{ display: 'none'}}
      src={'/img/no-connection/no-connection.png'}
      alt="no-connection"
    />
  )
}

export default LoadDisconnectImage;
