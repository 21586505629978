import { sendRequestAuth } from 'api/api'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useReducer } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  commissionActions,
  commissionReducer,
} from '../provider/_reducer'

import {
  commissionInitialState,
} from '../provider/_initState'

const useCommission = () => {
  const [searchParams] = useSearchParams()
  const nav = useNavigate()
  const [state, dispatch] = useReducer(commissionReducer, commissionInitialState)
  const { filter, table } = state

  const filterQueries = {
    date_type: filter.dateTime?.activeValue?.type?.value || '',
    start_date:
      filter.dateTime.activeValue?.start && filter.dateTime.activeValue.value
        ? convertDateTimeToApiFormat(
        filter.dateTime.activeValue.value.split(' - ')[0],
        )
        : '',
    end_date:
      filter.dateTime.activeValue?.end && filter.dateTime.activeValue.value
        ? convertDateTimeToApiFormat(
        filter.dateTime.activeValue.value.split(' - ')[1],
        )
        : '',
    type: filter.orderType.activeValue?.value?.value || '',
    principal_id: filter.principal.activeValue?.value?.value || '',
    teacher_id: filter.teacher.activeValue?.value?.value || '',
    order_id: filter.orderId.activeValue || '',
    customer: filter.customerInfo.activeValue || '',
  }

  const handleOriginFetch = async () => {
    const startDate = convertDateTimeToApiFormat(
      filter.dateTime.activeValue.value.split(' - ')[0],
    )
    const endDate = convertDateTimeToApiFormat(
      filter.dateTime.activeValue.value.split(' - ')[1],
    )
    const querySearch = searchParams.get('search') || ''
    if (querySearch)
      dispatch({
        type: commissionActions.FILTER_ACTIVE_DATE_TIME_UPDATE,
        payload: {
          end: '',
          start: '',
          type: filter.dateTime.type,
          value: '',
        },
      })
    const response = await sendRequestAuth(
      'get',
      `${config.API}/report/teacher/commission/date?order_id=&type=&customer=&order_status=&payment_status=&payment_method=&principal_id=&teacher_id=&start_date=${startDate}&end_date=${endDate}&start=0&per_page=20`,
    )

    if (!!response?.data?.success) {
      const displayListData = Array.isArray(response?.data?.data)
        ? response.data.data
        : []
      // set default value for input filter
      dispatch({
        type: commissionActions.FILTER_SEARCH_UPDATE,
        payload: { value: querySearch },
      })
      // update display list
      dispatch({
        type: commissionActions.TABLE_AMOUNT_UPDATE,
        payload: {
          display: {
            list: displayListData,
          },
          pagination: {
            totalItems: response?.data?.meta?.total || 0,
            active: 0,
            amount: 20,
            total: response?.data?.meta?.total
              ? Math.ceil(
                response?.data?.meta?.total / 20,
              )
              : 0,
          },
        },
      })
      dispatch({
        type: commissionActions.PANELS_UPDATE,
        payload: response?.data?.meta
      })
    }

    if (!querySearch) {
      dispatch({
        type: commissionActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })
    }
  }

  const handlePaginationAmountChange = async n => {
    dispatch({
      type: commissionActions.TABLE_SELECTED_LIST_UPDATE,
      payload: { selected: { list: [] } },
    })
    dispatch({
      type: commissionActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: true } } },
    })

    const currentPage = table.pagination.active || 0
    const totalPages = Math.ceil(table.pagination.totalItems / n)
    const page =
      table.pagination.totalItems < currentPage * n
        ? totalPages - 1
        : currentPage

    let queryStr = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...filterQueries,
      per_page: n,
      start: page * n,
    })) {
      queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await sendRequestAuth(
      'get',
      `${config.API}/report/teacher/commission/${state?.pageOption?.value||'date'}${queryStr}`,
    )

    if (!!response?.data?.success) {
      const displayListData = Array.isArray(response?.data?.data)
        ? response.data.data
        : []
      dispatch({
        type: commissionActions.TABLE_AMOUNT_UPDATE,
        payload: {
          display: {
            list: displayListData,
          },
          pagination: { active: page, amount: n, totalItems: response?.data?.meta?.total || 0, total: totalPages },
        },
      })

    }

    dispatch({
      type: commissionActions.TABLE_DISPLAY_DETAIL_UPDATE,
      payload: { active: null },
    })

    dispatch({
      type: commissionActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: false } } },
    })
  }

  const handlePaginationPageChange = async page => {
    dispatch({
      type: commissionActions.TABLE_SELECTED_LIST_UPDATE,
      payload: { selected: { list: [] } },
    })
    dispatch({
      type: commissionActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: true } } },
    })

    const amount = table.pagination?.amount || 20

    let queryStr = '?'
    let i = 0
    for (const [key, value] of Object.entries({
      ...filterQueries,
      per_page: amount,
      start: page * amount,
    })) {
      queryStr += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await sendRequestAuth(
      'get',
      `${config.API}/report/teacher/commission/${state?.pageOption?.value||'date'}${queryStr}`,
    )

    if (!!response?.data?.success) {
      const displayListData = Array.isArray(response?.data?.data)
        ? response.data.data
        : []

      dispatch({
        type: commissionActions.TABLE_PAGINATION_UPDATE,
        payload: {
          display: {
            list: displayListData,
          },
          pagination: { active: page },
        },
      })
    }

    dispatch({
      type: commissionActions.TABLE_DISPLAY_DETAIL_UPDATE,
      payload: { active: null },
    })

    dispatch({
      type: commissionActions.TABLE_DISPLAY_LOADING_UPDATE,
      payload: { table: { display: { loading: false } } },
    })
  }

  return {
    fetch: {
      origin: handleOriginFetch,
    },
    pagination: {
      onAmountChange: handlePaginationAmountChange,
      onPageChange: handlePaginationPageChange,
    },
    provider: { state, dispatch },
  }
}

export default useCommission
