import styled from 'styled-components'

export const StyledDashboardPanels = styled.div`
  background: #fff;
  display: flex;
  flex-wrap: wrap;

  .order-panels {
    &__item {
      width: calc(20% - 16px);
      margin: 8px 8px;
    }
  }
`
