import { useContext, useState } from 'react'
import { QrAffiliateContext} from '../provider/_context'
import { useQrProductAction } from '../provider/_action'
import { sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { removeAcent } from 'common/fieldText/_functions'

export const useQrProductTab = () => {
  const { pageState, pageDispatch } = useContext(QrAffiliateContext)
  const { productTab } = pageState
  const { filter } = productTab

  const searchActiveValue = filter.search.activeValue
  const searchValue = filter.search.value

  const handleSearchChange = value => {
    pageDispatch({
      type: useQrProductAction.FILTER_SEARCH_UPDATE,
      payload: value,
    })
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // CATEGORY FILTER
  // ===== ===== ===== ===== =====
  const categoryList = filter?.category?.list
  const categoryListOrigin = filter?.category?.listOrigin
  const categoryValue = filter?.category?.value
  const categoryActiveValue = filter?.category?.activeValue

  const handleCategoryChange = value => {
    pageDispatch({
      type: useQrProductAction.FILTER_CATEGORY_VALUE_UPDATE,
      payload: value
    })
  }

  const handleCategoryKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const categoryListData = categoryListOrigin.filter(item => {
      const formatNameItem = item?.title
        ? removeAcent(item?.title.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    pageDispatch({
      type: useQrProductAction.FILTER_CATEGORY_LIST_UPDATE,
      payload: categoryListData
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====


  const queries = {
    category_id: categoryValue?.id || '',
    keyword: searchValue || '',
    per_page: productTab?.table?.pagination?.amount || 20,
    start: (productTab?.table?.pagination?.active * productTab?.table?.pagination?.amount) || 0,
  }

  const applyOtherFilter = async () => {
    const qs = {
      ...queries,
      per_page: productTab?.table?.pagination?.amount,
      start: 0
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyProduct(queryString)
  }


  const handleAmountChange = async n => {
    const qs = { ...queries, per_page: n, start: 0 }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyProduct(queryString)

  }

  const handlePageChange = async page => {
    const amount = productTab?.table?.pagination?.amount || 20
    const qs = {
      ...queries,
      per_page: productTab?.table?.pagination?.amount,
      start: page * amount
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyProduct(queryString)
  }

  const fetchQrbyProduct = async (qs) => {
    pageDispatch({
      type: useQrProductAction.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/qrcode/products${qs}`)
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: useQrProductAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const tearchers = response[0]?.data
      pageDispatch({
        type: useQrProductAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: tearchers?.data,
          listDefault: tearchers?.data,
          loading: false
        }
      })
      pageDispatch({
        type: useQrProductAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(tearchers?.meta?.start / tearchers?.meta?.per_page),
          amount: tearchers?.meta?.per_page,
          total: Math.ceil(tearchers?.meta?.total / tearchers?.meta?.per_page),
          totalItems: tearchers?.meta?.total,
        }
      })

      pageDispatch({
        type: useQrProductAction.TABLE_SELECTED_LIST_UPDATE,
        payload: {selected: {list: []}},
      })
    }
  }
  return {
    table: pageState.productTab.table,
    queries,
    search: {
      value: searchValue,
      activeValue: searchActiveValue,
      onChange: handleSearchChange,
    },
    categoryFilter: {
      value: categoryValue,
      activeValue: categoryActiveValue,
      list: categoryList,
      onChange: handleCategoryChange,
      onKeywordChange: handleCategoryKeywordChange
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    },

    methods: {
      applyOtherFilter,
    },
  }
}