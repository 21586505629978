import React from "react";
import {Input} from "../../../../../../Component/Input";
import {Text} from "../../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../../common/theme/_semantics";
import {useCreateTeacherForm} from "../../hook/useCreateTeacherForm";

export const LastName = ()=>{
    const {lastName} = useCreateTeacherForm()
    const {methods} = lastName
    return (
        <Input
            label={
                <>
                    Họ
                </>
            }
            value={lastName?.value}
            onChange={e => methods.onChange(e.target?.value)}
            onBlur={e => methods.onBlur(e.target?.value)}
            validateText={lastName?.message}
            validateType={lastName?.status ? 'danger' : ''}
            placeholder={'Vui lòng nhập họ'}
            // maxLength={255}
        />
    )
}