import {TAG_DELETE} from "../interface";
import {useCreateTeacherAction} from "../component/createPrincipal/provider/_reducer";

export const useTeacherAction = {
    FILTER_SEARCH_UPDATE: 'FILTER_SEARCH_UPDATE',
    FILTER_SEARCH_ACTIVE_UPDATE: 'FILTER_SEARCH_ACTIVE_UPDATE',
    FILTER_STATUS_VALUE_UPDATE: 'FILTER_STATUS_VALUE_UPDATE',
    FILTER_STATUS_ACTIVE_VALUE_UPDATE: 'FILTER_STATUS_ACTIVE_VALUE_UPDATE',
    FILTER_TAG_DELETE: 'FILTER_TAG_DELETE',


    TABLE_LOADING_DISPLAY: 'TABLE_LOADING_DISPLAY',
    TABLE_UPDATE_DISPLAY_LIST: 'TABLE_UPDATE_DISPLAY_LIST',
    TABLE_UPDATE_PAGINATION: 'TABLE_UPDATE_PAGINATION',
    TABLE_DISPLAY_DETAIL_ID_UPDATE: 'TABLE_DISPLAY_DETAIL_ID_UPDATE',
    TABLE_DISPLAY_DETAIL_UPDATE: 'TABLE_DISPLAY_DETAIL_UPDATE',
    TABLE_SELECTED_LIST_UPDATE: 'TABLE_SELECTED_LIST_UPDATE',

    MODAL_IMPORT_TEACHER: 'MODAL_IMPORT_TEACHER',
    MODAL_IMPORT_RESULT_OPEN: 'MODAL_IMPORT_RESULT_OPEN',
    MODAL_IMPORT_RESULT_DATA: 'MODAL_IMPORT_TEACHER',

    FORM_DELETE_UPDATE: "FORM_DELETE_UPDATE",
    FORM_DELETE_OPEN_CONFIRM: "FORM_DELETE_OPEN_CONFIRM",
}
export const useTeacherInitialState = {
    filter: {
        search: {
            value: null,
            active: null
        },
        status: {
            activeValue: null,
            value: null,
        },
    },
    table: {
        display: {
            list: [],
            listDefault: [],
            loading: true,
        },
        pagination: {
            active: 0,
            amount: 20,
            total: 3,
            totalItems: 59,
        },
        selected: {
            list: [],
        },
        detail: {
            id: null,
            active: null,
            list: [],
        },
    },
    importExcel: {
        open: false,
        result: {
            open: false,
            data: null
        },
    },

    deleteConfirm: {
        open: false,
        value: '',
        status: false,
        message: '',
        id: ''
    }
}

export const useTeacherReducer = (state, action) => {
    switch (action.type) {
        case useTeacherAction.FILTER_SEARCH_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    search: {
                        ...state.filter.search,
                        ...action.payload,
                    }
                },
            }
        case useTeacherAction.FILTER_SEARCH_ACTIVE_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    search: {
                        ...state.filter.search,
                        ...action.payload,
                    }
                },
            }

        case useTeacherAction.FILTER_STATUS_VALUE_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    status: {
                        ...state.filter.status,
                        value: action.payload,
                    },
                },
            }
        case useTeacherAction.FILTER_STATUS_ACTIVE_VALUE_UPDATE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    status: {
                        ...state.filter.status,
                        activeValue: action.payload,
                    },
                },
            }
        case useTeacherAction.FILTER_TAG_DELETE:
            switch (action.payload) {
                case TAG_DELETE[0]:
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            search: {
                                ...state.filter.search,
                                active: '',
                                value: ''
                            },
                        },
                    }
                case TAG_DELETE[1]:
                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            status: {
                                ...state.filter.status,
                                activeValue: null,
                                value: null
                            },
                        },
                    }
                default:
                    return {...state};
            }
        case useTeacherAction.TABLE_LOADING_DISPLAY:
            return {
                ...state,
                table: {
                    ...state.table,
                    display: {
                        ...state?.table?.display,
                        loading: action?.payload
                    }
                },
            }
        case useTeacherAction.TABLE_UPDATE_DISPLAY_LIST:
            return {
                ...state,
                table: {
                    ...state.table,
                    display: {
                        ...state?.table?.display,
                        list: action?.payload?.list || [],
                        listDefault: action?.payload?.listDefault || [],
                        loading: action?.payload?.loading
                    }
                },
            }
        case useTeacherAction.TABLE_UPDATE_PAGINATION:
            return {
                ...state,
                table: {
                    ...state.table,
                    pagination: {
                        ...state.table.pagination,
                        active: action.payload?.active || 0,
                        amount: action.payload?.amount || 20,
                        total: action.payload?.total || 0,
                        totalItems: action.payload?.totalItems || 0,
                    },
                },
            }
        case useTeacherAction.TABLE_DISPLAY_DETAIL_ID_UPDATE:
            return {
                ...state,
                table: {
                    ...state.table,
                    detail: {
                        ...state.table.detail,
                        id: action.payload?.id || null,
                    },
                },
            }
        case useTeacherAction.TABLE_DISPLAY_DETAIL_UPDATE:
            return {
                ...state,
                table: {
                    ...state.table,
                    detail: {
                        ...state.table.detail,
                        active: action.payload?.active || null,
                        list: action.payload?.list || [],
                    },
                },
            }
        case useTeacherAction.TABLE_SELECTED_LIST_UPDATE:
            return {
                ...state,
                table: {
                    ...state.table,
                    selected: {
                        ...state.table.selected,
                        list: action.payload?.selected?.list || [],
                    },
                },
            }

        case useTeacherAction.MODAL_IMPORT_TEACHER:
            return {
                ...state,
                importExcel: {
                    ...state.importExcel,
                    open: action.payload
                }
            }
        case useTeacherAction.MODAL_IMPORT_RESULT_OPEN:
            return {
                ...state,
                importExcel: {
                    ...state.importExcel,
                    result: {
                        ...state.importExcel.result,
                        open: action.payload?.open,
                        data: action.payload?.data || null
                    }
                }
            }
        case useTeacherAction.MODAL_IMPORT_RESULT_DATA:
            return {
                ...state,
                importExcel: {
                    ...state.importExcel,
                    result: {
                        ...state.importExcel.result,
                        data: action.payload
                    }
                }
            }
        case useTeacherAction.FORM_DELETE_UPDATE:
            return {
                ...state,
                deleteConfirm: {
                    ...state.deleteConfirm,
                    ...action.payload,
                },
            }
        case useTeacherAction.FORM_DELETE_OPEN_CONFIRM:
            return {
                ...state,
                deleteConfirm: {
                    ...state.deleteConfirm,
                    ...action.payload,
                    value: '',
                    status: false,
                    message: ''
                },
            }
        default:
            break
    }
}