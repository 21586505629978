import { useContext, useState } from 'react'
import { QrAffiliateContext} from '../provider/_context'
import { useQrEventAction } from '../provider/_action'
import { sendRequestAuth } from '../../../api/api'
import config from '../../../config'
import { removeAcent } from 'common/fieldText/_functions'
import useAlert from 'hook/useAlert'

export const useQrEventTab = () => {
  const { pageState, pageDispatch } = useContext(QrAffiliateContext)
  const {showAlert} = useAlert()

  const { eventTab } = pageState
  const { filter } = eventTab

// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // EVENT FILTER
  // ===== ===== ===== ===== =====
  const eventList = filter?.event?.list
  const eventListOrigin = filter?.event?.listOrigin
  const eventValue = filter?.event?.value
  const eventActiveValue = filter?.event?.activeValue

  const handleEventChange = value => {
    pageDispatch({
      type: useQrEventAction.FILTER_EVENT_VALUE_UPDATE,
      payload: value
    })
    applyOtherFilter(value?.id || "")
  }

  const handleEventKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const eventListData = eventListOrigin.filter(item => {
      const formatNameItem = item?.title
        ? removeAcent(item?.title.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue?.trim())) return true
      return false
    })
    pageDispatch({
      type: useQrEventAction.FILTER_EVENT_LIST_UPDATE,
      payload: eventListData
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====


  const queries = {
    event_id: eventValue?.id || '',
    per_page: eventTab?.table?.pagination?.amount || 20,
    start: (eventTab?.table?.pagination?.active * eventTab?.table?.pagination?.amount) || 0,
  }

  const applyOtherFilter = async (eventId= "") => {
    const qs = {
      ...queries,
      event_id: eventId,
      per_page: eventTab?.table?.pagination?.amount,
      start: 0
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyEvent(queryString)
  }


  const handleAmountChange = async n => {
    const qs = { ...queries, per_page: n, start: 0 }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyEvent(queryString)

  }

  const handlePageChange = async page => {
    const amount = eventTab?.table?.pagination?.amount || 20
    const qs = {
      ...queries,
      per_page: eventTab?.table?.pagination?.amount,
      start: page * amount
    }
    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }
    fetchQrbyEvent(queryString)
  }

  const fetchQrbyEvent = async (qs) => {
    pageDispatch({
      type: useQrEventAction.TABLE_LOADING_DISPLAY,
      payload: true
    })
    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/qrcode/events${qs}`)
    ])
    if (response[0]?.data?.success) {
      pageDispatch({
        type: useQrEventAction.TABLE_LOADING_DISPLAY,
        payload: false
      })
      const tearchers = response[0]?.data
      pageDispatch({
        type: useQrEventAction.TABLE_UPDATE_DISPLAY_LIST,
        payload: {
          list: tearchers?.data,
          listDefault: tearchers?.data,
          loading: false
        }
      })
      pageDispatch({
        type: useQrEventAction.TABLE_UPDATE_PAGINATION,
        payload: {
          active: Math.ceil(tearchers?.meta?.start / tearchers?.meta?.per_page),
          amount: tearchers?.meta?.per_page,
          total: Math.ceil(tearchers?.meta?.total / tearchers?.meta?.per_page),
          totalItems: tearchers?.meta?.total,
        }
      })

      pageDispatch({
        type: useQrEventAction.TABLE_SELECTED_LIST_UPDATE,
        payload: {selected: {list: []}},
      })
    }
  }
  return {
    table: pageState.eventTab.table,
    queries,
    eventFilter: {
      value: eventValue,
      activeValue: eventActiveValue,
      list: eventList,
      onChange: handleEventChange,
      onKeywordChange: handleEventKeywordChange
    },
    pagination: {
      onAmountChange: handleAmountChange,
      onPageChange: handlePageChange,
    },

    methods: {
      applyOtherFilter,
    },
  }
}