import React from "react";
import styled from 'styled-components'
import {Grid} from "@mui/material";
import {AccountNumber} from "./_accountNumber";

export const InfoCommission = () => {
    return (
        <StyleInfoCommission>
            <Grid container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} item>
                    <AccountNumber/>
                </Grid>
            </Grid>
        </StyleInfoCommission>
    )
}
const StyleInfoCommission = styled.div``