import {Text} from 'common/text'
import { CustomToolTip } from 'Component/tooltip/CustomTooltip'
import styled from 'styled-components'
import { GLOBAL_ICONS } from '../../../../interface/icon'

export const CellCodeInvoice = ({id, inventory, time, ...props}) => {
  return (
    <StyledCellCodeInvoice {...props}>
      {id && (
        <div className="cell-code-invoice__upper">
          <Text as="b" className="cell-code-invoice__id">
            {id}
          </Text>
        </div>
      )}
      {time && (
        <div className="cell-code-invoice__under">
          <CustomToolTip title={'Ngày tạo khách'}>{GLOBAL_ICONS.clock}</CustomToolTip>
          <Text
            className="cell-code-invoice__time"
            color="#7C88A6"
            fontSize={13}
            lineHeight={18}
          >
            {time}
          </Text>
        </div>
      )}
    </StyledCellCodeInvoice>
  )
}

const StyledCellCodeInvoice = styled.div`
  .cell-code-invoice {
    &__upper,
    &__under {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;

        cursor: pointer;
      }
    }

    &__id {
      margin-right: 4px;
    }

    &__time {
      margin-left: 4px;
    }
  }
`
