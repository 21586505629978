export const IMPORT_ICON = {
    delete:(
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.7266 3.77734L7.99992 7.50401L4.27325 3.77734L3.33325 4.71734L7.05992 8.44401L3.33325 12.1707L4.27325 13.1107L7.99992 9.38401L11.7266 13.1107L12.6666 12.1707L8.93992 8.44401L12.6666 4.71734L11.7266 3.77734Z"
                fill="#7D9AC0"
            />
        </svg>
    )
}
