import React, { createContext, useReducer, useContext, useEffect } from 'react'

// import Navigation from './Component/SideMenu/SideMenu';
import Navigation from 'Component/NavBar/navBar'

const LayoutWrapper = (params) => {
  return (
    <div className="App">
      <Navigation/>
    </div>
  )
}
export default LayoutWrapper