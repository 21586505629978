import {CUSTOMER_TABLE_CELL_SHIPPING_STATUSES} from 'Pages/customer/interfaces/_constants'
import styled from 'styled-components'

export const CellStatusCustomer = ({id, ...props}) => {
  return (
    <StyledCellStatusCustomer
      {...props}
      style={{
        background: CUSTOMER_TABLE_CELL_SHIPPING_STATUSES[`${id}`].background,
        color: CUSTOMER_TABLE_CELL_SHIPPING_STATUSES[`${id}`].color,
      }}
    >
      {props?.children}
    </StyledCellStatusCustomer>
  )
}

const StyledCellStatusCustomer = styled.div`
  padding: 3px 12px;

  display: inline-block;

  background: #eff3fb;
  border-radius: 4px;

  color: #7c88a6;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`
