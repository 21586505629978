import {useReducer} from 'react'
import {profileInitialState} from '../provider/~initState'
import {profileReducer} from '../provider/~reducer'
import {profileActions} from '../provider/~action'
import * as href from "../../../api/url";
import {sendRequest} from "../../../api/api";

const useProfile = () => {
  const [state, dispatch] = useReducer(profileReducer, profileInitialState)

  const handleOriginFetch = async () => {
    const url = href.getUrlUserInfo()
    const res = await sendRequest('get', url)
    if (!!res.data?.user_id) {
      dispatch({
        type: profileActions.FORM_INIT_USER_DATA, payload: {
          userData: res.data
        }
      })
    }
  }

  return {
    fetch: {
      origin: handleOriginFetch
    },
    provider: {
      state,
      dispatch
    }
  }
}
export default useProfile