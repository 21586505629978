import React from "react";
import {Grid} from "@mui/material";
import {UserName} from "./infoPerson/_userName";
import {StatusPerson} from "./infoPerson/_statusPerson";
import {Password} from "./infoPerson/_password";
import {PasswordConfirm} from "./infoPerson/_passwordConfirm";
import {useParams} from "react-router-dom";

const UserAccount = ({...props}) => {

    const {teacherId} = useParams()

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} lg={12} item>
                <UserName/>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} item>
                <StatusPerson/>
            </Grid>
            {!!!teacherId && <Grid xs={12} sm={12} md={12} lg={12} item>
                <Password/>
            </Grid>}
            {!!!teacherId && <Grid xs={12} sm={12} md={12} lg={12} item>
                <PasswordConfirm/>
            </Grid>}
        </Grid>
    )
}
export default UserAccount;