import { sendRequestAuth } from 'api/api'
import { Button } from 'common/button'
import config from 'config'
import useCommissionFilterForm from 'Pages/commission/commissionPrincipal/hooks/useCommissionFilterForm'
import { CommissionContext } from 'Pages/commission/commissionPrincipal/provider/_context'
import { commissionActions } from 'Pages/commission/commissionPrincipal/provider/_reducer'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { CommissionTags } from '../commissionTags'
import { CommissionDateTime } from './_dateTime'
import { CommissionSearch } from './_search'
import { StyledCommissionFilterForm } from './_styled'
import { CommissionCustomerInfo } from './_customerInfo'
import { CommissionTeacher } from './_teacher'
import { GLOBAL_ICONS } from '../../../../../interface/icon'
import { OrderType } from './_orderType'
import useGlobalContext from "../../../../../containerContext/storeContext";

export const CommissionFilterForm = memo(({ ...props }) => {
  const { canSubmitOtherFilter, functions } = useCommissionFilterForm()
  const { pageState, pageDispatch } = useContext(CommissionContext)
  const [globalState, globalDispatch] = useGlobalContext()
  const {user} = globalState
  const userId = user?.user_id

  useEffect(() => {
    const fetchData = async () => {
      const response = await Promise.all([
        sendRequestAuth(
          'get',
          `${
            config.API
          }/commission/filter/teachers?principal_id=${userId}`,
        )
      ])
      pageDispatch({
        type: commissionActions.FILTER_ORIGIN_DATA_UPDATE,
        payload: {
          teacher: {
            list: response[0].data.map(item => ({
              data: item || null,
              name: (item?.last_name + ' ' + item?.name) || '',
              value: item?.id || '',
            })),
          },
        },
      })
    }

    fetchData()
  }, [])

  return (
    <StyledCommissionFilterForm {...props}>
      <div
        className="commission-filter-form__group commission-filter-form__collapse"
        data-collapse={true}
      >
        <CommissionSearch/>
        <CommissionCustomerInfo/>
        <OrderType/>
        <CommissionTeacher/>
        <CommissionDateTime/>
        <div className="commission-filter-form__input-wide flex">
          <Button
            appearance="secondary"
            disabled={!canSubmitOtherFilter}
            size="md-"
            onClick={() =>
              canSubmitOtherFilter && functions.applyCommissionOtherFilter()
            }
          >
            Tìm kiếm
          </Button>
          <Button
            appearance="secondary"
            size="md-"
            onClick={() => functions.refresh(pageState?.pageOption?.value)}
            className={'commission-filter-form__btn-refresh'}
          >
            {GLOBAL_ICONS.repeat}
          </Button>
        </div>
      </div>
      <div className="commission-filter-form__group" style={{ marginBottom: 4 }}>
        <CommissionTags/>
      </div>
    </StyledCommissionFilterForm>
  )
})
