import React from 'react'
export const TableProductHeader = () => {
  return (
     <div className="row-tab-detail__thead">
        <div className="row-tab-detail__tr">
           <div className="row-tab-detail__th">Sản phẩm</div>
           <div className="row-tab-detail__th">Loại sản phẩm</div>
           <div className="row-tab-detail__th">Số lượng mua</div>
           <div className="row-tab-detail__th">Đơn hàng cuối cùng</div>
           <div className="row-tab-detail__th"></div>
        </div>
     </div>
  )
}